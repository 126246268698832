import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../hooks';
import { useField } from '@unform/core';
import { EstoqueOperacoesService } from '../services/api/estoque/estoqueOperacoes/EstoqueOperacoesService';

type TAutoCompleteOption = {
  idoperacoes: string;
  label: string;
  faturamento: boolean;
  venda: boolean;
  ajuste: boolean;
  tipo: number;
} 

interface IAutoCompleteOperacoesProps {
  isExternalLoading?: boolean;
  disabled?: boolean;
  faturamento?: (novoTextoselect: boolean| undefined) => void;
  venda?: (novoTextoselect: boolean| undefined) => void;
  tipo?: (novoTextoselect: number | undefined) => void;
  ajuste?: (novoTextoselect: boolean| undefined) => void;
  operacao?: (novoTextoselect: string| undefined) => void;
}

export const AutoCompleteOperacoes01: React.FC<IAutoCompleteOperacoesProps> = ({ isExternalLoading = false, disabled = false, faturamento, venda, tipo, ajuste, operacao}) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField('idoperacoes');
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      EstoqueOperacoesService.getAlllist(busca, selectedId, 3)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(operacoes => ({ idoperacoes: operacoes.idoperacoes, label: operacoes.descricao, tipo: operacoes.tipo, operacao: operacoes.descricao,  faturamento: operacoes.faturamento, venda: operacoes.venda, ajuste: operacoes.ajuste})));
          }
        });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idoperacoes === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);


  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      loading={isLoading}
      disabled={disabled}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idoperacoes); faturamento?.(newValue?.faturamento); ajuste?.(newValue?.ajuste); venda?.(newValue?.venda);
        tipo?.(newValue?.tipo); operacao?.(newValue?.label); setBusca(''); clearError(); }}
      popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'    
          label="Operações"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};