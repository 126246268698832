
export const MenuFaturamento =  [
  {
    icon: 'signal_cellular_alt',
    path: '/dashboardFaturamento',
    label: 'pagina inicial',
  },
  {
    icon: 'add_shopping_cart',
    path: '/romaneios',
    label: 'Romaneios',
  },
  {
    icon: 'print',
    path: '/rromaneios',
    label: 'Relatório de Romaneios',
  },
  {
    icon: 'print',
    path: '/rproducao',
    label: 'Produção',
  },

];