import React, { useEffect, useRef, useCallback } from 'react';
import ApexCharts from 'apexcharts';

interface ChartOptions {
  series: number[];
  chart: {
    type: string;
    width?: string;
    height?: string;
  };
  labels: string[];
  title?: {
    text?: string;
  },
  colors: string[];
  theme?: {
    mode?: 'dark' | 'light' | undefined;
  };
  responsive?: {
    breakpoint: number;
    options: {
      chart: {
        width: string;
        height: string;
      };
      legend: {
        position: string;
      };
    };
  }[];
}

interface GraficoRoscaProps {
  id: string; // Add id prop
  series: number[];
  chart: ChartOptions['chart'];
  width?: string;
  height?: string;
  labels: string[];
  themeMode?: 'dark' | 'light' | undefined; // Add themeMode prop
  title?: string;
  cor: string[];
}

const GraficoRoscaLCDPR: React.FC<GraficoRoscaProps> = ({
  id, // Add id prop
  series,
  chart,
  width = '',
  labels,
  themeMode = 'dark', // Default to 'dark' if not provided
  title = '',
  height = '',
  cor,
}) => {
  const options: ChartOptions = {
    series,
    chart: {
      ...chart,
      width,
      height,
    },
    labels,
    title: {
      text: title
    },
    colors: cor,
    theme: {
      mode: themeMode, // Use the provided theme mode
    },
    responsive: [
      {
        breakpoint: 100,
        options: {
          chart: {
            width: width,
            height: height,
          },
          legend: {
            position: 'bottom',
          }
        },
      },
    ],
  };

  const chartRef = useRef<ApexCharts | null>(null);

  const renderChart = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new ApexCharts(document.querySelector(`#${id}`), options);
    chartRef.current.render();
  }, [options, id]);

  useEffect(() => {
    renderChart();

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [renderChart]);

  return <div id={id} />;
};

export default GraficoRoscaLCDPR;
