import { ApiFinanceira } from '../axiosfinanceiro';

interface ContasRecebidas {
  parceiro: string;
  cpf: string;
  fazenda: string;
  historico: string;
  cultivo: string;
  numerodoc: string;
  dataoperacao: Date;
  conta: string;
  valor: number;
}

const RelatorioContasRecebidas = async (parceiro: string, fazenda: string, cultivo: string, datainicial: string, datafinal: string): Promise<ContasRecebidas[] | Error> => {
  try {
    const urlRelativa = `/relatoriocontasrecebidas?fazenda=${fazenda}&parceiro=${parceiro}&cultivo=${cultivo}&datainicial=${datainicial}&datafinal=${datafinal}`;
      
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};
  
export const RelatorioContasRecebidasService = {
  RelatorioContasRecebidas
};