import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import dayjs from 'dayjs';
import { IListagemTransferencias, TransferenciaService } from '../../../shared/services/api/estoque/transferencia/TransferenciaService';
import { FerramentasDaListagemTransferencia } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemTransferencia';

export const ListagemDeTransferencias: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IListagemTransferencias[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [istexto, setIsTexto] = useState(false);
  
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'data'; 
  }, [searchParams]);

  const idlocalizacao = useMemo(() => {
    return searchParams.get('origem') || ''; 
  }, [searchParams]);

  const dataInicial = useMemo(() => {
    return searchParams.get('inicial') || dayjs().startOf('month').format('DD/MM/YYYY');
  }, [searchParams]);

  const dataFinal = useMemo(() => {
    return searchParams.get('final') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  function verificaFormato(data: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;   
    return regex.test(data);
  }

  useEffect(() => {
    if(tipo === 'data'){
      setIsTexto(false);
    }else{
      setIsTexto(true);
    }
    setRows([]);     
  }, [tipo]);  

  useEffect(() => {
    if (tipo === 'origem' && idlocalizacao != '' && verificaFormato(dataInicial) && verificaFormato(dataFinal) || tipo === 'data' && verificaFormato(dataInicial) && verificaFormato(dataFinal)) { 

      setIsLoading(true);
   
      debounce(() => {
        TransferenciaService.getAll(pagina, dataInicial, dataFinal, tipo, idlocalizacao)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }     
    
  }, [dataInicial, dataFinal, pagina, tipo, idlocalizacao]);
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Transferências'
      barraDeFerramentas={
        <FerramentasDaListagemTransferencia
          mostrarInputLocalizacao={istexto}
          mostrarInputData
          mostrarInputBasicSelect
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/transferenciaestoque/detalhe/novo')}
          aoMudarDataInicial={datainicial => setSearchParams({ origem: idlocalizacao, inicial: datainicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ origem: idlocalizacao, inicial: dataInicial, final: dataFinal, tipo: textoselect, pagina: '1' }, { replace: true})}
          aoMudarDataFinal={datafinal => setSearchParams({ origem: idlocalizacao, inicial: dataInicial, final: datafinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarLocalizacao={idLocalizacao => setSearchParams({ origem: idLocalizacao, inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          dadosSelecao={[
            { nameBD: 'data', descricao: 'Data' },
            { nameBD: 'origem', descricao: 'Data e Origem' },
          ]}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Origem</TableCell>
                <TableCell>Destino</TableCell>
                <TableCell>Portador</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idtransferencia} 
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/transferenciaestoque/detalhe/${row.idtransferencia}`)}
                >
                  <TableCell>{dayjs(row.datalan).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{row.origem}</TableCell>
                  <TableCell>{row.destino}</TableCell>
                  <TableCell>{row.portador}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
  
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
  
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > 17) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / 17)}
                      onChange={(_, newPage) => setSearchParams({  origem: idlocalizacao, inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};