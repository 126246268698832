import { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { Environment } from '../../../shared/environment';
import { ColaboradorService, IListagemHistoricoSalario } from '../../../shared/services/api/rh/colaborador/ColaboradorService';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

export const DialogHistoricoDeSalario: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
 
  const { onClose, open, ...other } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<IListagemHistoricoSalario[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  const idfun = useMemo(() => {
    return searchParams.get('idfun') || props.id;
  }, [searchParams]);
 
  useEffect(() => {
    if(idfun != 'novo'){
      setIsLoading(true);
      ColaboradorService.getAllHistoricoSalario(pagina, idfun)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    }
  }, [pagina, idfun, open]);

  const handleCancel = () => {
    onClose();
  };


  return (
    <Dialog
      open={open}
      {...other}      
    >
      <DialogTitle>Histórico de Salário</DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto',  height: 300}}>
          <Table size='small' >
            <TableHead>
              <TableRow>
                <TableCell>Última Atualização</TableCell>
                <TableCell>Salário</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idsalario}>
                  <TableCell>{moment(row.dataalteracao).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align='right'>{row.salario.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                </TableRow>
              ))}
            </TableBody>
  
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
  
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > 5) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / 5)}
                      onChange={(_, newPage) => setSearchParams({ idfun, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth:'30%'  }} variant='outlined' onClick={handleCancel}>Voltar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 