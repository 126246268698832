/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,  Grid,  LinearProgress, Paper } from '@mui/material';
import { AutoCompleteContaBancaria } from '../../../shared/forms/AutoCompleteContaBancaria';
import { IVFormErrors, VFieldMoney, VForm, VTextField, VTextFieldData, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { ChequeEmitidosService, IListagemCheque } from '../../../shared/services/api/Financeiro/ChequeEmitidos';

export interface ConfirmationDialogRawProps {
    value: string;
    open: boolean;
    dados?: IListagemCheque[];
    onClose: (value?: string) => void;
    onDados: (dados?: IListagemCheque[]) => void;
}

interface IFormData {
  databaixa: Date;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  databaixa: yup.date().required(), 
});


export const DialoBaixaCheque: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const { formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState('');

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (props.dados && typeof props.dados === 'object') {
          ChequeEmitidosService
            .Baixa(dadosValidados.databaixa, props.dados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                setValue('0');
                onClose('true');
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};  
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    setValue('0');
    onClose('false');
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }else{
      setId(valueProp);
    }
  }, [valueProp, open]);


  return (
    <Dialog 
      open={open}
      {...other}
    >
      <DialogTitle>Baixar</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef} onSubmit={handleSave}>
          <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
            <Grid container direction="column" padding={1} spacing={1}>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant='indeterminate' />
                </Grid>
              )}
              <Grid container item direction="row"  spacing={2}>  
                <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                  <VTextFieldData
                    fullWidth
                    name='databaixa'  
                    label='Data Baixa'
                    disabled={isLoading}
                  />
                </Grid>       
              </Grid> 
            </Grid> 
          </Box>
        </VForm>  
      </DialogContent>
      <DialogActions>
        <Button  variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
};