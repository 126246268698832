/* eslint-disable @typescript-eslint/no-unused-vars */
import {  useState } from 'react';
import {  Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface IAutoCompletePeriodoProps { 
  isExternalLoading?: boolean;
  novoperiodo?: (novoperiodoselect: string) => void;
}
export const AutoCompletePeriodo01: React.FC<IAutoCompletePeriodoProps> = ({ isExternalLoading = false, novoperiodo }) => {

  const anos = [ '2021', '2022', '2023', '2024', '2025', '2026' ];

  const anoAtual: number = new Date().getFullYear();
  const mesAtualIndex: number = new Date().getMonth();
  const [mes, setMes] = useState<string>('');
  const [ano, setAno] = useState<string>(anoAtual.toString());

  const handleChange = (event: SelectChangeEvent) => {
    setMes(event.target.value as string);
    novoperiodo?.(event.target.value as string+'/'+ano);
  };
     
  return (
    <Box display={'flex'} >
      <FormControl fullWidth  >
        <InputLabel id="demo-simple-select-helper-label">mês</InputLabel>
        <Select
          size='small'
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          disabled={isExternalLoading}
          value={mes}
          label='mês'
          onChange={handleChange}
        >
          <MenuItem value={'janeiro'}>janeiro</MenuItem>
          <MenuItem value={'fevereiro'}>fevereiro</MenuItem>
          <MenuItem value={'março'}>março</MenuItem>
          <MenuItem value={'abril'}>abril</MenuItem>
          <MenuItem value={'maio'}>maio</MenuItem>
          <MenuItem value={'junho'}>junho</MenuItem>
          <MenuItem value={'julho'}>julho</MenuItem>
          <MenuItem value={'agosto'}>agosto</MenuItem>
          <MenuItem value={'setembro'}>setembro</MenuItem>
          <MenuItem value={'outubro'}>outubro</MenuItem>
          <MenuItem value={'novembro'}>novembro</MenuItem>
          <MenuItem value={'dezembro'}>dezembro</MenuItem>
        </Select>  
        <FormHelperText></FormHelperText>
      </FormControl>
      <FormControl sx={{  minWidth: 90 }}>
        <InputLabel id='demo-simple-select-helper-label'>Ano</InputLabel>
        <Select
          size='small'
          labelId='demo-simple-select-helper-label'
          id='demo-simple-select-helper'
          label='Ano'
          value={ano}
          onChange={(event) => { setAno(event.target.value);}}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {anos.map((ano) => (
            <MenuItem
              key={ano}
              value={ano}
            >
              {ano}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};