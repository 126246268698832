import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import { TransitionProps } from '@mui/material/transitions';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { useSpring, animated } from '@react-spring/web';
import { Box, Stack,  Paper  } from '@mui/material';
import { DialogPlanoDeContas } from './DialogPlanoDeContas';
import { useDebounce } from '../../../shared/hooks';
import { PlanoDeContasService } from '../../../shared/services/api/cadastros/PlanoDeContasService';
import { LayoutBaseDePagina } from '../../../shared/layouts';


function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

export const PlanoDeContas: React.FC = () =>{
  const [ITEMS, setItems]= React.useState<TreeViewBaseItem[]>([]);
  
  const [lastSelectedItem, setLastSelectedItem] = React.useState<string | null>(null);
  const [label, setLabel] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [edit, ] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { debounce } = useDebounce();

  React.useEffect(() => {
    debounce(() => {
      PlanoDeContasService.getAll()
        .then((result) => {
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setItems(
              [
                {
                  id: 'null', 
                  label: 'PLANO DE CONTAS', 
                  children: result.data
                }
              ]);
          }
        });
    });

  },[]);

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      setLastSelectedItem(itemId);
    }
  };

  const findLabelById = (itemId: string, items: TreeViewBaseItem[]): string | null => {
    for (const item of items) {
      if (item.id === itemId) {
        return item.label;
      } else if (item.children && item.children.length > 0) {
        const foundLabel = findLabelById(itemId, item.children);
        if (foundLabel) {
          return foundLabel;
        }
      }
    }
    return null;
  };

  const handleItemDoubleClick = () => {
    if (lastSelectedItem) {
      const label = findLabelById(lastSelectedItem, ITEMS);
      if (label) {
        setLabel(label);
      }
    }
    setOpen(true);
  };
  
  const handleItensEditar = (dados: { id: string; label: string }) => {
    const updateItemLabel = (items: TreeViewBaseItem[]): TreeViewBaseItem[] => {
      return items.map(item => {
        if (item.id === dados.id) {
          return { ...item, label: dados.label };
        } else if (item.children && item.children.length > 0) {
          return { ...item, children: updateItemLabel(item.children) };
        } else {
          return item;
        }
      });
    };
  
    setItems(updateItemLabel(ITEMS));
  };
  
  const handleItensAdicionar = (dados: {id: string, label: string, children: TreeViewBaseItem[]}) => {
    
    const updateItemChildren = (items: TreeViewBaseItem[]): TreeViewBaseItem[] => {
      return items.map(item => {
        if (item.id === lastSelectedItem) {
          if (item.children && item.children.length > 0) {
            return { ...item, children: [...item.children, dados] };
          } else {
            return { ...item, children: [dados] };
          }
        } else if (item.children && item.children.length > 0) {
          return { ...item, children: updateItemChildren(item.children) };
        } else {
          return item;
        }
      });
    };
  
    setItems(updateItemChildren(ITEMS));
  };

 
  return (  
    <LayoutBaseDePagina
      titulo='Plano de Contas'
    >
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={2}
        display="flex"
        alignItems="center"
        component={Paper}
      >
        <Stack spacing={2}>
          <RichTreeView
            aria-label="customized"
            defaultExpandedItems={['1']}
            sx={{ overflowX: 'hidden', minHeight: '100%', flexGrow: 1, maxWidth: '100%' }}
            slotProps={{ item: { slots: { groupTransition: TransitionComponent } } }}
            items={ITEMS}
            onItemSelectionToggle={handleItemSelectionToggle}
            onDoubleClick={handleItemDoubleClick}
          />
          <DialogPlanoDeContas
            id={lastSelectedItem!}
            label={label}  
            open={open} 
            disabled={lastSelectedItem === 'null' ? true: false || edit}
            onDadosEditar={handleItensEditar} 
            onDadosAdicionar={handleItensAdicionar}
            onClose={handleClose}
          /> 
        </Stack>
      </Box>
    </LayoutBaseDePagina>
  );
};