import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
export interface IListagemCheque {
  idcheque: string;
	dataoperacao: Date;
	datadeposito: Date;
	agencia: string;
  banco: string;
	conta: string;
	titular: string;
	valor: number;
	status: number;
	numero: string;
	marcado: boolean;
	nome: string;  
	tipostatus: string;
}


async function PDFCheque(dados: IListagemCheque[], selecionado: number){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const cheques = dados.map((cheque) => {
    return [
      {text: moment(cheque.dataoperacao).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 10},
      {text: moment(cheque.datadeposito).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 10},
      {text: cheque.numero, alignment: 'left',  fontSize: 10},
      {text: cheque.banco +'\n'+ cheque.agencia +'/'+cheque.conta, alignment: 'center',  fontSize: 10},
      {text: cheque.nome +'\n Titular: '+cheque.titular, alignment: 'left',  fontSize: 10},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(cheque.valor), alignment: 'right',  fontSize: 10},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  
  const documentDefinition = {

    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 540],
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Cheques Selecionados', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {
        table:{
          widths: [ 55, 55, 50, 75, 200, 55],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [
              {text: 'Data', alignment: 'center', fillColor: '#dddddd', fontSize: 10},
              {text: 'Data Deposito', alignment: 'center', fillColor: '#dddddd',  fontSize: 10},
              {text: 'Numero', alignment: 'left', fillColor: '#dddddd', fontSize: 10},
              {text: 'Banco \n Agencia / Conta', alignment: 'center', fillColor: '#dddddd',  fontSize: 10},
              {text: 'Cliente / Titular da Conta', alignment: 'left', fillColor: '#dddddd', fontSize: 10},
              {text: 'Valor', alignment: 'right', fillColor: '#dddddd', fontSize: 10},
            ],
            ...cheques,
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: 'Valor Total', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(selecionado), alignment: 'right',  fillColor: '#dddddd', fontSize: 10, bold: true},
            ]
          ]
          
        },
      },
      
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default PDFCheque;

