/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosError, isAxiosError } from 'axios';
import { Environment } from '../../../environment';
import { ApiFinanceira } from '../axiosfinanceiro';
import { v4 as uuidv4 } from 'uuid';
import { ErrorResponse } from 'react-router-dom';

export interface IListagemCheque {
  idcheque: string;
  datadeposito: Date;
  dataoperacao: Date,
  numero: string;
  nome: string;
  valor: number; 
  historico: string;
  marcado: boolean;
  idconta: number;
  status: number;
}

export interface ICheque{
  datadeposito: Date;  
  emissao: Date;
  idconta: number;
  credor: string; 
  valor: number;
  contapagas: boolean;
  idparceiro?: string | null;
  numero: string;
  historico: string;
  status: number,
  idoperacao: string;
}

export interface Ibaixacheque{
  idcheque: string,
  databaixa: Date,
  idconta: number,
}

export interface IChequeRelatorio{
  numero: string;
  dataoperacao: Date;
  datadeposito: Date;
  databaixa: Date;
  nome: string;
  historico: string;  
  tipostatus: string;
  valor: number;
}

type TotalCount = {
    data: IListagemCheque[];
    totalCount: number;
}

const create = async (dados: Omit<ICheque, 'idoperacao'>): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await ApiFinanceira.post<ICheque>('/chequeemitidos',  {idcheque: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  }  catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao criar o registro.');
    }}
};

const updateById = async (id: string, dados: Omit<ICheque, 'idoperacao'>): Promise<void | Error> => {
  try {

    await ApiFinanceira.put(`/chequeemitidos/${id}`, dados);

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Atualizar Contas Pagar');
    }}
};

const getAll = async (page = 1, filter = '', tipo='', conta: string): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/chequeemitidos?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&tipo=${tipo}&conta=${conta}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa); 

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const Baixa = async (databaixa: Date, dados: IListagemCheque[]): Promise<void | Error> => {
  try {  
    const baixa = dados.map(conta => {
      return {
        idcheque: conta.idcheque,
        databaixa: databaixa,
        idconta: conta.idconta,
      };
    });
    await ApiFinanceira.post('/chequeemitidosbaixa', baixa); 
    return;   
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Baixra');
    }}
};

const getById = async (id: string): Promise<ICheque | Error> => {
  try {
    const { data } = await ApiFinanceira.get(`/chequeemitidos/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Get');
    }}
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await ApiFinanceira.delete(`/chequeemitidos/${id}`);

  }catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Apagar o Registro');
    }}
};

const RelatorioCheque = async (conta: number, status: number, tipodata: string,  datainicial: string, datafinal: string): Promise<IChequeRelatorio[] | Error> => {
  try {
    const urlRelativa = `/relatoriochequeemitidos?conta=${conta}&tipodata=${tipodata}&status=${status}&datainicial=${datainicial}&datafinal=${datafinal}`;
      
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const VoltarBaixa = async (id: string): Promise<void | Error> => {
  try {
    await ApiFinanceira.put(`/chequeemitidosvoltarbaixa/${id}`);

  }catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Voltar o Registro');
    }}
};

export const ChequeEmitidosService = {  
  getAll,
  Baixa,
  create,
  updateById,
  getById,
  deleteById,
  RelatorioCheque,
  VoltarBaixa,
};