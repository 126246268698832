/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import {  IconButton, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import {  useSearchParams } from 'react-router-dom';

import { LayoutBaseDeFinanceiro, } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IConfirmarVale, ValesFinanceiroConstasService } from '../../../shared/services/api/Financeiro/Vale';
import { DialogConfirmarVale } from './DialogConfirmar';

export const ListagemVale: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<IConfirmarVale[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [ids, setIds] = useState(''); 
  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [valortotal, setValorTotal] = useState(0);
  const [nome, setNome] = useState(''); 

  const guuid = uuidv4();   
  
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);  
  

  useEffect(() => {
    setIsLoading(true);  
    ValesFinanceiroConstasService.getAll(pagina)
      .then((result) => {
        setIsLoading(false);  
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });  
  }, [pagina]);  
  
          
  const handleCloseBaixa = (newValue?: string) => {
    setOpenConfirmar(false);  
    setIsLoading(true);  
    ValesFinanceiroConstasService.getAll(pagina)
      .then((result) => {
        setIsLoading(false);  
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });  
  };  

  return (
    <LayoutBaseDeFinanceiro
      mostraCardTotal={false}
      mostraCardSelecionado={false}
      titulo='Confirmar Vale'  
      labelSelecionado='Selecionado'
      labeltotal='Total Pagar'
      total={''}
      selecionado={''}
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Data Emissão</TableCell>
              <TableCell align='left'>Colaborador</TableCell>
              <TableCell align='left'>Histórico</TableCell>
              <TableCell align='right' >Valor do Vale</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idoperacao} 
                hover
                sx={{ cursor: 'pointer' }}  
              >
                <TableCell align='center'>{dayjs(row.dataemissao).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='left'>{row.nome}</TableCell>
                <TableCell align='left'>{row.historico}</TableCell>
                <TableCell align='right'>{row.totalvale.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                <TableCell >
                  <IconButton color='primary'>
                    <CheckCircleOutlineIcon sx={{ fontSize: 33 }} onClick={() => {setIds(row.idoperacao); setValorTotal(row.totalvale); 
                      setNome(row.nome); setOpenConfirmar(true);}} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody> 
   
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={7}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination  
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <DialogConfirmarVale
        value={guuid}
        open={openConfirmar} 
        id={ids}
        onClose={handleCloseBaixa}
        Historico={`Adiantamento de Salario de ${nome}`}
        ValorDoVale={valortotal}
      /> 
    </LayoutBaseDeFinanceiro>
  ); 
};