import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../hooks';
import { useField } from '@unform/core';
import { SubGrupoProdutoService } from '../services/api/estoque/subGrupoProduto/SubGrupoProdutoService';

type TAutoCompleteOption = {
  idsubgrupo: string;
  label: string;
}

interface IAutoCompleteSubgruposProps {
  isExternalLoading?: boolean;
  idgrupo?: string;
}

export const AutoCompleteSubgrupoProdutos: React.FC<IAutoCompleteSubgruposProps> = ({ isExternalLoading = false, idgrupo = '' }) => {

  const { fieldName, registerField, defaultValue, error, clearError } = useField('idsubgrupo');

  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setSelectedId(undefined);
    setBusca('');
  }, [idgrupo]);

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      SubGrupoProdutoService.getAllList(idgrupo, busca, selectedId)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            //alert(result.message);
          } else {
            setOpcoes(result.data.map(veiculos => ({ idsubgrupo: veiculos.idsubgrupo, label: veiculos.descricao })));
          }
        });
    });
  },[idgrupo, busca, selectedId]);
 
  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idsubgrupo === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);
  
  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...' 
      fullWidth
      disablePortal
      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idsubgrupo); setBusca(''); clearError(); }}
      popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          label="Subgrupo"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};