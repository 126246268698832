/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface IDataPrint {
  idvale: string,
  dataemissao: Date;
  totalvale: number 
  produtor: string;
  numero: string;
  realizacao: string;
  colaborador: string;
  endereco: string;
  cpf: string;
  rg: string;
}


function PDFReciboVale(dadosrecibo: IDataPrint){
  
  const numero = require('numero-por-extenso');
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

 
  const documentDefinition = {

    content: [
     
      {
        style: 'tableExample',
        table: {
          widths: [230, 130, 130],
          body: [
            [ {text: 'Recibo', fontSize: 20, alignment: 'center', },
              {text: 'Valor\n'+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(dadosrecibo.totalvale), fontSize: 18, alignment: 'center', }, 
              {text: 'Data\n '+ moment(dadosrecibo.dataemissao).format('DD/MM/YYYY'), fontSize: 18, alignment: 'center', },
            ],
            [
              {
                colSpan: 3,
                text: 'Recebi do:  '+dadosrecibo.produtor,
                heights: 10,
              },
            ],
            [
              {   colSpan: 1,
                text: 'CEI: '+ dadosrecibo.numero,
                margin: [0, 5, 0, 5],
                heights: 10,
              },
              {   colSpan: 2,
                text: 'Inscrição Estadual: Isento',
                margin: [0, 5, 0, 5],
                heights: 10,
              },
      
            ],
            [
              {  
                colSpan: 3,
                text: 'a importância de: '+numero.porExtenso(dadosrecibo.totalvale, numero.estilo.monetario),
                heights: 10,
              },
      
            ],   
            [
              {  
                colSpan: 3,
                text: 'em realizações de: '+dadosrecibo.realizacao,
                heights: 10,
              },
      
            ],
            [                            
              {
                colSpan: 3,
                table: {
                  widths: [245, 245],
                  body: [
                    [{text: '', border: [false, false, false, false], }, 'Pelo Que Damos Plena e Geral Quitação'],
                    [{text:'Serviços......'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(0) , border: [false, false, false, false],}, {text: 'Nome..: '+dadosrecibo.colaborador, border: [true, false, true, false]} ],
                    [{text:'Varbas........'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(0) , border: [false, false, false, false],}, {text: 'Local.: São Felix do Coribe-Ba', border: [true, false, true, false],}],
                    [{text:'Total.........'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(dadosrecibo.totalvale) , border: [false, false, false, false],}, {text: 'CPF...: '+dadosrecibo.cpf, border: [true, false, true, true],}],
                    [{text:'', border: [false, false, false, false],}, {text: '___________________________________ \n'+dadosrecibo.colaborador, border: [false, false, false, false], alignment: 'center',  margin: [0, 50, 0, 30]}],
                  ]
                },
                border: [true, false, true, false],
              }
            ],
            [
              {  
                colSpan: 3,
                text: 'Por ser expressão de verdade, dou quitação pela importância recebida, firmando o presente recibo nesta data.',
                heights: 10,
                fontSize: 9,
                border: [true, false, true, true],
              },
            ],
            [
              {   
                text:'',
                colSpan: 3,
                border: [false, false, false, false],
                heights: 10,
              },
            ],
            [
              {   
                text:'',
                colSpan: 3,
                border: [false, false, false, false],
                heights: 10,
              },
            ],
            [
              {   
                text:'',
                colSpan: 3,
                border: [false, false, false, false],
                heights: 10,
              },
            ],
            [
              {   
                text:'',
                colSpan: 3,
                border: [false, false, false, false],
                heights: 10,
              },
            ],
            [
              {   
                text:'',
                colSpan: 3,
                border: [false, false, false, false],
                heights: 10,
              },
            ],
          ],
        }
      }
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default PDFReciboVale;

