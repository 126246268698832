/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Checkbox, Icon, IconButton, LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LayoutBaseDeFinanceiro, } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import dayjs from 'dayjs';
import { FerramentasDaListagemFinanceiroChequeEmitidos } from '../../../shared/components';
import { v4 as uuidv4 } from 'uuid';
import { ChequeEmitidosService, IListagemCheque } from '../../../shared/services/api/Financeiro/ChequeEmitidos';
import { DialoBaixaCheque } from './DialogBaixa';

export const ListagemChequeEmitidos: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();  
  const [rows, setRows] = useState<IListagemCheque[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [istexto, setIsTexto] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selecionado, setSelecionado] = useState(0);
  const [openBaixa, setOpenBaixa] = useState(false);

    
  const guuid = uuidv4();   
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const contaselect = useMemo(() => {
    return searchParams.get('contaselect') || 0; 
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);  
  
 
  useEffect(() => {
    if(contaselect != 0){
      ChequeEmitidosService.getAll(pagina, busca, 'conta', contaselect.toString())
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setRows(result.data);
            setTotalCount(result.totalCount);
          }
        });
    }else{
      setIsLoading(false);  
      setRows([]);
      setTotalCount(0);
    }
  }, [contaselect]);  


  useEffect(() => {  
    if(contaselect != 0){
      setIsLoading(true);  
      debounce(() => {  
        ChequeEmitidosService.getAll(pagina, busca, 'numero', contaselect.toString())
          .then((result) => {
            setIsLoading(false);  
            if (result instanceof Error) {
              alert(result.message);
            } else { 
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setIsLoading(false);  
      setRows([]);
      setTotalCount(0);
    }
  }, [busca, pagina]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const index = rows.findIndex(row => row.idcheque === id);
    if (index === -1) return; 
    const updatedRow = { ...rows[index], marcado: checked };
    const updatedRows = [...rows.slice(0, index), updatedRow, ...rows.slice(index + 1)];
    setRows(updatedRows);
    const valueToUpdate = checked ? updatedRow.valor : -updatedRow.valor;
    setSelecionado(currentSelecionado => currentSelecionado + valueToUpdate);
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const updatedRows = rows.map(row => ({ ...row, marcado: checked }));
    setRows(updatedRows);
    if (checked) {
      const totalSelecionado = updatedRows.reduce((acc, row) => acc + row.valor, 0);
      setSelecionado(totalSelecionado);
    } else {
      setSelecionado(0);
    }
  };
           
  const handleCloseBaixa = (newValue?: string) => {
    setOpenBaixa(false);  
    if (newValue === 'true') {
      ChequeEmitidosService.getAll(pagina, busca, 'conta', contaselect.toString())
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    }
  };  

  const handleBaixa = () => {
    if(totalCount != 0){
      const filteredList = rows.filter((item) => item.marcado === true);
      setRows(filteredList);
      setOpenBaixa(true);
    }
  };

  const handleDesc = (dados?: IListagemCheque[]) => {
    if (dados) {
      setRows(dados);
    }
  };

  return (
    <LayoutBaseDeFinanceiro
      mostraCardTotal={false}
      titulo='Cheque Emitidos'
      labelSelecionado='Selecionado'
      labeltotal='Total Pagar'
      total={''}
      selecionado={selecionado.toLocaleString('pt-BR', { minimumFractionDigits: 2})}
      barraDeFerramentas={
        <FerramentasDaListagemFinanceiroChequeEmitidos
          mostrarInputBusca={istexto}
          mostrarInputBasicSelect
          textoDaBusca={busca}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/chequeemitidos/detalhe/novo')}
          aoClicarEmBaixa={handleBaixa}
          aoMudarTextoDoIdconta={texto => setSearchParams({ busca: '', contaselect: texto.toString(),  pagina: '1' }, { replace: true })}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, contaselect: contaselect.toString(), pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell > <Checkbox checked={rows.length > 0 && rows.every(row => row.marcado)} onChange={handleSelectAllChange} /></TableCell>
              <TableCell align='left' >Numero Cheque</TableCell>
              <TableCell >Data Operação</TableCell>
              <TableCell >Data Deposito</TableCell>
              <TableCell align='left' >Parceiro / Credor</TableCell>
              <TableCell align='left' >Histórico</TableCell>
              <TableCell align='right' >Valor</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idcheque} 
                hover
                sx={{ cursor: 'pointer' }}  
              >
                <TableCell> {row.status == 1 ? <Checkbox  checked={row.marcado} id={row.idcheque} onChange={handleChange}/> : 'X' }</TableCell>
                <TableCell align='center'>{row.numero}</TableCell>
                <TableCell align='center'>{dayjs(row.dataoperacao).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='center'>{dayjs(row.datadeposito).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='left'>{row.nome}</TableCell>
                <TableCell align='left'>{row.historico}</TableCell>
                <TableCell align='right'>{row.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                <TableCell >
                  <IconButton size='small'  onClick={() => navigate(`/chequeemitidos/detalhe/${row.idcheque}`)}>
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody> 
   
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination  
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <DialoBaixaCheque
        value={guuid}
        open={openBaixa} 
        onClose={handleCloseBaixa}
        dados={rows}
        onDados={handleDesc}
      /> 
    </LayoutBaseDeFinanceiro>
  ); 
};