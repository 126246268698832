/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
 
interface IFaltas {
  nome: string;
  faltas: number; 
}   

interface ICEI {
  idcei: string;
  numero: string;
  nome: string;
  faltas: IFaltas[];
}

interface dados {
  cei: ICEI;
  faltas: IFaltas[];  
}

async function RelatorioFaltaPorCei(data: dados[], periodo: string){
  
  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }


  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 10, 20, 10],
    pageOrientation: 'portrait',
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],  
          widths: [48, 500],  
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },  
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: '#FFFFFF',  alignment: 'center', fillColor: '#00A47B', border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Faltas Por CEI', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      { text: 'Período de: '+periodo,  alignment: 'left', fillColor: '#dddddd', fontSize: 8},     
      data.map(e => {
        const { cei, faltas } = e;
        const rows = faltas.map((row) => {
          return [
            {text: row.nome, alignment: 'left',  fontSize: 10},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.faltas) , fontSize: 10, alignment: 'right'},
          ];
        });  
        return [
          [             
            [                            
              {  
                margin: [0, 5, 0, 0],    
                table: { 
                  body: [
                    [
                      {text: 'CEI de Numero: '+ e.cei.numero+ ' de Produtor: '+e.cei.nome, bold: true, fontSize: 9, alignment: 'left', border: [false, false, false, false]},
                    ],
                  ] 
                },  
              },
              {   
                table: {
                  widths: [400, 70],  
                  body: [
                    [
                      { text: 'Nome',  alignment: 'left', fillColor: '#dddddd', fontSize: 10}, 
                      { text: 'Falta',  alignment: 'left', fillColor: '#dddddd', fontSize: 10}, 
                    ],
                    ...rows,
                  ]
                },       
              },                              
            ]
          ]                           
            
        ];
      }     
      ),     
    ]
  };
  pdfMake.createPdf(documentDefinition).open();

}

export default RelatorioFaltaPorCei;