/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, Icon, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VForm, useVForm, VTextFieldData, IVFormErrors, AutoCompleteLocalizacaoField } from '../../../shared/forms';
import dayjs from 'dayjs';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { DialogTransferencia } from './DialogTransferencia';
import { v4 as uuidv4 } from 'uuid';
import { TransferenciaService } from '../../../shared/services/api/estoque/transferencia/TransferenciaService';
import { FerramentasDeDetalheTransferencia } from '../../../shared/components/ferramentas-de-detalhe/FerramentasDeDetalheTransferencia';
import { DialogTransferenciaFinalizar } from './DialogTransferenciaFinalizar';

type NullableDate = Date | null | undefined;

interface IFormData {
  iddestino: string;
  idorigem: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  portador: string;
  status: number;
}

interface IFormData2 {
  iddestino: string;
  idorigem: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  status: number;
}

interface IitensTransferenciaDialog{
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: string | null;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;

  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  reqvencimento: boolean;
}

interface IItensids{
  iditens: string;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'status' | 'datarecebimento' | 'dataenvio' | 'portador' >> = yup.object().shape({
  idorigem: yup.string().required(),
  iddestino: yup.string().required().test('iddestino', 'Origem e Destino Não Pode ser o mesmo', function (value, context) {
    const confirmar = context.parent.idorigem || ''; 
    return value !== confirmar ;
  }),
  datalan: yup.date().required(),
});

export const DetalheDaTransferencia: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save} = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFinalizar, setOpenFinalizar] = useState(false);
  const [statuss, setStatuss] = useState(true);
  const [iditens, setIditens] = useState('');
  const [rows, setRows] = useState<IitensTransferenciaDialog[]>([]);
  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  const [dadositens, setDadositens] = useState<IitensTransferenciaDialog>();
  const guuid = uuidv4();
  const [idOrigem, setIdOrigem] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [cabecalho, setCabecalho] = useState<IFormData2>();

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      TransferenciaService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/transferenciaestoque');
          } else {
            formRef.current?.setData(result);
            setRows(result.itens);
            setIdOrigem(result.idorigem);

            if(result.status === 2 || result.status === 2){
              setStatuss(false);
            }

            if (result.datalan) {
              const dataLanDayjs = dayjs(result.datalan);
              const dataAtual = dayjs();
              if (dataAtual.diff(dataLanDayjs, 'days') >= 10) {
                setStatuss(false);
                setEdit(false);
              }
            }

            if(result.itens.length != 0){
              setEdit(true);
            }else{
              setEdit(false);
            }

          }
        });
    } else {
      formRef.current?.setData({
        iddestino: '',
        idorigem: '',
        datalan: new Date(),
        dataenvio: '',
        datarecebimento: '',
        portador: '',
        status: 1,
      });
      setRows([]);
      setIdOrigem('');
      setStatuss(true);
      setEdit(false);
    }  
  }, [id]);

  useEffect(() => {
    const totalSum = rows.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);
    formRef.current?.setFieldValue('total', totalSum);
  }, [rows]);

  const handleSave = (dados: IFormData) => {
    if(rows.length != 0){
      
      formValidationSchema.
        validate(dados, { abortEarly: false })
        .then((DadosValidados) => {

          const dadosValidados = { ...DadosValidados, status: 1 };
          setCabecalho(dadosValidados);
          setOpenFinalizar(true);
        })
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};

          errors.inner.forEach(error => {
            if (!error.path) return;

            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        });
    }else{
      alert('A saída deve conter no mínimo um ítem.');
    }
   
  };

  const handleDeleteEntrada = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      TransferenciaService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/transferenciaestoque');
          }
        });
    }
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {

      const meuObjeto = {iditens: id};
     
      rowsids.push(meuObjeto); 
      
      const indexToDelete = rows.findIndex((item) => item.iditens === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);
        if(updatedList.length != 0){
          setEdit(true);
        }else{
          setEdit(false);
        }
      }
    }
  };

  const handleBuscarDados = (id: string) => {
    const foundItem = rows.find((listItem) => listItem.iditens === id);
    if (foundItem) {
      setDadositens(foundItem);
    }
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFinalizar = () => {
    setOpenFinalizar(false);
  };

  type RowsType = IitensTransferenciaDialog[];
  
  const handleItens = (dados: IitensTransferenciaDialog) => {
    const index = rows.findIndex((listItem) => listItem.iditens === dados.iditens);
  
    let updatedList: RowsType;
  
    if (index !== -1) {
      updatedList = [...rows];
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);

    if(updatedList.length != 0){
      setEdit(true);
    }else{
      setEdit(false);
    }
    
  };

  const handleIdOrigem = (idOrigem: string | undefined) => {
    setIdOrigem(idOrigem);
  };

  return (
    <LayoutBaseDePaginaNfe
      barraDeFerramentas={
        <FerramentasDeDetalheTransferencia
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={false} //id !== 'novo'
          disableBotaoSalvar={statuss ? isLoading : true}
          aoClicarEmSalvar={save}
          aoClicarEmVoltar={() => navigate('/transferenciaestoque')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmNovo={() => navigate('/transferenciaestoque/detalhe/novo')}
          mostrarBotaoNovoCarregando={isLoading}
          aoClicarEmApagar={() => handleDeleteEntrada(id)} 
          aoClicarEmFinalizar={() => setOpenFinalizar(true)}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row"  spacing={2}>
            
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={2.5} lg={3} xl={3}>
                    <VTextFieldData
                      fullWidth
                      name='datalan'
                      label='Data do Lançamento'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                    <AutoCompleteLocalizacaoField
                      name='idorigem'
                      Label='Origem'
                      idLocalizacao={handleIdOrigem}
                      disabled={edit}
                    />  
                  </Grid>
                  <Grid item xs={12} sm={12} md={4.5} lg={3} xl={3}>
                    <AutoCompleteLocalizacaoField
                      name='iddestino'
                      Label='Destino'
                      disabled={statuss ? isLoading : true}
                    />  
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined">
                  <Table size="small">
                    <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                      <TableRow >
                        <TableCell style={{ color: '#fff' }} align='right'  >Qtd</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>UN</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>Descrição</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>Referência</TableCell>
                        <TableCell style={{ color: '#fff' }} align='center'>Validade</TableCell>
                        <TableCell style={{ color: '#fff' }} align='center'>
                          <IconButton color='inherit' disabled={statuss ? isLoading : true}  aria-label="add to shopping cart" size="small">
                            <AddShoppingCartIcon  onClick={() => {setIditens('novo');  setOpen(true);}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.iditens} 
                          hover
                          sx={{ cursor: 'pointer',  '&:last-child td, &:last-child th': { border: 0 }}}
                        >
                          <TableCell align='right' >{row.qtdsaida.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell align='left'>{row.un}</TableCell>
                          <TableCell align='left' >{row.descricao}</TableCell>
                          <TableCell align='left'>{row.referencia}</TableCell>
                          <TableCell align='center'>{row.validade ? dayjs(row.validade).format('DD/MM/YYYY') : ''}</TableCell>
                          <TableCell align='center'>
                            <IconButton size="small" onClick={() => {setIditens(row.iditens); handleBuscarDados(row.iditens);  setOpen(true);}}>
                              <Icon >edit</Icon>
                            </IconButton>
                            <IconButton disabled={statuss ? isLoading : true}  size="small" onClick={() => {handleDelete(row.iditens);}}>
                              <Icon>delete</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow> 
                      ))}
                    </TableBody>
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <DialogTransferencia 
              id={iditens} 
              value={guuid} 
              dados={dadositens}
              open={open} 
              onDados={handleItens} 
              onClose={handleClose} 
              status={statuss}
              idOrigem={idOrigem!}
            /> 
            <DialogTransferenciaFinalizar 
              id={id} 
              value={guuid} 
              dadosItens={rows!}
              open={openFinalizar} 
              dados={cabecalho!} 
              onClose={handleCloseFinalizar} 
              rowsids={rowsids}
            />
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePaginaNfe>
  );
};