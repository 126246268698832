import { AxiosError } from 'axios';
import { ApiFinanceira } from '../axiosfinanceiro';
import { ErrorResponse } from 'react-router-dom';


export interface IListagem {
  idcaixa: string;
  idconta: number;
  numero: string;
  nome: string;
  dataoperacao: Date;
  historico: string;
  entrada: number;
  saida: number;
  idpareceiro: string;
  idoperacao: string;
  tipo: string;
  datalan: Date;
  status: boolean;
  tipos: number;
}

export interface IResultado{
  saldoanterior: number;
  credito: number;
  debito: number;
  saldo: number;
  valorcheque: number;
  saldoreal: number;
}

interface IFecharCaixa {
  conta: number;
  datafechar: Date;
}

interface IAbrirCaixa {
  conta: number;
  dataabrir: Date;
}

export interface IVoltarLancamento {
  idoperacao: string;
  datacaixa: Date;
  idconta: number;
  tipos: number;
}


type TotalCount = {
    resultado: IResultado,
    lan: IListagem[];
    totalCount: number;
}

const getAll = async (page = 1, tipo: string, conta: string, datainicial = '', datafinal=''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/caixa?page=${page}&limit=${12}&tipo=${tipo}&conta=${conta}&datainicial=${datainicial}&datafinal=${datafinal}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa); 

    if (data) {
      return {
        resultado: data[0],
        lan: data[1],
        totalCount: Number(headers['x-total-count'] || 12),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const FecharCaixa = async (dados: IFecharCaixa): Promise<string | Error> => {
  try {

    const { data } = await ApiFinanceira.post<IFecharCaixa>('/fecharconciliacao',  dados);
  
    if (Object.keys(data).length === 0) {
      return 'ok';
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    const err = error as AxiosError; 
    if (err.response) {
      return new Error('Error ' + err.response.data);
    } else if (err.request) {
      if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
        const e = error as ErrorResponse;
        const errorMessages = Object.entries(e.data.errors)
          .map(([field, message]) => `${field}: ${message}`)
          .join('; ');
        return new Error(errorMessages);
      } else {
        return new Error('Erro ao Atualizar Receber');
      }
    } else {
      return new Error('Error' +  err.message);
    }
  }
};

const AbrirCaixa = async (dados: IAbrirCaixa): Promise<string | Error> => {
  try {

    const { data } = await ApiFinanceira.post<IAbrirCaixa>('/abrirconciliacao',  dados);
  
    if (Object.keys(data).length === 0) {
      return 'ok';
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    const err = error as AxiosError; 
    if (err.response) {
      return new Error('Error ' + err.response.data);
    } else if (err.request) {
      if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
        const e = error as ErrorResponse;
        const errorMessages = Object.entries(e.data.errors)
          .map(([field, message]) => `${field}: ${message}`)
          .join('; ');
        return new Error(errorMessages);
      } else {
        return new Error('Erro ao Atualizar Receber');
      }
    } else {
      return new Error('Error' +  err.message);
    }
  }
};

const VoltarLancamento = async (dados: IVoltarLancamento): Promise<string | Error> => {
  try {

    const { data } = await ApiFinanceira.post<IAbrirCaixa>('/voltarlancamento',  dados);
  
    if (Object.keys(data).length === 0) {
      return 'ok';
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    const err = error as AxiosError; 
    if (err.response) {
      return new Error('Error ' + err.response.data);
    } else if (err.request) {
      if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
        const e = error as ErrorResponse;
        const errorMessages = Object.entries(e.data.errors)
          .map(([field, message]) => `${field}: ${message}`)
          .join('; ');
        return new Error(errorMessages);
      } else {
        return new Error('Erro ao Voltar o Registro');
      }
    } else {
      return new Error('Error' +  err.message);
    }
  }
};

export const CaixaConstasService = {  
  getAll,
  FecharCaixa,
  AbrirCaixa,
  VoltarLancamento,
};