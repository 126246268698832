import { Apiestoque } from '../../axiosestoque';


export interface ILocalizacao {
  idprod: string;
  descricao: string;
  referencia: string;
  qtdestoque: number;
}

export interface IVencimentos {
  idprod: string;
  descricao: string;
  referencia: string;
  validade: Date;
  dias: number;
  qtdestoque: number;
}

export interface IOperacao{
  idoperacoes: string;
  idfazenda: string;
  idcultivo: string;
  idparceiro: string;
  datainicial: Date;
  datafinal: Date;
  detalhes: boolean;
  vendas: boolean,
  tipo: number,
}

interface Item {
  iditens: string;
  descricao: string;
  validade: Date;
  referencia: string;
  localizacao: string;
  qtdsaida: number;
  preco: number;  
  total: number;
}

interface ItemEnt {
  iditens: string;
  descricao: string;
  un: string;
  conversor: number;
  validade: Date;
  referencia: string;
  localizacao: string;
  qtdentrada: number;
  preco: number;  
  total: number;
}

interface Saida {
  idsaida: string;
  dataoperacao: string;
  cultivo: string;
  fazenda: string;
  parceiro: string;
  total: number;
}

interface entrada {
  identrada: string;
	dataemissao: string;
	numerodoc: string;
	numeronota: string;
	nome: string;
	cpf: string;
	subtotal: number;
	desconto: number;
	acrescimo: number;
	totalnota: number;
	planoconta: string;
	operacao: string;
}

interface Entry {
  saida: Saida;
  itens: Item[];
}

interface EntryEntrada{
  entrada: entrada;
  itens: ItemEnt[];
}

const Localizacao = async (localizacao = '', tipo = '', grupo = '', subgrupo = ''): Promise<ILocalizacao[] | Error> => {
  try {
    const urlRelativa = `/rlocalizacao?tipo=${tipo}&localizacao=${localizacao}&grupo=${grupo}&subgrupo=${subgrupo}`;
  
    const { data } = await Apiestoque.get(urlRelativa);
  
    if (data) {
      return data;
    }
  
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const Vencimentos = async (localizacao = '', tipo = '', dias = ''): Promise<IVencimentos[] | Error> => {
  try {
    const urlRelativa = `/restoquevencimentos?tipo=${tipo}&localizacao=${localizacao}&dias=${dias}`;
  
    const { data } = await Apiestoque.get(urlRelativa);
  
    if (data) {
      return data;
    }
  
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const Operacoes = async (dados: IOperacao): Promise<Entry[] | Error> => {
  try {

    const { data } = await Apiestoque.post<Entry[]>('/restoqueoperacoes', dados);
   
    if (data) {
      return data;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const OperacoesEntrada = async (dados: IOperacao): Promise<EntryEntrada[] | Error> => {
  try {

    const { data } = await Apiestoque.post<EntryEntrada[]>('/restoqueoperacoes', dados);
   
    if (data) {
      return data;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};
 
export const RelatorioEstoqueService = {
  Localizacao,
  Vencimentos,
  Operacoes,
  OperacoesEntrada,
}; 