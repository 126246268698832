/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Avatar, LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem, FerramentasDaListagemCadastros } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemDeProdutos, ProdutosService } from '../../../shared/services/api/estoque/produtos/ProdutosService';



export const ListagemDeProdutos: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemDeProdutos[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'descricao'; 
  }, [searchParams]);

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  
  useEffect(() => {
    setIsLoading(true);
   
    debounce(() => {
      ProdutosService.getAll(pagina, filter, tipo)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
    
  }, [filter, pagina, tipo]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Produtos'
      barraDeFerramentas={
        <FerramentasDaListagemCadastros
          mostrarInputBusca
          mostrarInputBasicSelect
          textoDaBusca={filter}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/estoque/detalhe/novo')}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ filter: filter, tipo: textoselect, pagina: '1' }, { replace: true})}
          dadosSelecao={[
            { nameBD: 'codbarrasaida', descricao: 'Código de Barras' },
            { nameBD: 'codinterno', descricao: 'Código Interno' },
            { nameBD: 'descricao', descricao: 'Descrição' },
            { nameBD: 'marca', descricao: 'Marca' },
            { nameBD: 'referencia', descricao: 'Referência' },
          ]}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell  sx={{maxWidth: 40, width:40, marginLeft:3 }}  aria-label="a dense table"></TableCell>
                <TableCell>Código de Barras</TableCell>
                <TableCell>Código Interno</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Referência</TableCell>
                <TableCell  align='right'>Valor</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idprod} 
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/estoque/detalhe/${row.idprod}`)}
                >
                  <Avatar sx={{maxWidth: 50, width:60, height:50, marginLeft:2, marginTop:1 }}   alt="F" src={process.env.REACT_APP_URL_IMGPRODUTOS+row.urlimge}/>
                  <TableCell>{row.codbarrasaida}</TableCell>
                  <TableCell>{row.codinterno}</TableCell>
                  <TableCell >{row.descricao}</TableCell>
                  <TableCell>{row.marca}</TableCell>
                  <TableCell>{row.referencia}</TableCell>
                  <TableCell align='right'>{row.precocompra.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
  
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
  
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > 10) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / 10)}
                      onChange={(_, newPage) => setSearchParams({ filter, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};