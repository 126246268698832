
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon} from '@mui/material';
import { IVFormErrors, VForm, VSwitch, VTextField, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { StatusService } from '../../../shared/services/api/rh/statusDeColaborador/StatusService';
import { v4 as uuidv4 } from 'uuid';


interface IFormData {
  descricao: string;
  encargos: boolean;
  salarios: boolean;
  folha: boolean;
  ferias: boolean;
  auxilio: boolean;
  experiencia: boolean;
  status: boolean;
  tipo: boolean;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  descricao: yup.string().required(),
  encargos: yup.boolean().required(),
  salarios: yup.boolean().required(),
  folha: yup.boolean().required(),
  ferias: yup.boolean().required(),
  auxilio: yup.boolean().required(),
  experiencia: yup.boolean().required(),
  status: yup.boolean().required(),
  tipo: yup.boolean().optional().default(false),
});

export const DialogStatusDeColaborador: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
  const { onClose, value: valueProp, open, ...other } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(valueProp);
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const guuid = uuidv4();
  const [tipo, setTipo] = useState(false);

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (props.id === 'novo') {
          StatusService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              }else {
                onClose(valueProp);
              }
            });
        } else {
          StatusService
            .updateById(props.id, dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                onClose(valueProp);
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  useEffect(() => {
    if(props.id !== 'novo'){
      if(open == true){
        setIsLoading(true);
        StatusService.getById(props.id)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              formRef.current?.setData(result);
              setTipo(result.tipo);
            }
          });
      }
    }else{
      formRef.current?.setData({
        descricao: '',
        encargos: false,
        salarios: false,
        folha: false,
        ferias: false,
        auxilio: false,
        experiencia: false,
        status: false
      });
      setTipo(false);
    }
  }, [open, guuid]);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    setTipo(false);
    onClose();
  };

  const handleDelete = () => {
    if (confirm('Realmente deseja apagar?')) {
      StatusService.deleteById(props.id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            onClose(valueProp);
          }
        });
    }
  };

  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Status de Colaborador</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} > 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>          
                    <VTextField
                      fullWidth
                      size='small'
                      name='descricao'
                      disabled={tipo === false ? isLoading : true}
                      label='Descrição'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VSwitch
                      size='small'
                      name='encargos'
                      label='Encargos'
                      fdisabled={tipo === false ? isLoading : true}
                      aliamento='top'
                    />
                  </Grid> 
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VSwitch
                      size='small'
                      name='salarios'
                      label='13º Salário'
                      fdisabled={tipo === false ? isLoading : true}
                      aliamento='top'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VSwitch
                      size='small'
                      name='folha'
                      label='Folha de Pagamento'
                      fdisabled={tipo === false ? isLoading : true}
                      aliamento='top'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VSwitch
                      size='small'
                      name='ferias'
                      label='Férias'
                      fdisabled={tipo === false ? isLoading : true}
                      aliamento='top'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VSwitch
                      size='small'
                      name='auxilio'
                      label='Auxílio'
                      fdisabled={tipo === false ? isLoading : true}
                      aliamento='top'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VSwitch
                      size='small'
                      name='experiencia'
                      label='Experiência'
                      fdisabled={tipo === false ? isLoading : true}
                      aliamento='top'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VSwitch
                      size='small'
                      name='status'
                      label='Status do Colaborador'
                      fdisabled={tipo === false ? isLoading : true}
                      aliamento='top'
                    />
                  </Grid>                     
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button sx={{ marginRight:'20%', minWidth:'25%' }}  color='error' startIcon={<Icon>delete</Icon>}  variant="contained"  onClick={handleDelete}>Excluir</Button>
        <Button sx={{ minWidth:'25%'  }} variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button sx={{ minWidth:'25%'  }} variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 