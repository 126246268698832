/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../../environment';
import { ApiFiscal } from '../../axiosfiscal';
import { v4 as uuidv4 } from 'uuid';

export interface IListagemProdNfe {
  idprod: string;
  descricao: string;
  gtin: string;
  un: string;
  ncm: string;
  status: boolean;
 }

export interface IDetalheProdutoNfe {
  descricao: string;
  gtin: string;
  un: string;
  origem: number;
  ncm: string;
  status: boolean;
}

type TotalCount = {
  data: IListagemProdNfe[];
  totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/produtosnfe?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await ApiFiscal.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllList = async (filter = '', id = ''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/produtosnfelist?limit=${Environment.LIMITE_DE_LINHAS}&tipo=descricao&filter=${filter}` : `/produtosnfelist/${id}`;

    const { data, headers } = await ApiFiscal.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IListagemProdNfe | Error> => {
  try {
    const { data } = await ApiFiscal.get(`/produtosnfe/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheProdutoNfe): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await ApiFiscal.post<IDetalheProdutoNfe>('/produtosnfe',  {idprod: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheProdutoNfe): Promise<void | Error> => {
  try {
    await ApiFiscal.put(`/produtosnfe/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await ApiFiscal.delete(`/produtosnfe/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const ProdutosNfeService = {
  getAllList,
  getAll,
  getById,
  create,
  updateById,
  deleteById,
};