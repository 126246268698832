import { ApiFinanceira } from '../axiosfinanceiro';

interface ContasPagas {
  dataemissao: Date;
  datalan: Date;
  parceiro: string;
  historico: string;
  fazenda: string;
  banco: string;
  numerodoc: string;
  valor: number;
}

const RelatorioContasPagas = async (parceiro: string, fazenda: string, planoconta: string, datainicial: string, datafinal: string): Promise<ContasPagas[] | Error> => {
  try {
    const urlRelativa = `/relatoriocontaspagas?fazenda=${fazenda}&parceiro=${parceiro}&planoconta=${planoconta}&datainicial=${datainicial}&datafinal=${datafinal}`;
      
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};
  

export const RelatorioContasPagasService = {
  RelatorioContasPagas
};