import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';

type TVTextFieldProps = TextFieldProps & {
  name: string;
};


export const VTextFieldCep: React.FC<TVTextFieldProps> = ({ name, ...rest }) => {
  

  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  const formatValueString = (value: string | undefined | null) => {
    if (value === undefined || value === null || value === '') {
      return '';
    }
    

    return value.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
  };

  const [value, setValue] = useState<string>(() => formatValueString(defaultValue));

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value.replace(/\D/g, ''), // Retorna o valor sem formatação
      setValue: (_, newValue) => setValue(formatValueString(newValue)),
    });
  }, [registerField, fieldName, value]);
  
  const formatInputValue = (inputValue: string) => {
    const formattedValue = inputValue.replace(/\D/g, '');
    const maskedValue = formattedValue.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
    return maskedValue;
  };


  return (
    
    <TextField
      {...rest}
      error={!!error}
      helperText={error}
      defaultValue={defaultValue}
      value={value || ''}
      onChange={(e) => {
        const inputValue = formatInputValue(e.target.value);
        setValue(inputValue);
        rest.onChange?.({ target: { value: inputValue } } as React.ChangeEvent<HTMLInputElement>);
      }}
      onKeyDown={(e) => {
        if (error) {
          clearError();
        }
        rest.onKeyDown?.(e);
      }}
    />
  );
};
