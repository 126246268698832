import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { DialogLocalizacao } from './DialogLocalizacao';
import { IListagemLocalizacao, LocalizacaoService } from '../../../shared/services/api/estoque/localizacao/LocalizacaoService';



export const ListagemDeLocalizacao: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  
  const [rows, setRows] = useState<IListagemLocalizacao[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const [open, setOpen] = useState(false);
  const [id, setId ] = useState<string>('novo');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose = (newValue?: string) => {
    setOpen(false);
  };

  
  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      LocalizacaoService.getAll(pagina, filter)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [pagina, filter, open]);

 
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Localizações'
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={filter}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => {setId('novo'); setOpen(true);}}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, pagina: '1' }, { replace: true })}
          
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Localização</TableCell>
                <TableCell>Fazenda</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idlocalizacao} 
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {setId(row.idlocalizacao); setOpen(true);}}
                >
                  <TableCell>{row.descricao}</TableCell>
                  <TableCell>{row.fazenda}</TableCell>
                  <TableCell><Switch checked={row.status} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHASSMALL) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHASSMALL)}
                      onChange={(_, newPage) => setSearchParams({ filter, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
      <DialogLocalizacao
        id={id}
        value={''}  
        open={open} 
        onClose={handleClose}
      /> 
    </LayoutBaseDePagina>
  );
};