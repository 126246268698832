import { Environment } from '../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apicadastro } from '../axioscadastro';

export interface IListagemVeiculos {
  idveiculo: string;
  idtransportadora: string;
  placa: string;
  uf: string;
  rntrc: string;
  renavam: string;
  tara: number;
  capacidadekg: number;
  capacidadem3: number;
  tiporoda: number;
  tipocarroceria: number;
  tac: number;
}

export interface IDetalheVeiculo {
  idtransportadora: string;
  placa: string;
  uf: string;
  rntrc: string;
  renavam: string;
  tara: number;
  capacidadekg: number;
  capacidadem3: number;
  tiporoda: number;
  tipocarroceria: number;
  tac: number;
}

type TotalCount = {
    data: IListagemVeiculos[];
    totalCount: number;
}

const getAll = async (idtransportadora: string, page = 1, tipo = '', filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/veiculos?idtransportadora=${idtransportadora}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&tipo=${tipo}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheVeiculo | Error> => {
  try {
    const { data } = await Apicadastro.get(`/veiculos/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheVeiculo): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalheVeiculo>('/veiculos',  {idveiculo: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheVeiculo): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/veiculos/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/veiculos/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const getAllList = async (idtransportadora = '', filter = '', id = ''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/veiculoslist?limit=${Environment.LIMITE_DE_LINHAS}&idtransportadora=${idtransportadora}&filter=${filter}` : `/veiculoslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return { 
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const VeiculosService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  getAllList,
};