import { v4 as uuidv4 } from 'uuid';
import { Apiestoque } from '../../axiosestoque';
import { Environment } from '../../../../environment';


export interface IListagemLocalizacao {
  idlocalizacao: string;
  fazenda: string;
  descricao: string;
  status: boolean;
}

export interface IDetalheLocalizacao {
  idfazenda: string;
  descricao: string;
  status: boolean;
}

type TComTotalCount = {
    data: IListagemLocalizacao[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/localizacao?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&filter=${filter}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllList = async (idgrupo = '', filter = '', id = ''): Promise<TComTotalCount | Error> => {

  try {
    
    const urlRelativa: string = id === '' ? `/localizacaolist?limit=${Environment.LIMITE_DE_LINHAS}&idgrupo=${idgrupo}&filter=${filter}` : `/localizacaolist/${id}` ;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return { 
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheLocalizacao | Error> => {
  try {
    const { data } = await Apiestoque.get(`/localizacao/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheLocalizacao): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apiestoque.post<IDetalheLocalizacao>('/localizacao',  {idlocalizacao: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheLocalizacao): Promise<void | Error> => {
  try {
    await Apiestoque.put(`/localizacao/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/localizacao/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const LocalizacaoService = {
  getAll,
  getAllList,
  create,
  getById,
  updateById,
  deleteById,
};