import { Environment } from '../../../../environment';
import { Apicadastro } from '../../axioscadastro';

export interface IListagemContaBancaria {
  idconta: number;
  nomebanco: string;
  agencia: string;
  numeroconta: string;
  status: boolean;
  titular: string;
  ir: boolean;
  banco: string;
}

export interface IDetalheContaBancaria {
  nomebanco: string;
  agencia: string;
  numeroconta: string;
  status: boolean;
  titular: string;
  ir: boolean;
  banco: string;
}

type TComTotalCount = {
    data: IListagemContaBancaria[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/contabancaria?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllList = async (filter = '', id = ''): Promise<TComTotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ?  `/contabancarialist?limit=5&filter=${filter}` : `/contabancarialist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheContaBancaria | Error> => {
  try {
    const { data } = await Apicadastro.get(`/contabancaria/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheContaBancaria): Promise<string | Error> => {
  try {

    const { data } = await Apicadastro.post<IDetalheContaBancaria>('/contabancaria', dados);

    if (Object.keys(data).length === 0) {
      return 'ok';
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheContaBancaria): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/contabancaria/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/contabancaria/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const ContaBancariaService = {
  getAll,
  getAllList,
  create,
  getById,
  updateById,
  deleteById,
};