
import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, VTextFieldCpf, VTextFieldTelefone, VTextFieldCep, cpfValidator, VTextFieldNumber } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { AutoCompleteMunicipio } from '../../../shared/forms/AutoCompleteMunicipio';
import { ProdutorService } from '../../../shared/services/api/cadastros/ProdutorService';

interface IFormData {
  nome: string;
  cpf: string;
  endereco: string;
  numero: number;
  complemento: string;
  bairro: string;
  cep: string;
  email: string;
  numtel: string;
  idmunicipio: string;
  ie: string;
  obs: string;
  status: boolean;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  nome: yup.string().required().min(3).max(100),
  cpf: yup.string().required().max(150).test('cpf', 'CPF inválido', cpfValidator),
  endereco: yup.string().required().max(100),
  numero: yup.number().transform((value, originalValue) => (originalValue === '' ? undefined : value)).required('O campo é obrigatório').typeError('O campo deve ser um número válido'),
  complemento: yup.string().optional().default(''),
  bairro: yup.string().required().max(60),
  cep: yup.string().required().max(20),
  email: yup.string().email().default(''),
  numtel: yup.string().required(),
  idmunicipio: yup.string().required().max(60),
  ie: yup.string().required(),
  obs: yup.string().optional().default(''),
  status: yup.boolean().optional().default(true),
});


export const DetalheDeProdutor: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      ProdutorService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/produtores');
          } else {
            setDescricao(result.nome);
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        nome: '',
        cpf: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        email: '',
        numtel: '',
        idmunicipio: '',
        ie: '',
        obs: '',
        status: true,
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          ProdutorService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/produtores');
                } else {
                  navigate(`/produtores/detalhe/${result}`);
                }
              }
            });
        } else {
          ProdutorService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/produtores');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ProdutorService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/produtores');
          }
        });
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Produtor' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvar={false}
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/produtores')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/produtores/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  size='small'
                  name='nome'
                  disabled={isLoading}
                  label='Nome'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>   
                <VTextFieldCpf
                  fullWidth
                  size='small'
                  name='cpf'
                  disabled={isLoading}
                  label='CPF/CNPJ'
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>          
                <VTextField
                  fullWidth
                  size='small'
                  name='ie'
                  disabled={isLoading}
                  label='Inscrição Estadual'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>     
                <AutoCompleteMunicipio isExternalLoading={isLoading}/>     
              </Grid>
              <Grid item xs={9} sm={9} md={6} lg={4} xl={4}>          
                <VTextField
                  fullWidth
                  size='small'
                  name='endereco'
                  disabled={isLoading}
                  label='Endereço'
                />
              </Grid>
              
              <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>          
                <VTextFieldNumber
                  fullWidth
                  size='small'
                  name='numero'
                  disabled={isLoading}
                  label='Número'
                />
              </Grid>
              <Grid item xs={6} sm={12} md={3} lg={2} xl={2}>          
                <VTextFieldCep
                  fullWidth
                  size='small'
                  name='cep'
                  disabled={isLoading}
                  label='CEP'
                />
              </Grid>
              <Grid item xs={6} sm={12} md={6} lg={4} xl={4}>          
                <VTextField
                  fullWidth
                  size='small'
                  name='bairro'
                  disabled={isLoading}
                  label='Bairro'
                />
              </Grid>
              <Grid item xs={6} sm={12} md={6} lg={4} xl={4}>          
                <VTextField
                  fullWidth
                  size='small'
                  name='complemento'
                  disabled={isLoading}
                  label='Complemento'
                />
              </Grid>
              <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                <VTextFieldTelefone
                  fullWidth
                  size='small'
                  name='numtel'
                  disabled={isLoading}
                  label='Telefone'
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>          
                <VTextField
                  fullWidth
                  size='small'
                  name='email'
                  disabled={isLoading}
                  label='E-mail'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={1} xl={1}>
                {(id != 'novo') && (
                  <VSwitch
                    name='status'
                    label='Ativo'
                    fdisabled={isLoading}
                    aliamento='top'
                  />
                )}
              </Grid> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <VTextField
                  fullWidth
                  name='obs'
                  disabled={isLoading}
                  label='Observações'
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};