import { Environment } from '../../../environment';
import { Apicadastro } from '../axioscadastro';

export interface IListagemConta {
  idconta: number;
  nomebanco: string;
  agencia: string;
  numeroconta: string;
  status: boolean;
  titular: string;
  banco: string;
}

type TComTotalCount = {
    data: IListagemConta[];
    totalCount: number;
}

// const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
//   try {
//     const urlRelativa = `/produtores?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

//     const { data, headers } = await Apicadastro.get(urlRelativa);

//     if (data) {
//       return {
//         data,
//         totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
//       };
//     }

//     return new Error('Erro ao listar os registros.');
//   } catch (error) {
//     return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
//   }
// };
const getAllList = async (filter = '', id = ''): Promise<TComTotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ?  `/contabancarialist?limit=5&filter=${filter}` : `/contabancarialist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

// const getById = async (id: string): Promise<IDetalheCei | Error> => {
//   try {
//     const { data } = await Api.get(`/cei/${id}`);

//     if (data) {
//       return data;
//     }

//     return new Error('Erro ao consultar o registro.');
//   } catch (error) {
//     console.error(error);
//     return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
//   }
// };

// const create = async (dados: IDetalheCei): Promise<void | Error> => {
//   try {
//     const { data } = await Api.post<IDetalheCei>('/cei', dados);

//     if (data) {
//       return;
//     }

//     return new Error('Erro ao criar o registro.');
//   } catch (error) {
//     console.error(error);
//     return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
//   }
// };

// const updateById = async (id: string, dados: IDetalheCei): Promise<void | Error> => {
//   try {
//     await Api.put(`/cei/${id}`, dados);
//   } catch (error) {
//     console.error(error);
//     return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
//   }
// };

// const deleteById = async (id: string): Promise<void | Error> => {
//   try {
//     await Api.delete(`/cei/${id}`);
//   } catch (error) {
//     console.error(error);
//     return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
//   }
// };


export const ContaBancariaService = {
// getAll,
  getAllList,
//   create,
//   getById,
//   updateById,
//   deleteById,
};