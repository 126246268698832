/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { LayoutBaseDeDashboard, LayoutBaseDePagina, LayoutBaseDePaginaNfe } from '../../../shared/layouts';
import OutlinedCard2 from '../../../shared/forms/OutlinedCard2';
import { useEffect, useState } from 'react';


import { BarChart } from '@mui/x-charts';
import GraficoArea from '../../../shared/forms/GraficoArea';
import GraficoRosca from './GraficoRosca';
import { getTemaSistema } from '../../../shared/contexts';
import GraficoColuna from './GraficoColuna';
import OutlinedCard2Financeiro from './OutlinedCard2Financeiro';
import { DashboardFinanceiroService } from '../../../shared/services/api/Financeiro/dashboardFinanceiro/DashboardFinanceiroService';

export const DashboardFinanceiro = () => {
  const teme = getTemaSistema();
  const [tema, setTema] = useState<'dark' | 'light'>('light');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (teme === 'dark') {
      setTema('dark');
    } else {
      setTema('light');
    }
  }, [teme]); 
  const [Server, setServer] = useState(
    {
      nivel: 1,

      receber: 0,
      pagar: 0,
      recebervencido: 0,
      pagarvencido: 0,

      receitadespesa:[],

      contasreceitas:[],
      contasdespesas:[],

      contasreceitasdia:[],
      contasdespesasdia:[],

      fazendasreceitas:[],
      fazendasdespesa:[],
    }
  );

  const seriesData = extrairValores(Server.receitadespesa);
  const labels = extrairNomes(Server.receitadespesa);

  useEffect(() => {
    setLoading(true);
    DashboardFinanceiroService.GetDashboardCadastroService()
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setServer(result);
          setLoading(false);
        }
      });
  },[]); 


  type Extrair = {
    titulo: string;
    valor: number;
  };
  type ExtrairValor = {
    valor: number;
  };

  function extrairNomes(titulo: Extrair[]): string[] {
    return titulo ? titulo.map(titulo => titulo.titulo) : [];
  }

  function extrairValores(titulo: Extrair[]): number[] {
    return titulo ? titulo.map(titulo => titulo.valor) : [];
  }

  function extrairValores2(titulo: ExtrairValor[]): number[] {
    return titulo ? titulo.map(titulo => titulo.valor) : [];
  }




  const seriesDataContas = [
    {
      name: 'Receita',
      data: extrairValores(Server.contasreceitas) ?? [],
    },
    {
      name: 'Despesa',
      data: extrairValores(Server.contasdespesas) ?? [],
    }
  ];

  const optionsDataContas = {
    title: {
      text: 'Receitas e Despesas dos Últimos 12 Meses',
    },
    colors:['#00E396', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.contasreceitas) 
    } 
  };

  const seriesDataContasDia = [
    {
      name: 'Receita',
      data: extrairValores2(Server.contasreceitasdia) ?? [],
    },
    {
      name: 'Despesa',
      data: extrairValores2(Server.contasdespesasdia) ?? [],
    }
  ];

  const optionsDataContasDia = {
    title: {
      text: 'Receitas e Despesas Diárias',
    },
    colors:['#00E396', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    } 
  };
  

  const seriesDataFazendas = [
    {
      name: 'Receita',
      data: extrairValores(Server.fazendasreceitas) ?? [],
    },
    {
      name: 'Despesa',
      data: extrairValores(Server.fazendasdespesa) ?? [],
    }
  ];

  const optionsDataFazendas = {
    title: {
      text: 'Receitas e Despesas de Fazendas dos Últimos 12 Meses',
    },
    colors:['#00E396', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.fazendasdespesa)
    },
    
  };




  return (
    <LayoutBaseDeDashboard>
      <Box display="flex" component={Paper} variant="outlined" >
        <Grid container item direction="row" >
          {(Server.nivel === 2) && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <OutlinedCard2Financeiro
                  nome="Total a Receber"
                  valor={parseFloat(Server.receber.toFixed(2))} 
                  cor="#82D2ED"
                  img='/img/receber.png' 
                  loading={loading}
                />
              </Grid><Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <OutlinedCard2Financeiro
                  nome="Total a Pagar"
                  valor={parseFloat(Server.pagar.toFixed(2))}
                  cor="#DEED82"
                  img='/img/pagar.png' 
                  loading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <OutlinedCard2Financeiro
                  nome="Total a Receber Vencido"
                  valor={parseFloat(Server.recebervencido.toFixed(2))}
                  cor="#82D2ED"
                  img='/img/receber.png' 
                  loading={loading}
                />
              </Grid><Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <OutlinedCard2Financeiro
                  nome="Total a Pagar Vencido"
                  valor={parseFloat(Server.pagarvencido.toFixed(2))}
                  cor="#DEED82"
                  img='/img/pagar.png' 
                  loading={loading}
                />
              </Grid>
            </>   
          )}         
        </Grid>
          
      </Box>
      <Box display="flex" component={Paper} variant="outlined">
        <Grid container item direction="row" >
          {(Server.nivel === 1) && (
            <Grid container style={{ height: '95vh' }}>
              <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography sx={{ fontSize: 150, color: '#757575', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} gutterBottom component="div">
                  Financeiro
                </Typography>
              </Grid>
            </Grid>
          )}

          {(Server.nivel === 2) && (
            <>
              <Grid component={Paper} item xs={12} sm={12} md={12} lg={5} xl={3}>
                <Box margin={2}>
                  <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} gutterBottom component="div">
                    Receitas e Despesas dos Últimos 12 Meses
                  </Typography>
                  {loading ? <Skeleton variant="rectangular" height={215}/> : 
                    <GraficoRosca
                      series={seriesData}
                      chart={{
                        type: 'donut',
                      }}
                      width={320}
                      labels={labels}
                      themeMode={tema}
                    />
                  }
                </Box>
              </Grid>
              <Grid component={Paper} item xs={12} sm={12} md={12} lg={7} xl={9}>
                <Box margin={2}>
                  {loading ? <Skeleton variant="rectangular" height={215}/> : 
                    <GraficoArea
                      series={seriesDataContas} 
                      options={optionsDataContas}
                      height={215}
                    />
                  }
                </Box>
              </Grid>
              <Grid component={Paper} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box margin={2}>
                  {loading ? <Skeleton variant="rectangular" height={215}/> : 
                    <GraficoArea
                      series={seriesDataContasDia} 
                      options={optionsDataContasDia}
                      height={215}
                    />
                  }
                </Box>
              </Grid>
              <Grid component={Paper} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box margin={2}>
                  {loading ? <Skeleton variant="rectangular" height={215}/> : 
                    <GraficoColuna
                      series={seriesDataFazendas} 
                      options={optionsDataFazendas}
                      height={215}
                    />
                  }
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

    </LayoutBaseDeDashboard>
  );
};