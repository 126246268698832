/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Avatar,  Checkbox,  LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { LayoutBaseDeFolha, } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { FolhaService, ITipoPgtoCheque } from '../../../shared/services/api/rh/folha/FolhaService';
import { FerramentasDaListagemFolhaCheque } from '../../../shared/components';
import ImprTipoPagamentoCheque from './ImprTipoPagamentoCheque';
import PDFCheques from './ImprmirCheque';
     
export const ListagemFolhaCheque: React.FC = () => {
  const { id = '' } = useParams<'id'>();
  const { ano = '' } = useParams<'ano'>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();  
  const [rows, setRows] = useState<ITipoPgtoCheque[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [fazenda, setFazenda] = useState('');
  const [nomefazenda, setNomeFazenda] = useState('');
      
             
  const pagina = useMemo(() => {   
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const handleFazenda = (uuid: string, nome: string) => {
    setIsLoading(true);  
    setNomeFazenda(nome);
    FolhaService.TipoPagamentoCheque(1, uuid, `${id}/${ano}`)
      .then((result) => {
        setIsLoading(false);  
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
  };         

  const handleImprimirFolha = () => {
    ImprTipoPagamentoCheque(rows, `${id}/${ano}`, nomefazenda);
  };

  const handleImprimirCheque = () => {
    const filteredList = rows.filter((item) => item.marcado === true);
    PDFCheques(filteredList);  
  };
       
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const index = rows.findIndex(row => row.idfolha === id);
    if (index === -1) return; 
    const updatedRow = { ...rows[index], marcado: checked };
    const updatedRows = [...rows.slice(0, index), updatedRow, ...rows.slice(index + 1)];
    setRows(updatedRows);
  };
       
  return (
    <LayoutBaseDeFolha
      titulo='Folha de Pagamento por Cheque'
      barraDeFerramentas={
        <FerramentasDaListagemFolhaCheque
          aoClicarEmImprimirFolha={handleImprimirFolha}
          aoClicarEmImprimirCheque={handleImprimirCheque}
          aoMudarTextoDoFazenda={handleFazenda}
        />     
      }              
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto',  marginLeft: 3,  marginRight: 2 }}>
        <Table>
          <Table size="small">   
            <TableHead>
              <TableRow>
                <TableCell  sx={{ maxWidth: 10, width:10 }} size="small" aria-label="a dense table"></TableCell>
                <TableCell>Colaborador</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell align='right' sx={{ maxWidth: 100, width:100 }}>Valor</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idfolha}    
                  hover
                  sx={{ cursor: 'pointer' }}  
                >
                  <TableCell><Avatar alt="F" src={Environment.URL_IMGFUNCIONARIO+row.urlimg}/></TableCell>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</TableCell>
                  <TableCell align='right'>{row.total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                  <TableCell> 
                    <Checkbox
                      checked={row.marcado} id={row.idfolha} onChange={handleChange}
                    />
                  </TableCell>       
                </TableRow>   
              ))}
            </TableBody> 
   
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHASSMALL) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination  
                      page={pagina}
                      count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHASSMALL)}
                      onChange={(_, newPage) => setSearchParams({ fazenda, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
    </LayoutBaseDeFolha>
  ); 
};