/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apiestoque } from '../../axiosestoque';
import { ErrorResponse } from 'react-router-dom';

type NullableDate = Date | null | undefined;

interface IImage {
  imgPath: string;
}

export interface IListagemOS {
  idos: string;
  numero: number;
  dataabertura: Date;
  patrimonio: string;
  nomepatrimonio: string;
  modelo: string;
  situacao: string;
}

export interface IDetalheOS {
  idpatrimonio: string;
  idfazenda: string;
  operador: string; 
  mecanico: string; 
  dataabertura: Date;
  datafechamento?: NullableDate;
  defeitoreclamado: string; 
  defeitoconstatado: string;
  servicosrealizados: string;
  situacao: number;
}

interface IgetOS {
  idpatrimonio: string;
  idfazenda: string;
  operador: string; 
  mecanico: string; 
  dataabertura: Date;
  datafechamento?: NullableDate;
  defeitoreclamado: string; 
  defeitoconstatado: string;
  servicosrealizados: string;
  situacao: number;
  npatrimonio: string;
  numero: number;
  nomepatrimonio: string;
  modelo: string;
  itens: IitensSaidaDialog[];
  fotos: IImage[];
}

interface IItensids{
  iditens: string;
}

export interface IitensSaidaDialog{
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: string | null;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;
  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean;
}

type TotalCount = {
    data: IListagemOS[];
    totalCount: number;
}

const getAll = async (page = 1, dataInicial = '', dataFinal='', fazenda='', filter = '', tipo=''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/os?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&tipo=${tipo}&datainicial=${dataInicial}&datafinal=${dataFinal}&fazenda=${fazenda}&filter=${filter}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) { 
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IgetOS | Error> => {
  try {
    const { data } = await Apiestoque.get(`/os/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheOS, dadositens: IitensSaidaDialog[]): Promise<string | Error> => {
  try {
  
    const guuid = uuidv4();
    const dadosExtraidos = dadositens.map(({ qtdestoque, referencia, descricao, localizacao, reqvencimento, filtro, ...restoDoObjeto }) => ({ idoperacao: guuid,
      tipo: 2, forma: 4, ...restoDoObjeto }));
    const dadosOS = {idos: guuid, ...dados};

    const { data } = await Apiestoque.post<IDetalheOS>('/os',  [dadosOS, dadosExtraidos]);
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lançar Entrada');
    }
  }
};

const updateById = async (id: string, dados: IDetalheOS, dadositens: IitensSaidaDialog[], iddeletados: IItensids[]): Promise<void | Error> => {
  try {
    const dadosExtraidos = dadositens.map(({ qtdestoque, referencia, descricao, reqvencimento, filtro, ...restoDoObjeto }) => ({ idoperacao: id, 
      tipo: 2, forma: 4, ...restoDoObjeto }));
    const dadosOS = {idos: id, ...dados};

    await Apiestoque.put(`/os/${id}`, [dadosOS, dadosExtraidos, iddeletados]);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/os/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const OsService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};