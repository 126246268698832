/* eslint-disable @typescript-eslint/no-unused-vars */
import {  useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField } from '@mui/material';
import * as yup from 'yup';
import { FeriasService } from '../../../shared/services/api/rh/ferias/FeriasService';
import { IVFormErrors, useVForm, VFieldMoney, VForm, VTextFieldData } from '../../../shared/forms';

interface IFormData {
  idfun: string;
  datainicial: Date;
  datafinal: Date;
  valorinss: number;
}

export interface ConfirmationDialogRawProps {
  id: string;
  idfun: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

const FeriasSchema = yup.object().shape({
  datainicial: yup.date().required().test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();
    if (!value || (value.getMonth() < currentDate.getMonth() && value.getFullYear() <= currentDate.getFullYear())) {
      return false;
    }
    return true;
  }),
  datafinal: yup.date().required().test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();
    if (!value || (value.getMonth() < currentDate.getMonth() && value.getFullYear() <= currentDate.getFullYear())) {
      return false;
    }
    return true;
  }),
  valorinss: yup.number().required(),
}); 

export const DialogFeriasAdd: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
  const { formRef, save } = useVForm();
  const { onClose, open, ...other } = props;
  const [isLoading, setIsLoading] = useState(false);
 
  const handleCancel = () => {
    onClose();
  };
  const handleSave = (dados: IFormData) => {

    FeriasSchema.validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        FeriasService.create({idfun: props.idfun, ...dadosValidados})
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              onClose();
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <Dialog
      open={open}
      {...other}      
    >
      <DialogTitle>Adicionar Férias</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef} onSubmit={handleSave}>
          <Box display="flex" padding={3} gap={1} component={Paper} variant="outlined">
            <Grid container marginTop={1} item direction="row" spacing={1.5} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1.5}>
                  <Grid item xs={6} sm={12} md={3} lg={4} xl={4}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='datainicial'
                      label='Data de Inicial'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={3} lg={4} xl={4}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='datafinal'
                      label='Data de Final'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={3} lg={4} xl={4}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='valorinss'
                      label='Valor'
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </VForm> 
      </DialogContent>
      <DialogActions>
        <Button size='small' sx={{ minWidth:'30%'  }} variant='outlined' onClick={handleCancel}>Voltar</Button>      
        <Button size='small' sx={{ minWidth:'30%'  }} variant='contained' onClick={save}>Adicionar</Button>     
      </DialogActions>
    </Dialog>
  );
}; 