/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid,  LinearProgress,   Paper } from '@mui/material';
import { useState } from 'react';
import { AutoCompletePeriodo01 } from '../../../shared/forms';
import { FolhaService } from '../../../shared/services/api/rh/folha/FolhaService';
import ImpressaoFolha from './ImpressaoFolha';
           
export const GeraFolha: React.FC = () => {                      
  const [isLoading, setIsLoading] = useState(false); 
  const [periodo, setPeriodo] = useState('');  
  const [periodos, setPeriodos] = useState('');  
  
  const handlPeriodo = (fperiodo: string | undefined) => {
    if (fperiodo != null) {
      setIsLoading(true);
      const ames = fperiodo.slice(0, -5);         
      const aano = fperiodo.slice(-4);
      const aperiodo = `${ames}%2f${aano}`;   
      const aperiodos = `${ames}/${aano}`;    
      setPeriodos(aperiodos);    
      setPeriodo(aperiodo);      
      setIsLoading(false);            
    }       
  };
                      
  const handleGeraFolha = () => {            
    setIsLoading(true);                         
    FolhaService                 
      .create(periodo)      
      .then((result) => {           
        setIsLoading(false);              
        if (result instanceof Error) {                
          alert(result.message);                     
        }else {   
          alert('Folha Gerada Com Sucesso!');  
        }             
      });              
  };   

  const handleExcluir = () => {    
    if (confirm('Realmente deseja Excluir a Folha?')) {
      setIsLoading(true);                         
      FolhaService                 
        .deleteFolha(periodo)   
        .then((result) => {           
          setIsLoading(false);              
          if (result instanceof Error) {                  
            alert(result.message);                  
          }else {   
            alert('Folha Excluida Com Sucesso!');  
          }             
        });         
    }             
  };   
   
  const handleImprimir = () => {                              
    setIsLoading(true);                              
    FolhaService              
      .ImprimirFolha(periodo)   
      .then((result) => {              
        setIsLoading(false);              
        if (result instanceof Error) {                      
          alert(result.message);                              
        }else {    
          if(result.length === 0 ){
            alert('Não a Registro');       
          }else{
            ImpressaoFolha(result, periodos);   
          }          
                
        }                              
      });                                               
  };                                                                  
  return (
    <Box  alignItems="center" width='auto' alignContent='center' height="auto"  display="flex" flexDirection="column"  >
      <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='50%' height="20%" margin={1}  alignContent='center'></Box>
      <Box   width='auto' alignItems="center"  component={Paper} height="auto" margin={1} 
        alignContent='center'>       
        <DialogTitle>Folha de Pagamento</DialogTitle>
        <DialogContent  dividers>    
          {isLoading && (             
            <Grid item>        
              <LinearProgress variant='indeterminate' />       
            </Grid>                              
          )}   
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompletePeriodo01 isExternalLoading={isLoading} novoperiodo={handlPeriodo} />
              </Grid> 
            </Grid> 
          </Grid>         
        </DialogContent>     
        <DialogActions>
          <Button variant='contained' color='error'  autoFocus onClick={handleExcluir}>Excluir Folha</Button>
          <Button variant='outlined' autoFocus onClick={handleImprimir}>Imprimir Folha</Button>
          <Button variant='contained' autoFocus onClick={handleGeraFolha}>Gera Folha</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   