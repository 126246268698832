import { Box, Grid, LinearProgress, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import NavTabs from './NavTabs';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { FerramentasDaListagemPontoAnual } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemPontoAnual';
import { PontoAnualService } from '../../../shared/services/api/rh/colaborador/PontoAnualService';
import GraficoArea from '../../../shared/forms/GraficoArea';
import { getTemaSistema } from '../../../shared/contexts';

interface IDetalhesPonto{
  titulo: string;
  ponto: number;
  falta: number;
  atestado: number;
  horaextra: number;
  bomba: number;
  pistola: number;
  cafe: number;
  almoco: number;
  janta: number;
}

export const ListagemDePontoAnual: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<IDetalhesPonto[]>([]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const idFun = useMemo(() => {
    return searchParams.get('idfun') || '';
  }, [searchParams]);

  const ano = useMemo(() => {
    return searchParams.get('ano') || new Date().getFullYear().toString();
  }, [searchParams]);

  
  const teme = getTemaSistema();
  const [tema, setTema] = useState<'dark' | 'light'>();

  useEffect(() => {
    if (teme === 'dark') {
      setTema('dark');
    } else {
      setTema('light');
    }
  }, [teme]);

  type Extrair = {
    titulo: string;
    valor: number;
  };

  function extrairNomes(titulo: Extrair[] | undefined): string[] {
    return titulo ? titulo.map(titulo => titulo.titulo) : [];
  }
  
  function extrairValores(titulo: Extrair[] | undefined): number[] {
    return titulo ? titulo.map(titulo => titulo.valor) : [];
  }

  const [Server, setServer] = useState(
    {        
      presenca:[], 
      falta:[],
      atestado:[],
    
      cafe:[], 
      almoco:[],
      janta:[],
        
      horaextra:[], 
      bomba:[],
      pistola:[],
    }
  );


  //Ponto Gráfico Área
  const seriesDataPonto = [
    {
      name: 'Presença',
      data: extrairValores(Server.presenca) ?? [],
    },
    {
      name: 'Falta',
      data: extrairValores(Server.falta) ?? [],
    },
    {
      name: 'Atestado',
      data: extrairValores(Server.atestado) ?? [],
    }
  ];
  const optionsDataPonto = {
    title: {
      text: 'Ponto',
    },
    colors:['#00E396', '#FF4560', '#14AEE0'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema, 
    },
    xaxis: { 
      categories: extrairNomes(Server.presenca) 
    }
  };
  //Alimentação Gráfico Área
  const seriesDataAlimentacao = [
    {
      name: 'Café',
      data: extrairValores(Server.cafe) ?? [],
    },
    {
      name: 'Almoço',
      data: extrairValores(Server.almoco) ?? [],
    },
    {
      name: 'Janta',
      data: extrairValores(Server.janta) ?? [],
    },
  ];
  const optionsDataAlimentacao = {
    title: {
      text: 'Alimentação',
    },
    colors:['#00E396', '#14AEE0', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.cafe) 
    }
  };
  //Extra Gráfico Área
  const seriesDataExtra = [
    {
      name: 'Hora Extra',
      data: extrairValores(Server.horaextra) ?? [],
    },
    {
      name: 'Bomba',
      data: extrairValores(Server.bomba) ?? [],
    },
    {
      name: 'Pistola',
      data: extrairValores(Server.pistola) ?? [],
    },
  ];
  const optionsDataExtra = {
    title: {
      text: 'Extra',
    },
    colors:['#00E396', '#14AEE0', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.horaextra) 
    }
  };


  useEffect(() => {
    if (idFun != ''){
      setIsLoading(true);
      PontoAnualService.getAll(idFun, ano)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setServer(result);
            setRows(result.detalhes);
          }
        });
    }
  }, [pagina, idFun, ano]);

  return (
    <LayoutBaseDePagina
      titulo='Ponto Anual'
      barraDeFerramentas={
        <FerramentasDaListagemPontoAnual
          anoInicial={ano}
          aoSelecionar={ano => setSearchParams({ idfun: idFun, pagina: '1', ano: ano}, { replace: true })}
        />
      }
    >
      <Box display="flex" flexDirection="column" component={Paper} variant="outlined">
        <Grid container direction="column" padding={2}>
          {isLoading && (
            <Grid item>
              <LinearProgress variant='indeterminate' />
            </Grid>
          )}
          <NavTabs
            id={idFun}  
            case={3}
          />
          
          <Grid container item direction="row"  spacing={3}>
            <Grid container item direction="row" padding={2} spacing={2} gap={2} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <Box display="flex" component={Paper} variant="outlined">
                  <Grid container item direction="row" >
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper elevation={5}>
                          {isLoading ? <Skeleton variant="rectangular" height={260}/> : 
                            <GraficoArea
                              series={seriesDataPonto}
                              options={optionsDataPonto} />
                          }
                        </Paper>
                      </Grid>
                      <Grid  item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Paper elevation={5}>
                          {isLoading ? <Skeleton variant="rectangular" height={260}/> : 
                            <GraficoArea
                              series={seriesDataAlimentacao}
                              options={optionsDataAlimentacao} />
                          }
                        </Paper>
                      </Grid>
                      <Grid  item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Paper elevation={5}>
                          {isLoading ? <Skeleton variant="rectangular" height={260}/> : 
                            <GraficoArea
                              series={seriesDataExtra}
                              options={optionsDataExtra} />
                          }
                        </Paper>
                      </Grid>
                    </>
                  </Grid>  
                </Box>  
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined" sx={{width: 'auto', marginRight: 2 }}>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: 60 }} align='center' >Mês</TableCell>
                        <TableCell style={{ width: 60 }}>Presença</TableCell>
                        <TableCell style={{ width: 60 }} align='center' >Falta</TableCell>
                        <TableCell style={{ width: 60 }} align='center' >Atestado</TableCell>
                        <TableCell style={{ width: 60}} align='right'>Hora Extra</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Bomba</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Pistola</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Café</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Almoço</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Janta</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.titulo} 
                          hover
                        >
                          <TableCell style={{ width: 60 }} align='center' >{row.titulo}</TableCell>
                          <TableCell style={{ width: 60 }} >{row.ponto}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.falta}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.atestado}</TableCell>
                          <TableCell style={{ width: 60 }} align='right'>{row.horaextra.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.bomba}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.pistola}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.cafe}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.almoco}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.janta}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>  
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}

                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>        
        </Grid>
      </Box>
    </LayoutBaseDePagina>
  );
};