import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthService } from '../services/api/auth/AuthService';
import { Apicadastro } from '../services/api/axioscadastro';

interface IAuthContextData {
  logout: () => void;
  isAuthenticated: boolean;
  isSistema: boolean;
  login: (usuario: string, senha: string) => Promise<string | void>;
  sistema: (sistemas: string)  => void;
  logoutsistema: () => void;
}

const AuthContext = createContext({} as IAuthContextData);


const LOCAL_STORAGE_KEY__ACCESS_TOKEN = 'APP_ACCESS_TOKEN';

interface IAuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string>();
  const [accessSistema, setAccessSistema] = useState<string>();

  useEffect(() => { 
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);

    if (accessToken) {
      setAccessToken(JSON.parse(accessToken));
    } else {
      setAccessToken(undefined);
    }

  }, []);

  const handleLogin = useCallback(async (usuario: string, senha: string) => {
    const result = await AuthService.auth(usuario, senha);
    if (result instanceof Error) {
      return result.message;
    } else {
      Apicadastro.defaults.headers.authorization = `Bearer ${result.accessToken}`;
      localStorage.setItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN, JSON.stringify(result.accessToken));
      setAccessToken(result.accessToken);
      window.location.reload();
    }
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);
    setAccessToken(undefined);
    Apicadastro.defaults.headers.authorization = null;
    setAccessSistema('');
  }, []);

  const handleLogoutsistema = useCallback(() => {
    setAccessSistema('');
  }, []);

  const handleSistema = useCallback((sistemas: string) => {
    setAccessSistema(sistemas);
  }, []);

  const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);


  const isSistema = useMemo(() => !!accessSistema, [accessSistema]);


  return (
    <AuthContext.Provider value={{ isAuthenticated, isSistema, sistema: handleSistema,  logoutsistema: handleLogoutsistema,  login: handleLogin, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);