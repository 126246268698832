/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, DialogContent, DialogTitle, Grid,  LinearProgress,  Paper,  TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { AutoCompleteLocalizacao01 } from '../../../shared/forms';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { RelatorioEstoqueService } from '../../../shared/services/api/estoque/Relatorios/RelatoriosService';
import RVencimentos from './RVencimentos';
import RVencimentosdias from './RVencimentosdias';

export const RelatoriosVencimentos: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [idLocalizacao, setIdLocalizacao] = useState(''); 
  const [localizacao, setLocalizacao] = useState(''); 
  const [dias, setDias] = useState('');

  

  const handleuuidLocalizacao = (uuid: string | undefined) => {
    if (uuid != null) {
      setIdLocalizacao(uuid);
    }
  }; 

  const handleuuidLocalizacaotext = (descricao: string | undefined) => {
    if (descricao != null) {
      setLocalizacao(descricao);
    }
  }; 
  
  const handleVencidos = () => {
    if(idLocalizacao === '' ){
      alert('Informe uma Localização');
    }else{
      setIsLoading(true);
      RelatorioEstoqueService
        .Vencimentos(idLocalizacao, 'vencidos', '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            if(result.length > 0){
              RVencimentos(result, localizacao, '');
            }else{
              alert('Não a Registros'); 
            }     
          }
        });  
    }   
  };

  const handleVencer = () => {
    if(idLocalizacao === '' || dias === ''){
      alert('Informe uma Localização ou  Quantidade de dias');
    }else{
      setIsLoading(true);
      RelatorioEstoqueService  
        .Vencimentos(idLocalizacao, 'vencer', dias)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {  
            alert(result.message);
          } else {  
            if(result.length > 0){
              RVencimentos(result, localizacao, dias);
            }else{
              alert('Não a Registros'); 
            }    
          }
        });
    }  
  };

  const handleVencendo = () => {
    if(idLocalizacao === ''){
      alert('Informe uma Localização');
    }else{
      setIsLoading(true);
      RelatorioEstoqueService
        .Vencimentos(idLocalizacao, 'vencendo', '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);  
          } else {
            if(result.length > 0){
              RVencimentosdias(result, localizacao, '');
            }else{
              alert('Não a Registros'); 
            }  
          }
        });
    }
  };

  return (
    <Box  alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
      <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='auto' height="20%" margin={1}  alignContent='center'></Box>
      <Box   width='auto' alignItems="center"  component={Paper} height="auto" margin={1} 
        alignContent='center'>          
        <DialogTitle>Relatório de Vencimentos</DialogTitle>
        <DialogContent  dividers>
          {isLoading && (
            <Grid item>
              <LinearProgress variant='indeterminate' />
            </Grid>
          )}
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                <AutoCompleteLocalizacao01
                  idLocalizacao={handleuuidLocalizacao}
                  localizacaoText={handleuuidLocalizacaotext}
                /> 
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize:18,
                    alignContent:'end'
                  }}
                >
                  Vencidos
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 40 }} color='primary'  onClick={() => {handleVencidos();}} />
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize:18,
                    alignContent:'end'
                  }}
                >
                  À Vencer em 
                </Typography>
              </Grid>
              <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  size='small'
                  value={dias}
                  sx={{width:80}}
                  onChange={e => setDias(e.target.value)}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 40 }} color='primary'  onClick={() => {handleVencer();}} />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize:18, 
                    alignContent:'end'
                  }}
                >
                  Vencendo
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 40 }} color='primary'  onClick={() => {handleVencendo();}} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>   
      </Box>
    </Box>
  );
};