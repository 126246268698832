/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';
import { Grid, Icon, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useDrawerContext } from '../contexts';
import OutlinedCard01 from '../forms/OutlinedCard01';


interface ILayoutBaseDePaginaProps {
    titulo: string;
    saldoanterior: string;
    credito: string;
    debito: string; 
    saldo: string;
    cheque: string;
    saldoreal: string; 
    children: React.ReactNode
    barraDeFerramentas?: ReactNode;
}

export const LayoutBaseDeFinanceiroConciliacao: React.FC<ILayoutBaseDePaginaProps> = ({ 
  children, 
  saldoanterior,
  credito, 
  debito,  
  saldo,
  cheque,
  saldoreal,   
  barraDeFerramentas,
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();
  
  const { toggleDrawerOpen } = useDrawerContext();

  return (
    <Box  height="auto" display="flex" flexDirection="column" gap={1} >
      <Box padding={1.5}  display="flex" alignItems="center" gap={1} >
        {smDown && (
          <IconButton onClick={toggleDrawerOpen}>
            <Icon>menu</Icon>
          </IconButton>
        )}      
        <Box flex={1} display="flex"   justifyContent="end">
          <Grid container item direction="row" spacing={0.5}>  
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
              <Grid container item direction="row" >  
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>  
                  <OutlinedCard01
                    cor='#4D8FDB'
                    nome='Saldo Anterior' 
                    valor={saldoanterior} 
                  />  
                </Grid>  
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>  
                  <OutlinedCard01
                    cor='#3789E6'
                    nome='Crédito'
                    valor={credito} 
                  />
                </Grid>  
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>  
                  <OutlinedCard01
                    cor='#E63746'
                    nome='Debito' 
                    valor={debito} 
                  />
                </Grid>  
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>  
                  <OutlinedCard01
                    cor='#23DEA1'
                    nome='Saldo' 
                    valor={saldo} 
                  />
                </Grid>  
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>  
                  <OutlinedCard01
                    cor='#E63746'
                    nome='Valor Cheques' 
                    valor={cheque} 
                  />
                </Grid>  
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>  
                  <OutlinedCard01
                    cor='#23DEA1'
                    nome='Saldo Real' 
                    valor={saldoreal} 
                  />
                </Grid>                 
              </Grid>   
            </Grid>
          </Grid>  
        </Box>
      </Box>

      {barraDeFerramentas && (
        <Box>
          {barraDeFerramentas}
        </Box>
      )}

      <Box padding={1}  overflow="auto">
        {children}
      </Box>
    </Box>
  );
};