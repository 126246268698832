/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import JsBarcode from 'jsbarcode';
 
interface Iitens{
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: string | null;
  qtdentrada: number;
  preco: number;
  un: string;
  conversor: number;
  total: number;
  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean; 
}

interface IgetImpressao {
  idsaida: string;
  idfazenda: string;
  idoperacoes: string;
  idparceiro: string;
  idcultivo: string; 
  idpivot: string;
  datalan: Date;
  dataoperacao: Date;
  total: number;
  obs: string;
  venda: boolean;
  nome: string;
  fazenda: string;
  itens: Iitens[];
}



async function PDFSaida(dados: IgetImpressao){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const produtos = dados.itens.map((producao) => {
    return [
      {text: producao.descricao, alignment: 'left', bold: true, border: [false, false, false, false], fontSize: 12},
    ]; 
  });  

  const quantidade = dados.itens.map((producao) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(producao.qtdsaida), alignment: 'right', bold: true, 
        border: [false, false, false, false],  fontSize: 10},
    ]; 
  });

  const unidade = dados.itens.map((producao) => {
    return [
      {text: producao.un, alignment: 'center', bold: true, border: [false, false, false, false], fontSize: 10},
    ]; 
  });  

  const preco = dados.itens.map((producao) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(producao.preco), alignment: 'right', bold: true, 
        border: [false, false, false, false],  fontSize: 10},
    ]; 
  });

  const total = dados.itens.map((producao) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(producao.total), alignment: 'right', bold: true, 
        border: [false, false, false, false],  fontSize: 10},
    ]; 
  });

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const gerarCodigoBarrasBase64 = (textoCodigo: string) => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, textoCodigo, { format: 'CODE128' });
    return canvas.toDataURL('image/png');
  };

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }

  const codigoBarras = gerarCodigoBarrasBase64(dados.idsaida);

  const documentDefinition = {

    content: [
      {
        style: 'tableExample',
        table: {  
          widths: [90, 420],
          body: [
            [ 
              {
                image: imageData,
                width: 90,
                border: [false, false, false, false]
              },
              { border: [false, false, false, false],
                style: 'tableExample',
                table: {
                  widths: [200, 218],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 22,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                      {text: 'PRODUÇÃO E COMERCIALIZACAO', margin: [0, 10, 0, 0], italics: true, bold: true,  color: '#008080', fontSize: 13, border: [false, false, false, false]}, 
                    ],
                    [ 
                      {text: 'Rodovia BA 172, KM 1, nº 80, São Felix do Cobribe - Ba - CEP 47.665-000 - Fone (77) 3491-2455', fontSize: 10, colSpan: 2, border: [false, false, false, true]},
                      {}, 
                    ],
                    [ 
                      {text: '', border: [false, false, false, false] },
                      {
                        image: codigoBarras, // Adiciona o código de barras gerado como imagem
                        width: 200,
                        border: [false, false, false, false]
                      },
                    ],
                  ]
                }  
              }, 
            ],
          ]
        }
      },
      {
        style: 'tableExample',
        table: {
          widths: [435, 80],
          body: [
            [ {text: 'Destinatario', fontSize: 8, border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [ 
              {text: ''+(dados.venda ? (
                'RAZAO SOCIAL / NOME\n'+ dados.nome
              ) : (
                'FAZENDA\n'+ dados.fazenda
              )), alignment: 'left',  fontSize: 10},
              {text: 'Data Emisão\n'+ moment(dados.dataoperacao).format('DD/MM/YYYY'), fontSize: 10, alignment: 'center',},
            ],
          ]
        }
      },
            
      {
        table:{
          widths: [ 218, 80, 30, 80, 80],
          heights:[2, 0, 150],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},

            ],
            [
              {text: 'Produtos', margin: [5, 0, 0, 0],  alignment: 'left', fontSize: 10},
              {text: 'Quant.', alignment: 'right',  fontSize: 10},
              {text: 'Unid', alignment: 'center', fontSize: 10},
              {text: 'Pço Unit', alignment: 'right',  fontSize: 10},
              {text: 'Total R$', alignment: 'right', fontSize: 10},
            ],
            [
              { 
                style: 'tableExample',
                table: {
                  body: [
                    ...produtos
                  ]
                }
              },
              { 
                margin: [26, 0, 0, 0],
                table: {
                  body: [      
                    ...quantidade
                  ]
                }
              },
              {
                margin: [5, 0, 0, 0],
                table: {
                  body: [      
                    ...unidade
                  ]
                }
              },
              {
                margin: [35, 0, 0, 0],
                table: {
                  body: [      
                    ...preco
                  ]
                }
              },
              { margin: [26, 0, 0, 0],
                table: {
                  body: [      
                    ...total
                  ]
                }
              },
            ],
          ]
          
        },
        // layout: 'lightHorizontalLines'
      },
      {
        style: 'tableExample',
        table: {
          widths: [ 218, 80, 30, 80, 80],
          heights:[2, 130],
          body: [
            [ 
              {text: 'Data da Retirada dos Produtos', border: [false, false, false, false]},
              {text: '_____/____/___', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: 'Valor Total', border: [false, false, false, false]},
              {text: ''+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.total),
                alignment: 'right',  fontSize: 9,  border: [true, false, true, true]},
            ]
          ]
        }
      },
      {    
        
        table: {
          widths: [ 300 ],
          body: [
            [ 
              {text: '____________________________________________\nAssinatura do Recebedor', alignment: 'center', border: [false, false, false, false], margin: [50, 16, 0, 0],},
            ]
          ]
        }
      }
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default PDFSaida;

