import { ChangeEvent, useEffect, useState } from 'react';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import { IVFormErrors, VForm, VTextFieldPassword, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { UsuarioService } from '../../services/api/cadastros/UsuariosService';
import { useAuthContext } from '../../contexts';

interface IFormData {
  senha: string;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  img: string;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  senha: yup.string().required(),
});

export const DialogPefilFoto: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [, setValue] = useState(valueProp);
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const { logout} = useAuthContext();

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        UsuarioService
          .updateByIdImg(props.id, selectedImage, dadosValidados)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              logout();
              onClose(valueProp);
              
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.files?.[0]);
    const imagen =  event.target.files?.[0];  
    if (imagen) {
      const imageUrl = URL.createObjectURL(imagen);
      setImage(imageUrl);
    }
  };


  return (
    <Dialog
      open={open}
      {...other}
      sx={{ width: '100%'}}
      maxWidth="sm"
      
    >
      <DialogTitle>Foto do Perfil</DialogTitle>
      <DialogContent   sx={{ width: '100%', maxWidth: 280, height: 300, maxHeight:300}} dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column">
            <Grid container item direction="row">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={3} > 
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={12} >
                    <Box width="100%">
                      <input
                        type="file"
                        id="avatar-input"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                      <label htmlFor="avatar-input">
                        <Avatar 
                          variant='rounded'
                          alt='F'
                          src={image || undefined}
                          sx={{ width: '100%', height: 200 }}
                        />
                      </label>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={12}>          
                    <VTextFieldPassword
                      fullWidth
                      name='senha'
                      disabled={isLoading}
                      label='Senha'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth:'25%'  }} variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button sx={{ minWidth:'25%'  }} variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 