/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
 
interface Item {
  iditens: string;
  descricao: string;
  validade: Date;
  referencia: string;
  localizacao: string;
  qtdsaida: number;
  preco: number;
  total: number;
}

interface Saida {
  idsaida: string;
  dataoperacao: string;
  cultivo: string;
  fazenda: string;
  parceiro: string;
  total: number;
}

interface Entry {
  saida: Saida;
  itens: Item[];
}

async function ROperacaoSaidaDetalhes(data: Entry[], operacao: string){
  const TotalGeral = data.reduce((acc, item) => acc + item.saida.total, 0);

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 50, 20, 10],
    pageOrientation: 'portrait',
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],  
          widths: [48, 500],  
          body: [
            [ 
              {
                image: imageData,   
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Operações', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      { text: 'Tipo de Operação: '+operacao,  alignment: 'left', fillColor: '#dddddd', fontSize: 14},     
      data.map(e => {
        const { saida, itens } = e;
        const rows = itens.map((row) => {
          return [
            {text: row.descricao, alignment: 'left',  fontSize: 7},
            {text: row.referencia, alignment: 'left',  fontSize: 7},
            {text: (row.validade != null ? (moment(row.validade).format('DD/MM/YYYY')):('')), alignment: 'left',  fontSize: 7},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.qtdsaida) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.preco) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.total) , fontSize: 7, alignment: 'right'},
          ]; 

        });  
        return [
          [             
            [                            
              {  
                margin: [0, 5, 0, 0],    
                table: { 
                  widths: [230, 100, 200], 
                  body: [
                    [
                      {text: 'Data \n '+ moment(e.saida.dataoperacao).format('DD/MM/YYYY'), bold: true, fontSize: 10, alignment: 'left', border: [true, true, false, false]},
                      {text: 'Nº: '+e.saida.idsaida, fontSize: 10, alignment: 'left', colSpan: 2, border: [false, true, true, false] },
                      {text: '', border: [false, true, true, false]}, 
                      
                    ],
                    [ {text: 'Fazenda: \n  '+e.saida.fazenda, fontSize: 10, alignment: 'left', border: [true, false, false, false]},
                      {text: 'Cultivo: \n '+e.saida.cultivo  , fontSize: 10, alignment: 'left', border: [false, false, false, false]}, 
                      {text: 'Valor Total \n '+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.saida.total) , fontSize: 11, bold: true, alignment: 'right', border: [false, false, true, false]},
                    ],
                  ] 
                },  
              },
              {
                table: {
                  widths: [247, 100, 43, 43, 35, 35],
                  body: [
                    [
                      { text: 'Descrição',  alignment: 'left', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Referência',  alignment: 'left', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Vencimento',  alignment: 'center', fillColor: '#dddddd', fontSize: 8},
                      { text: 'Quantidade',  alignment: 'left', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Preço',  alignment: 'right', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Total',  alignment: 'right', fillColor: '#dddddd', fontSize: 8}
                    ],
                    ...rows,
                  ]
                },       
              }                                   
            ]
          ]                           
            
        ];
      }     
      ) ,
      { margin: [0, 5, 0, 0],  text: 'Total Geral: '+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalGeral) , fontSize: 14, alignment: 'right'}
    ]
  };
  pdfMake.createPdf(documentDefinition).open();

}

export default ROperacaoSaidaDetalhes;