import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper  } from '@mui/material';
import { useState } from 'react';
import { AutoCompletePeriodo, IVFormErrors, VForm, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { RelatorioService } from '../../../shared/services/api/rh/Relatorios/RelatoriosService';
import * as XLSX from 'xlsx';

interface IFomeTipoPgto {
  periodo: string;
  
}

const formValidationSchema: yup.ObjectSchema<IFomeTipoPgto> = yup.object().shape({
  periodo: yup.string().required(),
});

          
export const ExporteFolhaExcel: React.FC = () => {          
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false); 
  const [periodo, setPeriodo] = useState('');     
              
  const handleSave = (dados: IFomeTipoPgto) => {                                  
    formValidationSchema.     
      validate(dados, { abortEarly: false })     
      .then((dadosValidados) => {                 
        setIsLoading(true);   
        RelatorioService
          .ExportFolhaExcel(dadosValidados.periodo)   
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {           
              alert(result.message);   
            } else 
            {
              const workbook = XLSX.utils.book_new();
              const worksheet = XLSX.utils.json_to_sheet(result);
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Posts');
              XLSX.writeFile(workbook, 'folha-'+periodo+'.xlsx', { compression: true });
            }
          });
      }   
      )
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};   
        errors.inner.forEach(error => {   
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });            
  };    
  
  const handlePeriodo = (periodos: string | undefined) => {    
    if (periodos != null) {     
      setPeriodo(periodos);                  
    }else{                       
      setPeriodo('');                         
    }                 
  }; 
  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Box  alignItems="center" width='auto' alignContent='center' height="auto"  display="flex" flexDirection="column"  >
        <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' alignContent='center'></Box>
        <Box   width='auto' alignItems="center"  component={Paper} height="auto" margin={1} 
          alignContent='center'>       
          <DialogTitle>Exportar Folha para Excel</DialogTitle>
          <DialogContent  dividers>    
            {isLoading && (           
              <Grid item>  
                <LinearProgress variant='indeterminate' />  
              </Grid>                      
            )}   
            <Grid container  padding={1} item direction="row" gap={2}>
              <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AutoCompletePeriodo 
                    isExternalLoading={isLoading}  
                    novoperiodo={handlePeriodo}
                  />
                </Grid> 
              </Grid>        
            </Grid>         
          </DialogContent>     
          <DialogActions>
            <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={save}>Visualizar</Button>
          </DialogActions>
        </Box>
      </Box>
    </VForm> 
  );
};   