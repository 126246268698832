

import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, BasicSelect, VTextFieldData, VFieldMoney } from '../../../shared/forms';

import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';


import { Api } from '../../../shared/services/api/axios-config';
import { EventosService } from '../../../shared/services/api/rh/colaborador/EventosService';

type NullableDate = Date | null | undefined;

interface IFormData {
  descricao: string;
  valor: number;
  fixo: boolean;
  tipocalculo: string;
  dataexpiracao: NullableDate;
  tipoverba: string;
  status: boolean;
  idfun: string;
  basecalculo: string;
  colaborador: string;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'colaborador'>> = yup.object().shape({
  descricao: yup.string().required(). min(3),
  valor: yup.number().required(),
  fixo: yup.boolean().optional().default(false),
  tipocalculo: yup.string().required(). min(3),
  dataexpiracao: yup.date().nullable().optional().default(null),
  tipoverba: yup.string().required(). min(3),
  status: yup.boolean().optional().default(true),
  idfun: yup.string().required(). min(3),
  basecalculo: yup.string().required(). min(3),
});



export const DetalheEventos: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { idColaborador = 'novo' } = useParams<'idColaborador'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  
  const [nomeColaborador, setNomeColaborador] = useState('');
  
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      EventosService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate(`/verbaseventos?idfun=${idColaborador}`);
          } else {
            setDescricao(result.descricao);
            const objetoComColaborador = {
              ...result,
              colaborador: `${nomeColaborador}`,
            };
            formRef.current?.setData(objetoComColaborador);
          }
        });
    } else {
      formRef.current?.setData({
        descricao: '',
        valor: 0,
        fixo: false,
        tipocalculo: '',
        dataexpiracao: null,
        tipoverba: '',
        status: true,
        idfun: {idColaborador},
        basecalculo: '',
        colaborador: nomeColaborador,
      });
    }

  }, [id, nomeColaborador]);

  

  useEffect(() => {
    const fetchData = async () => {
     
      try {
        const response = await Api.get(`/funcionarios/${idColaborador}`);
        const colaboradorData = response.data;

        setNomeColaborador(colaboradorData.nome);
          
      } catch (error) {
        setIsLoading(false);
        alert('Erro ao obter dados do colaborador.');
        navigate(`/verbaseventos?idfun=${idColaborador}`);
      }
      
    };
  
    fetchData();
  }, [id, idColaborador]);
  


  const handleSave = (dados: IFormData) => {
    dados.idfun = idColaborador;
    

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { colaborador, ...dadosSemColaborador } = dados;
  
    
    formValidationSchema.
      validate(dadosSemColaborador, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          EventosService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate(`/verbaseventos?idfun=${idColaborador}`);
                } else {
                  navigate(`/verbaseventos/detalhe/${result}/${idColaborador}`);
                  
                }
              }
            });
        } else {
          EventosService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate(`/verbaseventos?idfun=${idColaborador}`);
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      EventosService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate(`/verbaseventos?idfun=${idColaborador}`);
          }
        });
    }
  };

  return (
    
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Evento' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoImpressao={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate(`/verbaseventos?idfun=${idColaborador}`)}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate(`/verbaseventos/detalhe/novo/${idColaborador}`)}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >
      
      <VForm ref={formRef} onSubmit={handleSave}>
        
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid item>
              <Typography variant='h6'>Geral</Typography>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                <VTextField
                  fullWidth
                  size='small'
                  name='colaborador'
                  disabled={true}
                  label='Colaborador'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                <VTextField
                  fullWidth
                  size='small'
                  name='descricao'
                  disabled={isLoading}
                  label='Descrição'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='valor'
                  label='Valor'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <BasicSelect
                  data={[
                    { nameBD: 'percentual', descricao: 'Percentual' },
                    { nameBD: 'valor', descricao: 'Valor' },
                  ]}
                  label='Tipo de Cálculo'
                  name='tipocalculo'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <BasicSelect
                  data={[
                    { nameBD: 'salario', descricao: 'Salário' },
                    { nameBD: 'salario carteira', descricao: 'Salário Carteira' },
                  ]}
                  label='Base de Cálculo'
                  name='basecalculo'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <BasicSelect
                  data={[
                    { nameBD: 'desconto', descricao: 'Desconto' },
                    { nameBD: 'vencimento', descricao: 'Vencimento' },
                  ]}
                  label='Tipo de verba'
                  name='tipoverba'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <VTextFieldData
                  fullWidth
                  name='dataexpiracao'
                  label='Data Expiração'
                  disabled={isLoading}
                />
              </Grid>
              {(id != 'novo') && (
                <Grid item xs={3} sm={3} md={3} lg={1.5} xl={1.5}>
                  <VSwitch
                    size='small'
                    name='status'
                    label='Status'
                    fdisabled={isLoading}
                    aliamento='start'
                  />
                </Grid> 
              )}
              <Grid item xs={3} sm={3} md={3} lg={1.5} xl={1.5}>
                <VSwitch
                  size='small'
                  name='fixo'
                  label='Fixo'
                  fdisabled={isLoading}
                  aliamento='start'
                />
              </Grid> 
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};