import { ApiFinanceira } from '../axiosfinanceiro';

interface ContasReceber {
  parceiro: string;
  cpf: string
  vencimento: Date;
  fazenda: string;
  cultivo: string;
  historico: string;
  numerodoc: string;
  valorcompra: number; 
  recebido: number;
  acrescimos: number;
  desconto: number;
  valorparcela: number;
  receber: number;
}

const RelatorioContasReceber = async (parceiro: string, fazenda: string, cultivo: string, datainicial: string, datafinal: string): Promise<ContasReceber[] | Error> => {
  try {
    const urlRelativa = `/relatoriocontasreceber?fazenda=${fazenda}&parceiro=${parceiro}&cultivo=${cultivo}&datainicial=${datainicial}&datafinal=${datafinal}`;
      
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};
  

export const RelatorioContasReceberService = {
  RelatorioContasReceber
};