import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagemCadastros } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemContaBancaria, ContaBancariaService } from '../../../shared/services/api/cadastros/contaBancaria/ContaBancariaService';

export const ListagemDeContaBancaria: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemContaBancaria[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  
  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      ContaBancariaService.getAll(pagina, filter)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [pagina, filter]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Conta Bancária'
      barraDeFerramentas={
        <FerramentasDaListagemCadastros
          mostrarInputBusca
          mostrarInputBasicSelect={false}
          textoDaBusca={filter}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/contabancaria/detalhe/novo')}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ filter: filter, tipo: textoselect, pagina: '1' }, { replace: true})}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Banco</TableCell>
              <TableCell>Agência</TableCell>
              <TableCell>Nº Conta</TableCell>
              <TableCell>Titular</TableCell>
              <TableCell align='center'>IR</TableCell>
              <TableCell align='center'>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idconta} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/contabancaria/detalhe/${row.idconta}`)}
              >
                <TableCell>{row.nomebanco}</TableCell>
                <TableCell>{row.agencia}</TableCell>
                <TableCell>{row.numeroconta}</TableCell>
                <TableCell>{row.titular}</TableCell>
                <TableCell align='center'><Switch size='small' checked={row.ir}/></TableCell>
                <TableCell align='center'><Switch size='small' checked={row.status}/></TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ filter, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};