/* eslint-disable linebreak-style */
import { BrowserRouter } from 'react-router-dom';

import './shared/forms/TraducoesYup';

import { AppThemeProvider, AuthProvider, DrawerProvider } from './shared/contexts';
import { Login, MenuLateral, PaginaInicial } from './shared/components';

import { AppRoutesPrincipal } from './routes';

export const App = () => {
  return (
    <AuthProvider>
      <AppThemeProvider>

        <Login>

          <DrawerProvider>
            <BrowserRouter>

              <PaginaInicial>
            
                <MenuLateral>
                  
                  <AppRoutesPrincipal />
                      
            
                </MenuLateral>

               

              </PaginaInicial>
              
                

            </BrowserRouter>
          </DrawerProvider>

        </Login>

      </AppThemeProvider>
    </AuthProvider>
  );
};