/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';
import { Environment } from '../../../../environment';
import { Apiestoque } from '../../axiosestoque';



export interface IListagemDeProdutos {
  urlimge: string;
  idprod: string;
  codbarrasaida: string;
  codinterno: number;
  descricao: string;
  marca: string;
  referencia: string;
  precocompra: number;
}

export interface IDetalhesProduto {
  idgrupo: string;
  idsubgrupo: string;
  descricao: string;
  referencia: string;
  codbarraentrada: string;
  codbarrasaida: string;
  precocompra: number;
  precocusto: number;
  precovenda: number;
  precosaida: number;
  status: boolean;
  percentual: number;
  frete: number;
  local: string;
  diasvencimento: number;
  marca: string;
  vencimento: boolean;
  obs: string;
  codinterno?: number;
}

interface IListagemDeProdutosGetAlllist {
  idprod: string;
  idgrupo: string;
  idsubgrupo: string;
  descricao: string;
  referencia: string;
  codbarraentrada: string;
  codbarrasaida: string;
  precocompra: number;
  precocusto: number;
  precovenda: number;
  precosaida: number;
  status: boolean;
  percentual: number;
  frete: number;
  local: string;
  diasvencimento: number;
  marca: string;
  vencimento: boolean;
  obs: string;
  codinterno?: number;
}

export interface IUpdateImg {
  urlimge: string;
}

type TComTotalCount = {
  data: IListagemDeProdutos[];
  totalCount: number;
}

type TComTotalCountGetAlllist = {
  data: IListagemDeProdutosGetAlllist[];
  totalCount: number;
}

const getAll = async (page = 1, filter = '', tipo = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/estoque?page=${page}&limit=${10}&filter=${filter}&tipo=${tipo}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || 10),
      };
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllist = async (filter = '', id = '', tipo = '', combustivel = ''): Promise<TComTotalCountGetAlllist | Error> => {
  
  
  try {
    const urlRelativa: string = id === '' ?  `/estoquelist?limit=${Environment.LIMITE_DE_LINHAS}&tipo=${tipo}&filter=${filter}&combustivel=${combustivel}` : `/estoquelist/${id}`;
    const { data, headers } = await Apiestoque.get(urlRelativa);
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
      
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IListagemDeProdutos | Error> => {
  try {
    const { data } = await Apiestoque.get(`/estoque/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (image: File | undefined,  dados: IDetalhesProduto): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const formData = new FormData();
    formData.append('idprod', guuid);
       
    Object.keys(dados).forEach((key) => {
      formData.append(key, (dados as any)[key]);
    });

    formData.append('file', image || '');    

    const { data } = await Apiestoque.post<IDetalhesProduto>('/estoque', formData);

    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalhesProduto): Promise<void | Error> => {
  try {
    await Apiestoque.put(`/estoque/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const updateByIdImg = async (id: string, image: File | undefined, urlimge: string | null): Promise<void | Error> => {
  try {
  
    const dados = {urlimge: urlimge};
    
    const formData = new FormData();
     
    Object.keys(dados).forEach((key) => {
      formData.append(key, (dados as any)[key]);
    });

    formData.append('file', image || ''); 

    await Apiestoque.put(`/estoqueupdateimg/${id}`, formData);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/estoque/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const ProdutosService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
  updateByIdImg,
};