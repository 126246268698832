export const menufinanceiro =  [
  {
    icon: 'home',
    path: '/dashboardFinanceiro',
    label: 'Página inicial',
  },
  {
    icon: 'paymentsIcon',
    path: '/contabancaria',
    label: 'Conta Bancária',
  },
  {
    icon: 'request_quote',
    path: '/contasreceber',
    label: 'Contas Receber',
  },

  {
    icon: 'payment',
    path: '/contaspagar',
    label: 'Contas Pagar',
  },

  {
    icon: 'featured_play_list',
    path: '/chequeemitidos',
    label: 'Cheque Emitidos',
  },

  {
    icon: 'subtitles',
    path: '/cheque',
    label: 'Cheque Cliente',
  },

  {
    icon: 'check_circle',
    path: '/confirmarboleto',
    label: 'Confirmar Boleto',
  },
  {
    icon: 'expand_circle_down',
    path: '/confirmarvale',
    label: 'Confirmar Vale',
  },

  {
    icon: 'transform',
    path: '/lancamentoentrecontas',
    label: 'Lancamento Entre Contas',
  },

  {
    icon: 'account_balance',
    path: '/conciliacao',
    label: 'Conciliação',
  },
];

export const menufinanceirorelatorio =  [
  {
    icon: 'print',
    path: '/relatoriocontaspagar',
    label: 'Contas Pagar',
  },
  {
    icon: 'print',
    path: '/relatoriocontaspagas',
    label: 'Contas Pagas',
  },
  {
    icon: 'print',
    path: '/relatoriocontasreceber',
    label: 'Receber',
  },
  {
    icon: 'print',
    path: '/relatoriocontasrecebidas',
    label: 'Recebidos',
  },
  {
    icon: 'print',
    path: '/relatoriocheque',
    label: 'Cheque',
  },
  {
    icon: 'print',
    path: '/relatoriochequeemitidos',
    label: 'Cheque Emitidos',
  }
];