/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Icon, MenuItem, Paper, Select, TextField, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { AutoCompleteProdutorListagem } from '../../forms';
import { Environment } from '../../environment';
interface IDadosSelect {
  nameBD: string;
  descricao: string;
}

interface IFerramentasDaListagemProps {
  produtor?: number;
  dadosSelecao?: IDadosSelect[];
  dataInicial?: string;
  dataFinal?: string;
  textonome?: string;
  mostrarInputData?: boolean;
  aoMudarDataInicial?: (novoTexto: string) => void;
  aoMudarDataFinal?: (novoTexto: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmNovo?: () => void;
  mostrarInputBasicSelect?: boolean
  textoDoSelect?: string;
  aoMudarTextoDoSelect?: (novoTextoselect: string) => void;
  mostrarInputBusca?: boolean;
  textoDaBusca?: string;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  mostrarAutoComplete?: boolean;
  aoMudarTextoDoParceiro?: (novoTextoselect: number, texto1: string) => void;
}
export const FerramentasDaListagemLCDPR: React.FC<IFerramentasDaListagemProps> = ({
  produtor,
  textoDaBusca = '',
  textonome='',
  dataInicial = '',
  aoMudarDataInicial,
  dataFinal = '',
  aoMudarDataFinal,
  mostrarInputData = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
  dadosSelecao =[],
  mostrarInputBasicSelect = false,
  textoDoSelect = '',
  aoMudarTextoDoSelect,
  mostrarInputBusca = false,
  aoMudarTextoDeBusca,
  mostrarAutoComplete = false,
  aoMudarTextoDoParceiro, 
}) => {
  const theme = useTheme();
  const partesDaDataInicial = dataInicial.split('/');
  const dataInicialFormatada = partesDaDataInicial[2] + '-' + partesDaDataInicial[1] + '-' + partesDaDataInicial[0];

  const partesDaDataFinal = dataFinal.split('/');
  const dataFinalFormatada = partesDaDataFinal[2] + '-' + partesDaDataFinal[1] + '-' + partesDaDataFinal[0];

  const handleuuidparceiroNome = (nome: string | undefined) => {
    if (nome != null) {
      textonome = nome;
    }  
  };

  const handleuuidparceiro = (uuid: number | undefined) => {
    if (uuid != null) {
      aoMudarTextoDoParceiro?.(uuid, textonome);
    }  
  };

  return (
    <Box
      gap={1}
      marginX={3}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
      sx={{ height: '60px' }}
    >
      {mostrarInputBasicSelect && (
        <Select
          size="small" sx={{ minWidth: 150}} 
          value={textoDoSelect}
          onChange={(event) => aoMudarTextoDoSelect?.(event.target.value)}
        >
          {dadosSelecao.map((item) => (
            <MenuItem key={item.nameBD} value={item.nameBD}>
              {item.descricao}
            </MenuItem>
          ))}
        </Select>
      )}

      {mostrarInputBusca && (
        <TextField
          size='small'
          value={textoDaBusca}
          placeholder={Environment.INPUT_DE_BUSCA}
          onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
        />
      )} 

      {mostrarAutoComplete && (
        <Box sx={{ minWidth: 350, }} >
          <AutoCompleteProdutorListagem
            nome={handleuuidparceiroNome}
            uuidprodutor={handleuuidparceiro}
            isExternalLoading={false}
            defaultvalue={produtor}
          />
        </Box>
      )} 

      {mostrarInputData && (

        <LocalizationProvider dateAdapter={AdapterDayjs}>
        
          <DatePicker 
            sx={{ minWidth: 150, maxWidth: 150}}
            label="Data Inicial" 
            format='DD/MM/YYYY'
            defaultValue={dayjs()}
            value={dayjs(dataInicialFormatada)}
            onChange={(date: Dayjs | null) => {
              const novoTexto = date ? date.format('DD/MM/YYYY') : '';
              aoMudarDataInicial?.(novoTexto);
            }}
            slotProps={{
              textField: {    
                size:'small',
              }
            }}
          />

          <DatePicker 
            sx={{ minWidth: 150, maxWidth: 150}}
            label="Data Final" 
            format='DD/MM/YYYY'
            defaultValue={dayjs()}
            value={dayjs(dataFinalFormatada)}
            onChange={(date: Dayjs | null) => {
              const novoTexto = date ? date.format('DD/MM/YYYY') : '';
              aoMudarDataFinal?.(novoTexto);
            }}
            slotProps={{
              textField: {    
                size:'small',
              }
            }}
          />
      
        </LocalizationProvider>
      )} 

      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={aoClicarEmNovo}
            endIcon={<Icon>add</Icon>}
          >{textoBotaoNovo}</Button>
        )}
      </Box>
    </Box>
  );
};