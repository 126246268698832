/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';


import { v4 as uuidv4 } from 'uuid';

type NullableDate = Date | null | undefined;

export interface IListagemColaborador {
  idfun: string;
  nome: string;
  apelido: string;
  cpf: string;
  urlimg: string;
  status: boolean;
}

export interface IListagemHistoricoSalario {
  idsalario: string;
  idfun: string;
  dataalteracao: Date;
  salario: number;
}


export interface IDetalheColaborador {
  nome: string;
  apelido: string;
  cpf: string;
  rg: string;
  ctps: string;
  estadocivil: string;
  nascimento: Date;
  endereco: string;
  bairro: string;
  cidade: string;
  uf: string;
  telefone: string;
  telefone1: string;
  email: string;
  banco: string;
  agencia: string;
  conta: string;
  sexo: string;
  cep: string;
  dv: string;
  tipoconta: string;
  datacadastro?: NullableDate;
  pai: string;
  mae: string;
  conjuge: string;
  cargo: string;
  demissional?: NullableDate;
  admissao: Date;
  tipopagamento: string;
  status: boolean;
  pis: string;
  funcao: string;
  salario: number;
  scarteira: number;
  idcei: string;
  idfazenda: string;
  urlimg: string;
  obs: string;
  almoco: boolean;
  janta: boolean;
  cafe: boolean;
  sus: string;
  codcartao: string;
  dataexperiencia: Date;
  meiaalimentacao: boolean;
  insalubridade: boolean;
  percentualinslubridade: number;
  taxanegociavel: boolean;
  idstatus: string;
  presencaautomatica: boolean;
  pisosalarial: number;
}

type TotalCount = {
  data: IListagemColaborador[];
  totalCount: number;
}

type TotalCountHistoricoSalario = {
  data: IListagemHistoricoSalario[];
  totalCount: number;
}

const getAll = async (page = 1, filter = '', tipo = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/funcionarios?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&filter=${filter}&tipo=${tipo}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheColaborador | Error> => {
  try {
    const { data } = await Api.get(`/funcionarios/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getAllHistoricoSalario = async (page = 1, idfun = ''): Promise<TotalCountHistoricoSalario | Error> => {
  try {
    const urlRelativa = `/funcionariossalarios?page=${page}&limit=${5}&idfun=${idfun}`;
    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || 5),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


const create = async (image: File | undefined, dados: Omit<IDetalheColaborador, 'urlimg'>): Promise<string | Error> => {

  try {
    const { datacadastro, demissional, status, ...dadosIDetalheColaborador } = dados;
    const formData = new FormData();
    const guuid = uuidv4();
    formData.append('idfun', guuid);

    Object.keys(dadosIDetalheColaborador).forEach((key) => {
      formData.append(key, (dadosIDetalheColaborador as any)[key]);
    });

    formData.append('file', image || '');


    const { data } = await Api.post<IDetalheColaborador>('/funcionarios', formData);

    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: Omit<IDetalheColaborador, 'idfun' | 'urlimg'>): Promise<void | Error> => {
  const { status, ...dadosIDetalheColaborador } = dados;
  try {
    await Api.put(`/funcionarios/${id}`, dadosIDetalheColaborador);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/funcionarios/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const getAllList = async (filter = '', id = ''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/funcionarioslist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/funcionarioslist/${id}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const updateByIdImg = async (id: string, image: File | undefined, urlimg: string | null): Promise<void | Error> => {
  try {
    const dados = { urlimg: urlimg };
    const formData = new FormData();

    Object.keys(dados).forEach((key) => {
      formData.append(key, (dados as any)[key]);
    });

    formData.append('file', image || '');

    await Api.put(`/funcionariosupdateimg/${id}`, formData);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

export const ColaboradorService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  getAllList,
  updateByIdImg,
  getAllHistoricoSalario,
};