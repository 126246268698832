import { Environment } from '../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apicadastro } from '../axioscadastro';

export interface IListagemTransportadoras {
  idtransportadora: string;
  nome: string;
  fantasia: string;
  cpf: string;
}

export interface IListagemveiculos {
  idveiculos: string;
  placa: string;
}

export interface IDetalheTransportadora {
  nome: string;
  cpf: string;
  fantasia: string;
  rg: string;
  telefone: string;
  endereco: string;
  numero: string;
  idmunicipio: string;
  cep: string;
  obs: string;
  status: boolean;
  bairro: string;
}

type TotalCount = {
    data: IListagemTransportadoras[];
    totalCount: number;
}

const getAll = async (page = 1, tipo = '', filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/transportadoras?page=${page}&limit=${Environment.LIMITE_DE_CADASTRO}&tipo=${tipo}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_CADASTRO),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheTransportadora | Error> => {
  try {
    const { data } = await Apicadastro.get(`/transportadoras/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheTransportadora): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalheTransportadora>('/transportadoras',  {idtransportadora: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheTransportadora): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/transportadoras/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/transportadoras/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const getAllList = async (filter = '', id = ''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/transportadoraslist?limit=${Environment.LIMITE_DE_LINHAS}&tipo=nome&filter=${filter}` : `/transportadoraslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const TransportadoraService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  getAllList,
};