import { Box, Button, Grid, Icon,  Paper, TextField, Typography, useTheme } from '@mui/material';
import { Environment } from '../../environment';
import { AutoCompleteContaBancariaListagem } from '../../forms';


interface IFerramentasDaListagemProps {
  textoDaBusca?: string;
  mostrarInputBusca?: boolean;
  disbilitarBaixar?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  aoMudarTextoDoIdconta?: (novoTextoselect: number) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  mostrarBotaoCheque?: boolean;
  mostrarInputBasicSelect?: boolean
  aoClicarEmNovo?: () => void;
  aoClicarEmBaixa?: () => void;
}
export const FerramentasDaListagemFinanceiroChequeEmitidos: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  aoMudarTextoDeBusca,
  aoMudarTextoDoIdconta,
  aoClicarEmNovo,
  aoClicarEmBaixa,
  disbilitarBaixar,
}) => {
  const theme = useTheme();

  const handleidconta = (codigo: number | undefined) => {
    if (codigo!= null) {
      aoMudarTextoDoIdconta?.(codigo);
    }  
  };
 
  return (  
    <Box
      gap={1}
      marginX={2}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(6)}
      component={Paper}
    >
      <Grid container item direction="row" spacing={1}>  
        <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
          <AutoCompleteContaBancariaListagem
            codigoconta={handleidconta}
            isExternalLoading={false}
          />  
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>  
          <TextField
            value={textoDaBusca}
            size='small'
            placeholder={Environment.INPUT_DE_BUSCA}
            onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
          />
        </Grid>   
        <Grid item  marginTop={1} xs={2} sm={2} md={2} lg={2} xl={1}> 
          <Button
            size="small"
            color='primary'
            variant='contained'
            disableElevation
            onClick={aoClicarEmNovo}
            startIcon={<Icon>add</Icon>}
          >
            <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  Adicionar
            </Typography>
          </Button>
        </Grid> 
        <Grid item marginTop={1} xs={2} sm={2} md={2} lg={2} xl={1}> 
          <Button 
            size="small"
            color='primary'
            variant='contained'
            disabled={disbilitarBaixar}
            disableElevation
            onClick={aoClicarEmBaixa}
            startIcon={<Icon>paid</Icon>}
          >
            <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  Baixar
            </Typography>
          </Button>
        </Grid>  
      </Grid>       
    </Box>
  );
};