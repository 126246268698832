/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';


import { v4 as uuidv4 } from 'uuid';

export interface IListagemVale {
    idvale: string;
    idfun: string;
    tipodesconto: string;
    valor: number;
    periodo: string;
    nome: string;
    cpf: string;
    parcela: string;
    urlimg: string;
    status: boolean;
}

export interface IDetalheVale {
  idfun: string;
  nome: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
  idoperacao: string;  
  totalvale: number;
  urlimg: string;
}

export interface IDetalheValeCreate {
  idfun: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
}

export interface IDetalheValeUpdate {
  idfun: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
}

interface IFormDataParcelas {
  idvale: string;
  idfun: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
  idoperacao: string;
  totalvale: number;
}

interface IDataPrint {
  idvale: string,
  dataemissao: Date;
  totalvale: number 
  produtor: string;
  numero: string;
  realizacao: string;
  colaborador: string;
  endereco: string;
  cpf: string;
  rg: string;
}

type TotalCount = {
    data: IListagemVale[];
    totalCount: number;
}

const getAll = async (page = 1, filter = '', situacao=''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/vales?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&filter=${filter}&situacao=${situacao}`;

    const { data, headers } = await Api.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheVale | Error> => {
  try {
    const { data } = await Api.get(`/vales/${id}`);
   
    if (data) {
      return data;
    }
 
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getByIdPrint = async (id: string): Promise<IDataPrint | Error> => {
  try {
    const { data } = await Api.get(`/valeprint/${id}`);
   
    if (data) {
      return data;
    }
 
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheValeCreate): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const total = dados.valor;

    const { data } = await Api.post<IDetalheVale>('/vales',  [{idvale: guuid, idoperacao: guuid, totalvale: total, ...dados}]);
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const createparcelas = async (dados: IFormDataParcelas[]): Promise<string | Error> => {
  try {

    const guuid = dados[0].idvale;

    const { data } = await Api.post<IFormDataParcelas>('/vales',  dados);
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheValeUpdate): Promise<void | Error> => {
  try {     
    await Api.put(`/vales/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/vales/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const ValeService = {
  getAll,
  create,
  createparcelas,
  getById,
  updateById,
  deleteById,
  getByIdPrint,
};