/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { AutoCompleteFazenda, IVFormErrors, VFieldMoney, VForm, VTextField, VTextFieldData, useVForm } from '../../../shared/forms';
import { PontoService } from '../../../shared/services/api/rh/ponto/PontoService';
import * as yup from 'yup';

interface IFormData {
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
  idfazenda: string;
  datalan: Date;
}

export interface ConfirmationDialogRawProps {
  id: string;
  idfun: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  ponto: yup.string().required().matches(/^[AFPDM]$/, 'Permitir apenas estas letras: A, F, P, D, M').min(1).max(1),
  idfazenda: yup.string().required(),
  datalan: yup.date().required(),
  pistola: yup.number().optional().default(0).max(1).min(0),
  horaextra: yup.number().optional().default(0),
  bomba: yup.number().optional().default(0).max(1).min(0),
  almoco: yup.number().optional().default(0).max(1).min(0),
  janta: yup.number().optional().default(0).max(1).min(0), 
  cafe: yup.number().optional().default(0).max(1).min(0),
});

export const DialogPontoAdd: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(valueProp);
  const [id, setId] = useState('');

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        PontoService
          .create({idfun: props.idfun, ...dadosValidados})
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              onClose(valueProp);
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  useEffect(() => {
    formRef.current?.setData(
      { ponto: 'P',
        horaextra: 0,
        bomba: 0,
        pistola: 0,
        cafe: 0,
        almoco: 0,  
        janta: 0, 
        datalan: new Date(),
      }  
    );
  }, [id, formRef]); 

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }else{
      setId(valueProp);
    }
  }, [valueProp, open]);

  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Lançar Ponto</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column"  >
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <VTextFieldData
                      fullWidth
                      name='datalan'
                      disabled={isLoading}
                      label='Data'
                    />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={6} xl={6}>
                    <AutoCompleteFazenda isExternalLoading={isLoading}  />
                  </Grid>   
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='ponto'
                      label='Ponto'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      size='small'
                      name='horaextra'
                      label='Hora Extra'
                      disabled={isLoading}
                    />
                  </Grid>          
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='bomba'
                      label='Bomba'
                      disabled={isLoading}
                    />
                  </Grid>    
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='pistola'
                      label='Pistola'
                      disabled={isLoading}
                    />
                  </Grid>    
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='cafe'
                      label='Café'
                      disabled={isLoading}
                    />
                  </Grid>    
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='almoco' 
                      label='Almoço'
                      disabled={isLoading}
                    />
                  </Grid>  
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      type='number'
                      name='janta'
                      label='Janta'
                      disabled={isLoading}
                    />
                  </Grid>                           
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button disabled={isLoading} variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}; 