import { ApiFiscal } from '../../axiosfiscal';

export interface DataDashboard {
  nivel: number,
       
  card:[],
  total:[],
  periodo:[],
  nfe:[],
  receitaspercentual:[],
  despesaspercentual:[],
  receitasindividuais:[],
  despesasindividuais:[],
}

const GetDashboardLCDPRService = async (): Promise<DataDashboard | Error> => {
  try {
    const { data } = await ApiFiscal.get('/dashboard');
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DashboardLCDPRService = {
  GetDashboardLCDPRService,
};