import { Api } from '../../axios-config';

interface faltas {
  nome: string;
  faltas: number; 
}

export interface IFaltaCei {
  idcei: string;
  numero: string;
  nome: string;
  faltas: faltas[];
}

export interface IAniversariantes {
  nome: string; 
  aniversario: string;
}

export interface IFuncionariosPorFazenda {
  nome: string; 
  funcao : string;      
}

interface dados {
  cei: IFaltaCei;
  faltas: faltas [];  
}

const RelatorioAniversariantes = async (mes = ''): Promise<IAniversariantes[] | Error> => {
  try {
    const urlRelativa = `/aniversariantes?mes=${mes}`;
  
    const { data } = await Api.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RelatorioFuncionariosporFazenda = async (fazenda= ''): Promise<IFuncionariosPorFazenda[] | Error> => {
  try {
    const urlRelativa = `/funcionariosfazenda?fazenda=${fazenda}`;
    
    const { data } = await Api.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RelatorioFaltaPorCei = async (periodo = ''): Promise<dados[] | Error> => {
  try {
    const urlRelativa = `/faltasporcei?periodo=${periodo}`;
    
    const { data } = await Api.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const ExportFolhaExcel = async (periodo = ''): Promise<dados[] | Error> => {
  try {
    const urlRelativa = `/exportfolha?periodo=${periodo}`;
    
    const { data } = await Api.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const RelatorioService = {
  RelatorioAniversariantes,
  RelatorioFuncionariosporFazenda,
  RelatorioFaltaPorCei,
  ExportFolhaExcel,
};


   