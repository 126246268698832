/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Checkbox, Icon, IconButton, LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LayoutBaseDeFinanceiro, } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import dayjs from 'dayjs';
import { FerramentasDaListagemFinanceiro } from '../../../shared/components';
import { DialoDesconto } from './DialogDesconto';
import { DialoBaixa } from './DialogBaixa';
import { v4 as uuidv4 } from 'uuid';
import { ContasPagarService, IListagemContasPagar } from '../../../shared/services/api/Financeiro/ContasPagar';
import PDFPagar from './ImpressaoPagar';

export const ListagemContasPagar: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();  
  const [rows, setRows] = useState<IListagemContasPagar[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [istexto, setIsTexto] = useState(false);
  const [isAutoComplete, setIsAutoComplete] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [selecionado, setSelecionado] = useState(0);
  const [open, setOpen] = useState(false);
  const [openBaixa, setOpenBaixa] = useState(false);
  const [nome, setNome] = useState('');

    
  const guuid = uuidv4();   
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const basicselect = useMemo(() => {
    return searchParams.get('basicselect') || 'r.idparceiro'; 
  }, [searchParams]);
  
  const basicstatus = useMemo(() => {
    return searchParams.get('basicstatus') || 'false'; 
  }, [searchParams]);


  const parceiroselect = useMemo(() => {
    return searchParams.get('parceiroselect') || ''; 
  }, [searchParams]);

  const parceiro = useMemo(() => {
    return searchParams.get('parceiro') || ''; 
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);  
  
  useEffect(() => {
    if(basicselect === 'r.idparceiro'){
      setIsTexto(false);
      setIsAutoComplete(true);
    }else{
      setIsTexto(true);
      setIsAutoComplete(false);
    }
    setRows([]);     
  }, [basicselect]);    

  useEffect(() => {
    if(parceiroselect !== ''){
      setNome(parceiro);
      ContasPagarService.getAll(pagina, parceiroselect, 'parceiro', basicstatus)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setTotal(result.total);
            setSelecionado(result.selecionado); 
            setRows(result.data);
          }
        });
    }
  }, [basicstatus, parceiroselect ]);  

  useEffect(() => {  
    setIsLoading(true);  
    debounce(() => {  
      ContasPagarService.getAll(pagina, busca, 'numero', 'true')
        .then((result) => {
          setIsLoading(false);  
          if (result instanceof Error) {
            alert(result.message);
          } else { 
            setTotalCount(result.totalCount);
            setTotal(result.total); 
            setSelecionado(result.selecionado);
            setRows(result.data);
          }
        });
    });
  }, [busca]);

  useEffect(() => {  
    if(parceiroselect !== ''){
      setIsLoading(true);  
      debounce(() => {  
        ContasPagarService.getAll(pagina, parceiroselect, 'parceiro', basicstatus)
          .then((result) => {
            setIsLoading(false);  
            if (result instanceof Error) {
              alert(result.message);
            } else { 
              setTotalCount(result.totalCount);
              setTotal(result.total); 
              setSelecionado(result.selecionado);
              setRows(result.data);
            }
          });
      });
    }
  }, [pagina]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const index = rows.findIndex(row => row.idcontaspagar === id);
    if (index === -1) return; 
    const updatedRow = { ...rows[index], marcado: checked };
    const updatedRows = [...rows.slice(0, index), updatedRow, ...rows.slice(index + 1)];
    setRows(updatedRows);
    const valueToUpdate = checked ? updatedRow.valorpagar : -updatedRow.valorpagar;
    setSelecionado(currentSelecionado => currentSelecionado + valueToUpdate);
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const updatedRows = rows.map(row => {
      if (row.boleto) {
        return  { ...row, marcado: checked };
      }else{
        return  { ...row, marcado: false };
      }
    });
    setRows(updatedRows);
    if (checked) {
      const totalSelecionado = updatedRows
        .filter(row => row.boleto && row.marcado)
        .reduce((acc, row) => acc + row.valorpagar, 0);
      setSelecionado(totalSelecionado);
    } else {
      setSelecionado(0);
    }
  };
  
  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {     
      // setValue(newValue); 
    }          
  };  
            
  const handleCloseBaixa = (newValue?: string) => {
    setOpenBaixa(false);  
    if (newValue === 'true') {
      ContasPagarService.getAll(pagina, parceiroselect, 'parceiro', basicstatus)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setTotal(result.total);
            setSelecionado(result.selecionado);
            setRows(result.data);
          }
        });
    }
  };  

  const handleDesconto = () => {
    if(totalCount != 0){
      const filteredList = rows.filter((item) => item.marcado === true);
      setRows(filteredList);
      setOpen(true);
    }
  };

  const handleBaixa = () => {
    if(totalCount != 0){
      const filteredList = rows.filter((item) => item.marcado === true);
      setRows(filteredList);
      setOpenBaixa(true);
    }
  };

  const handleSave = async () => {
    if(totalCount != 0){
      setIsLoading(true);
      const marcados = rows.map(listItem => ({
        idcontaspagar: listItem.idcontaspagar,
        marcado: listItem.marcado,
        desconto: listItem.desconto,
        valorpagar: listItem.valorpagar,

      }));
      try {
        const result = await ContasPagarService.Marcados(marcados);
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } 
      } catch (error) {
        setIsLoading(false);
        alert('Ocorreu um erro ao processar.');
      }
    }
  };

  const handlePrint = () => {
    if(totalCount != 0){
      const filteredList = rows.filter((item) => item.marcado === true);
      PDFPagar(filteredList, total, selecionado, nome);
    }   
  };   

  const handleDesc = (dados?: IListagemContasPagar[]) => {
    if (dados) {
      setRows(dados);
    }
  };

  const handletotal = (valor?: number) => {
    if (valor) {
      setSelecionado(valor);
    }
  };

  return (
    <LayoutBaseDeFinanceiro
      titulo='Contas a Pagar'
      labelSelecionado='Selecionado'
      labeltotal='Total Pagar'
      total={total.toLocaleString('pt-BR', { minimumFractionDigits: 2})}
      selecionado={selecionado.toLocaleString('pt-BR', { minimumFractionDigits: 2})}
      barraDeFerramentas={
        <FerramentasDaListagemFinanceiro
          mostrarInputBusca={istexto}
          mostrarAutoComplete={isAutoComplete} 
          mostrarInputBasicSelect
          textSelectFalse='Pagar'
          textSelectTrue='Pagas'
          textoDaBusca={busca}
          textoDoSelect={basicselect}
          textoDoStatus={basicstatus}
          disbilitarBaixar={basicstatus === 'true' ? true : false}
          disbilitarDesconto={basicstatus === 'true' ? true : false}
          disbilitarSalvar={basicstatus === 'true' ? true : false}
          disbilitarImprimir={basicstatus === 'true' ? true : false}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/contaspagar/detalhe/novo')}
          aoClicarEmDesconto={handleDesconto}  
          aoClicarEmSalvar={handleSave}
          aoClicarEmBaixa={handleBaixa}
          aoClicarEmImpressao={() => handlePrint()}
          aoMudarTextoDoParceiro={(texto, texto1) => setSearchParams({ busca: '',  basicstatus: basicstatus, parceiroselect: texto, parceiro: texto1, pagina: '1' }, { replace: true })}
          aoMudarTextoDoStatus={texto => setSearchParams({ busca: '', parceiroselect: parceiroselect, pagina: '1',  basicstatus: texto  }, { replace: true })}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, basicselect: basicselect })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ busca: '', basicselect: textoselect, parceiroselect: parceiroselect, pagina: '1' }, { replace: true })}
        />  
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <Table size="small">  
            <TableHead>
              <TableRow>
                <TableCell> <Checkbox  checked={rows.length > 0 && rows.every(row => row.marcado)} onChange={handleSelectAllChange} /></TableCell>
                <TableCell align='center'>Emissão</TableCell>
                <TableCell align='center'>Vencimento</TableCell>
                <TableCell align='center'>Parcela</TableCell>
                <TableCell align='right' >Numero</TableCell>
                <TableCell align='right' >Valor</TableCell>
                <TableCell align='right' >Valor Parcela</TableCell>
                <TableCell align='right' >Juros/Acré...</TableCell>
                <TableCell align='right' >Valor Pago</TableCell>
                <TableCell align='right' >Desconto</TableCell>
                <TableCell align='right' >Valor Pagar</TableCell>
                <TableCell>LCDPR</TableCell>
                <TableCell align='center' ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idcontaspagar} 
                  hover
                  sx={{ cursor: 'pointer' }}  
                >
                  <TableCell style={{ backgroundColor: row.boleto ? 'inherit' : '#ffe2db'}} > 
                    {row.boleto && !row.status ? <Checkbox checked={row.marcado} id={row.idcontaspagar} onChange={handleChange} />: '' }</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='center'>{dayjs(row.dataemissao).format('DD/MM/YYYY')}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='center'>{dayjs(row.vencimento).format('DD/MM/YYYY')}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='center'>{row.parcela}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='right'>{row.numerodoc}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='right'>{row.valornota.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='right'>{row.valorparcela.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='right'>{row.acrescimo.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='right'>{row.valorpago.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='right'>{row.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                  <TableCell style={{ color: row.venc ? '#db4a39' : row.boleto ? 'inherit' : '#ff9615'}} align='right'>{row.valorpagar.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                  <TableCell>
                    <Switch size='small' checked={row.lcdpr} />
                  </TableCell>
                  <TableCell>
                    <IconButton size='small'  onClick={() => navigate(`/contaspagar/detalhe/${row.idcontaspagar}`)}>
                      <Icon >edit</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody> 
   
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {basicstatus === 'true' && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Pagination  
                      page={pagina}
                      count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                      onChange={(_, newPage) => setSearchParams({busca: busca, basicstatus: basicstatus, basicselect: basicselect, parceiroselect: parceiroselect, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter> 
          </Table>
        </Table>
      </TableContainer>
      <DialoDesconto
        open={open} 
        onClose={handleClose}
        dados={rows}
        onDados={handleDesc}
        ontotal={handletotal}
        vselecionado={selecionado}
      /> 
      <DialoBaixa
        value={guuid}
        open={openBaixa} 
        onClose={handleCloseBaixa}
        dados={rows}
        onDados={handleDesc}
        ontotal={handletotal}
        vselecionado={selecionado}
      /> 
    </LayoutBaseDeFinanceiro>
  ); 
};