import { Apicadastro } from '../axioscadastro';

export interface IDetalheCnpj {
  nome: string;
  fantasia: string;
  logradouro: string;
  numero: string;
  municipio: string;
  bairro: string;
  uf: string;
  cep: string;
  email: string;
  telefone: string;
}

export interface IModifiedDetalheCnpj {
  nome: string;
  apelido: string;
  telefone: string;
  endereco: string;
  numero: string;
  bairro: string;
  idmunicipio: string;
  cep: string;
  email: string;
  cfop: number,
}

export interface IMunicipio{
    idmunicipio: string;
    cidade: string;
    uf: string;
}



const getAll = async (cnpj = ''): Promise<IModifiedDetalheCnpj[]> => {
  try {

    const { data } = await Apicadastro.get(`/buscarcnpj/${cnpj}`);

    if (data) {

      const Muni = await getMunicipio(data.municipio, data.uf);

      const modifiedData: IModifiedDetalheCnpj = {
        nome: data.nome,
        apelido: data.fantasia,
        telefone: data.telefone,
        endereco: data.logradouro,
        numero: data.numero,
        bairro: data.bairro,
        idmunicipio:  Muni.idmunicipio,
        cep: data.cep,
        email: data.email,
        cfop: Muni.uf === 'BA' ? 5101 : 6101,
      };
      
      return [modifiedData];
    }
        
    throw new Error('Erro ao obter os dados ou CNPJ inválido.');
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : 'Erro ao obter os dados.');
  }
};

const getMunicipio = async (municipo: string, uf: string): Promise<IMunicipio> => {
  try {

    const { data } = await Apicadastro.get(`/municipio?filter=${municipo}&uf=${uf}`);

    if (data) {    
      return data;
    }
        
    throw new Error('Erro ao obter os dados ou CNPJ inválido.');
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : 'Erro ao obter os dados.');
  }
};

export const CnpjService = {
  getAll,
};