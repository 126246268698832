/* eslint-disable @typescript-eslint/no-unused-vars */

import { Api } from '../../axios-config';


import { v4 as uuidv4 } from 'uuid';

export interface IListagemDependente {
  iddependente: string;
  idfun: string;
  nome: string;
  nascimento: Date;
  datadoc: Date;
  tipodependente: string;
  tipo: string;
}

export interface IDetalheDependente {
  idfun: string;
  nome: string;
  nascimento: Date;
  tipo: string;
  status: boolean;
  datadoc: Date;
  tipodependente: string;
    
}

// export interface IDetalheDependenteById {
//   idfun: string;
//   nome: string;
//   nascimento: Date;
//   tipo: string;
//   status: boolean;
//   datadoc: Date;
//   tipodependente: string;
//   colaborador: string;
// }

const getAll = async (idfun : string): Promise<IListagemDependente[] | Error> => {
  try {
    const urlRelativa = `/dependentes?idfun=${idfun}`;
    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data;
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


const getById = async (id: string): Promise<IDetalheDependente | Error> => {
  try {
    const { data } = await Api.get(`/dependentes/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheDependente): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const { data } = await Api.post<IDetalheDependente>('/dependentes',  {iddependente: guuid, ...dados});

    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheDependente ): Promise<void | Error> => {
  
  try {
    await Api.put(`/dependentes/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/dependentes/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const DependenteService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};