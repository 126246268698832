/* eslint-disable @typescript-eslint/no-explicit-any */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface IRReceita {
  vencimento: Date;
  numeronfe: string;
  nome: string;
  cpf: string;
  historico: string;
  valornfe: number;
  nferecebido: number;
  nfereceber: number;
}

async function RelatorioReceita(dados: IRReceita [], produtor: string, periodo: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const TTotal = dados.reduce((acc, item) => acc + item.nfereceber, 0);
  const rows = dados.map((row) => {
    return [
      {text: moment(row.vencimento).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8},
      {text: row.numeronfe, alignment: 'left',  fontSize: 7},
      {text: row.nome+' \n CPF/CNPJ: '+(row.cpf.length == 11 ? (
        row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')  
      ) : (
        row.cpf.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      )), fontSize: 7, alignment: 'left', }, 
      {text: row.historico, alignment: 'left',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.valornfe), alignment: 'right', fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.nferecebido), alignment: 'right',  fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.nfereceber), alignment: 'right',  fontSize: 7},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }


  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [10, 10, 10, 10],
    header: 'simple text',
    footer: {
      columns: [
        'Left part',
        { text: 'Right part', alignment: 'right' }
      ]
    },
    content: ([
      {   
        table: {
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],  
                table: {
                  widths: [500],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: '#FFFFFF',  alignment: 'center', fillColor: process.env.REACT_APP_URL_EMPRESA, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                  ]
                }
              },
              
            ],
            [
              {text: 'Relatório de Contas a Receber LCDPR', colSpan: 2, margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
              {}
            ]
            
          ]
        }
      },
      {text: 'Produtor: ' + produtor, alignment: 'center',  fontSize: 13},
      {text: periodo,  fontSize: 8},
      {
        table:{
          widths: [45, 30, 160, 150, 42, 35, 42],
          body: [
            [  
              {text: 'Vencimento', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Número', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Parceiro', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Histórico', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Valor NFE', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Recebido', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Receber', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
            ],
            ...rows,
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: 'Totais',  fontSize: 8, alignment: 'right',  border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TTotal), alignment: 'right', fontSize: 7},
            ]
          ]
          
        },
      },
      
    ]),
      
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default RelatorioReceita;

