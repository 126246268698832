
import {  useState } from 'react';
import { Box, MenuItem, Paper, Select, useTheme } from '@mui/material';


interface IFerramentasDaListagemProps {
  //textoano?: string;
  aoSelecionar?: (ano: string ) => void;
  anoInicial?: string;
 // mostrarBotaoano?: boolean;
 
}

export const FerramentasDaListagemvaleresumo: React.FC<IFerramentasDaListagemProps> = ({
  
  aoSelecionar,
  //textoano = 'ano',
  //mostrarBotaoano = true,
  
}) => {
  const theme = useTheme();

  const currentYear = new Date().getFullYear(); // Obtém o ano atual
  const [ano, setAno] = useState<string>(currentYear.toString()); // Define o ano atual como valor inicial
  // const [ano, setAno] = useState<string>('');
 
  const anos = ['2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026' ];
  
  return (
    
    <Box
      gap={2}
      marginX={1}
      padding={1}
      paddingX={1}
      display="flex"
      alignItems="center"
      height={theme.spacing(6)}
      component={Paper}
    > {/*mostrarBotaoano && (
        <Button
          component={Paper}
          
          disableElevation
          variant='contained'
        >{textoano}</Button>
    )*/}
      <Select
        labelId='demo-simple-select-helper-label'
        id='demo-simple-select-helper'
        label='Ano'
        
        value={ano}
        onChange={(event) => { setAno(event.target.value); aoSelecionar?.(event.target.value); }}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {anos.map((ano) => (
          <MenuItem
            
            key={ano}
            value={ano}
          >
            {ano}
          </MenuItem>
          
        ))}
      </Select> 
     
    </Box>
  );
};