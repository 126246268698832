/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { LayoutBaseDeDashboard } from '../../../shared/layouts';
import { useEffect, useState } from 'react';
import { getTemaSistema } from '../../../shared/contexts';
import GraficoRoscaLCDPR from './GraficoRoscaLCDPR';
import OutlinedCard2LCDPR from './OutlinedCard2LCDPR';
import GraficoBarraLCDPR from './GraficoBarraLCDPR';
import { DashboardLCDPRService } from '../../../shared/services/api/fiscal/dashboardLCDPR/DashboardLCDPRService';
import GraficoArea from '../../../shared/forms/GraficoArea';

export const DashboardLCDPR = () => {
  const teme = getTemaSistema();
  const [tema, setTema] = useState<'dark' | 'light'>();
  const [loading, setLoading] = useState(false);

  const [xl, setXl] = useState(0);
  const [lg, setLg] = useState(0);
  const [md, setMd] = useState(0);
  const [sm, setSm] = useState(0);
  const [xs, setXs] = useState(0);

  type Extrair = {
    titulo: string;
    valor: number;
    cor: string;
  };

  type Extrair2 = {
    titulos: string;
    valor: { valor: number }[];
    cor: string;
  };

  function extrairNomes(titulo: Extrair[]): string[] {
    return titulo ? titulo.map(titulo => titulo.titulo.trim().split(' ')[0]) : [];
  }

  function extrairValores2(titulo: Extrair[] | undefined): number[] {
    return titulo ? titulo.map(titulo => parseFloat(titulo.valor.toFixed(2))) : [];
  }

  function extrairCor(titulo: Extrair[]): string[] {
    return titulo ? titulo.map(titulo => titulo.cor) : [];
  }

  interface Dados {
    entrada: number;
    saida: number;
  }
  

  const extrairCores = (periodo: Extrair2[]): string[] => periodo.map(produtor => produtor.cor);

  useEffect(() => {
    if (teme === 'dark') {
      setTema('dark');
    } else {
      setTema('light');
    }
  }, [teme]);

  const [Server, setServer] = useState(
    {
      nivel: 1,

      card: [{ titulo: '', valor: 0, cor: '' }],
      total: [{ titulo: '', valor: 0, cor: '' }],
      nfe: [{ titulo: '', valor: 0, cor: '' }],
      periodo: [{entrada: 0, saida: 0}],

      receitaspercentual: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
      despesaspercentual: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
      receitasindividuais: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
      despesasindividuais: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
    }
  );

  useEffect(() => {
    if (Server.card.length === 2) {
      setXl(6);
      setLg(6);
      setMd(6);
      setSm(12);
      setXs(6);
    } else if (Server.card.length === 3) {
      setXl(4);
      setLg(4);
      setMd(6);
      setSm(12);
      setXs(6);
    } else if (Server.card.length === 4) {
      setXl(3);
      setLg(3);
      setMd(6);
      setSm(12);
      setXs(6);
    } else if (Server.card.length === 5) {
      setXl(2.4);
      setLg(2.4);
      setMd(6);
      setSm(12);
      setXs(6);
    } else {
      setXl(3);
      setLg(3);
      setMd(6);
      setSm(12);
      setXs(6);
    }
  }, [Server]);

  useEffect(() => {
    setLoading(true);
    DashboardLCDPRService.GetDashboardLCDPRService()
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          if (result.nivel === 1) {
            setServer(
              {
                nivel: result.nivel,

                card: [{ titulo: '', valor: 0, cor: '' }],
                total: [{ titulo: '', valor: 0, cor: '' }],
                nfe: [{ titulo: '', valor: 0, cor: '' }],
                periodo: [{entrada: 0, saida: 0}],

                receitaspercentual: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
                despesaspercentual: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
                receitasindividuais: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
                despesasindividuais: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
              }
            );
          } else {
            //setServer(result);
                        
            setServer(
              {
                ...result,

                receitaspercentual: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
                despesaspercentual: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
                receitasindividuais: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
                despesasindividuais: [{ titulos: '', valor: [{ valor: 0 }], cor: '' }],
              }
            );
          }
          setLoading(false);
        }
      });
  }, []);


  //Gráfico de Barras Receitas e Despesas por Peíodo 
  const periodo = [
    { titulos: 'Receita', valor: Server.periodo.map(item => ({ valor: item.entrada })), cor: '#47E637' },
    { titulos: 'Despesa', valor: Server.periodo.map(item => ({ valor: item.saida })), cor: '#E65E37' }
  ];
  
  const seriesDataperiodo = periodo.map(produtor => ({
    name: produtor.titulos,
    data: produtor.valor.map(v => parseFloat(v.valor.toFixed(2)))
  }));

  const optionsDataperiodo = {
    title: {
      text: 'Movimento do LCDPR',
    },
    colors: extrairCores(periodo),
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: {
      categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    }
  };

  //Gráfico de Barras Receitas por Peíodo com Percentual
  const seriesDataReceitaPercentual = Server.receitaspercentual.map(produtor => ({
    name: produtor.titulos,
    data: produtor.valor.map(v => parseFloat(v.valor.toFixed(2)))
  }));

  const optionsDataReceitaPercentual = {
    title: {
      text: 'Receitas por Peíodo com Percentual',
    },
    colors: extrairCores(Server.receitaspercentual),
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: {
      categories: ['Janeirio', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    }
  };

  //Gráfico de Barras Despesas por Peíodo com Percentual
  const seriesDataDespesasPercentual = Server.despesaspercentual.map(produtor => ({
    name: produtor.titulos,
    data: produtor.valor.map(v => parseFloat(v.valor.toFixed(2)))
  }));

  const optionsDataDespesasPercentual = {
    title: {
      text: 'Despesas por Peíodo com Percentual',
    },
    colors: extrairCores(Server.despesaspercentual),
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: {
      categories: ['Janeirio', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    }
  };

  //Gráfico de Barras Receitas por Peíodo Individual
  const seriesDataReceitasIndividuais = Server.receitasindividuais.map(produtor => ({
    name: produtor.titulos,
    data: produtor.valor.map(v => parseFloat(v.valor.toFixed(2)))
  }));

  const optionsDataReceitasIndividuais = {
    title: {
      text: 'Receitas por Peíodo sem Percentual',
    },
    colors: extrairCores(Server.receitasindividuais),
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: {
      categories: ['Janeirio', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    }
  };

  //Gráfico de Barras Despesas por Peíodo Individual
  const seriesDataDespesasIndividuais = Server.receitasindividuais.map(produtor => ({
    name: produtor.titulos,
    data: produtor.valor.map(v => parseFloat(v.valor.toFixed(2)))
  }));

  const optionsDataDespesasIndividuais = {
    title: {
      text: 'Despesas por Peíodo sem Percentual',
    },
    colors: extrairCores(Server.receitasindividuais),
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: {
      categories: ['Janeirio', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    }
  };

  return (
    <LayoutBaseDeDashboard>
      <Box display="flex" component={Paper} variant="outlined" >
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {(Server.nivel === 2) && (
              <Grid container item direction="row" >
                {Server.card.map((item, index) => (
                  <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={index}>
                    <OutlinedCard2LCDPR
                      nome={item.titulo.trim().split(' ')[0]}
                      valor={item.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                      cor={item.cor}
                      img="/img/patrao.png"
                      loading={loading}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container item direction="row" >
              {(Server.nivel === 1) && (
                <Grid container style={{ height: '95vh' }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ fontSize: 150, color: '#757575', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} gutterBottom component="div">
                      LCDPR
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {(Server.nivel === 2) && (
                <>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                    <Grid sx={{ margin: 0.5 }}>
                      {loading ? <Skeleton variant="rectangular" height={260} /> :
                        <Box>
                          <GraficoRoscaLCDPR
                            id="chart1"
                            series={extrairValores2(Server.total)}
                            chart={{
                              type: 'donut',
                            }}
                            width="100%"
                            height="260"
                            labels={extrairNomes(Server.total)}
                            themeMode={tema} //"dark" // ou "dark" ou deixe para padrão "dark"
                            title="Total de Receitas e Despesas"
                            cor={extrairCor(Server.total)}
                          />
                        </Box>}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                    <Grid sx={{ margin: 0.5 }}>
                      {loading ? <Skeleton variant="rectangular" height={260} /> :
                        <Box>
                          <GraficoRoscaLCDPR
                            id="chart2"
                            series={extrairValores2(Server.nfe)}
                            chart={{
                              type: 'donut',
                            }}
                            width="100%"
                            height="260"
                            labels={extrairNomes(Server.nfe)}
                            themeMode={tema} //"dark" // ou "dark" ou deixe para padrão "dark"
                            title="NF-e Emitidas por Produtor"
                            cor={extrairCor(Server.nfe)}
                          />
                        </Box>}
                    </Grid>
                  </Grid>


                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <Paper sx={{ margin: 0.5 }}>
                      {loading ? <Skeleton variant="rectangular" height={260} /> :
                        <GraficoArea
                          series={seriesDataperiodo}
                          options={optionsDataperiodo} />}
                    </Paper>
                  </Grid>


                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Paper sx={{ margin: 0.5 }}>
                      {loading ? <Skeleton variant="rectangular" height={260} /> :
                        <GraficoBarraLCDPR
                          series={seriesDataReceitaPercentual}
                          options={optionsDataReceitaPercentual} />}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Paper sx={{ margin: 0.5 }}>
                      {loading ? <Skeleton variant="rectangular" height={260} /> :
                        <GraficoBarraLCDPR
                          series={seriesDataDespesasPercentual}
                          options={optionsDataDespesasPercentual} />}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Paper sx={{ margin: 0.5 }}>
                      {loading ? <Skeleton variant="rectangular" height={260} /> :
                        <GraficoBarraLCDPR
                          series={seriesDataReceitasIndividuais}
                          options={optionsDataReceitasIndividuais} />}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Paper sx={{ margin: 0.5 }}>
                      {loading ? <Skeleton variant="rectangular" height={260} /> :
                        <GraficoBarraLCDPR
                          series={seriesDataDespesasIndividuais}
                          options={optionsDataDespesasIndividuais} />}
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LayoutBaseDeDashboard>
  );
};