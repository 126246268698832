/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Icon, MenuItem, Paper, Select, TextField, Typography, useTheme } from '@mui/material';
import { Environment } from '../../environment';
import { AutoCompleteParceiroListagem } from '../../forms';

interface IFerramentasDaListagemProps {
  textoDaBusca?: string;
  textoDoSelect?: string;
  textoDoStatus?: string;
  textonome?: string;
  textSelectFalse?: string;
  textSelectTrue?: string;
  mostrarInputBusca?: boolean;
  mostrarAutoComplete?: boolean;
  disbilitarBaixar?: boolean;
  disbilitarDesconto?: boolean;
  disbilitarSalvar?: boolean;
  disbilitarCheque?: boolean;
  disbilitarImprimir?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  aoMudarTextoDoSelect?: (novoTextoselect: string) => void;
  aoMudarTextoDoStatus?: (novoTextoDoStatus: string) => void;
  aoMudarTextoDoParceiro?: (novoTextoselect: string, texto1: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  mostrarBotaoCheque?: boolean;
  mostrarInputBasicSelect?: boolean
  aoClicarEmNovo?: () => void;
  aoClicarEmDesconto?: () => void;
  aoClicarEmSalvar?: () => void;
  aoClicarEmBaixa?: () => void;
  aoClicarEmCheque?: () => void;
  aoClicarEmImpressao?: () => void;
}
export const FerramentasDaListagemFinanceiro: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  textoDoSelect = '',
  textoDoStatus = '',
  textonome='',
  textSelectFalse='',
  textSelectTrue='', 
  aoMudarTextoDeBusca,
  aoMudarTextoDoSelect,
  aoMudarTextoDoStatus,
  aoMudarTextoDoParceiro, 
  mostrarInputBusca = false,
  mostrarBotaoCheque = false,
  mostrarAutoComplete = false,
  mostrarInputBasicSelect = false,
  aoClicarEmNovo,
  aoClicarEmDesconto,
  aoClicarEmSalvar,
  aoClicarEmBaixa,
  aoClicarEmCheque,
  aoClicarEmImpressao,
  disbilitarBaixar,
  disbilitarDesconto,
  disbilitarSalvar,
  disbilitarCheque,
  disbilitarImprimir,
}) => {
  const theme = useTheme();

  const handleuuidparceiroNome = (nome: string | undefined) => {
    if (nome != null) {
      textonome = nome;
    }  
  };

  const handleuuidparceiro = (uuid: string | undefined) => {
    if (uuid != null) {
      aoMudarTextoDoParceiro?.(uuid, textonome);
    }  
  };
 
  return (
    
    <Box
      gap={1}
      marginX={2}
      padding={1} 
      paddingX={2}
      display="flex"
      alignItems="center"
      //height={theme.spacing(5)}
      component={Paper}
    >
      <Grid container item direction="row" spacing={1}>  
        <Grid item xs={6} sm={6} md={3} lg={1.5} xl={1}>  
          {mostrarInputBasicSelect && (
            <Select
              fullWidth
              size='small'
              value={textoDoSelect}
              onChange={(event) => aoMudarTextoDoSelect?.(event.target.value)}
            >
              <MenuItem value={'r.numerodoc'}>Numero</MenuItem>
              <MenuItem value={'r.idparceiro'}>Parceiros</MenuItem>
            </Select>  
          )}   
        </Grid>  
        <Grid item xs={6} sm={6} md={3} lg={1.5} xl={1}>  
          {mostrarInputBusca && (
            <TextField
              fullWidth
              size='small'
              value={textoDaBusca}
              placeholder={Environment.INPUT_DE_BUSCA}
              onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
            />
          )} 
          {mostrarAutoComplete && (
            <Select
              fullWidth
              size='small'
              value={textoDoStatus}
              onChange={(event) => aoMudarTextoDoStatus?.(event.target.value)}
            >
              <MenuItem value={'false'}>{textSelectFalse}</MenuItem>
              <MenuItem value={'true'}>{textSelectTrue}</MenuItem>
            </Select>
          )}   
        </Grid>   
           
        <Grid item xs={12} sm={12} md={6} lg={8} xl={4}>     
              
          {mostrarAutoComplete && (
            <AutoCompleteParceiroListagem
              nome={handleuuidparceiroNome}
              uuidparceiro={handleuuidparceiro}
              isExternalLoading={false}
            />  
          )} 
        </Grid>
        <Grid item  xs={6} sm={6} md={2} lg={1.5} xl={1}> 
          <Button
            fullWidth
            size="small"
            color='primary'
            variant='contained'
            disableElevation
            onClick={aoClicarEmNovo}
            startIcon={<Icon>add</Icon>}
          >
            adicionar
          </Button>
        </Grid> 
        <Grid item xs={6} sm={6} md={2} lg={1.5} xl={1}> 
          <Button 
            size="small"
            fullWidth
            color='primary'
            variant='contained'
            disabled={disbilitarBaixar}
            disableElevation
            onClick={aoClicarEmBaixa}
            startIcon={<Icon>paid</Icon>}
          >
            Baixar
          </Button>
        </Grid> 
        {mostrarBotaoCheque && (
          <Grid item xs={6} sm={6} md={2} lg={1.5} xl={1}> 
            <Button 
              size="small"
              fullWidth
              color='primary'
              variant='contained'
              disableElevation
              disabled={disbilitarCheque}
              onClick={aoClicarEmCheque}
              startIcon={<Icon>payment</Icon>}
            >
               Cheque
            </Button>              
          </Grid> 
        )}
        <Grid item xs={6} sm={6} md={2} lg={1.5} xl={1}> 
          <Button
            size="small"
            fullWidth
            color='primary'
            disableElevation
            variant='outlined'
            disabled={disbilitarImprimir}
            onClick={aoClicarEmImpressao}
            startIcon={<Icon>printoutlined</Icon>}
          >
             Imprimir
          </Button>
        </Grid> 
        <Grid item xs={6} sm={6} md={2} lg={1.5} xl={1}> 
          <Button
            fullWidth
            size="small"
            color='primary'
            disableElevation
            disabled={disbilitarDesconto}
            variant='outlined'
            onClick={aoClicarEmDesconto}
            startIcon={<Icon>percent</Icon>}
          >
             Desconto
          </Button>
        </Grid>      
        <Grid item  xs={6} sm={6} md={2} lg={1.5} xl={1}> 
          <Button
            fullWidth
            size="small"
            color='primary'
            disableElevation
            disabled={disbilitarSalvar}
            variant='outlined'
            onClick={aoClicarEmSalvar}
            startIcon={<Icon>save</Icon>}
          >
             Salvar
          </Button>
        </Grid>    
      </Grid>   
    </Box>
  );
};