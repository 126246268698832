
export const menucadastro =  [
  {
    icon: 'dashboardicone',
    path: '/dashboardcadastro',
    label: 'Página inicial',
  },
  {
    icon: 'people',
    path: '/parceiros',
    label: 'Parceiros',
  },
  {
    icon: 'local_shipping',
    path: '/transportadoras',
    label: 'Transportadoras',
  },
  {
    icon: 'waves',
    path: '/fazendas',
    label: 'Fazendas',
  },
  {
    icon: 'compost',
    path: '/cultivos',
    label: 'Cultivo',
  },
  {
    icon: 'fit_screen',
    path: '/frutas',
    label: 'Frutas',
  },
  {
    icon: 'schema',  
    path: '/grupopatrimonio',
    label: 'Grupo de Patrimônio',
  },
  {
    icon: 'agriculture',
    path: '/patrimonios',
    label: 'Patrimônio',
  },
  {
    icon: 'contacts',
    path: '/produtores',
    label: 'Produtor',
  },
  {
    icon: 'account_tree',
    path: '/planodecontas',
    label: 'Plano de Conta',
  },
  {
    icon: 'location_on',
    path: '/municipios',
    label: 'Municipíos',
  },
  {
    icon: 'person_2',
    path: '/usuarios',
    label: 'Usuário',
  },

];

