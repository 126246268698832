import { ReactNode } from 'react';
import { Icon, IconButton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useDrawerContext } from '../contexts';
import OutlinedCard01 from '../forms/OutlinedCard01';


interface ILayoutBaseDePaginaProps {
    mostraCardTotal?: boolean;
    mostraCardSelecionado?: boolean;
    titulo: string;
    labelSelecionado: string;
    labeltotal: string;
    selecionado: string;
    total: string;
    children: React.ReactNode
    barraDeFerramentas?: ReactNode;
}

export const LayoutBaseDeFinanceiro: React.FC<ILayoutBaseDePaginaProps> = ({ 
  children, 
  titulo, 
  selecionado,
  total, 
  labelSelecionado,  
  labeltotal,  
  mostraCardTotal = true,
  mostraCardSelecionado = true,
  barraDeFerramentas,
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();
  
  const { toggleDrawerOpen } = useDrawerContext();

  return (
    <Box  height="100%" display="flex" flexDirection="column" >
      <Box padding={1}  marginLeft={2}  display="flex" alignItems="center" gap={1} height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}>
        {smDown && (
          <IconButton onClick={toggleDrawerOpen}>
            <Icon>menu</Icon>
          </IconButton>
        )}

        <Typography
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipses" 
          variant={smDown ? 'h5' : mdDown ? 'h4' : 'h3'}
        >
          {titulo}
        </Typography>


        <Box flex={1} display="flex"  padding={1} justifyContent="end">
          {mostraCardTotal && (
            <OutlinedCard01
              cor='#4D8FDB'
              nome={labeltotal} 
              valor={total} 
            />  
          )}   
          {mostraCardSelecionado && (
            <OutlinedCard01
              cor='#23DEA1'
              nome={labelSelecionado} 
              valor={selecionado} 
            />
          )}   
          
          
        </Box>
      </Box>

      {barraDeFerramentas && (
        <Box>
          {barraDeFerramentas}
        </Box>
      )}

      <Box padding={1}  overflow="auto">
        {children}
      </Box>
    </Box>
  );
};