import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Card, CardMedia, Typography } from '@mui/material';
import { useAuthContext, useDrawerContext } from '../../contexts';
import { MenuFiscal, MenuFiscalrelatorio, menucadastro, menuestoque, menuestoquerelatorio, menurh, menurhrelatorio } from '../../layouts';
import { MenuFaturamento } from '../../layouts/MenuFaturamento';
import { menufinanceiro, menufinanceirorelatorio } from '../../layouts/MenuFinanceiro';

interface IPaginaInicialProps {
  children: React.ReactNode;
}
export const PaginaInicial: React.FC<IPaginaInicialProps> = ({ children }) => {

  const { isSistema, sistema } = useAuthContext();
  const { setMenu, setMenuR } = useDrawerContext();
  const navigate = useNavigate();

  const handleRh = () => {
    setMenu(menurh);
    setMenuR(menurhrelatorio);
    sistema('rh'); 
    navigate('/dashboardrh');
  };

  const handleCadastro = () => {
    setMenu(menucadastro);
    setMenuR([]);
    sistema('cadastro');
    navigate('/dashboardcadastro');
  };

  const handleestoque = () => {
    setMenu(menuestoque);
    setMenuR(menuestoquerelatorio);
    sistema('estoque');
    navigate('/dashboardestoque');
  };

  const handleFiscal = () => {
    setMenu(MenuFiscal);
    sistema('fiscal');
    setMenuR(MenuFiscalrelatorio);
    navigate('/dashboardlcdpr');
  };

  const handleFaturamento = () => {
    setMenu(MenuFaturamento);
    setMenuR([]);
    sistema('Faturamento');
    
    navigate('/dashboardFaturamento');
  };

  const handleFinanceiro = () => {
    setMenu(menufinanceiro);
    setMenuR(menufinanceirorelatorio);
    sistema('Faturamento'); 
    navigate('/dashboardFinanceiro');
  };

  if (isSistema) return (
    <>{children}</>
  );

 
  return (

    <Box width='100vw' height='100vh' display='flex' alignItems='center'  justifyContent='center'>

      <Grid container direction="column" alignItems='center' padding={2} spacing={3}>

        <Typography variant='h6'>Escolha um Sistema</Typography>

        <Grid container item alignItems='center' justifyContent='center' direction="row" spacing={2}>

          <Grid container item direction="row" spacing={2} xs={8} sm={8} md={12} lg={8} xl={6}>

            <Grid container item direction="row" spacing={2} xs={6} sm={4} md={2} lg={2} xl={2}>
              <Box padding={2} display='flex' justifyContent='center'>
                <Card sx={{ minWidth: 100, maxWidth: 100, paddingLeft: 2, paddingRight: 1, paddingTop: 1 }}>
                  <Box padding={1} display='flex' alignItems='center' justifyContent='center'>
                    <CardMedia
                      component="img"
                      sx={{ height: 80 }}
                      image="/img/cadastro.png"
                      onClick={handleCadastro} />
                  </Box>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button variant="text"
                      onClick={handleCadastro}
                    >
                        Cadastros
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Grid>
            <Grid container item direction="row" spacing={2} xs={6} sm={4} md={2} lg={2} xl={2}>
              <Box padding={2} display='flex' justifyContent='center'>
                <Card sx={{ minWidth: 100, maxWidth: 100, paddingLeft: 2, paddingRight: 1, paddingTop: 1 }}>
                  <Box padding={1} display='flex' alignItems='center' justifyContent='center'>
                    <CardMedia
                      component="img"
                      sx={{ height: 80 }}
                      image="/img/estoque.png"
                      onClick={handleestoque} />
                  </Box>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button variant="text"
                      onClick={handleestoque}
                    >
                        ESTOQUE
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Grid>



            <Grid container item direction="row" spacing={2} xs={6} sm={4} md={2} lg={2} xl={2}>
              <Box padding={2} display='flex' justifyContent='center'>
                <Card sx={{ minWidth: 100, maxWidth: 100, paddingLeft: 2, paddingRight: 1, paddingTop: 1 }}>
                  <Box padding={1} display='flex' alignItems='center' justifyContent='center'>
                    <CardMedia
                      component="img"
                      sx={{ height: 80 }}
                      image="/img/faturamento.png"
                      onClick={handleFaturamento} />
                  </Box>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button variant="text"
                      onClick={handleFaturamento}
                    >
                        faturamento
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Grid>



            <Grid container item direction="row" spacing={2} xs={6} sm={4} md={2} lg={2} xl={2}>
              <Box padding={2} display='flex' justifyContent='center'>
                <Card sx={{ minWidth: 100, maxWidth: 100, paddingLeft: 2, paddingRight: 1, paddingTop: 1 }}>
                  <Box padding={1} display='flex' alignItems='center' justifyContent='center'>
                    <CardMedia
                      component="img"
                      sx={{ height: 80 }}
                      image="/img/financeiro.png"
                      onClick={handleFinanceiro} />
                  </Box>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button variant="text"
                      onClick={handleFinanceiro}
                    >
                        financeiro
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Grid>



            <Grid container item direction="row" spacing={2} xs={6} sm={4} md={2} lg={2} xl={2}>
              <Box padding={2} display='flex' justifyContent='center'>
                <Card sx={{ minWidth: 100, maxWidth: 100, paddingLeft: 2, paddingRight: 1, paddingTop: 1 }}>
                  <Box padding={1} display='flex' alignItems='center' justifyContent='center'>
                    <CardMedia
                      component="img"
                      sx={{ height: 80 }}
                      image="/img/rh.png"
                      onClick={handleRh} />
                  </Box>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button variant="text"
                      onClick={handleRh}
                    >
                        recursos humanos
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Grid>

            <Grid container item direction="row" spacing={2} xs={6} sm={4} md={2} lg={2} xl={2}>
              <Box padding={2} display='flex' justifyContent='center'>
                <Card sx={{ minWidth: 100, maxWidth: 100, paddingLeft: 2, paddingRight: 1, paddingTop: 1 }}>
                  <Box padding={1} display='flex' alignItems='center' justifyContent='center'>
                    <CardMedia
                      component="img"
                      sx={{ height: 80 }}
                      image="/img/fiscal.png"
                      onClick={handleFiscal} />
                  </Box>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button variant="text"
                      onClick={handleFiscal}
                    >
                      fiscal
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>

  );
};