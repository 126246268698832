/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Divider, Icon, Paper, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';


interface IFerramentasDeDetalheProps {
  textoBotaoNovo?: string;

  mostrarBotaoNovo?: boolean;
  mostrarBotaoVoltar?: boolean;
  mostrarBotaoApagar?: boolean;
  mostrarBotaoSalvar?: boolean;
  disableBotaoSalvar?: boolean;
  disableBotaoSalvarEFechar?: boolean;
  mostrarBotaoSalvarEFechar?: boolean;
  mostrarBotaoImpressao?: boolean;
  mostrarBotaoFechar?: boolean;
  disableBotaoFechar?: boolean;
  mostrarBotaoAbrir?: boolean;
  disableBotaoAbrir?: boolean;
 
  mostrarBotaoNovoCarregando?: boolean;
  mostrarBotaoVoltarCarregando?: boolean;
  mostrarBotaoApagarCarregando?: boolean;
  mostrarBotaoSalvarCarregando?: boolean;
  mostrarBotaoSalvarEFecharCarregando?: boolean;
  mostrarBotaoImpressaoCarregando?: boolean;
  mostrarBotaoFecharCarregando?: boolean;
  mostrarBotaoAbrirCarregando?: boolean;
 
  aoClicarEmNovo?: () => void;
  aoClicarEmVoltar?: () => void; 
  aoClicarEmApagar?: () => void;
  aoClicarEmSalvar?: () => void;
  aoClicarEmSalvarEFechar?: () => void;
  aoClicarEmImpressao?: () => void;
  aoClicarEmFechar?: () => void; 
  aoClicarEmAbrir?: () => void; 
}
export const FerramentasDeDetalheRomaneio: React.FC<IFerramentasDeDetalheProps> = ({
  textoBotaoNovo = 'Novo',

  mostrarBotaoNovo = true,
  mostrarBotaoVoltar = true,
  mostrarBotaoApagar = true,
  mostrarBotaoSalvar = true,
  disableBotaoSalvar = false,
  disableBotaoSalvarEFechar = false,
  mostrarBotaoSalvarEFechar = false,
  mostrarBotaoImpressao = true,
  mostrarBotaoFechar = true,
  disableBotaoFechar = false,
  mostrarBotaoAbrir = true,
  disableBotaoAbrir = false,
 
  mostrarBotaoNovoCarregando = false,
  mostrarBotaoVoltarCarregando = false,
  mostrarBotaoApagarCarregando = false,
  mostrarBotaoSalvarCarregando = false,
  mostrarBotaoSalvarEFecharCarregando = false,
  mostrarBotaoImpressaoCarregando = false,
  mostrarBotaoFecharCarregando = false,
  mostrarBotaoAbrirCarregando = false,
 
  aoClicarEmNovo,
  aoClicarEmVoltar,
  aoClicarEmApagar,
  aoClicarEmSalvar,
  aoClicarEmSalvarEFechar,
  aoClicarEmImpressao,
  aoClicarEmFechar,
  aoClicarEmAbrir,

}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  return (
    <Box
      gap={1}
      marginX={2}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(6)}
      component={Paper}
    >
      {(mostrarBotaoVoltar && !mostrarBotaoVoltarCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmVoltar}
          startIcon={<Icon>arrow_back</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              Voltar
          </Typography>
        </Button>
      )}

      {mostrarBotaoVoltarCarregando && (
        <Skeleton width={110} height={60} />
      )}
      {
        (
          mostrarBotaoVoltar &&
          (mostrarBotaoNovo || mostrarBotaoApagar || mostrarBotaoSalvar || mostrarBotaoSalvarEFechar)
        ) && (
          <Divider variant='middle' orientation='vertical' />
        )
      }

      {(mostrarBotaoSalvar && !mostrarBotaoSalvarCarregando) && (
        <Button
          color='primary'
          disableElevation
          disabled={disableBotaoSalvar}
          variant='contained'
          onClick={aoClicarEmSalvar}
          startIcon={<Icon>save</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Salvar
          </Typography>
        </Button>
      )}

      {mostrarBotaoSalvarCarregando && (
        <Skeleton width={110} height={60} />
      )} 

      {(mostrarBotaoSalvarEFechar && !mostrarBotaoSalvarEFecharCarregando && !smDown && !mdDown) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          disabled={disableBotaoSalvarEFechar}
          onClick={aoClicarEmSalvarEFechar}
          startIcon={<Icon>save</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Salvar e Fechar
          </Typography>
        </Button>
      )}

      {(mostrarBotaoSalvarEFecharCarregando && !smDown && !mdDown) && (
        <Skeleton width={180} height={60} />
      )}

      {(mostrarBotaoApagar && !mostrarBotaoApagarCarregando) && (
        <Button
          color='error'
          disableElevation
          variant='contained'
          onClick={aoClicarEmApagar}
          startIcon={<Icon>delete</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Apagar
          </Typography>
        </Button>
      )}

      {mostrarBotaoApagarCarregando && (
        <Skeleton width={110} height={60} />
      )}
      
      {(mostrarBotaoNovo && !mostrarBotaoNovoCarregando && !smDown) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmNovo}
          startIcon={<Icon>add</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {textoBotaoNovo}
          </Typography>
        </Button>
      )}

      {(mostrarBotaoNovoCarregando && !smDown) && (
        <Skeleton width={110} height={60} />
      )}
     
      {(mostrarBotaoImpressao && !mostrarBotaoImpressaoCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmImpressao}
          startIcon={<Icon>printoutlined</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Imprimir
          </Typography>
        </Button>
      )}

      {(mostrarBotaoFechar && !mostrarBotaoFecharCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          disabled={disableBotaoFechar}
          onClick={aoClicarEmFechar}
          startIcon={<Icon>lock</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Fechar Romaneio
          </Typography>
        </Button>
      )}  

      {(mostrarBotaoAbrir && !mostrarBotaoAbrirCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          disabled={disableBotaoAbrir}
          onClick={aoClicarEmAbrir}
          startIcon={<Icon>lockopenoutlined</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Abrir Romaneio
          </Typography>
        </Button>
      )}   

      {mostrarBotaoImpressaoCarregando && (
        <Skeleton width={110} height={60} />
      )}

    </Box >
  );
};