/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, VFieldMoney  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Configuracoes } from '../../../shared/services/api/rh/configuracoes/Configuracoes';

interface IFormData {
  valorfaixa1: number;
  valorfaixa2: number;
  valorfaixa3: number;
  valorfaixa4: number;
  faixa2:number;
  aliquota1: number;
  aliquota2: number;
  aliquota3: number;
  aliquota4: number;
  remuneracao: number;
  idade: number;
  almoco: number;
  cafe: number;  
  faltas1 :number;
  faltas2 :number;
  alivalor1 :number;
  alivalor2 :number;
  alivalor3 :number;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  
  valorfaixa1: yup.number().required().moreThan(0),
  valorfaixa2: yup.number().required().moreThan(0),
  valorfaixa3: yup.number().required().moreThan(0),
  valorfaixa4: yup.number().required().moreThan(0),
  faixa2:yup.number().required().moreThan(0),
  aliquota1: yup.number().required().moreThan(0),
  aliquota2: yup.number().required().moreThan(0),
  aliquota3:yup.number().required().moreThan(0),
  aliquota4:yup.number().required().moreThan(0),
  remuneracao: yup.number().required().moreThan(0),
  idade: yup.number().required().moreThan(0),
  almoco: yup.number().required().moreThan(0),
  cafe: yup.number().required().moreThan(0),
  faltas1 : yup.number().required().moreThan(0),
  faltas2 : yup.number().required().moreThan(0),
  alivalor1 : yup.number().required().moreThan(0),
  alivalor2 : yup.number().required().moreThan(0),
  alivalor3 : yup.number().required().moreThan(0),
});

export const Detalheconfiguracoes: React.FC = () => {
  const { id = '1'} = useParams<'id'>(); 
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { formRef, saveAndClose, isSaveAndClose } = useVForm();
  const [faixa01, setfaixa01] = useState('');
  const [faixa02, setfaixa02] = useState('');
  const [faixa03, setfaixa03] = useState('');
   
  useEffect(() => {
    if (id === '1') {
      setIsLoading(true);
      Configuracoes.getAll()
        .then((result) => {
          setIsLoading(false);
          
          if (result instanceof Error) {
            alert(result.message);
            navigate('/paginainicial');
          } else {
            let decimal = 0.01;
            let decimal2 = 0.01;
            let decimal3 = 0.01;
            const valor1 = result.valorfaixa1;
            const valorfinal1 = decimal += valor1;
            const valor2 = result.valorfaixa2;
            const valorfinal2 = decimal2 += valor2;
            const valor3 = result.valorfaixa3;
            const valorfinal3 = decimal3 += valor3;
            setfaixa01((valorfinal1).toFixed(2));
            setfaixa02((valorfinal2).toFixed(2));
            setfaixa03((valorfinal3).toFixed(2));          
            formRef.current?.setData(result);
          }
        });
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id !== 'novo'){
          Configuracoes
            .updateById(id, dadosValidados )
            .then((data) => {
              setIsLoading(false);

              if (data instanceof Error) {
                alert(data.message);
                
              } else {
                if (isSaveAndClose()) {
                  navigate('/dashboardrh');
                }
              }
            });
        }else{
          navigate('/dashboardrh');
        }
      });
  };
  return (
    <LayoutBaseDePagina
      titulo='Configuraçoes'
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoSalvarEFechar
          mostrarBotaoVoltar
          mostrarBotaoImpressao={id === 'novo'}
          mostrarBotaoApagar={id === 'nova'}
          mostrarBotaoNovo={id === 'nova'}
          mostrarBotaoSalvar={id === 'nova'}
          aoClicarEmSalvarEFechar={saveAndClose}            
          aoClicarEmVoltar={() => navigate('/dashboardrh')}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>INSS</Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                <Typography textAlign="center" variant='h6'>De</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                <Typography textAlign="center" variant='h6'>Até</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={1}>
                <Typography textAlign="center" variant='h6'>Alíquota</Typography>
              </Grid>

            </Grid>
            <Grid container item direction="row" spacing={0.5}>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <TextField
                  fullWidth
                  size='small'
                  value={'0,01'}
                  disabled={isLoading}
                />
              </Grid>
             
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='valorfaixa1'
                  disabled={isLoading}
                  
                />
              </Grid>
              <Grid item xs={3.3} sm={4} md={4} lg={2} xl={1}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='aliquota1'
                  disabled={isLoading}                  
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={0.5}>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <TextField
                  fullWidth
                  size='small'
                  value={faixa01}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='valorfaixa2'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={3.3} sm={4} md={4} lg={2} xl={1}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='aliquota2'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={0.5}>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <TextField
                  fullWidth
                  size='small'
                  value={faixa02}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='valorfaixa3'
                  disabled={isLoading}
                />
              </Grid> 
              <Grid item xs={3.3} sm={4} md={4} lg={2} xl={1}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='aliquota3'
                  disabled={isLoading}
                />
              </Grid> 
            </Grid>
            <Grid container item direction="row" spacing={0.5}>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <TextField
                  fullWidth
                  size='small'
                  value={faixa03}
                  disabled={isLoading}
                />
              </Grid> 
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='valorfaixa4'
                  disabled={isLoading}
                />
              </Grid> 
              <Grid item xs={3.3} sm={4} md={4} lg={2} xl={1}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='aliquota4'
                  disabled={isLoading}
                />
              </Grid> 
            </Grid>
          </Grid>
        </Box>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Salário Família</Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                <VTextField
                  fullWidth
                  size='small'
                  name='idade'
                  label='Idade'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='remuneracao'
                  label='Remuneração'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={1}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='faixa2'
                  label='Cota'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Alimentação</Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                <VTextField
                  fullWidth
                  size='small'
                  name='almoco'
                  label='Almoço'
                  disabled={isLoading}                 
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='cafe'
                  label='Café'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Alimentação</Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                <Typography textAlign="center" variant='h6'>De</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                <Typography textAlign="center" variant='h6'>Até</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2} xl={1}>
                <Typography textAlign="center" variant='h6'>Valor</Typography>
              </Grid>

            </Grid>
            <Grid container item direction="row" spacing={0.5}>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <TextField
                  fullWidth
                  size='small'
                  value={'0'}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='faltas1'
                  disabled={isLoading}
                  
                />
              </Grid>
              <Grid item xs={3.3} sm={4} md={4} lg={2} xl={1}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='alivalor1'
                  disabled={isLoading}                  
                />
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={0.5}>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <TextField
                  fullWidth
                  size='small'
                  value={'2'}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='faltas2'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={3.3} sm={4} md={4} lg={2} xl={1}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='alivalor2'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={0.5}>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <TextField
                  fullWidth
                  size='small'
                  value={'3'}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4.3} sm={4} md={4} lg={2} xl={2}>
                <TextField
                  fullWidth
                  size='small'
                  value={'31'}
                  disabled={isLoading}
                />
              </Grid> 
              <Grid item xs={3.3} sm={4} md={4} lg={2} xl={1}>
                <VFieldMoney
                  fullWidth
                  size='small'
                  name='alivalor3'
                  disabled={isLoading}
                />
              </Grid> 
            </Grid>
          </Grid>
        </Box>
      </VForm>  
    </LayoutBaseDePagina>
  );  
};