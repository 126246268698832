/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, BasicSelect, VFieldMoney, VTextFieldData } from '../../../shared/forms';

import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { VerbaService } from '../../../shared/services/api/rh/verbas/VerbaService';

type NullableDate = Date | null | undefined;

interface IFormData {
  descricao: string;
  valor: string;
  status: boolean;
  tipo: boolean;
  fixo: boolean;
  tipovalor: string;
  tipocalculo: string;
  dataexpiracao: NullableDate;
  tipoverba: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  descricao: yup.string().required().min(3).max(100),
  valor: yup.string().required(),
  status: yup.boolean().required().default(true),
  tipo: yup.boolean().optional().default(false),
  fixo: yup.boolean().required().default(false),
  tipovalor: yup.string().required(),
  tipocalculo: yup.string().required(),
  dataexpiracao: yup.date().nullable(), 
  tipoverba: yup.string().required(),
});


export const DetalheDeVerba: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      VerbaService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/verbas');
          } else {
            setDescricao(result.descricao);
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        idverbas: '',
        descricao: '',
        valor: '',
        status: true,
        tipo: false,
        fixo: false,
        tipovalor: '',
        tipocalculo: '',
        tipoverba: '',
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          VerbaService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/verbas');
                } else {
                  navigate(`/verba/detalhe/${result}`);
                }
              }
            });
        } else {
          VerbaService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/verbas');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      VerbaService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/verbas');
          }
        });
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Nova Verba' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Nova'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/verbas')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/verba/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Geral</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Grid container item direction="row" spacing={1} padding={1} >
                <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>          
                  <VTextField
                    fullWidth
                    size='small'
                    name='descricao'
                    disabled={isLoading}
                    label='Descrição'
                    onChange={e => setDescricao(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                  <VFieldMoney
                    fullWidth
                    size='small'
                    name='valor'
                    disabled={isLoading}
                    label='Valor'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} padding={1} >     
                <Grid container item direction="row" spacing={2} >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <BasicSelect
                      data={[
                        { nameBD: 'percentual', descricao: 'Percentual' },
                        { nameBD: 'valor', descricao: 'Valor' },
                      ]}
                      label='Tipo de Valor'
                      name='tipovalor'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <BasicSelect
                      data={[
                        { nameBD: 'salario', descricao: 'Salário' },
                        { nameBD: 'salariocarteira', descricao: 'Salário Carteira' },
                      ]}
                      label='Base de Cálculo'
                      name='tipocalculo'
                      disabled={isLoading}
                    />
                  </Grid>
            
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <BasicSelect
                      data={[
                        { nameBD: 'desconto', descricao: 'Desconto' },
                        { nameBD: 'vencimento', descricao: 'Vencimento' },
                      ]}
                      label='Tipo de Verba'
                      name='tipoverba'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <VTextFieldData
                      fullWidth
                      name='dataexpiracao'
                      label='Data Expiração'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={1}>
                    <VSwitch
                      size='small'
                      name='fixo'
                      label='Fixo'
                      fdisabled={isLoading}
                      aliamento='start'
                    />
                  </Grid> 
                  <Grid item xs={6} sm={6} md={2} lg={2} xl={1}>
                    {(id != 'novo') && (
                      <VSwitch
                        size='small'
                        name='status'
                        label='Ativo'
                        fdisabled={isLoading}
                        aliamento='start'
                      />
                    )}
                  </Grid> 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};