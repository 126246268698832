/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Icon,  IconButton,  MenuItem,  Paper, Select, TextField, Typography, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

interface IFerramentasDaListagemProps {
  mostrarInputData?: boolean;
  disbilitarBaixar?: boolean;
  aoClicarEmPesquisa?: (datainicial: Dayjs | null, datafinal: Dayjs | null) => void;
  aoClicarEmNovo?: () => void;
}
export const FerramentasDaListagemFinanceiroEntreContas: React.FC<IFerramentasDaListagemProps> = ({
  mostrarInputData = true,
  aoClicarEmPesquisa,
  aoClicarEmNovo,
}) => {
  const theme = useTheme();
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs()); 
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 

  const handlePesquisa = () => {
    aoClicarEmPesquisa?.(dataInicial, dataFinal);
  };

  return (
    <Box
      gap={1}
      marginX={3}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      // height={theme.spacing(6)}
      component={Paper}
    >
      <Grid container item direction="row" spacing={0.5}>  
        <Grid item xs={5.5} sm={5.5} md={3} lg={2} xl={2}>  
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format='DD/MM/YYYY'
              value={dataInicial}
              onChange={(newValue) => setDataInicial(newValue)}
              slotProps={{
                textField: {    
                  size:'small',
                }
              }}
            />
          </LocalizationProvider>
        </Grid>   


        <Grid item xs={5.5} sm={5.5} md={3} lg={2} xl={2}>  
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format='DD/MM/YYYY'
              value={dataFinal}
              onChange={(newValue) => setDataFinal(newValue)} 
              slotProps={{
                textField: {    
                  size:'small',
                }
              }}
            />
          </LocalizationProvider>
        </Grid>   

        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>  
          <IconButton color='primary'>
            <SearchIcon sx={{ fontSize: 30 }} onClick={() => {handlePesquisa();}} />
          </IconButton>
        </Grid>   
  
        <Grid item  xs={12} sm={12} md={2} lg={1.5} xl={1.5}> 
          <Box flex={1} display="flex" justifyContent="end">
            <Button
              color='primary'
              disableElevation
              variant='contained'
              onClick={aoClicarEmNovo}
              endIcon={<Icon>add</Icon>}
            >Adicionar</Button>
          </Box>
        </Grid> 
      </Grid> 
    </Box>
  );
};