import { Environment } from '../../../environment';
import { Apicadastro } from '../axioscadastro';


import { v4 as uuidv4 } from 'uuid';


export interface IListagemCfop {
    cfop: number;
    natureza: string;
    caepf: string;
    tipo: number;
    obs: string;
}

export interface IDetalheCfop {
  natureza: string;
  caepf: string;
  tipo: number;
  obs: string;
}

type TCfopComTotalCount = {
    data: IListagemCfop[];
    totalCount: number;
}

const getAlllist = async (filter = '',  id = '',): Promise<TCfopComTotalCount | Error> => {
  try {

    if(id != ''){
      filter=id;
    }

    const urlRelativa: string = filter === '' ?  `/cfop?tipo=natureza&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/cfoplist/${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa); 

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAll = async (page = 1,  filter = ''): Promise<TCfopComTotalCount | Error> => {
  try {
    const urlRelativa = `/cfop?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheCfop | Error> => {
  try {
    const { data } = await Apicadastro.get(`/cfop/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheCfop): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalheCfop>('/cfop',  {cfop: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheCfop): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/cfop/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/cfop/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const CfopService = {
  getAlllist,
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};