import { Environment } from '../../../environment';
import { ApiFinanceira } from '../axiosfinanceiro';
import { ErrorResponse } from 'react-router-dom';


export interface IConfirmarVale {
    idoperacao: string;
    nome: string;
    dataemissao: Date;
    historico: string;
    totalvale: number;
  }

export interface IValeConfirmar{
    idoperacao: string;
    idconta: number;
    databaixa: Date;
    historico: string;
    totalvale: number;
  }


type TotalCount = {
    data: IConfirmarVale[];
    totalCount: number;
}

const getAll = async (page = 1, ): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/valeconfirmar?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa); 

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const Confirmar = async (dados: IValeConfirmar): Promise<void | Error> => {
  try {  
    await ApiFinanceira.post('/valeconfirmar', dados); 
    return;   
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Baixra');
    }}
};

export const ValesFinanceiroConstasService = {  
  getAll, 
  Confirmar,  
};