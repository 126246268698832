import { useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type TVTextFieldProps = TextFieldProps & {
  id?: string;
  valor?: (id: string, valor: string) => void;
}
export const VFieldMoney4Faturamento: React.FC<TVTextFieldProps> = ({ valor, id, ...rest }) => {

  const formatValue = (inputValue: string | number): string => {
    let formattedValue: string;

    if (typeof inputValue === 'number') {
      inputValue = inputValue.toString();
    }
    // Caso seja um número inteiro
    if (!inputValue.includes('.')) {
      formattedValue = inputValue.replace(/\D/g, ''); // Remove caracteres não numéricos
      formattedValue += ',0000'; // Adiciona a vírgula e 4 zeros
      formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(,))/g, '$1.'); // Adiciona pontos como separadores de mil
    } else {
      // Caso seja um número decimal
      formattedValue = inputValue.replace(/\./g, ','); // Substitui ponto por vírgula
      formattedValue = formattedValue.replace(/(\d+,\d{0,4})\d*/, '$1'); // Limita a 4 casas decimais
      formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(,))/g, '$1.'); // Adiciona pontos como separadores de mil
    }

    return formattedValue;
  };

  const formatInputValue = (inputValue: string) => {
    inputValue = inputValue.replace(/\D/g, '');
    inputValue = inputValue.replace(/(\d)(\d{4})$/, '$1,$2');
    inputValue = inputValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return inputValue;
  };

  const formatSimple = (inputValue: string): string => {
    // Remove os pontos
    inputValue = inputValue.replace(/\./g, '');
    // Substitui as vírgulas por pontos
    inputValue = inputValue.replace(/,/g, '.');
    return inputValue;
  };

  const [value, setValue] = useState(formatValue(String(rest.defaultValue)));

  return (
    <TextField
      {...rest}

      value={value}
      onChange={e => { setValue(formatInputValue(e.target.value)); valor?.(id || '', formatSimple(formatInputValue(e.target.value))); rest.onChange?.(e); }}
      onKeyDown={(e) => { rest.onKeyDown?.(e); }}
    />
  );
}; 