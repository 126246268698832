import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,  MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { IListagemContasReceber } from '../../../shared/services/api/Financeiro/ContasReceber';

export interface ConfirmationDialogRawProps {
    open: boolean;
    dados?: IListagemContasReceber[];
    vselecionado: number;
    onClose: (value?: string) => void;
    ontotal: (value?: number) => void;
    onDados: (dados?: IListagemContasReceber[]) => void;
}

export const DialoDesconto: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose,  open, ...other } = props;
  const [age, setAge] = useState('1');
  const [value, setValue] = useState<string>('0');

  const formatInputValue = (inputValue: string) => {
    inputValue = inputValue.replace(/\D/g, '');
    inputValue = inputValue.replace(/(\d)(\d{2})$/, '$1,$2');
    inputValue = inputValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return inputValue;
  };

  const formatValueNumber = (Value: string) => {
    if (Value === undefined || Value === null) {
      return ''; 
    }  
    Value = Value.replace(/\D/g, '');
    Value = Value.replace(/(\d)(\d{2})$/, '$1.$2');
    Value = Value.replace(/(?=(\d{3})+(\D))\B/g, '');
    return Value;
  };

  const handleSave = () => {

    let descontoPercentual = 0;

    if(age === '1'){
      descontoPercentual = Number(formatValueNumber(value)) * 100 / props.vselecionado;
    }else{
      descontoPercentual = Number(formatValueNumber(value));
    }
    if(descontoPercentual === 0){
      alert('Valor tem que ser superior a zero');
    }else{
      const dadosComDesconto = props.dados?.map(conta => {
        const desconto = conta.receber * (descontoPercentual / 100); 
        const receber = conta.receber - desconto;
        return {
          ...conta,
          receber,
          desconto,};
      });

      const totalComDesconto = dadosComDesconto?.reduce((acumulador, conta) => {
        return acumulador + conta.receber;
      }, 0);

      props.ontotal(totalComDesconto);
      props.onDados(dadosComDesconto); 
      setValue('0');
      setAge('1');
      onClose();
    }
  };

  const handleCancel = () => {
    setValue('0');
    setAge('1');
    onClose();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
 
  return (
    <Dialog 
      open={open}
      {...other}
    >
      <DialogTitle>Desconto</DialogTitle>
      <DialogContent dividers>
        <Box 
          gap={1}
          padding={1}
          display="flex"
          alignItems="center"
        >
          <Select
            size='small'
            sx={{ m: 1, minWidth: 125 }}
            value={age}
            onChange={handleChange}
          >
            <MenuItem value={'1'}>Monetário</MenuItem>
            <MenuItem value={'2'}>Percentual</MenuItem>
          </Select>
          <TextField
            size='small'
            label='Desconto'
            value={value || ''}
            onChange={(e) => {
              const inputValue = formatInputValue(e.target.value);
              e.target.value = inputValue;
              setValue(inputValue);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button  variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button  variant='outlined' autoFocus onClick={handleSave}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
};