import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, SelectNumber} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { ProdutosNfeService } from '../../../shared/services/api/fiscal/produtosnfe/ProdutosNfeService';

interface IFormData {
  descricao: string;
  gtin: string;
  un: string;
  origem: number;
  ncm: string;
  status: boolean;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  descricao: yup.string().required(),
  gtin: yup.string().optional().default(''),
  un: yup.string().required().min(2).max(4),
  origem: yup.number().required(),
  ncm: yup.string().required().min(8).max(8),
  status: yup.boolean().optional().default(true),
});


export const DetalheDeProdutosNfe: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      ProdutosNfeService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/produtosnfe');
          } else {
            setDescricao(result.descricao);
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        descricao: '',
        gtin: '',
        un: '',
        origem: '',
        ncm: '',
        status: true,
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          ProdutosNfeService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/produtosnfe');
                } else {
                  navigate(`/produtosnfe/detalhe/${result}`);
                }
              }
            });
        } else {
          ProdutosNfeService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/produtosnfe');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ProdutosNfeService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/produtosnfe');
          }
        });
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Produtor' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvar
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/produtosnfe')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/produtosnfe/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <VTextField
                  fullWidth
                  size='small'
                  name='descricao'
                  disabled={isLoading}
                  label='Nome do Produto'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid> 
              <Grid item xs={8} sm={12} md={4} lg={4} xl={2}>
                <VTextField
                  fullWidth
                  size='small'
                  name='gtin'
                  disabled={isLoading}
                  label='GTIN'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid> 
              <Grid item xs={4} sm={12} md={2} lg={2} xl={1}>
                <VTextField
                  fullWidth
                  size='small'
                  name='un'
                  disabled={isLoading}
                  label='UN'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid> 
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                  <SelectNumber
                  
                    data={[
                      { nameBD: 0, descricao: 'Nacional' },
                      { nameBD: 1, descricao: 'Estrangeira Importação Direta' },
                      { nameBD: 2, descricao: 'Estrangeira Adquirida no Brasil' },
                      { nameBD: 3, descricao: 'Nacional Conteúdo Importação Superior a 40%' },
                      { nameBD: 4, descricao: 'Nacional Processos Básicos' },
                      { nameBD: 5, descricao: 'Nacional Conteúdo Importação Inferior Igual a 40%' },
                      { nameBD: 6, descricao: 'Estrangeira Importação Direta sem Similar' },
                      { nameBD: 7, descricao: 'Estrangeira Adquirida no Brasil sem Similar' },
                      { nameBD: 8, descricao: 'Nacional Conteúdo Importação Superior a 70%' },
                      { nameBD: 9, descricao: 'Reservado para uso Futuro' },
                      { nameBD: 10, descricao: 'Vazio' },
                    ]}
                    label='Origem'
                    name='origem'
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={8} sm={4} md={4} lg={4} xl={2}>
                  <VTextField
                    fullWidth
                    size='small'
                    name='ncm'
                    disabled={isLoading}
                    label='NCM'
                    onChange={e => setDescricao(e.target.value)}
                  />
                </Grid> 
                <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                  {(id != 'novo') && (
                    <VSwitch
                      name='status'
                      label='Ativo'
                      fdisabled={isLoading}
                      aliamento='top'
                    />
                  )}
                </Grid> 
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};