/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Avatar, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

import { FerramentasDaListagemVale } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemVale, ValeService } from '../../../shared/services/api/rh/vales/ValeService';

export const ListagemDeVale: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemVale[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const basicselect = useMemo(() => {
    return searchParams.get('basicselect') || 'descontar'; 
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  
  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      ValeService.getAll(pagina, busca, basicselect)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [busca, pagina, basicselect]);

  function cpf(v: string){
    v=v.replace(/\D/g,'');                    
    v=v.replace(/(\d{3})(\d)/,'$1.$2');       
    v=v.replace(/(\d{3})(\d)/,'$1.$2');       
    v=v.replace(/(\d{3})(\d{1,2})$/,'$1-$2'); 
    return v;
  }

  const StyledBadgeAtivo = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#3364f5',
      color: '#3364f5',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,  
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
   
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Vales'
      barraDeFerramentas={
        <FerramentasDaListagemVale
          mostrarInputBusca
          mostrarInputBasicSelect
          textoDaBusca={busca}
          textoDoSelect={basicselect}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/vale/detalhe/novo')}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, basicselect: basicselect, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ busca: busca, basicselect: textoselect, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
        <Table>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell  sx={{ maxWidth: 10, width:10 }} size="small" aria-label="a dense table"></TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell align='right' >Valor</TableCell>
                <TableCell style={{ width: 160 }} >Período</TableCell>
                <TableCell>Parcelas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idvale} 
                
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/vale/detalhe/${row.idvale}`)}
                >
                  <TableCell>
                    { row.status ? (
                      <StyledBadgeAtivo
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                      >
                        <Avatar alt="F" src={Environment.URL_IMGFUNCIONARIO+row.urlimg}/>
                      </StyledBadgeAtivo>

                    ) : (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                      >
                        <Avatar alt="F" src={Environment.URL_IMGFUNCIONARIO+row.urlimg}/>
                      </StyledBadge>
                    )}                 
                  </TableCell>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{cpf(row.cpf)}</TableCell>
                  <TableCell align='right'>{row.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                  <TableCell>{row.periodo}</TableCell>
                  <TableCell>{row.parcela}</TableCell>
                </TableRow>
              ))}
            </TableBody>
  
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
  
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHASSMALL) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHASSMALL)}
                      onChange={(_, newPage) => setSearchParams({ busca, basicselect: basicselect, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  ); 
};