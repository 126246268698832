import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
export interface IVencimentos {
  idprod: string;
  descricao: string;
  referencia: string;
  validade: Date;
  dias: number;
  qtdestoque: number;
}


async function RVencimentosdias(dados: IVencimentos[], localizacao: string, dias: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const rows = dados.map((row) => {
    return [
      {text: row.descricao, alignment: 'left',  fontSize: 7},
      {text: row.referencia, alignment: 'left',  fontSize: 7},
      {text: moment(row.validade).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 7},
      {text: row.dias, alignment: 'left',  fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.qtdestoque), alignment: 'right',  fontSize: 7},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);
 
  const documentDefinition = {
 
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 540],
          body: [
            [ 
              {
                image: imageData,  
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Vencimentos', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {text: 'Localização: '+localizacao, border: [false, false, false, false]},
      {text: (dias ? 'À Vencer em : '+dias+' Dias' : '')  , border: [false, false, false, false]},
      {
        table:{
          widths: [ 270, 120, 50, 20, 45],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [
              {text: 'Descrição', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'Referência', alignment: 'left', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Validade', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'dias', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Quantidade', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
            ],
            ...rows,
          ]
          
        },
      },
      
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default RVencimentosdias;

