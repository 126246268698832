import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';


import { v4 as uuidv4 } from 'uuid';


export interface IListagemGratificacoes {
    idgratificacao: number;
    idfun: string;
    periodo: string;
    nome:string;
    urlimg: string;
    valor:number;
    status: boolean;
}

export interface IDetalheGratificacoes {
  idfun: string;
  periodo: string;
  valor:number;
  historico: string;
  urlimg: string;
  nome: string;
}

export interface IDetalheGratificacoesCreate {
  idfun: string;
  periodo: string;
  valor:number;
  historico: string; 
}

type TotalCount = {
    data: IListagemGratificacoes[];
    totalCount: number;
}

const getAll = async (page = 1, filter = '', situacao=''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/gratificacoes?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&filter=${filter}&tipo=${situacao}`;

    const { data, headers } = await Api.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheGratificacoes | Error> => {
  try {
    const { data } = await Api.get(`/gratificacoes/${id}`);
   
    if (data) {
      return data;
    }
 
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheGratificacoesCreate): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
      
    const { data } = await Api.post<IDetalheGratificacoes>('/gratificacoes',  {idgratificacao: guuid, ...dados});
    
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) { 
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};


const updateById = async (id: string, dados: IDetalheGratificacoesCreate): Promise<void | Error> => {
  try {
    await Api.put(`/gratificacoes/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/gratificacoes/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const GratificacoesService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};