import { Apicadastro } from '../axioscadastro';

const getAll = async (filter = ''): Promise< string> => {
  try {
    const urlRelativa = `/parceiroscpf/${filter}`;
    
    const { data } = await Apicadastro.get(urlRelativa);

    if (data && typeof data === 'string') { 
      return data; 
    }

    throw new Error('Erro ao listar os registros ou dados inválidos.');
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlltransportadora = async (filter = ''): Promise< string> => {
  try {
    const urlRelativa = `/transportadorascpf/${filter}`;
    
    const { data } = await Apicadastro.get(urlRelativa);

    if (data && typeof data === 'string') { 
      return data; 
    }

    throw new Error('Erro ao listar os registros ou dados inválidos.');
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const CpfService = {
  getAlltransportadora,
  getAll,
};