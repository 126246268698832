/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface itensproducao {
  descricao: string; 
  quantidade: number;
  media: number;
  total: number;
  qtdajuste: number;
  mediadesconto: number;
  desconto: number;
}


async function RProducaoResumido(data: itensproducao[], datai: string, dataf: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const TotalQuantidade = data.reduce((acc, item) => acc + item.quantidade, 0);
  const TotalGeral = data.reduce((acc, item) => acc + item.total, 0);
  const TotalAjuste = data.reduce((acc, item) => acc + item.qtdajuste, 0);
  const TotalDesconto = data.reduce((acc, item) => acc + item.desconto, 0);
  const Totalmedia = TotalGeral / TotalQuantidade;
  const Totalmediadesconto = TotalDesconto / TotalAjuste;
  const rows = data.map((row) => {
    return [
      {text: row.descricao, alignment: 'left',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.quantidade), alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.media), alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.total), alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.qtdajuste), alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.mediadesconto), alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.desconto), alignment: 'right',  fontSize: 8, margin: [0, 3, 0, 0]},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    throw new Error('A imagem não foi convertida corretamente para base64.');
  }

 
  const documentDefinition = {

    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 540],
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Romaneio Produção', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {text: 'Período: '+datai+' a '+ dataf,  fontSize: 8},
      {
        table:{
          widths: [ 130, 60, 30, 60, 60, 30, 60],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [
              {text: 'Descrição', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'Quantidade', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Media', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Valor Total', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Qtd. Ajuste', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Media', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Total Desconto', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
            ],
            ...rows,
            [
              {text: '', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalQuantidade) , fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Totalmedia) , fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalGeral) , fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalAjuste) , fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Totalmediadesconto) , fontSize: 8, alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalDesconto) , fontSize: 8, alignment: 'right', border: [false, false, false, false]},
            ],
          ]
        },
      },
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition); 

  pdfDocGenerator.open();

}
export default RProducaoResumido;

