/* eslint-disable @typescript-eslint/no-explicit-any */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
 
export interface IImprimirFolha {
  nome: string;
  funcao: string;
  salario: number;
  carteira: number;
  percentualinslubridade: number;
  inss: number;
  insalubridade: boolean,
  horaextra: number;
  bomba: number;
  domingo: number;
  pistola: number;
  salariofamilia: number;
  gratificacao: number;
  vinsalubridade: number;
  falta: number;
  vale: number;
  alimentacao: number;
  verbasfixasv: number;
  verbasfixasd: number;
  verbasexpv: number;
  verbasexpd: number;
  eventosfixov: number;
  eventosfixosd: number;
  eventosexpv: number;
  eventosexpd: number;
  total: number;
}


async function ImpressaoFolha(dados: IImprimirFolha[], periodo: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const Tinss = dados.reduce((acc, item) => acc + item.inss, 0);
  const THE = dados.reduce((acc, item) => acc + item.horaextra, 0);
  const TBC = dados.reduce((acc, item) => acc + item.bomba, 0);
  const TDom = dados.reduce((acc, item) => acc + item.domingo, 0);
  const TPI = dados.reduce((acc, item) => acc + item.pistola, 0);
  const TFamilia = dados.reduce((acc, item) => acc + item.salariofamilia, 0);
  const TGarti = dados.reduce((acc, item) => acc + item.gratificacao, 0);
  const TFalta = dados.reduce((acc, item) => acc + item.falta, 0);
  const TVale = dados.reduce((acc, item) => acc + item.vale, 0);
  const TOutrosAl = dados.reduce((acc, item) => acc + item.alimentacao, 0);
  const Tboni = dados.reduce((acc, item) => acc + item.vinsalubridade, 0);
  const TEvFixoV = dados.reduce((acc, item) => acc + item.eventosfixov, 0);
  const TEvFixoD = dados.reduce((acc, item) => acc + item.eventosfixosd, 0);
  const TVeFixoV = dados.reduce((acc, item) => acc + item.verbasfixasv, 0);
  const TVeFixoD = dados.reduce((acc, item) => acc + item.verbasfixasd, 0);
  const TVeExpV = dados.reduce((acc, item) => acc + item.verbasexpv, 0);
  const TEvExpV = dados.reduce((acc, item) => acc + item.eventosexpv, 0);
  const TEvExpD = dados.reduce((acc, item) => acc + item.eventosexpd, 0);
  const Ttotal = dados.reduce((acc, item) => acc + item.total, 0);
  
  const rows = dados.map((row) => {  
    return [
      {text: row.nome +'\n Função: '+row.funcao, alignment: 'left',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.salario), alignment: 'right', fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.carteira), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 1, maximumFractionDigits: 2}).format(row.horaextra), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 0}).format(row.bomba), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 0}).format(row.domingo), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 0}).format(row.pistola), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.salariofamilia), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.gratificacao), alignment: 'right',  fontSize: 6},
      {text: row.percentualinslubridade, alignment: 'center', fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.vinsalubridade), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.inss), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 1, maximumFractionDigits: 1}).format(row.falta), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.vale), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.alimentacao), alignment: 'right',  fontSize: 6},
      {text: 'V. '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.eventosfixov)+'\n D. '+
        new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.eventosfixosd), alignment: 'right',  fontSize: 6},
      {text: 'V. '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.eventosexpv)+'\n D. '+
        new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.eventosexpd), alignment: 'right',  fontSize: 6},
      {text: 'V. '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.verbasfixasv)+'\n D. '+
        new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.verbasfixasd), alignment: 'right',  fontSize: 6},
      {text: 'V. '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.verbasexpv)+'\n D. '+
        new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.verbasexpd), alignment: 'right',  fontSize: 6},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.total), alignment: 'right',  fontSize: 8},
    ]; 
  }); 

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }

   
  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [10, 10, 10, 10],
    pageOrientation: 'landscape',  
    header: 'simple text',
    footer: {
      columns: [
        'Left part',
        { text: 'Right part', alignment: 'right' }
      ]
    },
    content: ([
      {   
        table: {
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],  
                table: {
                  widths: [720],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: '#FFFFFF',  alignment: 'center', fillColor: '#00A47B', border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                  ]
                }
              },
              
            ],
            [
              {text: 'Relatório de Folha de Pagamento do Período de '+periodo, colSpan: 2, margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
              {}
            ]
            
          ]
        }
      },
      {
        table:{
          widths: [ 130, 35, 35, 20, 20, 20, 20, 30, 30, 10, 30, 30, 20, 30, 32, 25, 25, 25, 25, 35],
          headerRows: 1,
          body: [
            [
              {text: 'Colaborador', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'salarios', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'carteira', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'H. E', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'B. C', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Dom.', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'PI', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Familia', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Grati.', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: '%', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Bonifi.', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},    
              {text: 'inss', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Falta', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Vale', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Outro AL', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Ev. Fixo', alignment: 'center', fillColor: '#dddddd',  fontSize: 7}, 
              {text: 'Ev. Exp', alignment: 'center', fillColor: '#dddddd',  fontSize: 7},    
              {text: 'Ve. Fixo', alignment: 'center', fillColor: '#dddddd',  fontSize: 7},   
              {text: 'Ve. Exp', alignment: 'center', fillColor: '#dddddd',  fontSize: 7}, 
              {text: 'total', alignment: 'center', fillColor: '#dddddd', fontSize: 8},

            ],
            ...rows,
          ] 
        },
      },
      {text: 'Valores Totais', style: 'subheader', fillColor: '#dddddd',  margin: [8, 3, 10, 2]},
      {  
        table:{
         
          widths: [45, 40, 30, 30, 30, 30, 45, 45, 45, 45, 45, 45, 45, 45, 45, 60],
          body: [
            [
              {text: 'H. E \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(THE), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'B. C \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TBC), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'Dom.\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TDom), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'PI \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TPI), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'Familia\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TFamilia), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'Grati.\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TGarti), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'Bonifi.\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tboni), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},  
              {text: 'inss \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Tinss), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'Falta\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TFalta), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'Vale\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TVale), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'Outro Al\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TOutrosAl), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},
              {text: 'Ev. Fixo\nV:'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TEvFixoV)+'\n D '+
                 new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TEvFixoD), alignment: 'center', fillColor: '#dddddd',  fontSize: 7}, 
              {text: 'Ev. Exp\nV:'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TEvExpV)+'\n D '+
                 new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TEvExpD), alignment: 'center', fillColor: '#dddddd',  fontSize: 7}, 
              {text: 'Ve. Fixo\nV:'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TVeFixoV)+'\n D '+
                 new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TVeFixoD), alignment: 'center', fillColor: '#dddddd',  fontSize: 7},    
              {text: 'Ve. Exp\nV:'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TVeExpV)+'\n D '+
                 new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(TEvExpD), alignment: 'center', fillColor: '#dddddd',  fontSize: 7}, 
              {text: 'total \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Ttotal), alignment: 'center', fillColor: '#dddddd', fontSize: 7},
            ],
          ] 
        },
      },
      
    ]),
      
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default ImpressaoFolha;


