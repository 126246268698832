import { Environment } from '../../../environment';
import { Apicadastro } from '../axioscadastro';


export interface IListagemMunicipios {
  idmunicipio: string;
  uf: string;
  cidade: string;   
}

export interface IDetalheMunicipios {
  idmunicipio: string;
  uf: string;
  cidade: string;   
}


type TMunicipiosComTotalCount = {
    data: IListagemMunicipios[];
    totalCount: number;
}

const getAlllist = async ( filter = '', id=''): Promise<TMunicipiosComTotalCount| Error> => {
  try {

    const urlRelativa: string = id === '' ? `/municipioslist?limit=${Environment.LIMITE_DE_CADASTRO}&filter=${filter}` : `/municipioslist/${id}` ;
    
    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_CADASTRO),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


const getAll = async (page = 1,  filter = '', id=''): Promise<TMunicipiosComTotalCount | Error> => {
  try {
    const urlRelativa = `/municipios?_page=${page}&_limit=${Environment.LIMITE_DE_CADASTRO}&filter=${filter}&id_like=${id}`;
    
    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_CADASTRO),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


const getById = async (id: string): Promise<IDetalheMunicipios | Error> => {
  try {
    const { data } = await Apicadastro.get(`/municipios/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheMunicipios): Promise<void | Error> => {
  try {
    const { data } = await Apicadastro.post<IDetalheMunicipios>('/municipios', dados);

    if (data) {
      return;
    }
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheMunicipios): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/municipios/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/municipios/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const MunicipiosService = {
  getAlllist,
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};