/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagemOS } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemOS, OsService } from '../../../shared/services/api/estoque/os/OsService';
import dayjs from 'dayjs';

export const ListagemDeOS: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemOS[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [buscafazenda, setBuscaFazenda] = useState(true);
  const [data, setData] = useState(true);
  const [patrimonio, setPatrimmonio] = useState(true);

  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'data'; 
  }, [searchParams]);

  useEffect(() => {
    if(tipo === 'data'){
      setData(true);
      setBuscaFazenda(false);
      setPatrimmonio(false);
    }
    else if(tipo === 'patrimonio'){
      setBuscaFazenda(false);
      setData(false);
      setPatrimmonio(true);
    }else if(tipo === 'numero'){
      setBuscaFazenda(false);
      setData(false);
      setPatrimmonio(true);
    }else{
      setBuscaFazenda(true);
      setData(false);
      setPatrimmonio(false);
    }
  }, [tipo]);

  const idfazenda = useMemo(() => {
    return searchParams.get('fazenda') || ''; 
  }, [searchParams]);

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const dataInicial = useMemo(() => {
    return searchParams.get('inicial') || dayjs().startOf('month').format('DD/MM/YYYY');
  }, [searchParams]);
 
  const dataFinal = useMemo(() => {
    return searchParams.get('final') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  function verificaFormato(data: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(data);
  }

  useEffect(() => {
    if (tipo === 'data' && verificaFormato(dataInicial) && verificaFormato(dataFinal)) { 
      setIsLoading(true);
      debounce(() => {
        OsService.getAll(pagina, dataInicial, dataFinal, '', '', tipo)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setRows([]);
    }        
  }, [dataInicial, dataFinal, pagina]);

  useEffect(() => {
    if (tipo === 'patrimonio' || tipo === 'numero'  && filter != '') { 
      setIsLoading(true);
      debounce(() => {
        OsService.getAll(pagina, dataInicial, dataFinal, '', filter, tipo)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }
    
  }, [filter, pagina]);

  useEffect(() => {
    if (tipo === 'fazenda' && idfazenda != '') { 
      setIsLoading(true);
      debounce(() => {
        OsService.getAll(pagina, '', '', idfazenda, '', tipo)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setRows([]);
    }        
  }, [idfazenda, pagina]);
    
  return (
    <LayoutBaseDePagina
      titulo='Listagem de OS'
      barraDeFerramentas={
        <FerramentasDaListagemOS
          mostrarInputFazenda={buscafazenda}
          mostrarInputData={data}
          mostrarInputBusca={patrimonio}
          mostrarInputBasicSelect
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          textoDoSelect={tipo}
          textoDaBusca={filter}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/os/detalhe/novo')}
          aoMudarDataInicial={datainicial => setSearchParams({ fazenda: idfazenda, inicial: datainicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ fazenda: idfazenda, inicial: dataInicial, final: dataFinal, tipo: textoselect, pagina: '1' }, { replace: true})}
          aoMudarDataFinal={datafinal => setSearchParams({ fazenda: idfazenda, inicial: dataInicial, final: datafinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, tipo: tipo, pagina: '1', azenda: idfazenda, datainicial: dataInicial, datafinal: dataFinal }, { replace: true })}
          aoMudarFazenda={idFaz => setSearchParams({ fazenda: idFaz, inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          dadosSelecao={[
            { nameBD: 'data', descricao: 'Data' },
            { nameBD: 'fazenda', descricao: 'Fazenda' },
            { nameBD: 'patrimonio', descricao: 'Patrimônio' },
            { nameBD: 'numero', descricao: 'Numero OS' },
          ]}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nº OS</TableCell>
              <TableCell>Data da Abertura</TableCell>
              <TableCell>Nº Patrimônio</TableCell>
              <TableCell>Patrimônio</TableCell>
              <TableCell>Modelo</TableCell>
              <TableCell>Situação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idos} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/os/detalhe/${row.idos}`)}
              >
                <TableCell>{row.numero}</TableCell>
                <TableCell>{dayjs(row.dataabertura).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{row.patrimonio}</TableCell>
                <TableCell>{row.nomepatrimonio}</TableCell>
                <TableCell>{row.modelo}</TableCell>
                <TableCell>{row.situacao}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};