import { Apicadastro } from '../axioscadastro';

export interface DataDashboard {
  parceiros: number,
  fazendas: number,
  trnasportadoras: number,
  patrimonios: number
}

const GetDashboardCadastroService = async (): Promise<DataDashboard | Error> => {
  try {
    const { data } = await Apicadastro.get('/dashboard');
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DashboardCadastroService = {
  GetDashboardCadastroService,
};