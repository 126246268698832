/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { VFieldMoney4Faturamento } from '../../Estoque/entradaEstoque/VFieldMoney4Faturamento';
import { ContasPagarService } from '../../../shared/services/api/Financeiro/ContasPagar';

interface IFormData {
  idcontaspagar: string;
  idparceiro: string;
	idplanoconta: string;
	idfazenda: string;
	idcultivo?: string | null;
	idpatrimonio?: string | null;
	tipopagamento: string;
	dataoperacao: Date;
	dataemissao: Date;
	vencimento: Date;
	parcela: string;
	numerodoc: string;
	valorparcela: number,
	valornota: number,
	acrescimo: number,
	valorpago: number,
	desconto: number,
	valorpagar: number,
	idprodutor: number,
	numeronfe: string,
	valornfe: number,
	nfeparcela: number,
	nfepagar: number,
  nfepago: number,
	tipodoc: number,
	historicolcdpr: string;
	historico: string;
	lcdpr: boolean,
  tipo: number,
}

interface IFormDataParcelas {
  idparceiro: string;
	idplanoconta: string;
	idfazenda: string;
	idcultivo?: string | null;
	idpatrimonio?: string | null;
	tipopagamento: string;
	dataoperacao: Date;
	dataemissao: Date;
	vencimento: Date;
	parcela: string;
	numerodoc: string;
	valorparcela: number, 
	valornota: number,
	acrescimo: number,
	valorpago: number,
	desconto: number,
	valorpagar: number,
	idprodutor: number,
	numeronfe: string,
	valornfe: number,
	nfeparcela: number,
	nfepagar: number,
  nfepago: number,
	tipodoc: number,
	historicolcdpr: string;
	historico: string;
	lcdpr: boolean,
  tipo: number,
}

export interface ConfirmationDialogRawProps {
  value: string;
  dados: IFormDataParcelas;
  open: boolean;
  onClose: (value?: string) => void;
}

export const DialogParcelas: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const navigate = useNavigate();
  const [lista, setLista] = useState<IFormData[]>([]);
  const [atualizarLista, setAtualizarLista] = useState<{ idcontaspagar: string; valorparcela: number; }[]>([]);


  useEffect(() => {
    if (props.dados && typeof props.dados === 'object') {
      setLista([]);
   
      if (Number(props.dados?.parcela) > 1) {
        const valorparcela =  props.dados.valornota ! / Number(props.dados?.parcela);
        const valorparcelanfe =   props.dados.valornfe ! / Number(props.dados?.parcela);
        
        for (let i = 0; i < Number(props.dados?.parcela); i++) {
          const dataVencimento = new Date(props.dados.vencimento);
          const newMonth = dataVencimento.getMonth() + i;
          dataVencimento.setMonth(newMonth);
          const  ops = (
            { idcontaspagar: uuidv4(), 
              idparceiro: props.dados.idparceiro,
              idplanoconta: props.dados.idplanoconta,
              idfazenda: props.dados.idfazenda,
              idcultivo: props.dados.idcultivo,
              idpatrimonio: props.dados.idpatrimonio,
              tipopagamento: props.dados.tipopagamento,
              dataoperacao: props.dados.dataoperacao,
              dataemissao: props.dados.dataemissao,
              vencimento: dataVencimento,
              parcela: `${i+1}/${props.dados.parcela}`, 
              numerodoc: props.dados.numerodoc, 
              valorparcela: valorparcela,
              valornota: props.dados.valornota,
              acrescimo: props.dados.acrescimo,
              valorpago: props.dados.valorpago,
              desconto: props.dados.desconto,
              valorpagar: valorparcela,
              idprodutor: props.dados.idprodutor,
              numeronfe: props.dados.numeronfe,
              valornfe: props.dados.valornfe,
              nfeparcela: valorparcelanfe,
              nfepagar: valorparcelanfe,
              nfepago: props.dados.nfepago,
              tipodoc: props.dados.tipodoc,
              historicolcdpr: props.dados.historicolcdpr,
              historico: props.dados.historico,
              lcdpr: props.dados.lcdpr,
              tipo: props.dados.tipo,
            });
          setLista((oldLista) => [...oldLista, ops]);       
        }
      }
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    const updatedList = lista.map((listItem) => {
      return {
        idcontaspagar: listItem.idcontaspagar,
        idparceiro: listItem.idparceiro,
        idplanoconta: listItem.idplanoconta,
        idfazenda: listItem.idfazenda,
        idcultivo: listItem.idcultivo,
        idpatrimonio: listItem.idpatrimonio,
        tipopagamento: listItem.tipopagamento,
        dataoperacao: listItem.dataoperacao,
        dataemissao: listItem.dataemissao,
        vencimento: listItem.vencimento,
        parcela: listItem.parcela,
        numerodoc: listItem.numerodoc,
        valorparcela: listItem.valorparcela,
        valornota: listItem.valornota,
        acrescimo: listItem.acrescimo,
        valorpago: listItem.valorpago,
        desconto: listItem.desconto,
        valorpagar: listItem.valorpagar,
        idprodutor: listItem.idprodutor,
        numeronfe: listItem.numeronfe,
        valornfe: listItem.valornfe,
        nfeparcela: listItem.nfeparcela,
        nfepagar: listItem.nfepagar,
        nfepago: listItem.nfepago,
        tipodoc: listItem.tipodoc,
        historicolcdpr: listItem.historicolcdpr,
        historico: listItem.historico,
        lcdpr: listItem.lcdpr,
        tipo: listItem.tipo,
      };           
    });
    const soma = updatedList.reduce((acc, listItem) => acc + listItem.valorpagar, 0);
    if (soma.toFixed(2) === props.dados.valornota.toString()){    
      ContasPagarService
        .createparcelas(updatedList)
        .then((result) => {
          if (result instanceof Error){
            alert(result.message);
          }else{
            onClose(value);
            navigate('/contaspagar');
          }
        });   
    }else{
      alert('Valor Total não Confere');
    }
  };

  const handleInputChange = useCallback((id: string, valor: string) => {
    const updatedList = lista.map((listItem) => {
      if (listItem.idcontaspagar === id) {
        const novoObjeto = { idcontaspagar: id, valorparcela: Number(valor) };
        setAtualizarLista(prevLista => [...prevLista, novoObjeto]);

        return {
          ...listItem,
          valorparcela: valor,  
        };
      } else {

        return listItem;
      }
    });
    
  }, [lista]);



  const handleInputChangeDate = useCallback((id: string, valor: Date) => {
    const updatedList = lista.map((listItem) => {
      if (listItem.idcontaspagar === id) {
        return {
          ...listItem,
          vencimento: valor, 
        };
      } else {

        return listItem;
      }
    });
  }, [lista]);

  return (
    <Dialog
      sx={{ width: '100%'  }}
      maxWidth="sm"
      open={open}
      {...other}
    >
      <DialogTitle>Lançamento de Parcelas</DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {lista.map((listItem) => (
            <ListItem disableGutters key={listItem.idcontaspagar}>
              <Grid container direction="column"  >
                <Grid container item direction="row" >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container item direction="row" spacing={1} >
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3.5}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format='DD/MM/YYYY'
                            //defaultValue={dayjs()}
                            value={dayjs(listItem.vencimento)}
                            label="Vencimento"
                            onChange={(newValue) => {
                              const inputValue = newValue!.toDate();
                              handleInputChangeDate(listItem.idcontaspagar, inputValue);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={2.8}>
                        <TextField
                          value={listItem.numerodoc}
                          label="Nº do Documento"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={1.5}>
                        <TextField
                          value={listItem.parcela}
                          label="Parcela"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={4}>
                        <VFieldMoney4Faturamento
                          id={listItem.idcontaspagar}
                          valor={handleInputChange}
                          label="Valor"
                          defaultValue={String(listItem.valorparcela)}
                        //variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancelar
        </Button>
        <Button onClick={handleOk}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 