/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import {  IVFormErrors, VForm,  VTextFieldData, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { RomaneioService } from '../../../shared/services/api/faturamento/romaneios/RomaneioService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';


export interface ConfirmationDialogRawProps {
    id: string;
    open: boolean;
    onClose: (value?: string) => void;
}

export const DialoFecharRomaneio: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const {formRef, save, } = useVForm();
  const { onClose,  open, ...other } = props;
  const [datafechamento, setDatafechamento] = useState<Dayjs | null>(dayjs());
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate(); 


  const handleSave = () => {
    setIsLoading(true);
    RomaneioService.FecharRomaneio(props.id, dayjs(datafechamento).format('DD/MM/YYYY'))
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          onClose();
          navigate('/romaneios'); 
        }
      });
  };

  const handleCancel = () => {
    onClose();
  };
  
  return (
    <Dialog 
      open={open}
      {...other}
      maxWidth='sm'
    >
      <DialogTitle>Fechar Romaneio</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={12}> 
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={datafechamento}
                    onChange={(newValue) => setDatafechamento(newValue)}
                  />
                </LocalizationProvider>
              </Grid>  
            </Grid>  
          </Grid>  
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
};