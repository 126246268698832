/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';



async function CartaoDePontoVerso(nome: string, id: string) {

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl = process.env.REACT_APP_URL_IMGLOG || '';
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }

  const documentDefinition : any = {
    pageSize: {
      width: 160,
      height: 250 
    },
    pageOrientation: 'landscape',
    pageMargins: [0, 0, 0, 0],
    
    content: [      
      {
        table: {
          margin: [0, 0, 0, 0], 
          widths: [120, 110],
          headerRows: 2,
          
          body: [
            [
              {
                text: nome,
                bold: true,
                alignment: 'left',
                fontSize: 10,
                margin: [5, 10, 0, 0],
                border: [false, false, false, false], 
                style: 'tableHeader', colSpan: 2,
                
              },
              {}
            ],
            [
              {
                image: imageData,
                width: 90,
                height: 90,
                border: [false, false, false, false],
                margin: [0, 20, 0, 17],
                alignment: 'center'
              },
              {
                qr: id,
                fit: '70',
                alignment: 'center',
                border: [false, false, false, false],
                margin: [0, 30, 0, 27],
                // [left, top, right, bottom]
              }
            ],
          ]
        },
      } 

    ],

  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();


}
export default CartaoDePontoVerso;

