import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';

import { v4 as uuidv4 } from 'uuid';

export interface IListagemFerias {
  idferias: string;
  idfun: string;
  datainicial: Date;
  datafinal: Date;
  periodo: string;
  valorinss: number;
  status: boolean;
}

export interface IFerias {
  idfun: string;
  datainicial: Date;
  datafinal: Date;
  valorinss: number;
}

type TCeiComTotalCount = {
    data: IListagemFerias[];
    totalCount: number;
}

const getAll = async (page = 1, idfun = ''): Promise<TCeiComTotalCount | Error> => {
  try {
    const urlRelativa = `/ferias?page=${page}&limit=${Environment.LIMITE_DE_LINHAS1}&idfun=${idfun}`;
    const { data, headers } = await Api.get(urlRelativa);
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS1),
      };
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const create = async (dados: IFerias): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const { data } = await Api.post<IFerias>('/ferias',  {idferias: guuid, ...dados});
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/ferias/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const FeriasService = {
  getAll,
  create,
  deleteById,
};