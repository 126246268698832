import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';


import {
  Dashboard,
  DetalheDeCei,
  ListagemDeCei,
  ListagemDeVale,
  DetalheDeVale,
  DetalheDePonto,
  Detalheconfiguracoes,
  ListagemDeGratificacoes,
  DetalheDeGratificacoes,
  Listagemcolaboradorvale,
  ListagemDeColaboradores,
  DetalheDeColaborador,
  ListagemDependentes,
  DetalheDependentes,
  ListagemEventos,
  DetalheEventos,
  ListagemDeVerba,
  DetalheDeVerba,
  ListagemFolha,
  DetalheDaFolha,
  TipoPgamento,
  ListagemFolhaCheque,
  FaltaPorCei,
  Aniversariante,
  ReciboFolha,
  SegurancaTrabalho,
  DashboardRH,
  ListagemDeStatusDeColaborador,
  ListagemDePontoAnual,
  ExporteFolhaExcel,
} from '../pages/rh';

import {
  ListagemUsuarios,
  DetalheUsuario,
  ListagemPermissao,
  ListagemDeCultivos,
  DetalheDeCultivos,
  ListagemDeTransportadoras,
  DetalheDaTransportadora,
  ListagemDeVeiculos,
  ListagemDeReboques,
  DetalheDeVeiculos,
  DetalheDeReboques,
  DetalheDeParceiros,
  ListagemDeParceiros,
  ListagemDeFazendas,
  DetalheDaFazenda,
  ListagemDeGrupoDePatrimonio,
  ListagemDePatrimonios,
  DetalheDePatrimonio,
  ListagemDeFrutas,
  ListagemDeProdutores,
  DetalheDeProdutor,
  ListagemDeMunicipios,
  PlanoDeContas,
  DashboardCadastro,
}from '../pages/cadastros'; 
import { 
  DetalheDeProduto,
  ListagemDeGrupoDeProdutos,
  ListagemDeOperacoes, 
  ListagemDeProdutos, 
  ListagemDeSubGrupoDeProdutos, 
  ListagemDeTransferencias,
  DetalheDaTransferencia,
  ListagemDeLocalizacao,
  ListagemDeRecebimentos,
  ListagemDeSaida,
  DetalheDaSaida,
  ListagemDeEntradas,
  DetalheDaEntrada,
  ListagemDeOS,
  DetalheDaOS,
  RelatoriosLocalizacao,
  RelatoriosVencimentos,
  RelatoriosOperacoes,
  DashboardEstoque,
  DashboardProd,
} from '../pages/Estoque';

import { 
  ListagemDeNfe,
  DetalheDaNfe,
  ListagemDeLCDPR,
  DetalheDeLCDPR,
  RelatoriosReceitaDespesa,
  RelatoriosConferencia,
  Relatorioslcdpr,
  RelatoriosNFE,
  ListagemDeProdutosNfe,
  DetalheDeProdutosNfe,
  DashboardLCDPR,
} from '../pages/fiscal';

import { useDrawerContext } from '../shared/contexts';
import { DashboardFaturamento, ListagemDeRomaneios, RelatoriosProducao, RelatoriosRomaneios } from '../pages/Faturamento';
import { DetalheDaRomaneio } from '../pages/Faturamento/Romaneios/DetalheDaRomaneio';
import { Conciliacao, DashboardFinanceiro, DetalheDeChequeEmitidos, DetalheDeContaBancaria, DetalheDePagar, DetalheDeReceber, ListagemCheque, ListagemChequeEmitidos, ListagemConfirmarBoleto, ListagemContasPagar, ListagemContasReceber, ListagemDeContaBancaria, ListagemEntreContas, ListagemVale, RelatoriosCheque, RelatoriosChequeEmitidos, RelatoriosContasPagar, RelatoriosContasPagas, RelatoriosContasReceber, RelatoriosContasRecebidas } from '../pages/Financeiro';
import { DetalheDeEntreContas } from '../pages/Financeiro/LancamentoEntreContas/DetalheDeEntreContas';
import { GeraFolha } from '../pages/rh/folha/GeraFolha';

export const AppRoutesPrincipal = () => {

  const { setDrawerOptions, menu } = useDrawerContext();

  useEffect(() => {
    setDrawerOptions(menu);
  }, []);


  return (
    <Routes>
      <Route path="/pagina-inicialrH" element={<Dashboard />} />

      <Route path="/transportadoras" element={<ListagemDeTransportadoras />} />
      <Route path="/transportadoras/detalhe/:id" element={<DetalheDaTransportadora />} />
      <Route path="/veiculos" element={<ListagemDeVeiculos />} />
      <Route path="/veiculos/detalhe/:id/:idTransportadora" element={<DetalheDeVeiculos />} />
      <Route path="/reboques" element={<ListagemDeReboques />} />
      <Route path="/reboques/detalhe/:id/:idTransportadora" element={<DetalheDeReboques />} />
      <Route path="/parceiros" element={<ListagemDeParceiros />} />
      <Route path="/parceiros/detalhes/:id" element={<DetalheDeParceiros />} />
      <Route path="/fazendas" element={<ListagemDeFazendas />} />
      <Route path="/fazendas/detalhes/:id" element={<DetalheDaFazenda />} />
      <Route path="/grupopatrimonio" element={<ListagemDeGrupoDePatrimonio />} />
      <Route path="/patrimonios" element={<ListagemDePatrimonios />} />
      <Route path="/patrimonios/detalhe/:id" element={<DetalheDePatrimonio/>}/>
      <Route path="/frutas" element={<ListagemDeFrutas />} />
      <Route path="/produtores" element={<ListagemDeProdutores />} />
      <Route path="/produtores/detalhe/:id" element={<DetalheDeProdutor />} />
      <Route path="/municipios" element={<ListagemDeMunicipios />} />
      <Route path="/usuarios" element={<ListagemUsuarios />} />
      <Route path="/usuarios/:id" element={<DetalheUsuario />} />
      <Route path="/usuarios/permissoes/:id/:nome" element={<ListagemPermissao />} />
      <Route path="/planodecontas" element={<PlanoDeContas/>} />
      <Route path="/dashboardcadastro" element={<DashboardCadastro/>} />

      <Route path="/estoque" element={<ListagemDeProdutos/>} />
      <Route path="/estoque/detalhe/:id" element={<DetalheDeProduto/>}/>
      <Route path="/grupos" element={<ListagemDeGrupoDeProdutos/>} />
      <Route path="/subgrupos" element={<ListagemDeSubGrupoDeProdutos/>} />
      <Route path="/estoqueoperacoes" element={<ListagemDeOperacoes/>} />
      <Route path="/transferenciaestoque" element={<ListagemDeTransferencias/>} />
      <Route path="/transferenciaestoque/detalhe/:id" element={<DetalheDaTransferencia/>}/>
      <Route path="/localizacao" element={<ListagemDeLocalizacao/>} />
      <Route path="/transferenciarecebimento" element={<ListagemDeRecebimentos/>} />
      <Route path="/saidaestoque" element={<ListagemDeSaida/>} />
      <Route path="/saidaestoque/detalhe/:id" element={<DetalheDaSaida/>}/>
      <Route path="/entradaestoque" element={<ListagemDeEntradas/>} />
      <Route path="/entradaestoque/detalhe/:id" element={<DetalheDaEntrada/>}/>
      <Route path="/os" element={<ListagemDeOS/>} />
      <Route path="/os/detalhe/:id" element={<DetalheDaOS/>}/>
      <Route path="/restoquelocalizacao" element={<RelatoriosLocalizacao/>}/>
      <Route path="/restoquevencimento" element={<RelatoriosVencimentos/>}/>
      <Route path="/restoqueoperacoes" element={<RelatoriosOperacoes/>}/>
      <Route path="/dashboardestoque" element={<DashboardEstoque />} />
      <Route path="/dashboardprod" element={<DashboardProd />} />

      <Route path="/vales" element={<ListagemDeVale />} />
      <Route path="/ponto" element={<DetalheDePonto />} />
      <Route path="/vale/detalhe/:id" element={<DetalheDeVale />} />


      <Route path="/cultivos" element={<ListagemDeCultivos />} />
      <Route path="/cultivos/detalhe/:id" element={<DetalheDeCultivos />} />
      <Route path="/configuracoes/:id" element={<Detalheconfiguracoes />} />
      <Route path="/gratificacoes" element={<ListagemDeGratificacoes />} />
      <Route path="/gratificacoes/detalhe/novo" element={<DetalheDeGratificacoes />} />
      <Route path="/gratificacoes/:id" element={<DetalheDeGratificacoes />} />
      <Route path="/valeresumo" element={<Listagemcolaboradorvale />} />
      <Route path="/funcionarios" element={<ListagemDeColaboradores />} />
      <Route path="/funcionarios/detalhe/:id" element={<DetalheDeColaborador />} />
      <Route path="/cei" element={<ListagemDeCei />} />
      <Route path="/cei/detalhe/:id" element={<DetalheDeCei />} /> 
      <Route path="/dependentes" element={<ListagemDependentes />} />
      <Route path="/dependentes/detalhe/:id/:idColaborador" element={<DetalheDependentes />} />
      <Route path="/verbaseventos" element={<ListagemEventos />} />
      <Route path="/verbaseventos/detalhe/:id/:idColaborador" element={<DetalheEventos />} />
      <Route path="/verbas" element={<ListagemDeVerba />} />
      <Route path="/verba/detalhe/:id" element={<DetalheDeVerba />} />
      <Route path="/gerafolha" element={<GeraFolha />} />
      <Route path="/folha" element={<ListagemFolha />} />
      <Route path="/folha/detalhe/:id" element={<DetalheDaFolha />} />
      <Route path="/rhtipopagamento" element={<TipoPgamento />} />
      <Route path="/listagemfolhacheque/:id/:ano" element={<ListagemFolhaCheque />} />
      <Route path="/faltacei" element={<FaltaPorCei />} />
      <Route path="/aniversariante" element={<Aniversariante />} />
      <Route path="/recibopagamento" element={<ReciboFolha />} />
      <Route path="/segtrabalho" element={<SegurancaTrabalho />} />
      <Route path="/statuscolaborador" element={<ListagemDeStatusDeColaborador />} />
      <Route path="/pontoanual" element={<ListagemDePontoAnual />} />
      <Route path="/exportfolha" element={<ExporteFolhaExcel />} />
      <Route path="/dashboardrh" element={<DashboardRH/>} />

      <Route path="/nfe" element={<ListagemDeNfe />} />
      <Route path="/nfe/detalhe/:id" element={<DetalheDaNfe />} />
      <Route path="/dashboardlcdpr" element={<DashboardLCDPR />} />
      <Route path="/lcdpr" element={<ListagemDeLCDPR />} />
      <Route path="/lcdpr/detalhe/:id" element={<DetalheDeLCDPR/>}/>
      <Route path="/relatorioreceitadespesa" element={<RelatoriosReceitaDespesa/>}/>
      <Route path="/relatorioconferencia" element={<RelatoriosConferencia/>}/>
      <Route path="/relatoriolcdpr" element={<Relatorioslcdpr/>}/>
      <Route path="/relatorionfe" element={<RelatoriosNFE />}/>
      <Route path="/produtosnfe" element={<ListagemDeProdutosNfe />} />
      <Route path="/produtosnfe/detalhe/:id" element={<DetalheDeProdutosNfe />}/>


      <Route path="/romaneios" element={<ListagemDeRomaneios />} />
      <Route path="/romaneios/detalhe/:id" element={<DetalheDaRomaneio />} />
      <Route path="/rromaneios" element={<RelatoriosRomaneios />} />
      <Route path="/rproducao" element={<RelatoriosProducao />} />

      <Route path="/contasreceber" element={<ListagemContasReceber />} />
      <Route path="/contasreceber/detalhe/:id" element={<DetalheDeReceber />} />
      <Route path="/contaspagar" element={<ListagemContasPagar />} />
      <Route path="/contaspagar/detalhe/:id" element={<DetalheDePagar />} />
      <Route path="/chequeemitidos" element={<ListagemChequeEmitidos />} />
      <Route path="/chequeemitidos/detalhe/:id" element={<DetalheDeChequeEmitidos />} />
      <Route path="/relatoriochequeemitidos" element={<RelatoriosChequeEmitidos />} />
      <Route path="/cheque" element={<ListagemCheque />} />
      <Route path="/relatoriocheque" element={<RelatoriosCheque />} />
      <Route path="/confirmarboleto" element={<ListagemConfirmarBoleto />} />
      <Route path="/lancamentoentrecontas" element={<ListagemEntreContas />} />
      <Route path="/lancamentoentrecontas/detalhe/:id" element={<DetalheDeEntreContas />} />
      <Route path="/conciliacao" element={<Conciliacao />} />
      <Route path="/relatoriocontaspagar" element={<RelatoriosContasPagar />} />
      <Route path="/relatoriocontaspagas" element={<RelatoriosContasPagas />} />
      <Route path="/relatoriocontasreceber" element={<RelatoriosContasReceber />} />
      <Route path="/relatoriocontasrecebidas" element={<RelatoriosContasRecebidas />} />
      <Route path="/dashboardFaturamento" element={<DashboardFaturamento />} />
      <Route path="/dashboardFinanceiro" element={<DashboardFinanceiro />} />
      <Route path="/contabancaria" element={<ListagemDeContaBancaria />} />
      <Route path="/contabancaria/detalhe/:id" element={<DetalheDeContaBancaria />}/>
      <Route path="/confirmarvale" element={<ListagemVale />} />

      <Route path="*" element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};