import { Apiestoque } from '../../axiosestoque';

export interface DataDashboard {
  nivel: number,
  estoque: number,
  vencer: number,
  vencidos: number,
  topdez: [],
  entrada: [],
  saida: [],
}

export interface DataDashboardProd {
  entrada: number,
  saida: number,
  saldo: number,
  entradas: [],
  saidas: [],
  entradasaida:[],
}

export interface IDashboardProdList {
  iditens: string;
  dataemissao: Date;
  quantidade: number;
  origem: string;
  preco: number;
  conversor: number; 
  descricao: string;
}

type TotalCount = {
  data: IDashboardProdList[];
  totalCount: number;
}

const GetDashboardEstoqueService = async (): Promise<DataDashboard | Error> => {
  try {
    const { data } = await Apiestoque.get('/dashboard');
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const DashboardProd = async (idprod: string, idlocalizacao: string): Promise<DataDashboardProd | Error> => {
  try {
    const { data } = await Apiestoque.get(`/estoquedashboard?idprod=${idprod}&idlocalizacao=${idlocalizacao}`);
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const DashboardProdListEnt = async (page = 1, idprod: string, idlocalizacao: string): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/estoquedashboardlistent?page=${page}&limit=${5}&idprod=${idprod}&idlocalizacao=${idlocalizacao}`;
    const { data, headers } = await Apiestoque.get(urlRelativa);  
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || 8),
      };
    }
    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const DashboardProdListSaida = async (page = 1, idprod: string, idlocalizacao: string): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/estoquedashboardlistsaida?page=${page}&limit=${8}&idprod=${idprod}&idlocalizacao=${idlocalizacao}`;
    const { data, headers } = await Apiestoque.get(urlRelativa);  
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || 8),
      };
    }
    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DashboardEstoqueService = {  
  GetDashboardEstoqueService,
  DashboardProd,
  DashboardProdListEnt,
  DashboardProdListSaida,
};