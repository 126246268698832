import { Environment } from '../../../environment';
import { ApiFinanceira } from '../axiosfinanceiro';
import { ErrorResponse } from 'react-router-dom';

export interface IListagemCheque {
  idcheque: string;
	dataoperacao: Date;
	datadeposito: Date;
	agencia: string;
  banco: string;
	conta: string;
	titular: string;
	valor: number;
	status: number;
	numero: string;
	marcado: boolean;
	nome: string;
	tipostatus: string;
}

export interface ICheque{
  datadeposito: Date;
  emissao: Date;
  idconta: number;
  credor: string;
  valor: number;
  contapagas: boolean;
  idparceiro?: string | null;
  numero: string;
  historico: string;
  status: number,
}

export interface IChequeRelatorio{
   banco: string;
   dataoperacao: Date;
   datadeposito: Date;
   databaixa: Date;
   agencia: string;
   conta: string;
   titular: string;
   valor: number;
   numero: string;
   nome: string;
   tipostatus: string;
}

export interface Ibaixacheque{
  idconta: number;
  databaixa: Date;
}

type TotalCount = {
    data: IListagemCheque[];
    totalCount: number;
}

const getAll = async (page = 1, filter = '', tipo='', datainicial: string, datafinal: string): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/cheque?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&tipo=${tipo}&datainicial=${datainicial}&datafinal=${datafinal}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa); 

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const Baixa = async (dadosbaixa: Ibaixacheque, dados: IListagemCheque[]): Promise<void | Error> => {
  try {  
    const baixa = dados.map(conta => {
      return {
        idcheque: conta.idcheque,
        databaixa: dadosbaixa.databaixa,
        idconta: dadosbaixa.idconta,
      };
    });
    await ApiFinanceira.post('/chequebaixa', baixa); 
    return;   
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Baixra');
    }}
};

const RelatorioCheque = async (parceiro: string, status: number, conta: number, tipodata: string,  datainicial: string, datafinal: string): Promise<IChequeRelatorio[] | Error> => {
  try {
    const urlRelativa = `/relatoriocheque?parceiro=${parceiro}&tipodata=${tipodata}&conta=${conta}&status=${status}&datainicial=${datainicial}&datafinal=${datafinal}`;
      
    const { data } = await ApiFinanceira.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const ChequeService = {  
  getAll,
  Baixa,
  RelatorioCheque,
};