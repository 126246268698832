/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Divider, Icon, MenuItem, Menu, Paper, Skeleton, Theme, Typography, useMediaQuery, useTheme, Fade } from '@mui/material';
import { useState } from 'react';

interface IFerramentasDeDetalheProps {
  textoBotaoNovo?: string;
  textoBotaoCartaoPonto?: string;
  textoBotaoCartaoPontoVerso?: string;

  mostrarBotaoNovo?: boolean;
  mostrarBotaoVoltar?: boolean;
  mostrarBotaoApagar?: boolean;
  mostrarBotaoSalvar?: boolean;
  mostrarBotaoSalvarEFechar?: boolean;
  mostrarBotaoImpressao?: boolean;
  disableBotaoApagar?: boolean;
  disableBotaoSalvar?: boolean;
  mostrarBotaoNovoCarregando?: boolean;
  mostrarBotaoVoltarCarregando?: boolean;
  mostrarBotaoApagarCarregando?: boolean;
  mostrarBotaoSalvarCarregando?: boolean;
  mostrarBotaoSalvarEFecharCarregando?: boolean;
  mostrarBotaoImpressaoCarregando?: boolean;
  mostrarBotaoHistorico?: boolean;
  mostrarBotaoHistoricoCarregando?: boolean;
  mostrarBotaoferais?: boolean;
  mostrarBotaoferaisCarregando?: boolean; 
  mostrarBotaoauxilio?: boolean;
  mostrarBotaoauxilioCarregando?: boolean;

  mostrarBotaoCartaoPonto?: boolean;
  mostrarBotaoCartaoPontoCarregando?: boolean;
  aoClicarEmCartaoPonto?: () => void;

  mostrarBotaoCartaoPontoVerso?: boolean;
  mostrarBotaoCartaoPontoVersoCarregando?: boolean;
  aoClicarEmCartaoPontoVerso?: () => void;

  aoClicarEmNovo?: () => void;
  aoClicarEmVoltar?: () => void;
  aoClicarEmApagar?: () => void;
  aoClicarEmSalvar?: () => void;
  aoClicarEmSalvarEFechar?: () => void;
  aoClicarEmImpressao?: () => void;
  aoClicarEmHistorico?: () => void;
  aoClicarEmFerias?: () => void;
  aoClicarEmFeriasAdd?: () => void;
  aoClicarEmAuxilio?: () => void;
  aoClicarEmAuxilioAdd?: () => void;
}
export const FerramentasDeDetalhe: React.FC<IFerramentasDeDetalheProps> = ({
  textoBotaoNovo = 'Novo',
  textoBotaoCartaoPonto = 'Cartão de Ponto Frente',
  textoBotaoCartaoPontoVerso = 'Cartão de Ponto Verso',

  mostrarBotaoCartaoPonto = false,
  mostrarBotaoCartaoPontoVerso = false,

  mostrarBotaoNovo = true,
  mostrarBotaoVoltar = true,
  mostrarBotaoApagar = true,
  mostrarBotaoSalvar = true,
  mostrarBotaoSalvarEFechar = false,
  mostrarBotaoImpressao = true,
  mostrarBotaoHistorico = false,
  mostrarBotaoferais = false,
  mostrarBotaoauxilio = false,

  mostrarBotaoCartaoPontoCarregando = false,
  mostrarBotaoCartaoPontoVersoCarregando = false,

  mostrarBotaoNovoCarregando = false,
  mostrarBotaoVoltarCarregando = false,
  mostrarBotaoApagarCarregando = false,
  mostrarBotaoSalvarCarregando = false,
  mostrarBotaoSalvarEFecharCarregando = false,
  mostrarBotaoImpressaoCarregando = false,
  disableBotaoApagar = false,
  disableBotaoSalvar = false,
  mostrarBotaoHistoricoCarregando = false,
  mostrarBotaoferaisCarregando = false,
  mostrarBotaoauxilioCarregando = false,

  aoClicarEmCartaoPonto,
  aoClicarEmCartaoPontoVerso,
  aoClicarEmNovo,
  aoClicarEmVoltar,
  aoClicarEmApagar,
  aoClicarEmSalvar,
  aoClicarEmSalvarEFechar,
  aoClicarEmImpressao,
  aoClicarEmHistorico,
  aoClicarEmFerias,
  aoClicarEmFeriasAdd,
  aoClicarEmAuxilio,
  aoClicarEmAuxilioAdd,
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElAuxilio, setAnchorElAuxilio] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const opena = Boolean(anchorElAuxilio);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickAuxilio = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAuxilio(event.currentTarget);
  };
  const handleCloseAuxilio = () => {
    setAnchorElAuxilio(null);
  };

  const handleFeriasClick = () => {
    if (aoClicarEmFerias) {
      aoClicarEmFerias();
    }
    handleClose();
  };

  const handleFeriasAddClick = () => {
    if (aoClicarEmFeriasAdd) {
      aoClicarEmFeriasAdd();
    }
    handleClose();
  };

  const handleAuxilioAddClick = () => {
    if (aoClicarEmAuxilioAdd) {
      aoClicarEmAuxilioAdd();
    }
    handleCloseAuxilio();
  };

  const handleAuxilioClick = () => {
    if (aoClicarEmAuxilio) {
      aoClicarEmAuxilio();
    }
    handleCloseAuxilio();
  };
  
  return (
    <Box
      gap={1}
      marginX={3}
      padding={1}
      paddingX={1}
      display="flex"
      alignItems="center"
      height={theme.spacing(6)}
      component={Paper}
    >
      {(mostrarBotaoVoltar && !mostrarBotaoVoltarCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmVoltar}
          startIcon={<Icon>arrow_back</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Voltar
          </Typography>
        </Button>
      )}

      {mostrarBotaoVoltarCarregando && (
        <Skeleton width={110} height={60} />
      )}
      {
        (
          mostrarBotaoVoltar &&
          (mostrarBotaoNovo || mostrarBotaoApagar || mostrarBotaoSalvar || mostrarBotaoSalvarEFechar)
        ) && (
          <Divider variant='middle' orientation='vertical' />
        )
      }

      {(mostrarBotaoSalvar && !mostrarBotaoSalvarCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='contained'
          disabled={disableBotaoSalvar}
          onClick={aoClicarEmSalvar}
          startIcon={<Icon>save</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Salvar
          </Typography>
        </Button>
      )}

      {mostrarBotaoSalvarCarregando && (
        <Skeleton width={110} height={60} />
      )}

      {(mostrarBotaoSalvarEFechar && !mostrarBotaoSalvarEFecharCarregando && !smDown && !mdDown) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          disabled={disableBotaoSalvar}
          onClick={aoClicarEmSalvarEFechar}
          startIcon={<Icon>save</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Salvar e Fechar
          </Typography>
        </Button>
      )}

      {(mostrarBotaoSalvarEFecharCarregando && !smDown && !mdDown) && (
        <Skeleton width={180} height={60} />
      )}

      {(mostrarBotaoApagar && !mostrarBotaoApagarCarregando) && (
        <Button
          color='error'
          disableElevation
          variant='contained'
          disabled={disableBotaoApagar}
          onClick={aoClicarEmApagar}
          startIcon={<Icon>delete</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Apagar
          </Typography>
        </Button>
      )}

      {mostrarBotaoApagarCarregando && (
        <Skeleton width={110} height={60} />
      )}

      {(mostrarBotaoNovo && !mostrarBotaoNovoCarregando && !smDown) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmNovo}
          startIcon={<Icon>add</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {textoBotaoNovo}
          </Typography>
        </Button>
      )}

      {(mostrarBotaoNovoCarregando && !smDown) && (
        <Skeleton width={110} height={60} />
      )}

      {(mostrarBotaoCartaoPonto && !mostrarBotaoCartaoPontoCarregando && !smDown) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmCartaoPonto}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {textoBotaoCartaoPonto}
          </Typography>
        </Button>
      )}

      {(mostrarBotaoCartaoPontoCarregando && !smDown) && (
        <Skeleton width={110} height={60} />
      )}

      {(mostrarBotaoCartaoPontoVerso && !mostrarBotaoCartaoPontoVersoCarregando && !smDown) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmCartaoPontoVerso}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {textoBotaoCartaoPontoVerso}
          </Typography>
        </Button>
      )}

      {(mostrarBotaoCartaoPontoVersoCarregando && !smDown) && (
        <Skeleton width={110} height={60} />
      )}

      {(mostrarBotaoImpressao && !mostrarBotaoImpressaoCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmImpressao}
          startIcon={<Icon>printoutlined</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Imprimir
          </Typography>
        </Button>
      )}

      {(mostrarBotaoHistorico && !mostrarBotaoHistoricoCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmHistorico}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Histórico de Salário
          </Typography>
        </Button>
      )}

      {(mostrarBotaoferais && !mostrarBotaoferaisCarregando) && (
        <div>
          <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color='primary'
            disableElevation
            variant='outlined'
            onClick={handleClick}
          >
            <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
               ferias
            </Typography>
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleFeriasClick}>Listagem</MenuItem>
            <MenuItem onClick={handleFeriasAddClick}>Adicionar</MenuItem>
          </Menu>
        </div>
      )}

      {(mostrarBotaoauxilio && !mostrarBotaoauxilioCarregando) && (
        <div>
          <Button
            id="fade-button"
            aria-controls={opena ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={opena ? 'true' : undefined}
            color='primary'
            disableElevation
            variant='outlined'
            onClick={handleClickAuxilio}
          >
            <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
               Auxílio
            </Typography>
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorElAuxilio}
            open={opena}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleAuxilioClick}>Listagem</MenuItem>
            <MenuItem onClick={handleAuxilioAddClick}>Adicionar</MenuItem>
          </Menu>
        </div>
      )}

      {mostrarBotaoImpressaoCarregando && (
        <Skeleton width={110} height={60} />
      )}

    </Box >
  );
};