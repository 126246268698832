import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';

interface OutlinedCardProps {
  nome: string;
  valor: number;
  cor: string;
  img: string;
  loading: boolean;
}

const OutlinedCard2Financeiro: React.FC<OutlinedCardProps> = ({ nome, valor, cor, img, loading }) => {
  return (
    <Box sx={{ margin: 0.5 }}>
      <Card variant="outlined" sx={{ borderRadius: '8px' }}>
        <CardContent style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '8px', display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '3px', backgroundColor: cor }} sx={{ width: '0.1px', height: '65px' }} />
          </Box>
          <Box sx={{ flex: '1 0 auto', margin: 0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: 20, flexGrow: 1 }} >
                {nome}
              </Typography>
              {(img != '') && (
                <img src={img} alt="Cadastro" style={{ width: '30px', height: '30px'}} />
              )}
            </Box>
            <Typography sx={{ fontSize: 25, textAlign: 'right' }} color="text.secondary">
              {loading ? <Skeleton variant="text" /> : valor.toLocaleString('pt-BR', { minimumFractionDigits: 2})}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OutlinedCard2Financeiro;
