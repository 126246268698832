/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Environment } from '../../../environment';
import { Apicadastro } from '../axioscadastro';

export interface IListagemPlanoDeContas {
  id: string;
  label: string;
}

interface GetAllListPlanoDeContas {
  idplanoconta: string;
  descricao: string;
  grupo: string;
}

export interface IDetalhePlanoDeContas {
  idpai: string;
  id: string;
  label: string;
}

interface CreatePlanoDeContas {
  idpai: string;
  idplanoconta: string;
  descricao: string;
}

type TComTotalCount = {
  data: IListagemPlanoDeContas[];
  totalCount: number;
}
type TComTotalCountGetAllList = {
  data: GetAllListPlanoDeContas[];
  totalCount: number;
}

const getAll = async (): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = '/planoconta';

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllist = async (filter = '', id=''): Promise<TComTotalCountGetAllList | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/planocontalist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/planoconta/${id}` ;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const create = async (dados: CreatePlanoDeContas): Promise<string | Error> => {
  

  try {

    if(dados.idpai === 'null'){
      const { idpai, ...dadosIDetalheSaida } = dados;
      const { data } = await Apicadastro.post<CreatePlanoDeContas>('/planoconta', dadosIDetalheSaida);

      if (Object.keys(data).length === 0) {
        return 'ok';
      }

      return new Error('Erro ao criar o registro.');
    }else{
      const { data } = await Apicadastro.post<CreatePlanoDeContas>('/planoconta', dados);

      if (Object.keys(data).length === 0) {
        return 'ok';
      }

      return new Error('Erro ao criar o registro.');
    }
    
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, descricao: string): Promise<void | Error> => {
  try {
    if(id != 'null'){
      await Apicadastro.put(`/planoconta/${id}`, {descricao: descricao});
    }
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

export const PlanoDeContasService = {
  getAll,
  getAlllist,
  create,
  updateById,
};