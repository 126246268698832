/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useState } from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField,  VForm, useVForm, IVFormErrors, AutoCompleteFazenda, AutoCompleteLocalizacao, VSwitch, VTextFieldPassword, VTextFieldNumber  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { UsuarioService } from '../../../shared/services/api/cadastros/UsuariosService';
import NavTabsUsuario from './NavTabsUsuario';
import { Environment } from '../../../shared/environment';


interface IFormData {
  nome: string;
  usuario: string;
  senha: string; 
  idfazenda: string;
  idlocalizacao: string;
  status: boolean;
  nivelcadastro: number;
  nivelrh: number;
  nivelfiscal: number;
  nivelestoque: number;
  nivelfaturamento: number;
  nivelfinanceiro: number;
}

interface IFormDataUpdate {
  idfazenda: string;
  idlocalizacao: string;
  status: boolean;
  nivelcadastro: number;
  nivelrh: number;
  nivelfiscal: number;
  nivelestoque: number;
  nivelfaturamento: number;
  nivelfinanceiro: number;
}


const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  nome: yup.string().required().min(10),
  usuario: yup.string().required().min(5),
  senha: yup.string().required().min(6),
  idfazenda: yup.string().required(),
  idlocalizacao: yup.string().required(),
  status: yup.boolean().optional().default(true),
  nivelcadastro: yup.number().required(),
  nivelrh: yup.number().required(),
  nivelfiscal: yup.number().required(),
  nivelestoque: yup.number().required(),
  nivelfaturamento: yup.number().required(),
  nivelfinanceiro: yup.number().required(),
});


const formValidationSchema1: yup.ObjectSchema<IFormDataUpdate> = yup.object().shape({
  idfazenda: yup.string().required(),
  idlocalizacao: yup.string().required(),
  status: yup.boolean().optional().default(true),
  nivelcadastro: yup.number().required(),
  nivelrh: yup.number().required(),
  nivelfiscal: yup.number().required(),
  nivelestoque: yup.number().required(),
  nivelfaturamento: yup.number().required(),
  nivelfinanceiro: yup.number().required(),
});

export const DetalheUsuario: React.FC = () => {
  const navigate = useNavigate();
  const { id = 'novo' } = useParams<'id'>();
  const { formRef,  saveAndClose,  isSaveAndClose  } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState('');
  const [urlimg, setUrlimg] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      UsuarioService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/usuarios');
          } else {
            setNome(result.nome);
            formRef.current?.setData(result);
            if (result.urlimg != null) {
              setUrlimg(result.urlimg);
              setImage(Environment.URL_IMGUSUARIOS+result.urlimg);
            } 
          }
        });
    } else {
      formRef.current?.setData({
        nome: '',
        usuario: '',
        senha: '',
        idfazenda: '',
        idlocalizacao: '',
        status: true,
        nivelcadastro: 1,
        nivelrh: 1,
        nivelfiscal: 1,
        nivelestoque: 1,
        nivelfaturamento: 1,
        nivelfinanceiro: 1, 
      });
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    if (id === 'novo') {
      formValidationSchema.
        validate(dados, { abortEarly: false })
        .then((dadosValidados) => {
          setIsLoading(true);
          UsuarioService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/usuarios');
                }
              }
            });
        })
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};
          errors.inner.forEach(error => {
            if (!error.path) return;
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        });
    }else{
      const updatedados = {
        idfazenda: dados.idfazenda,
        idlocalizacao: dados.idlocalizacao,
        status: dados.status,
        nivelcadastro: dados.nivelcadastro,
        nivelrh: dados.nivelrh,
        nivelfiscal: dados.nivelfiscal,
        nivelestoque: dados.nivelestoque,
        nivelfaturamento: dados.nivelfaturamento,
        nivelfinanceiro: dados.nivelfinanceiro,
      };
      formValidationSchema1.
        validate(updatedados, { abortEarly: false })
        .then((dadosValidados) => {
          setIsLoading(true);
          UsuarioService
            .updateById(id, dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/usuarios');
                }
              }
            });
        })
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};
          errors.inner.forEach(error => {
            if (!error.path) return;
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={nome}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoSalvarEFechar
          mostrarBotaoImpressao={false}
          mostrarBotaoSalvar={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          mostrarBotaoApagarCarregando = {isLoading}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/usuarios')}
          mostrarBotaoVoltarCarregando = {isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
       
        <Box margin={1} display="flex" alignItems='center'  flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column"  padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            {(id != 'novo') && (
              <NavTabsUsuario
                id={id}
                nome={nome}
                case={0}                
              />
            )}

            <Grid item>
              <Typography variant='h6'>Dados do Cadastro</Typography>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={5} sm={5} md={3} lg={2} xl={1.5} >
                <Box width="100%" marginRight={5} >
                  <Avatar 
                    variant='rounded'
                    alt='F'
                    src={image || undefined}
                    sx={{ width: '100%', height: 200 }}
                  />
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                <Grid container item direction="row" spacing={2}> 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='nome'
                      disabled={id === 'novo' ? false : true || isLoading}
                      label='Nome'
                      onChange={e => setNome(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='usuario'
                      label='Login'
                      disabled={id === 'novo' ? false : true || isLoading}
                    />
                  </Grid>
                  {(id === 'novo') && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <VTextFieldPassword
                        fullWidth
                        size='small'
                        name='senha'
                        label='Senha'
                        isDisabled={isLoading}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <AutoCompleteFazenda
                      isExternalLoading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <AutoCompleteLocalizacao
                      isExternalLoading={isLoading}
                    />
                  </Grid>
                  {(id != 'novo') && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <VSwitch
                        size='small'
                        name='status'
                        label='Status'
                        fdisabled={isLoading}
                        aliamento='start'
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='nivelcadastro'
                      label='Nivel Cadastro'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='nivelestoque'
                      label='Nivel Estoque'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='nivelfaturamento'
                      label='Nivel Faturamento'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='nivelfinanceiro'
                      label='Nivel Financeiro'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='nivelrh'
                      label='Nivel RH'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='nivelfiscal'
                      label='Nivel Fiscal'
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 
    </LayoutBaseDePagina>
  );
};