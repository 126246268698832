import { ApiFinanceira } from '../../axiosfinanceiro';

export interface DataDashboard {
  nivel: number,
  
  receber: number,
  pagar: number,
  recebervencido: number,
  pagarvencido: number,

  receitadespesa:[],

  contasreceitas:[],
  contasdespesas:[]
  contasreceitasdia:[],
  contasdespesasdia:[],

  fazendasreceitas:[],
  fazendasdespesa:[],
}

const GetDashboardCadastroService = async (): Promise<DataDashboard | Error> => {
  try {
    const { data } = await ApiFinanceira.get('/dashboardfinanceiro');
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DashboardFinanceiroService = {
  GetDashboardCadastroService,
};