import { Environment } from '../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apicadastro } from '../axioscadastro';

export interface IListagemGrupoPatrimonio {
  idgrupopatrimonio: string;
  descricao: string;
  veiculos: boolean;
}

export interface IDetalheGrupoPatrimonio {
  descricao: string;
  veiculos: boolean;
}

type TComTotalCount = {
    data: IListagemGrupoPatrimonio[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/grupopatrimonio?page=${page}&limit=${Environment.LIMITE_DE_CADASTRO}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_CADASTRO),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllist = async (filter = '', id = ''): Promise<TComTotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/grupopatrimoniolist?limit=${Environment.LIMITE_DE_LINHAS}&tipo=nome&filter=${filter}` : `/patrimonioslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheGrupoPatrimonio | Error> => {
  try {
    const { data } = await Apicadastro.get(`/grupopatrimonio/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheGrupoPatrimonio): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalheGrupoPatrimonio>('/grupopatrimonio',  {idgrupopatrimonio: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheGrupoPatrimonio): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/grupopatrimonio/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/grupopatrimonio/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const GrupoPatrimonioService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
};