import { Environment } from '../../../environment';
import { Apicadastro } from '../axioscadastro';


import { v4 as uuidv4 } from 'uuid';


export interface IListagemParceiros {
   idparceiro: string,
   nome: string,
   cpf: string,
   apelido:string,
   cfop: number;
   diasvencimento: number;
}

export interface IDetalheParceiros {
    nome:string,
    cpf:string,
    apelido:string,
    rg:string,
    telefone: string,
    celular: string,
    endereco: string,
    numero: string,
    bairro: string,
    idmunicipio:string,
    cep:string,
    email: string,
    comprador: string,
    obs: string,
    diasvencimento: number,
    cfop: number,
    contribuinte: number,
    presensa: number,
    consumidorfinal:number,  
    emailnfe: string;
    enviaremailnfe: boolean;
}

type TotalCount = {
    data: IListagemParceiros[];
    totalCount: number;
}

const getAll = async (pagina = 1, filter = '', tipo=''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/parceiros?page=${pagina}&limit=${Environment.LIMITE_DE_CADASTRO}&filter=${filter}&tipo=${tipo}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_CADASTRO),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheParceiros | Error> => {
  try {
    const { data } = await Apicadastro.get(`/parceiros/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheParceiros): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalheParceiros>('/parceiros',  {idparceiro: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheParceiros): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/parceiros/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/parceiros/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const getAllList = async (filter = '', id = ''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ?  `/parceiroslist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&tipo=nome` : `/parceiroslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const ParceirosService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  getAllList,
};