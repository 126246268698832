/* eslint-disable @typescript-eslint/no-explicit-any */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface ContasReceber {
  parceiro: string;
  cpf: string
  vencimento: Date;
  fazenda: string;
  cultivo: string;
  historico: string;
  numerodoc: string;
  valorcompra: number; 
  recebido: number;
  acrescimos: number;
  desconto: number;
  valorparcela: number;
  receber: number;
}

async function RelatorioContasReceber(dados: ContasReceber[], periodo: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const TValorReceber = dados.reduce((acc, item) => acc + item.receber, 0);
  const ContaReceber = dados.map((receber) => {
    return [
      {text: moment(receber.vencimento).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8},
      {text: receber.parceiro+' \n CPF/CNPJ: '+receber.cpf , alignment: 'left',  fontSize: 7},
      {text: receber.numerodoc, alignment: 'left',  fontSize: 7},
      {text: receber.fazenda, alignment: 'left',  fontSize: 7},
      {text: receber.cultivo, alignment: 'left',  fontSize: 7},   
      {text: receber.historico, alignment: 'left',  fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(receber.valorparcela), alignment: 'right', fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(receber.acrescimos), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(receber.desconto), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(receber.recebido), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(receber.receber), alignment: 'right',  fontSize: 8},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }

  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 10, 20, 10],
    pageOrientation: 'landscape',  
    header: 'simple text',
    footer: {
      columns: [
        'Left part',
        { text: 'Right part', alignment: 'right' }
      ]
    },
    content: ([
      {   
        table: {
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],  
                table: {
                  widths: [720],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                  ]
                }
              },
              
            ],
            [
              {text: 'Relatório de Contas a Receber', colSpan: 2, margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
              {}
            ]
            
          ]
        }
      },
      {text: periodo,  fontSize: 8},
      {
        table:{
          widths: [45, 130, 30, 70, 70, 140, 50, 32, 35, 40, 47],
          body: [
            [
              {text: 'Vencimento', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Parceiro', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Número', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Fazenda', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Cultivo', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Histórico', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Valor Parcela', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Acrés.', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Desconto', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Recebido', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Receber', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
            ],
            ...ContaReceber,
            [  
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},  
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: 'Totais',  fontSize: 8, alignment: 'right',  border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TValorReceber), alignment: 'right', fontSize: 7},
            ]
          ]
          
        },
      },
      
    ]),
      
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default RelatorioContasReceber;

