/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface IItensImpressao{
  iditensrom: string;
	descricao: string;
	qtd: number;
	un: string;
	preco: number;
	subtotal: number;
}

interface IImpressaoRomaneio{
  idromaneio: string;
	nome: string;
	cpf: string;
	rg: string;
	endereco: string;
	bairro: string;
	cep: string;
	cidade: string;
	puf: string;
	telefone: string;
	comprador: string;
	numeronfe: string;
	dataemissao: string;
	vencimento: string;
	status: string;
	tuf: string;
	placa: string;
	vuf: string;
  numero: number;
	transportadora: string;
	ttelefone: string;
	tcpf: string;
	tendereco: string;
  tcidade: string;
  trg: string;
  tipofrete: number; 
  frete: number; 
  tipoicmsfrete: number; 
  icms: number; 
  tipobalanca: number;
  balanca: number;
  tiposeguro: number;
  seguro: number;
  icmsmercaodira: number; 
  valoritens: number;
  tipofunrural: number;
  funrural: number;
  descontofrutas: number;
  desconto: number;
  outros: number;
  valortotal: number;
  obs: string;
  nomebanco: string;
  agencia: string;
  numeroconta: string;
  titular: string;
  itens: IItensImpressao[];
}


async function PDFRomaneio(dados: IImpressaoRomaneio){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const produtos = dados.itens.map((producao) => {
    return [
      {text: producao.descricao, alignment: 'left', bold: true, border: [false, false, false, false], fontSize: 12},
    ]; 
  });  

  const quantidade = dados.itens.map((producao) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(producao.qtd), alignment: 'right', bold: true, 
        border: [false, false, false, false],  fontSize: 10},
    ]; 
  });

  const unidade = dados.itens.map((producao) => {
    return [
      {text: producao.un, alignment: 'center', bold: true, border: [false, false, false, false], fontSize: 10},
    ]; 
  });  

  const preco = dados.itens.map((producao) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(producao.preco), alignment: 'right', bold: true, 
        border: [false, false, false, false],  fontSize: 10},
    ]; 
  });

  const total = dados.itens.map((producao) => {
    return [
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(producao.subtotal), alignment: 'right', bold: true, 
        border: [false, false, false, false],  fontSize: 10},
    ]; 
  });

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }

  const documentDefinition = {

    content: [
      {
        style: 'tableExample',
        table: {
          widths: [90, 420],
          body: [
            [ 
              {
                image: imageData,
                width: 90,
                border: [false, false, false, false]
              },
              { border: [false, false, false, false],
                style: 'tableExample',
                table: {
                  widths: [200, 218],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 22,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                      {text: 'PRODUÇÃO E COMERCIALIZACAO', margin: [0, 10, 0, 0], italics: true, bold: true,  color: '#008080', fontSize: 13, border: [false, false, false, false]}, 
                    ],
                    [ 
                      {text: 'Rodovia BA 172, KM 1, nº 80, São Felix do Cobribe - Ba - CEP 47.665-000 - Fone (77) 3491-2455', fontSize: 10, colSpan: 2, border: [false, false, false, true]},
                      {}, 
                    ],
                    [ 
                      {text: 'ROMANEIO DE CARGA', margin: [5, 10, 0, 0], fontSize: 18, bold: true, color: '#00A47B',  alignment: 'center',  border: [false, false, false, false]},
                      {text: 'Nº '+dados.numero, margin: [30, 10, 0, 0], fontSize: 18, bold: true, alignment: 'left', border: [false, false, false, false]}, 
                    ],
                  ]
                }
              }, 
            ],
          ]
        }
      },
      {
        style: 'tableExample',
        table: {
          widths: [280, 130, 95],
          body: [
            [ {text: 'Destinatario', fontSize: 8, border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]}, 
              {text: '', border: [false, false, false, false]},
            ],
            [ {text: 'RAZAO SOCIAL / NOME\n'+ dados.nome, fontSize: 10, alignment: 'left', },
              {text: 'CPF / CNPJ\n'+ (dados.cpf.length == 11 ? (
                dados.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
              ) : (
                dados.cpf.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
              )), fontSize: 10, alignment: 'left', }, 
              {text: 'Inscrição Estadual\n'+ dados.rg, fontSize: 10, alignment: 'left', },
            ],
            [ {text: 'Endereço\n'+dados.endereco, fontSize: 10, alignment: 'left', },
              {text: 'Bairro\n'+dados.bairro, fontSize: 10, alignment: 'left', }, 
              {text: 'CEP\n '+dados.cep.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3'), fontSize: 10, alignment: 'left', },
            ],
            [ {text: 'Municipio\n'+dados.cidade, fontSize: 10, alignment: 'left', },
              {text: 'UF\n'+ dados.puf, fontSize: 10, alignment: 'left', }, 
              {text: 'Fone\n '+ dados.telefone, fontSize: 10, alignment: 'left', },
            ],
            [ {text: 'Nome do Comprador\n'+ dados.comprador, fontSize: 10, alignment: 'left', },
              {text: 'Numero da NFE\n'+ dados.numeronfe, fontSize: 10, alignment: 'left', }, 
              {text: 'Data da Emissão\n '+ moment(dados.dataemissao).format('DD/MM/YYYY'), bold: true,  fillColor: '#C0C0C0', fontSize: 10, alignment: 'center', },
            ],
          ]
        }
      },
      {
        style: 'tableExample',
        color: '#444',
        table: {
          widths: [250, 58, 65, 20, 95],
          headerRows: 3,
          heights:[2, 22, 22],
          body: [
            [ {text: 'Transportadora', fontSize: 8, border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]}, 
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '',border: [false, false, false, false]},
            ],
            [ {text: 'RAZAO SOCIAL / NOME\n'+ dados.transportadora, fontSize: 9, alignment: 'left', },
              {text: 'Telefone\n'+ dados.ttelefone, fontSize: 9, alignment: 'left',},
              {text: 'Placa Veiculo\n'+ dados.placa, fontSize: 9, alignment: 'left',},
              {text: 'UF\n'+ dados.vuf, fontSize: 9, alignment: 'left',},
              {text: 'CNPJ / CPF\n'+(dados.tcpf === 'null' ? (
                ''
              ) : (
                dados.tcpf.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
              )), fontSize: 9, alignment: 'left', },
            ],
            [ {text: 'Endereço\n'+ dados.tendereco, fontSize: 9, alignment: 'left', },
              {text: 'Municipio\n'+ dados.tcidade, colSpan: 2, fontSize: 9, alignment: 'left',},
              {},
              {text: 'UF\n'+ dados.tuf, fontSize: 9, alignment: 'left',},
              {text: 'Inscrição Estadual\n'+ dados.trg, fontSize: 9, alignment: 'left', },
            ],
          ]
        }
      },
      {
        style: 'tableExample',
        table: {
          widths: [280, 200, 25],
          headerRows: 2,
          heights:[2, 18],
          body: [
            [ 
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '',border: [false, false, false, false]},
            ],
            [ {text: 'Forma de Pagamento: A Prazo', margin: [2, 3, 0, 0], fontSize: 10, fillColor: '#C0C0C0', border: [true, true, false, true]},
              {text: 'Vencimento: '+ moment(dados.vencimento).format('DD/MM/YYYY') , bold: true, fillColor: '#C0C0C0', border: [false, true, false, true]}, 
              {text: 'status\n'+(dados.status ? 'Aberto' : 'Fechado'), fontSize: 6, fillColor: '#C0C0C0', border: [false, true, true, true]},
            ],
          ]
        }
      },
        
      {
        table:{
          widths: [ 218, 80, 30, 80, 80],
          heights:[2, 0, 200],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},

            ],
            [
              {text: 'Produtos', margin: [5, 0, 0, 0],  alignment: 'left', fontSize: 10},
              {text: 'Quant.', alignment: 'right',  fontSize: 10},
              {text: 'Unid', alignment: 'center', fontSize: 10},
              {text: 'Pço Unit', alignment: 'right',  fontSize: 10},
              {text: 'Total R$', alignment: 'right', fontSize: 10},
            ],
            [
              { 
                style: 'tableExample',
                table: {
                  body: [
                    ...produtos
                  ]
                }
              },
              { 
                margin: [26, 0, 0, 0],
                table: {
                  body: [      
                    ...quantidade
                  ]
                }
              },
              {
                margin: [5, 0, 0, 0],
                table: {
                  body: [      
                    ...unidade
                  ]
                }
              },
              {
                margin: [45, 0, 0, 0],
                table: {
                  body: [      
                    ...preco
                  ]
                }
              },
              { margin: [26, 0, 0, 0],
                table: {
                  body: [      
                    ...total
                  ]
                }
              },
            ],
          ]
          
        },
        // layout: 'lightHorizontalLines'
      },
      {
        style: 'tableExample',
        table: {
          widths: [80, 80, 80, 80, 80, 80],
          // headerRows: 3,
          body: [
            [ 
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '',border: [false, false, false, false]},
              {text: '',border: [false, false, false, false]},
              {text: '',border: [false, false, false, false]},
              {text: '',border: [false, false, false, false]},
            ],
            [
              {text: 'FRETE - {'+dados.tipofrete+'}\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.frete), 
                alignment: 'right',  fontSize: 8,  border: [true, true, true, true]},
              {text: 'ICMS FRETE - {'+dados.tipoicmsfrete+'}\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.icms), 
                alignment: 'right',  fontSize: 8,  border: [true, true, true, true]},
              {text: 'ICMS MERCADORIA\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.icmsmercaodira),
                alignment: 'right', fontSize: 8,  border: [true, true, true, true]},
              {text: 'BALANÇA - {'+dados.tipobalanca+'}\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.balanca), 
                alignment: 'right', fontSize: 8,  border: [true, true, true, true]},
              {text: 'SEGURO - {'+dados.tiposeguro+'}\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.seguro),
                alignment: 'right', fontSize: 8,  border: [true, true, true, true]},
              {text: 'VALOR PRODUTOS\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.valoritens),
                alignment: 'right',  fontSize: 9,  border: [true, true, true, true]},
            ],
            [
              {text: 'FUNRURAL - {'+dados.tipofunrural+'}\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.funrural), 
                alignment: 'right',  fontSize: 8,  border: [true, true, true, true]},
              {text: 'DESCONTO FRUTAS\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.descontofrutas), 
                alignment: 'right',  fontSize: 8,  border: [true, true, true, true]},
              {text: 'DESCONTO\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.desconto), 
                alignment: 'right',   fontSize: 8,  border: [true, true, true, true]},
              {text: 'ACRÉSCIMOS\n0,00', alignment: 'right',  fontSize: 8, border: [true, true, true, true]},
              {text: 'OUTROS\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.outros),
                alignment: 'right',   fontSize: 8,  border: [true, true, true, true]},
              {text: 'TOTAL ROMANEIO\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dados.valortotal), 
                alignment: 'right',   fontSize: 9, fillColor: '#C0C0C0', border: [true, true, true, true]},
            ],
          ]
        }
      },
      {
        style: 'tableExample',
        table: {
          widths: [280, 1, 225],
          heights:[2, 130],
          body: [
            [ 
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [ 
              {text: dados.obs, fontSize: 10, border: [true, true, true, true]},
              {text: '', border: [false, false, false, false]},
              {text: 'Banco: '+dados.nomebanco+'\nAgencia: '+dados.agencia+'       Conta: '+dados.numeroconta+'\nTitular: '+dados.titular, fontSize: 10},
            ],
          ]
        }
      }
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default PDFRomaneio;

