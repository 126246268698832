/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { LayoutBaseDeDashboard } from '../../../shared/layouts';
import { useEffect, useState } from 'react';
import GraficoArea from '../../../shared/forms/GraficoArea';
import { getTemaSistema } from '../../../shared/contexts';
import OutlinedCard2RH from './OutlinedCard2RH';
import OutlinedCard2RHExperiencia from './OutlinedCard2RHExperiencia';
import GraficoLinha from '../../../shared/forms/GraficoLinha';
import { DashboardRHService } from '../../../shared/services/api/rh/dashboardRH/DashboardRHService';

export const DashboardRH = () => {
  const teme = getTemaSistema();
  const [tema, setTema] = useState<'dark' | 'light'>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (teme === 'dark') {
      setTema('dark');
    } else {
      setTema('light');
    }
  }, [teme]);
  
  const [Server, setServer] = useState(
    {
      nivel: 1,

      ativos: 0, 
      ferias: 0,
      auxilio: 0,
      experiencia: 0,
      experienciavencer: 0,
        
      admissoes:[], 
      demissoes:[],

      presencas:[], 
      faltas:[], 
      atestados:[], 
    
      cafe:[], 
      almoco:[], 
      janta:[],

      horaextra:[], 
      bomba:[], 
      pistola:[],
      
    }
  );

  useEffect(() => {
    setLoading(true);
    DashboardRHService.GetDashboardRHService()
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setServer(result);
          setLoading(false);
        }
      });
  },[]); 


  type Extrair = {
    titulo: string;
    valor: number;
  };

  function extrairNomes(titulo: Extrair[] | undefined): string[] {
    return titulo ? titulo.map(titulo => titulo.titulo) : [];
  }
  
  function extrairValores(titulo: Extrair[] | undefined): number[] {
    return titulo ? titulo.map(titulo => titulo.valor) : [];
  }

  type ExtrairValor = {
    valor: number;
  };
  function extrairValores2(titulo: ExtrairValor[]): number[] {
    return titulo ? titulo.map(titulo => titulo.valor) : [];
  }
  
  //Admissões e Demissões
  const seriesDataAdmissoesDemissoes = [
    {
      name: 'Admissões',
      data: extrairValores(Server.admissoes) ?? [],
    },
    {
      name: 'Demissões',
      data: extrairValores(Server.demissoes) ?? [],
    }
  ];

  const optionsDataAdmissoesDemissoes = {
    title: {
      text: 'Admissões e Demissões',
    },
    colors:['#00E396', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.admissoes) 
    }
  };

  //Ponto 
  const seriesDataPonto = [
    {
      name: 'Presença',
      data: extrairValores2(Server.presencas) ?? [],
    },
    {
      name: 'Falta',
      data: extrairValores2(Server.faltas) ?? [],
    },
    {
      name: 'Atestado',
      data: extrairValores2(Server.atestados) ?? [],
    }
  ];

  const optionsDataPonto = {
    title: {
      text: 'Ponto',
    },
    colors:['#00E396', '#FF4560', '#14AEE0'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema, 
    },
  };

  //Alimentação Gráfico Área
  const seriesDataAlimentacao = [
    {
      name: 'Café',
      data: extrairValores2(Server.cafe) ?? [],
    },
    {
      name: 'Almoço',
      data: extrairValores2(Server.almoco) ?? [],
    },
    {
      name: 'Janta',
      data: extrairValores2(Server.janta) ?? [],
    },
  ];

  const optionsDataAlimentacao = {
    title: {
      text: 'Alimentação',
    },
    colors:['#00E396', '#14AEE0', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
  };

  //Extra Gráfico Área
  const seriesDataExtra = [
    {
      name: 'Hora Extra',
      data: extrairValores2(Server.horaextra) ?? [],
    },
    {
      name: 'Bomba',
      data: extrairValores2(Server.bomba) ?? [],
    },
    {
      name: 'Pistola',
      data: extrairValores2(Server.pistola) ?? [],
    },
  ];

  const optionsDataExtra = {
    title: {
      text: 'Extra',
    },
    colors:['#00E396', '#14AEE0', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
  };

  return (
    <LayoutBaseDeDashboard>
      <Box display="flex" component={Paper} variant="outlined" >
        <Grid container item direction="row" spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
            <Grid container item direction="row" >
              {(Server.nivel === 2) && (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={6}>
                    <OutlinedCard2RH
                      nome="Ativos"
                      valor={Server.ativos}
                      cor="#82D2ED"
                      img='/img/ativo.png'
                      loading={loading} />
                  </Grid><Grid item xs={12} sm={12} md={6} lg={3} xl={6}>
                    <OutlinedCard2RH
                      nome="De Férias"
                      valor={Server.ferias}
                      cor="#DEED82"
                      img='/img/ferias.png'
                      loading={loading} />
                  </Grid><Grid item xs={12} sm={12} md={6} lg={3} xl={6}>
                    <OutlinedCard2RH
                      nome="Com Auxílio"
                      valor={Server.auxilio}
                      cor="#82D2ED"
                      img='/img/auxilio.png'
                      loading={loading} />
                  </Grid><Grid item xs={12} sm={12} md={6} lg={3} xl={6}>
                    <OutlinedCard2RHExperiencia
                      nome="Em Experiência"
                      valor={Server.experiencia}
                      nome2='A vencer em 10 dias'
                      valor2={Server.experienciavencer}
                      cor="#DEED82"
                      img='/img/experiencia.png'
                      loading={loading} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
            <Grid container item direction="row" >
              {(Server.nivel === 2) && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper sx={{ margin: 0.5 }}>
                    {loading ? <Skeleton variant="rectangular" height={260}/> : 
                      <GraficoArea
                        height={265}
                        series={seriesDataAdmissoesDemissoes}
                        options={optionsDataAdmissoesDemissoes} 
                      />
                    }
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" component={Paper} variant="outlined">
        <Grid container item direction="row" >
          {(Server.nivel === 1) && (
            <Grid container style={{ height: '95vh' }}>
              <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography sx={{ fontSize: 150, color: '#757575', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} gutterBottom component="div">
                  RH
                </Typography>
              </Grid>
            </Grid>
          )}

          {(Server.nivel === 2) && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper sx={{ margin: 0.5 }}>
                  {loading ? <Skeleton variant="rectangular" height={260}/> : 
                    <GraficoLinha
                      series={seriesDataPonto}
                      options={optionsDataPonto} 
                    />
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Paper sx={{ margin: 0.5 }}>
                  {loading ? <Skeleton variant="rectangular" height={260}/> : 
                    <GraficoArea
                      series={seriesDataAlimentacao}
                      options={optionsDataAlimentacao} 
                    />
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Paper sx={{ margin: 0.5 }}>
                  {loading ? <Skeleton variant="rectangular" height={260}/> : 
                    <GraficoArea
                      series={seriesDataExtra}
                      options={optionsDataExtra} 
                    />
                  }
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </LayoutBaseDeDashboard>
  );
};