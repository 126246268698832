/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box,  Button,  Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm, IVFormErrors, VSwitch, AutoCompleteParceiro, VTextFieldData, VFieldMoney, AutoCompleteProdutor, BasicSelect, AutoCompleteFazenda, AutoCompleteCultivo, VSwitch01, AutoCompleteContaBancaria  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { ChequeEmitidosService } from '../../../shared/services/api/Financeiro/ChequeEmitidos';


interface IFormData {
  datadeposito: Date;
  emissao: Date;
  idconta: number;
  credor: string;
  valor: number;
  contapagas: boolean;
  idparceiro?: string | null;
  numero: string;
  historico: string;
  status: number,
  idoperacao: string;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'idoperacao'>> = yup.object().shape({
  datadeposito: yup.date().required(),
  emissao: yup.date().required(),
  idconta: yup.number().required().moreThan(0),
  credor: yup.string().optional().default('').when('idparceiro', {is: undefined, then: () => yup.string().required()}),
  valor: yup.number().required().moreThan(0),
  contapagas: yup.boolean().optional().default(false),
  idparceiro:  yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  numero:  yup.string().required(),
  historico: yup.string().optional().default(''),
  status: yup.number().optional().default(1)
});


export const DetalheDeChequeEmitidos: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [status, setStatus] = useState(false);
  const [contapagas, setContapagas] = useState(false);
  const [idoperacao, setIdOperacao] = useState('');


  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      ChequeEmitidosService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            navigate('/chequeemitidos');
          } else {
            setDescricao('Lançamento Cheque');
            formRef.current?.setData(result);
            setContapagas(result.contapagas);
            setIdOperacao(result.idoperacao);
            if(result.status == 1){
              setStatus(false);
            }else{
              setStatus(true); 
            }
          }
        });
    } else {
      formRef.current?.setData({
        datadeposito: new Date(),
        emissao: new Date(),
        credor: '',
        valor: 0,
        contapagas: true,
        numero: '',
        historico: '',
        status: 1,
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        
        if (id === 'novo') {
          ChequeEmitidosService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/chequeemitidos');
                } else {
                  navigate(`/chequeemitidos/detalhe/${result}`);
                }
              }
            });
        } else {
          ChequeEmitidosService
            .updateById(id, dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/chequeemitidos');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ChequeEmitidosService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/chequeemitidos');
          }
        });
    }
  };

  const handleVoltarBaixa = () => {
    if (confirm('Realmente deseja Voltar estes Lançamentos?')) {
      ChequeEmitidosService.VoltarBaixa(idoperacao)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro Voltados com sucesso!');
            navigate('/chequeemitidos');
          }
        });
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Lançamento Cheque Emitidos' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/chequeemitidos')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/chequeemitidos/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Dados</Typography>
            </Grid>

            <Grid container item direction="row"  spacing={1}>  
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
                <Grid container item direction="row" spacing={2} >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
                    <AutoCompleteParceiro
                      isExternalLoading={status || contapagas ? true : isLoading}
                    />  
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='credor'
                      label='Credor'           
                      disabled={status ? true : isLoading}
                    />     
                  </Grid>  
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <AutoCompleteContaBancaria
                      name='idconta'
                      label='Conta Bancaria'
                      isExternalLoading={status ? true : isLoading}
                    />    
                  </Grid>     
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='emissao'
                      label='Emissão'
                      disabled={status ? true : isLoading}
                    />         
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='datadeposito'  
                      label='Data p/ Deposito'
                      disabled={status ? true : isLoading}
                    />
                  </Grid>      
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='numero'
                      label='Numero Cheque'           
                      disabled={status ? true : isLoading}
                    />     
                  </Grid>  
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='valor'
                      label='Valor Cheque'
                      disabled={status || contapagas ? true : isLoading}
                    />
                  </Grid>  
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='historico'
                      disabled={status ? true : isLoading}
                      multiline
                      rows={3}
                      label='Histório'
                    />
                  </Grid>  
                  <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                    {(id != 'novo') && (
                      <Button  disabled={status || contapagas ? false : isLoading} variant='contained' color='error' onClick={handleVoltarBaixa}>Voltar Baixa</Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>  
            </Grid>              
          </Grid>  
        </Box>
      </VForm> 
    </LayoutBaseDePagina>
  );
};