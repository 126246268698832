import { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { ValeService } from '../../../shared/services/api/rh/vales/ValeService';
import { useNavigate } from 'react-router-dom';
import PDFReciboVale from './ImpressaoVale';

interface IFormData {
  idvale: string;
  idfun: string;
  tipodesconto: string;
  valor: string;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
  idoperacao: string;
  totalvale: number;
}

interface IFormDataParcelas {
  idfun: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string; 
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
  totalvale: number;
}

export interface ConfirmationDialogRawProps {
  value: string;
  dados: IFormDataParcelas;
  open: boolean;
  onClose: (value?: string) => void;
}

export const DialogVale: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const navigate = useNavigate();

  const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro' ];

  const [lista, setLista] = useState<IFormData[]>([]);


  const SetPeriodo = (mesano: string, inc: number) => {
    const mes = mesano.slice(0, -5);
    const ano = mesano.slice(-4);
    
    const indice = meses.indexOf(mes);
    const totalMeses = 12;
  
    if (indice + inc >= totalMeses) {
      const newAno = Number(ano) + 1;
      const newMes = meses[indice + inc - totalMeses];
      return `${newMes}/${newAno}`;
    } else {
      const newMes = meses[indice + inc];
      return `${newMes}/${ano}`;
    }
  };

  useEffect(() => {
    if (props.dados && typeof props.dados === 'object') {
      setLista([]);
      const guuid = uuidv4();
      const valorparcela =  Number(props.dados.valor) / Number(props.dados?.parcela);
      if (Number(props.dados?.parcela) > 1) {
        for (let i = 0; i < Number(props.dados?.parcela); i++) {
          const  ops = (
            { idvale: uuidv4(), 
              idfun: props.dados.idfun,
              tipodesconto: props.dados.tipodesconto,
              valor: valorparcela.toLocaleString('pt-BR', { minimumFractionDigits: 4}),
              totalvale: props.dados.valor,
              realizacao: props.dados.realizacao,
              historico: props.dados.historico,
              dataemissao: props.dados.dataemissao,
              periodo: SetPeriodo(props.dados.periodo, i),  
              financeiro: props.dados.financeiro,
              parcela: `${i+1}/${props.dados.parcela}`, 
              idoperacao: guuid,
            });
          setLista((oldLista) => [...oldLista, ops]);       
        }
      }
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    const updatedList = lista.map((listItem) => {
      return {
        idvale: listItem.idvale, 
        idfun: listItem.idfun,
        tipodesconto: listItem.tipodesconto,
        valor: Number(formatValueNumber(listItem.valor)),
        realizacao: listItem.realizacao,
        historico: listItem.historico,
        dataemissao: listItem.dataemissao,
        periodo: listItem.periodo,
        financeiro: listItem.financeiro,
        parcela: listItem.parcela, 
        idoperacao: listItem.idoperacao,
        totalvale: listItem.totalvale,
      };           
    });
    const soma = updatedList.reduce((acc, listItem) => acc + listItem.valor, 0);
    if (soma.toFixed(2) === props.dados.valor.toString()){    
      ValeService
        .createparcelas(updatedList)
        .then((result) => {
          if (result instanceof Error){
            alert(result.message);
          }else{
            handlePrint(result); 
            onClose(value);
            navigate('/vales');
          }
        });   
    }else{
      alert('Valor Total não Confere');
    }
  };

  const handlePrint = (id: string) => {
    ValeService.getByIdPrint(id)
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          PDFReciboVale(result);
        }
      });
  };

  const handleInputChange = useCallback((id: string, valor: string) => {
    const updatedList = lista.map((listItem) => {
      if (listItem.idvale === id) {
         
        return {
          ...listItem,
          valor: valor, 
        };
      } else {

        return listItem;
      }
    });
    setLista(updatedList);
  }, [lista]);

  const formatInputValue = (inputValue: string) => {
    inputValue = inputValue.replace(/\D/g, '');
    inputValue = inputValue.replace(/(\d)(\d{4})$/, '$1,$2');
    inputValue = inputValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return inputValue;
  };

  const formatValueNumber = (Value: string) => {
    if (Value === undefined || Value === null) {
      return ''; 
    }
    Value = Value.replace(/\D/g, '');
    Value = Value.replace(/(\d)(\d{4})$/, '$1.$2');
    Value = Value.replace(/(?=(\d{3})+(\D))\B/g, '');
    return Value;
  };
 
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 520 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>Parcelamento de Vale</DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {lista.map((listItem) => (
            <ListItem disableGutters key={listItem.idvale}>
              <TextField
                value={listItem.periodo}
                label="Período"
              />
              <TextField
                value={listItem.valor}
                onChange={(e) => {
                  const inputValue = formatInputValue(e.target.value);
                  e.target.value = inputValue;
                  handleInputChange(listItem.idvale, inputValue);
                }}
                label="Valor"
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancelar
        </Button>
        <Button onClick={handleOk}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 