
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import { IVFormErrors, VForm, VTextField, VTextFieldData, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { TransferenciaService } from '../../../shared/services/api/estoque/transferencia/TransferenciaService';
import { useNavigate } from 'react-router-dom';

type NullableDate = Date | null | undefined;

interface IFormData2 {
  iddestino: string;
  idorigem: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  status: number;
}

interface IFormData {
  iddestino: string;
  idorigem: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  portador: string;
  status: number;
}

interface IitensTransferenciaDialog{
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: string | null;
  qtdentrada: number;
  preco: number;
  un: string;
  conversor: number;
  total: number;

  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  reqvencimento: boolean; 
}

interface IItensids{
  iditens: string;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  dados: IFormData2;
  dadosItens: IitensTransferenciaDialog[];
  onClose: (value?: string) => void;
  rowsids: IItensids[];
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'status' | 'iddestino' | 'idorigem' | 'datalan' | 'datarecebimento'>> = yup.object().shape({
  dataenvio: yup.date().required('O campo é obrigatório')
    .test('valid-date', 'Selecione uma data válida', function (value) {
      const currentDate = new Date();
      const tenDaysAgo = new Date();
      tenDaysAgo.setDate(currentDate.getDate() - 10);
      if (!value || value > currentDate) return false;
      if (value < tenDaysAgo) return false;

      return true;
    }),
  portador: yup.string().required(),
});

export const DialogTransferenciaFinalizar: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [, setValue] = useState(valueProp);
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((DadosValidados) => {
        setIsLoading(true);
        const dadosValidados = { ...DadosValidados, ...props.dados} as IFormData;
        dadosValidados.status = 2;
        const itens = props.dadosItens;
        if (props.id === 'novo') {
          TransferenciaService
            .create(dadosValidados, itens)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } 
              else {
                alert('Transferência realizada com sucesso!');
                navigate('/transferenciaestoque');
              }
            });
        } else {
          TransferenciaService
            .updateById(props.id, dadosValidados, itens, props.rowsids)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } 
              else {
                alert('Transferência atualizada com sucesso!');
                navigate('/transferenciaestoque');
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleSaveSemTransferir = () => {

    const dadosValidados = { dataenvio: null, portador: '', ...props.dados} as IFormData;
    const itens = props.dadosItens;
    if (props.id === 'novo') {
      TransferenciaService
        .create(dadosValidados, itens)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          } 
          else {
            alert('Transferência salva com sucesso!');
            navigate('/transferenciaestoque');
          }
        });
    } else {
      TransferenciaService
        .updateById(props.id, dadosValidados, itens, props.rowsids)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          } 
          else {
            alert('Transferência atualizada com sucesso!');
            navigate('/transferenciaestoque');
          }
        });
    }
      
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Finalizar Transferência</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} > 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>          
                    <VTextFieldData
                      fullWidth
                      name='dataenvio'
                      label='Data de Envio'
                      disabled={isLoading}
                    />
                  </Grid> 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>          
                    <VTextField
                      fullWidth
                      name='portador'
                      disabled={isLoading}
                      label='Portador'
                      InputProps={{
                        style: { minWidth: '250px' }, 
                      }}
                    />
                  </Grid>                                               
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth:'30%'  }} variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={handleSaveSemTransferir}>Salvar</Button>
        <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={save}>Transferir agora</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 