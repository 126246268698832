/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box,  FormControlLabel,  Grid, LinearProgress, Paper, Switch, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm, IVFormErrors, VSwitch, AutoCompleteParceiro, VTextFieldData, VFieldMoney, AutoCompleteProdutor, BasicSelect, AutoCompleteFazenda, AutoCompleteCultivo, VSwitch01, AutoCompletePlanoDeContas  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { ContasReceberService } from '../../../shared/services/api/Financeiro/ContasReceber';

interface IFormData {
  idparceiro: string;
  idcultivo?: string | null;
  idplanoconta: string;
  idfazenda: string;
  idprodutor: number;
  dataoperacao: Date;
  emissao: Date;
  vencimento: Date;
  historico: string;
  historicolcdpr: string;
  lcdpr: boolean;
  numerodoc: string;
  numeronfe: string;
  valorcompra: number;
  valorparcela: number;
  acrescimos: number;
  desconto: number;
  recebido: number;
  receber: number;
  valornfe: number;
  nfeparcela: number;
  nfereceber: number;
  nferecebido: number;
  tipo: number;
  tipodoc: number;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'dataoperacao'>> = yup.object().shape({
  idparceiro: yup.string().required(),
  idcultivo:  yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idplanoconta: yup.string().required(),
  idfazenda:  yup.string().required(),
  idprodutor: yup.number().optional().default(0).when('lcdpr', {is: true, then: () => yup.string().optional().default('').required('Campo obrigatório') }),
  emissao: yup.date().required(),
  vencimento: yup.date().required('O campo é obrigatório')
    .when('$isEditMode', {
      is: false, 
      then: () => yup.date().test('valid-date', 'Não se Lança conta já Vencida', function (value) {
        if (!value) {
          return this.createError({ message: 'Data inválida' });
        }
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 5);
        return value >= currentDate;
      }),
    }),
  historico: yup.string().optional().default(''),
  lcdpr: yup.boolean().required(),
  historicolcdpr: yup.string().optional().default('').when('lcdpr', {is: true, then: () => yup.string().optional().default('').required('Campo obrigatório') }),
  numerodoc: yup.string().required(),
  numeronfe: yup.string().optional().default('').when('lcdpr', {is: true, then: () => yup.string().optional().default('').required('Campo obrigatório') }),
  valorcompra: yup.number().required().moreThan(0),
  valorparcela: yup.number().required().test('valorparcela', 'o valor deve ser menor ou igual ao valor total e diferente de zero', function (value, context) {
    const valorcompra = context.parent.valorcompra || 0; 
    return value <= valorcompra && value !== 0;
  }),
  acrescimos: yup.number().required(),
  desconto: yup.number().required(),
  recebido: yup.number().required(),
  receber: yup.number().required(),
  valornfe: yup.number().required().when('lcdpr', {is: true, then: () => yup.number().required().moreThan(0)}),
  nfeparcela: yup.number().required().when('lcdpr', {is: true, then: () => yup.number().optional().required().moreThan(0) }).test('nfeparcela', 'o valor deve ser menor ou igual ao valor total e diferente de zero', function (value, context) {
    const valornfe = context.parent.valornfe || 0; 
    return value <= valornfe ;
  }),
  nfereceber: yup.number().required(),
  nferecebido: yup.number().required(),
  tipo: yup.number().optional().default(2),
  tipodoc: yup.number().optional().default(0).when('lcdpr', {is: true, then: () => yup.number().optional().required().moreThan(0) }),
});


export const DetalheDeReceber: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [statuss, setStatuss] = useState(false);
  const [statusRom, setStatusRom] = useState(false);
  const [statusRecebidas, setStatusRecebidas] = useState(false);
  const [statuslcdpr, setStatuslcdpr] = useState(false);
  const [idparceiro, setIdParceiro] = useState('');
  const [recebidas, setRecebidas] = useState(0);


  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      ContasReceberService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            navigate('/contasreceber');
          } else {
            setDescricao('Lançamento Contas Receber');
            formRef.current?.setData(result);
            setIdParceiro(result.idparceiro);
            setStatuss(result.status);  
            setRecebidas(result.recebidas);
            if(result.tipo == 1){
              setStatusRom(true);
            }else{
              setStatusRom(false); 
            }
            if(result.recebidas > 0){
              setStatusRecebidas(true);
            }else{
              setStatusRecebidas(false); 
            }
          }
        });
    } else {
      formRef.current?.setData({
        emissao: new Date(),
        vencimento: new Date(),
        historico: '',
        historicolcdpr: '',
        lcdpr: false,
        numerodoc: '',
        numeronfe: '',
        valorcompra: 0,
        valorparcela: 0,
        juros: 0,
        acrescimos: 0,
        desconto: 0,
        recebido: 0,
        receber: 0,
        valornfe: 0,
        nfeparcela: 0,
        nfereceber: 0,
        nferecebido: 0,
      });
    }
  }, [id]);
    
  const handleSave = (dados: IFormData) => {
    const validationContext = { isEditMode: id === 'novo' ? false : true};
    formValidationSchema.
      validate(dados, {context: validationContext , abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'novo') {
          ContasReceberService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/contasreceber');
                } else {
                  navigate(`/contasreceber/detalhe/${result}`);
                }
              }
            });
        } else {
          ContasReceberService
            .updateById(id, {recebidas: recebidas, status: statuss, ...dadosValidados})
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/contasreceber');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ContasReceberService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/contasreceber');
          }
        });
    }
  };

  const handlecalcularParcela = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const acrescimo = data?.acrescimos;
      const desconto= data?.desconto;
      const recebido= data?.recebido;  
      const tparcela = Number(valor);
      const vreceber = tparcela +  Number(acrescimo) - Number(desconto) - Number(recebido);
      formRef.current?.setFieldValue('receber', vreceber);
    }
  };

  const handlecalcularAcescimo = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const parcela = data?.valorparcela;
      const desconto= data?.desconto;
      const recebido= data?.recebido;  
      const acrescimo = Number(valor);
      const vreceber = acrescimo +  Number(parcela) - Number(desconto) - Number(recebido);
      formRef.current?.setFieldValue('receber', vreceber);
    }
  };

  const handlecalcularDesconto = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const parcela = data?.valorparcela;
      const acrescimo = data?.acrescimos;
      const recebido = data?.recebido;  
      const desconto = Number(valor);
      const vreceber =  Number(parcela) + Number(acrescimo) - desconto - Number(recebido);
      formRef.current?.setFieldValue('receber', vreceber);
    }
  };

  const handlecalcularParcelaNfe = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const nferecebido = data?.nferecebido;
      const vreceber =  Number(valor) - Number(nferecebido);
      formRef.current?.setFieldValue('nfereceber', vreceber);
    }
  };

  const handleSwitch = (checkeds: boolean) => {
    setStatuslcdpr(checkeds);
    if(!checkeds){
      formRef.current?.setFieldValue('historicolcdpr', ''); 
      formRef.current?.setFieldValue('numeronfe', ''); 
      formRef.current?.setFieldValue('tipodoc', 0); 
      formRef.current?.setFieldValue('valornfe', 0); 
      formRef.current?.setFieldValue('nfeparcela', 0); 
      formRef.current?.setFieldValue('nfereceber', 0); 
      formRef.current?.setFieldValue('nferecebido', 0); 
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Lançamento Contas Receber' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/contasreceber')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/contasreceber/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
          disableBotaoApagar={statuss}
          disableBotaoSalvar={statuss}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h6'>Dados</Typography>
            </Grid>

            <Grid container item direction="row"  spacing={1}>  
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
                <Grid container item direction="row" spacing={1.5} >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <AutoCompleteParceiro
                      isExternalLoading={statuss || statusRom || statusRecebidas ? true : isLoading}
                    />  
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} lg={4} xl={4}>
                    <AutoCompletePlanoDeContas 
                      isExternalLoading={statuss || statusRom || statusRecebidas ? true : isLoading}
                    />
                  </Grid>  
                  <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='dataoperacao'  
                      label='Data Operação'
                      disabled={true}
                    />
                  </Grid>        
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AutoCompleteFazenda
                      isExternalLoading={statuss || statusRom || statusRecebidas ? true : isLoading}
                    />    
                  </Grid>     
                  <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                    <AutoCompleteCultivo
                      isExternalLoading={statuss || statusRom ? true : isLoading}
                    />    
                  </Grid>         
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='emissao'
                      label='Emissão'
                      disabled={statuss || statusRom || statusRecebidas ? true : isLoading}
                    />         
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      size='small'
                      name='vencimento'
                      label='Vencimento'
                      disabled={statuss || statusRom ? true : isLoading}
                    />         
                  </Grid>  
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='numerodoc'
                      label='Numero'           
                      disabled={statuss || statusRom || statusRecebidas  ? true : isLoading}
                    />     
                  </Grid>  
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='valorcompra'
                      label='Valor Total'
                      disabled={statuss || statusRom || statusRecebidas  ? true : isLoading}
                    />
                  </Grid>    
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth 
                      size='small' 
                      name='valorparcela'
                      label='Valor Parcela'
                      calcular={handlecalcularParcela}
                      disabled={statuss || statusRom || statusRecebidas  ? true : isLoading}
                    />
                  </Grid>     
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='acrescimos'
                      label='Acréscimo / Juros'
                      calcular={handlecalcularAcescimo}
                      disabled={statuss  ? true : isLoading}
                    />
                  </Grid>  
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='desconto'
                      label='Desconto'
                      calcular={handlecalcularDesconto}
                      disabled={statuss  ? true : isLoading}
                    />
                  </Grid>  
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='recebido'
                      label='Recebido'
                      disabled={true}
                    />
                  </Grid>  
                  <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='receber'
                      label='Receber'
                      disabled={true}
                    />
                  </Grid>     
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <AutoCompleteProdutor
                      isExternalLoading={statuss || statusRom || statusRecebidas || !statuslcdpr ? true : isLoading}
                    />                 
                  </Grid>  
                  <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='numeronfe'
                      label='Numero NFE'           
                      disabled={statuss || statusRom || statusRecebidas || !statuslcdpr ? true : isLoading}
                    />     
                  </Grid>     
                  <Grid item xs={12} sm={12} md={3} lg={4} xl={2}>
                    <BasicSelect
                      name='tipodoc' 
                      label='Tipo Documento'
                      data={[
                        { nameBD: '1', descricao: 'Nota Fiscal' },
                        { nameBD: '2', descricao: 'Fatura' },
                        { nameBD: '3', descricao: 'Recibo' },
                        { nameBD: '4', descricao: 'Contrato' },
                        { nameBD: '5', descricao: 'Folha de Pagamento' },
                        { nameBD: '6', descricao: 'Outros' },
                      ]}
                      disabled={statuss || statusRom || statusRecebidas || !statuslcdpr ? true : isLoading}
                    />
                  </Grid>           
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={1}>  
                    <VFieldMoney   
                      fullWidth
                      size='small'  
                      name='valornfe'
                      label='Valor NFE'
                      disabled={statuss || statusRom || statusRecebidas || !statuslcdpr ? true : isLoading}
                    />
                  </Grid>     
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={1}>  
                    <VFieldMoney   
                      fullWidth  
                      size='small'
                      name='nfeparcela'
                      label='NFE Parcela'
                      calcular={handlecalcularParcelaNfe}
                      disabled={statuss || statusRom || statusRecebidas || !statuslcdpr ? true : isLoading}
                    />
                  </Grid>     
                  <Grid item xs={6} sm={6} md={3} lg={2} xl={1}>  
                    <VFieldMoney   
                      fullWidth 
                      size='small' 
                      name='nferecebido'
                      label='NFE Recebido'
                      disabled={true}
                    />
                  </Grid>     
                  <Grid item xs={6} sm={6} md={3} lg={4} xl={1}>  
                    <VFieldMoney   
                      fullWidth 
                      size='small' 
                      name='nfereceber'
                      label='NFE Receber'
                      disabled={true}
                    />
                  </Grid>       
                  <Grid item xs={12} sm={12} md={10} lg={10.5} xl={11}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='historicolcdpr'
                      disabled={statuss  || !statuslcdpr ? true : isLoading}
                      label='Histório LCDPR'
                    />
                  </Grid> 
                  <Grid item marginTop={1} xs={12} sm={12} md={2} lg={1.5} xl={1}>  
                    <VSwitch01
                      name='lcdpr'
                      label='LCDPR'
                      onFCheckedChange={handleSwitch}
                      fdisabled={statuss || statusRom || statusRecebidas ? true : isLoading}/>
                  </Grid> 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='historico'
                      disabled={isLoading}
                      multiline
                      rows={2}
                      label='Histório'
                    />
                  </Grid>
                </Grid>
              </Grid>  
            </Grid>              
          </Grid>  
        </Box>
      </VForm> 
    </LayoutBaseDePagina>
  );
};