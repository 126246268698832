/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

export interface ITipoPgto {
    idfolha: string;
    nome: string;
    urlimg: string;
    cpf: string;
    marcado: boolean;
    total: number;
  }
  


function PDFCheques(dados: ITipoPgto[]){
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const numero = require('numero-por-extenso');
  const ano: number = new Date().getFullYear();
  const dia: number = new Date().getDay();
  const data: number = new Date().getDate();
  const meses: string[] = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

  const mesAtualIndex: number = new Date().getMonth();
  const mes: string = meses[mesAtualIndex];
    
  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [5, 1, 0, 0],  // left, top, right, bottom
    pageOrientation: 'portrait',
    content: [
      dados.map((e) => {
 
        return [
          [       
            [
              {
                table: {
                  //  widths: [300, 130, 130],
                  heights: 8,
                  body: [
                    [ 
                      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.total) , 
                        fontSize: 9, 
                        border: [false, false, false, false], 
                        margin: [486, 0, 0, 0],
                      }
                    ],
                    [
                      {   
                        text: 'a importância de: '+numero.porExtenso(e.total, numero.estilo.monetario),
                        margin: [184, 3, 0, 0],
                        fontSize: 9,
                        border: [false, false, false, false]
                      },
                    ],
                    [
                      {   
                        text: '*******************************************************************',
                        margin: [109, 0, 0, 2],
                        fontSize: 10,
                        border: [false, false, false, false],
                        heights: 50,
                      },
                                    
                    ],   
                    [
                      {   
                        text: e.nome,
                        margin: [109, 3, 0, 2],
                        fontSize: 10,
                        border: [false, false, false, false]
                      },
                                    
                    ],
                    [
                      {  
                                                
                        text: 'São Feliz do Coribe, '+dia+'                      '+mes+'          '+ano,
                        heights: 10,
                        fontSize: 9,
                        margin: [348, 0, 0, 15],
                        border: [false, false, false, false],
                                                
                      },
                                    
                    ],
                    [
                      {
                        text: moment(data).format('DD/MM/YYYY'),
                        fontSize: 7,
                        border: [false, false, false, false]
                      },
                    ],
                    [
                      {
                        text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.total) ,
                        fontSize: 7,
                        border: [false, false, false, false]
                      },
                    ],
                    [   
                      {   
                        text: e.nome,
                        margin: [0, 5, 0, 0],
                        fontSize: 5,
                        border: [false, false, false, false]
                      },
                                          
                    ],
                    [
                      {
                        text: '',
                        fontSize: 10,
                        border: [false, false, false, false]
                      },
                    ],
                    [
                      {
                        text: '',
                        fontSize: 10,
                        border: [false, false, false, false]
                      },
                    ],
                    [
                      {
                        text: '',
                        fontSize: 10,
                        border: [false, false, false, false]
                      },
                    ],
                    [
                      {
                        text: '',
                        fontSize: 10,
                        border: [false, false, false, false],
                        margin: [345, 6, 0, 25],
                      },
                    ],
                                     
                  ]
                },
              } 
                                                 
            ]
          ]                           
                
        ];
      }
      ) 
    ]
  };
  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.print();

}
export default PDFCheques;