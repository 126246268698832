import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
export interface IListagem {
  idcaixa: string;
  idconta: number;
  numero: string;
  nome: string;
  dataoperacao: Date;
  historico: string;
  entrada: number;
  saida: number;
  idpareceiro: string;
  idoperacao: string;
  tipo: string;
  datalan: Date;
  status: boolean;
  tipos: number;
}

export interface IResultado{
  saldoanterior: number;
  credito: number;
  debito: number;
  saldo: number;
  valorcheque: number;
  saldoreal: number;
}


async function PDFCaixa(dados: IListagem[], resultado: IResultado, conta: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const lancaixa = dados.map((caixa) => {
    return [
      {text: moment(caixa.datalan).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 7},
      {text: caixa.numero, alignment: 'left',  fontSize: 7},
      {text: caixa.nome, alignment: 'left',  fontSize: 7},
      {text: caixa.historico, alignment: 'left',  fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(caixa.entrada), alignment: 'right',  fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(caixa.saida), alignment: 'right',  fontSize: 7},
      {text: (caixa.status ? 'X' : ''), alignment: 'center',   bold: true, fontSize: 10},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  
  const documentDefinition = {

    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 500],
          body: [
            [ 
              {
                image: imageData,
                width: 50,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Conciliação Bancaria', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {text: 'Conta Bancaria: '+conta, bold: true, fontSize: 12},

      {
        table:{
          widths: [ 80, 80, 80, 80, 80, 80],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},  
            ],
            [
              {text: 'Saldo Anterior \n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(resultado.saldoanterior), alignment: 'right',  fontSize: 10},
              {text: 'Crédito\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(resultado.credito), alignment: 'right',  fontSize: 10},
              {text: 'Débito\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(resultado.debito), alignment: 'right',  fontSize: 10},
              {text: 'Saldo\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(resultado.saldo), alignment: 'right',   fontSize: 10},
              {text: 'Cheque Comp.\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(resultado.valorcheque), alignment: 'right',  fontSize: 10},
              {text: 'Saldo Real\n'+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(resultado.saldoreal), alignment: 'right',  fontSize: 10},
            ],
          ]
          
        },
      },
      {
        table:{
          widths: [ 40, 40, 130, 180, 40, 40, 1],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [
              {text: 'Data', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Numero', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Parceiro', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'Histórico', alignment: 'center', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Crédito', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Débito', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: '', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
            ],
            ...lancaixa,
          ]
          
        },
      },
      
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default PDFCaixa;

