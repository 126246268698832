import { useEffect,  useState } from 'react';
import {  Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useField } from '@unform/core';

interface IAutoCompletePeriodoProps { 
  isExternalLoading?: boolean;
  novoperiodo?: (novoperiodoselect: string | undefined) => void;
}
export const AutoCompletePeriodo: React.FC<IAutoCompletePeriodoProps> = ({ isExternalLoading = false, novoperiodo }) => {
  const { fieldName, registerField, error, defaultValue, clearError } = useField('periodo');

  const [mes, setMes] = useState<string>('');
  const [ano, setAno] = useState<string>('');
  const [periodo, setPeriodo] = useState<string>('');

  const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro' ];
  const anos = [ '2021', '2022', '2023', '2024', '2025', '2026', '2027' ];

  useEffect(() => {
    registerField({
      name: fieldName, 
      getValue: () => periodo,
      setValue: (_, newperiodo) => setPeriodo(newperiodo), 
    });
    setMes(periodo.slice(0, -5));
    setAno(periodo.slice(-4));
  }, [registerField, fieldName, periodo]);
     
  return (
    <Box display={'flex'} >
      <FormControl fullWidth error={!!error} >
        <InputLabel id="demo-simple-select-helper-label">mês</InputLabel>
        <Select
          size='small'
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          disabled={isExternalLoading}
          defaultValue={defaultValue}
          value={mes}
          label='mês'
          onChange={(event) => { setMes(event.target.value); novoperiodo?.(event.target.value+'/'+ano); setPeriodo(event.target.value+'/'+ano); clearError();}}
        >
          {meses.map((mes) => (
            <MenuItem
              key={mes}
              value={mes}
            >
              {mes}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
      <FormControl sx={{  minWidth: 90 }}>
        <InputLabel id='demo-simple-select-helper-label'>Ano</InputLabel>
        <Select
          size='small'
          labelId='demo-simple-select-helper-label'
          id='demo-simple-select-helper'
          label='Ano'
          value={ano}
          onChange={(event) => { setAno(event.target.value);  setMes('janeiro');  clearError();}}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {anos.map((ano) => (
            <MenuItem
              key={ano}
              value={ano}
            >
              {ano}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};