/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Icon,  IconButton,  MenuItem,  Paper, Select, TextField, useTheme } from '@mui/material';
import { Environment } from '../../environment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { AutoCompleteParceiroListagem } from '../../forms';

interface IFerramentasDaListagemProps {
  textoDoSelect?: string;
  mostrarAutoCompliteParceiro?: boolean;
  mostrarInputData?: boolean;
  aoMudarTextoDoSelect?: (novoTextoselect: string) => void;
  aoClicarEmPesquisa?: (datainicial: Dayjs | null, datafinal: Dayjs | null) => void;
  aoMudarTextoDoParceiro?: (novoTextoselect: string) => void;
}
export const FerramentasDaListagemFinanceiroConfirmarBoleto: React.FC<IFerramentasDaListagemProps> = ({
  textoDoSelect = '',
  mostrarAutoCompliteParceiro= false,
  mostrarInputData = true,
  aoMudarTextoDoSelect,
  aoClicarEmPesquisa,   
  aoMudarTextoDoParceiro, 
}) => {
  const theme = useTheme();
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs()); 
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 

  const handlePesquisa = () => {
    aoClicarEmPesquisa?.(dataInicial, dataFinal);
  };

  const handleuuidparceiro = (uuid: string | undefined) => {
    if (uuid != null) {
      aoMudarTextoDoParceiro?.(uuid);
    }  
  };

  return (
    <Box
      gap={1}
      marginX={2}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      // height={theme.spacing(6)}
      component={Paper}
    >
      <Grid container item direction="row" spacing={0.5}>  
        <Grid item xs={12} sm={2} md={3} lg={2} xl={2}>  
          <Select
            fullWidth
            size='small'
            value={textoDoSelect}
            onChange={(event) => aoMudarTextoDoSelect?.(event.target.value)}
          >
            <MenuItem value={'parceiro'}>Parceiro</MenuItem>
            <MenuItem value={'data'}>Vencimento</MenuItem>
          </Select>
        </Grid>  

        {mostrarInputData && (
          <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>  
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format='DD/MM/YYYY'
                value={dataInicial}
                onChange={(newValue) => setDataInicial(newValue)}
                slotProps={{
                  textField: {    
                    size:'small',
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>   
        )}     
        {mostrarInputData && (
          <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>  
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format='DD/MM/YYYY'
                value={dataFinal}
                onChange={(newValue) => setDataFinal(newValue)} 
                slotProps={{
                  textField: {    
                    size:'small',
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>   

        )}  

        {mostrarInputData && (
          <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>  
            <IconButton color='primary'>
              <SearchIcon sx={{ fontSize: 30 }} onClick={() => {handlePesquisa();}} />
            </IconButton>
          </Grid>   
        )}   
            
        {mostrarAutoCompliteParceiro && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>  
            <AutoCompleteParceiroListagem
              uuidparceiro={handleuuidparceiro}
              isExternalLoading={false}
            />  
          </Grid>   
        )} 
      </Grid>    
    </Box>
  );
};