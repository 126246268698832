import { useEffect, useMemo, useState} from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useField } from '@unform/core';
import { CfopService } from '../services/api/cadastros/CfopService';


type TAutoCompleteOption = {
  cfop: number;
  label: string;
}

interface IAutoCompleteCfopProps {
  isExternalLoading?: boolean;
  cfops?: string;
}
export const AutoCompleteCfop: React.FC<IAutoCompleteCfopProps> = ({ isExternalLoading = false, cfops = '' }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField('cfop');
  
  const [selectedId, setSelectedId] = useState<number | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

 
  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setIsLoading(true);
    CfopService.getAlllist(busca, selectedId?.toString())
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          // alert(result.message);  
        } else {
          setOpcoes(result.data.map(cfop => ({ cfop: cfop.cfop, label: cfop.cfop + ' - ' + cfop.natureza })));
        }
      });
  }, [busca, selectedId]);

  useEffect(() => {
    if(cfops !== ''){
      setIsLoading(true);
      CfopService.getAlllist(cfops)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
          // alert(result.message);  
          } else {
            setOpcoes(result.data.map(cfop => ({ cfop: cfop.cfop, label: cfop.cfop + ' - ' + cfop.natureza })));
          }
        });
      setSelectedId(Number(cfops));
    }
  }, [cfops]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.cfop === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);


  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.cfop); setBusca(''); clearError(); }}
      popupIcon={(isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          label="CFOP"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};