import { Api } from '../../axios-config';

interface IDetalhesPonto{
  titulo: string;
  ponto: number;
  falta: number;
  atestado: number;
  horaextra: number;
  bomba: number;
  pistola: number;
  cafe: number;
  almoco: number;
  janta: number;
}

interface IResumoPonto{
  presenca:[], 
  falta:[],
  atestado:[],
    
  cafe:[],
  almoco:[],
  janta:[],
       
  horaextra:[],
  bomba:[],
  pistola:[],

  detalhes: IDetalhesPonto[];
}

const getAll = async (idfun: string, ano = ''): Promise<IResumoPonto | Error> => {
  try {
     
    const urlRelativa = `/pontoanual?idfun=${idfun}&ano=${ano}`;

    const { data } = await Api.get(urlRelativa);


    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};


export const PontoAnualService = {
  getAll,
};