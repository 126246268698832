import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../../shared/hooks';
import { useField } from '@unform/core';
import { MunicipiosService } from '../services/api/cadastros/MunicipiosService';

type TAutoCompleteOption = {
  idmunicipio: string;
  cidade: string;
  uf: string;
};

interface IAutoCompleteMunicipioProps {
  isExternalLoading?: boolean;
}

export const AutoCompleteMunicipio: React.FC<IAutoCompleteMunicipioProps> = ({ isExternalLoading = false }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField('idmunicipio'); 

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');
  const { debounce } = useDebounce();

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      MunicipiosService.getAlllist(busca, selectedId)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setOpcoes(result.data.map((municipio) => municipio));
          }
        });
    });
  }, [busca, selectedId, debounce]);

  const autocompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find((opcao) => opcao.idmunicipio === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);

  return (
    <Autocomplete
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem opções"
      loadingText="Carregando..."
      disablePortal
      value={autocompleteSelectedOption}
      loading={isLoading}
      disabled={isExternalLoading}
      popupIcon={isExternalLoading || isLoading ? <CircularProgress size={28} /> : undefined}
      onInputChange={(_, newValue) => setBusca(newValue)}
      options={opcoes}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.idmunicipio);
        setBusca('');
        clearError();
      }}
      getOptionLabel={(option) => `${option.idmunicipio} - ${option.cidade} - ${option.uf}`}
      renderInput={(params) => (
        <TextField {...params} label="Município" 
          error={!!error}
          size="small"
          helperText={error}
          defaultValue={defaultValue}
        
        />
      )}

      
    />
  );
};
