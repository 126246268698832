/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, ChangeEvent } from 'react';
import { Box, Grid, LinearProgress, Paper} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { AutoCompleteFazenda, AutoCompleteMunicipio, VSwitch, VTextFieldCep, VTextFieldCpfCnpj, cpfcnpjValidator } from '../../../shared/forms';
import { VForm, useVForm, IVFormErrors, VTextField, SelectNumber } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { ParceirosService } from '../../../shared/services/api/cadastros/ParceirosService';
import { CpfService } from '../../../shared/services/api/cadastros/cpf';
import { CnpjService } from '../../../shared/services/api/cadastros/CnpjService';
import { AutoCompleteCfop } from '../../../shared/forms/AutoCompleteCfop';


interface IFormData {
    nome:string,
    cpf:string,
    apelido:string,
    rg:string,
    telefone: string,
    celular: string,
    endereco: string,
    numero: string,
    bairro: string,
    idmunicipio:string,
    cep:string,
    email: string,
    comprador: string,
    obs: string,
    diasvencimento: number,
    cfop: number,
    contribuinte: number,
    presensa: number,
    consumidorfinal:number, 
    emailnfe: string;
    enviaremailnfe: boolean;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'idoperacao' | 'totalparceiros'>> = yup.object().shape({

  nome: yup.string().required().min(3).max(100),
  cpf: yup.string().required().min(11).max(14).test('cpf', 'CPF / CNPJ inválido', cpfcnpjValidator),
  apelido: yup.string().optional().default(''),
  rg: yup.string().optional().default(''),
  telefone: yup.string().optional().default('').max(16),
  celular: yup.string().optional().default('').max(16),
  endereco: yup.string().required().min(3).max(50),
  numero: yup.string().required().min(1).max(10),
  bairro: yup.string().required().min(3).max(60),
  idmunicipio: yup.string().required().min(7).max(7),
  cep: yup.string().required().min(8).max(20),
  email: yup.string().email().default(''),
  comprador: yup.string().optional().default(''),
  obs: yup.string().optional().default(''),
  diasvencimento: yup.number().required().min(0).max(99),
  cfop: yup.number().required().required().min(0).max(9999),
  contribuinte: yup.number().required().required().min(0).max(3),
  presensa: yup.number().required().required().min(0).max(7),
  consumidorfinal: yup.number().required().required().min(0).max(50),
  emailnfe: yup.string().email().default(''),
  enviaremailnfe: yup.boolean().required(),
});

export const DetalheDeParceiros: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  
  const [descricao, setDescricao] = useState<string>('');
 

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      ParceirosService.getById(id)
        
        .then((result) => {
          setIsLoading(false);
         
          if (result instanceof Error) {
            alert(result.message);
            navigate('/parceiros');
          } else {
            setDescricao(result.nome);

            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        nome:'',
        cpf:'',
        apelido:'',
        rg:'',
        telefone:'',
        celular: '',
        endereco:'',
        numero: '',
        bairro: '',
        idmunicipio:'',
        cep:'',
        email: '',
        comprador: '',
        obs: '',
        diasvencimento: 0,
        cfop: '',
        contribuinte: 0,
        presensa: 1,
        consumidorfinal:0,
        emailnfe: '',
        enviaremailnfe: false,
      });
    }  
  }, [id]);

  const handleSave = (dados: IFormData) => { 
    
    formValidationSchema
      .validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        
        setIsLoading(true);
        if (id === 'novo') {
          ParceirosService

            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/parceiros');
                } else {
                  navigate(`/parceiros/detalhes/${result}`);
                }
              }
            });
        } else {
          ParceirosService

            .updateById(id, dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/parceiros');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
  
        errors.inner.forEach(error => {
          if (!error.path) return;
  
          validationErrors[error.path] = error.message;
        });
  
        formRef.current?.setErrors(validationErrors);
      });
  };
  

  const handleCPFCNPJChange = async (event: ChangeEvent<HTMLInputElement>) => {

    const fieldName = 'cpf';
    const enteredValue = event.target.value.replace(/[^\d]/g, ''); // Remove todos os caracteres não numéricos
   
    try {
      await formValidationSchema.validateAt(fieldName, { [fieldName]: enteredValue });  
      if ((enteredValue.length === 11 || enteredValue.length === 14) && enteredValue) { // Verifica se o valor tem entre 9 e 14 dígitos
        setIsLoading(true);
        try {
       
          const response = await CpfService.getAll(enteredValue); // Envia o valor para a API
       
          setIsLoading(false);
  
          if (typeof response !== 'string') {
            const isErrorResponse = 'message' in response && 'name' in response;
  
            if (isErrorResponse) {
              console.error('Erro ao consultar a API:', response);
              return;
            }
            throw new Error('Resposta inesperada da API');
          }
  
          const data: string = response;
  
          if (data !== 'false') {
            navigate(`/parceiros/detalhes/${data}`);
          } else if (enteredValue.length === 14 && data === 'false') { // Verifica se é CNPJ e o retorno foi 'false'
            setIsLoading(true);
         
            const cnpjData = await CnpjService.getAll(enteredValue);

            if (Array.isArray(cnpjData) && cnpjData.length > 0) {
              const dataAtIndex0 = cnpjData[0]; // Acessa os dados na posição 0 do array
              const hasUndefinedFields = Object.values(dataAtIndex0).some(value => value === undefined);
  
              if (hasUndefinedFields) {
                alert('CNPJ não encontrado na SEFAZ');
              } else {
                formRef.current?.setData({ ...formRef.current?.getData(), ...dataAtIndex0 });
              }
            } else {
              alert('CNPJ não encontrado na SEFAZ');
            }
          }
        } catch (error) {
          // console.error('Erro ao consultar a API:', error);
        } finally {
          setIsLoading(false);
        }
      }} catch (error) {
      setIsLoading(false);
    }
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ParceirosService
      
        .deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/parceiros');
          }
        });
    }
    
  };
  

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Parceiros' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoImpressao={false}
          mostrarBotaoApagarCarregando={isLoading}
          mostrarBotaoImpressaoCarregando={isLoading}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/parceiros')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/parceiros/detalhes/novo')}
          mostrarBotaoNovoCarregando={isLoading}
        />
      }
    > 

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <VTextFieldCpfCnpj
                  fullWidth
                  size="small"
                  onChange={handleCPFCNPJChange}
                  name='cpf'
                  disabled={isLoading}
                  label='CPF / CNPJ'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                <VTextField
                  fullWidth
                  size="small"
                  name='rg'
                  label='RG / Inscrição Estadual'
                  disabled={isLoading}
                  
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                <VTextField
                  fullWidth
                  size="small"
                  name='nome'
                  label='Nome / Razão Social'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <VTextField
                  fullWidth
                  size="small"
                  name='apelido'
                  label='Apelido / Fantasia'
                  disabled={isLoading}
                />
              </Grid>
              
              <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                <VTextField
                  fullWidth
                  size="small"
                  name='telefone'
                  label='Telefone'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                <VTextField
                  fullWidth
                  size="small"
                  name='celular'
                  label='Celular'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
                <VTextField
                  fullWidth
                  size="small"
                  name='endereco'
                  label='Endereço'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <AutoCompleteMunicipio 
                  isExternalLoading={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                <VTextFieldCep
                  fullWidth
                  size="small"
                  name='cep'
                  label='CEP'
                  disabled={isLoading}
                />
              </Grid>            
              <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                <VTextField
                  fullWidth
                  size="small"
                  name='numero'
                  label='Número'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  size="small"
                  name='bairro'
                  label='Bairro'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  size="small"
                  name='comprador'
                  label='Comprador / Representante'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                <VTextField
                  fullWidth
                  size="small"
                  name='diasvencimento'
                  label='Vencimento'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <VTextField
                  fullWidth
                  size="small"
                  name='email'
                  label='E-mail'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <VTextField
                  fullWidth
                  size="small"
                  name='emailnfe'
                  label='E-mail Fiscal'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                <VSwitch
                  name='enviaremailnfe'
                  label='Env.NFE'
                  fdisabled={isLoading}
                  aliamento='top'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <SelectNumber
                  name='contribuinte'
                  label='Contribuinte'
                  data={[
                    { nameBD: 0, descricao: 'Contribuinte' },
                    { nameBD: 1, descricao: 'Insento' },
                    { nameBD: 2, descricao: 'Não Contribuinte' },   
                  ]}
                  disabled={isLoading}     
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                <SelectNumber
                  name='presensa'
                  label='Presença'
                  data={[
                    { nameBD: 0, descricao: 'Não' },
                    { nameBD: 1, descricao: 'Presencial' },
                    { nameBD: 2, descricao: 'Internet' },  
                    { nameBD: 3, descricao: 'Tele Atendimento' },
                    { nameBD: 4, descricao: 'Entega Domicílio' },
                    { nameBD: 5, descricao: 'Presencial Fora Estabelecimento' },  
                    { nameBD: 6, descricao: 'Outros' }, 
                  ]}
                  disabled={isLoading}   
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <SelectNumber
                  name='consumidorfinal'
                  label='Consumidor Final'
                  data={[
                    { nameBD: 0, descricao: 'Não' },
                    { nameBD: 1, descricao: 'Sim' },
                  ]}
                  disabled={isLoading}       
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <AutoCompleteCfop
                  isExternalLoading={isLoading}/>
              </Grid>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <VTextField
                fullWidth
                name='obs'
                label='OBS'
                multiline
                rows={4}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};