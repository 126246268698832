
import { Api } from '../../axios-config';


import { v4 as uuidv4 } from 'uuid';

type NullableDate = Date | null | undefined;

export interface IListagemEventos {
  ideventos: string;
  descricao: string;
  valor: number;
  fixo: boolean;
  tipocalculo: string;
  dataexpiracao?: NullableDate;
  tipoverba: string;
  status: boolean;
  idfun: string;
  basecalculo: string;
}

export interface IDetalheEventos {
  descricao: string;
  valor: number;
  fixo: boolean;
  tipocalculo: string;
  dataexpiracao?: NullableDate;
  tipoverba: string;
  status: boolean;
  idfun: string;
  basecalculo: string;
}




const getAll = async (idfun : string): Promise<IListagemEventos[] | Error> => {
  try {
    const urlRelativa = `/verbaseventos?idfun=${idfun}`;
    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data;
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


const getById = async (id: string): Promise<IDetalheEventos | Error> => {
  try {
    const { data } = await Api.get(`/verbaseventos/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheEventos): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const { data } = await Api.post<IDetalheEventos>('/verbaseventos',  {ideventos: guuid, ...dados});

    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheEventos ): Promise<void | Error> => {
  
  try {
    await Api.put(`/verbaseventos/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/verbaseventos/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const EventosService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};