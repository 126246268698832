
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon} from '@mui/material';
import { IVFormErrors, VForm, VSwitch, VTextField, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { PivotService } from '../../../shared/services/api/cadastros/PivotService';

interface IFormData {
  idfazenda: string;
  descricao: string;
  hectare: number;
  status: boolean;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
  idFazenda: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfazenda: yup.string().required(),
  descricao: yup.string().required().min(3).max(50),
  hectare: yup.number().required().moreThan(0),
  status: yup.boolean().optional().default(true)
});

export const DialogDescricaoPivot: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  

  const { onClose, value: valueProp, open, ...other } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(valueProp);
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = (dados: IFormData) => {
    dados.idfazenda = props.idFazenda;
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (props.id === 'novo') {
          PivotService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              }else {
                onClose(valueProp);
              }
            });
        } else {
          PivotService
            .updateById(props.id, dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                onClose(valueProp);
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  useEffect(() => {
    if(props.id !== 'novo'){
      if(open == true){
        setIsLoading(true);
        PivotService.getById(props.id)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              formRef.current?.setData(result);
            }
          });
      }
    }else{
      formRef.current?.setData({
        idfazenda: props.idFazenda,
        descricao: '',
        hectare: '',
        status: true,
      });
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = () => {
    if (confirm('Realmente deseja apagar?')) {
      PivotService.deleteById(props.id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            onClose(valueProp);
          }
        });
    }
  };

  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Terceiros</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>          
                    <VTextField
                      fullWidth
                      size='small'
                      name='descricao'
                      disabled={isLoading}
                      label='Descrição da área'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>          
                    <VTextField
                      fullWidth
                      size='small'
                      name='hectare'
                      disabled={isLoading}
                      label='Hectare'
                    />
                  </Grid>
                  {(props.id != 'novo') && (
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                      <VSwitch
                        name='status'
                        label='Ativo'
                        fdisabled={isLoading}
                        aliamento='start'
                      />
                    </Grid> 
                  )}                        
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button sx={{ marginRight:'20%' }}  color='error' startIcon={<Icon>delete</Icon>}  variant="contained"  onClick={handleDelete}>Excluir</Button>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 