import { Box, Button, Grid, Icon,  Paper,  Typography, useTheme } from '@mui/material';
import { AutoCompleteFazenda01 } from '../../forms';

interface IFerramentasDaListagemProps {
  textoTitulo?: string; 
  textonome?: string; 
  mostrarTextoTitulo?: boolean;
  aoClicarEmImprimirFolha?: () => void;
  aoClicarEmImprimirCheque?: () => void;
  aoClicarEmGeraCheque?: () => void;
  aoMudarTextoDoFazenda?: (novofazenda: string, novonomefazenda: string) => void;
}
export const FerramentasDaListagemFolhaCheque: React.FC<IFerramentasDaListagemProps> = ({
  textoTitulo = '',
  textonome='',
  mostrarTextoTitulo = false,
  aoClicarEmImprimirFolha,
  aoClicarEmImprimirCheque,
  aoClicarEmGeraCheque,
  aoMudarTextoDoFazenda,

}) => {
  const theme = useTheme();

  const handleuuidFazendaNome = (nome: string | undefined) => {
    if (nome != null) {
      textonome = nome;
    }  
  };

  const handleuuidfazenda = (uuid: string | undefined) => {
    if (uuid != null) {
      aoMudarTextoDoFazenda?.(uuid, textonome);
    }  
  };

  return (
    <Box
      gap={1}
      marginX={4}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(8)}
      component={Paper}
    >
      {mostrarTextoTitulo && (
        <Typography
       
          component="span"
          sx={{
            fontFamily: 'Arial, sans-serif',  
          }}
        >
          {textoTitulo}
        </Typography>
      )}
      <Grid container item direction="row" spacing={2}>  
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container item direction="row" spacing={2}>  
            <Grid item xs={2} sm={2} md={2} lg={2} xl={3}> 
              <AutoCompleteFazenda01
                isExternalLoading={false}
                nome={handleuuidFazendaNome}
                uuidfazenda={handleuuidfazenda} />
            </Grid>   
            <Grid item xs={2} sm={4} md={2} lg={2} xl={1}> 
              <Button
                color='primary'
                disableElevation
                variant='outlined'
                onClick={aoClicarEmImprimirFolha}
                endIcon={<Icon>printoutlined</Icon>}
              >Imprimir Folha</Button>
            </Grid>  
            <Grid item xs={2} sm={4} md={2} lg={2} xl={1}> 
              <Button
                color='primary'
                disableElevation
                variant='outlined'
                onClick={aoClicarEmImprimirCheque}
                endIcon={<Icon>printoutlined</Icon>}
              >Imprimir Cheque</Button>
            </Grid>    
            <Grid item xs={2} sm={4} md={2} lg={2} xl={1}> 
              <Button
                color='primary'
                disableElevation
                variant='outlined'
                onClick={aoClicarEmGeraCheque}
                endIcon={<Icon>settings</Icon>}
              >Gera Cheques</Button>
            </Grid>      
          </Grid>   
        </Grid>   
      </Grid>   
    </Box>
  );
};