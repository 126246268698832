/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Icon, MenuItem, Paper, Select, TextField, useTheme } from '@mui/material';
import { Environment } from '../../environment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


interface IFerramentasDaListagemProps {
  textoDaBusca?: string;
  textoDoSelect?: string;
  textoDoData?: Dayjs | null;
  mostrarInputBusca?: boolean;
  mostrarInputData?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  aoMudarTextoDoSelect?: (novoTextoselect: string) => void;
  aoMudarData?: (novaData: Dayjs | null) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  mostrarInputBasicSelect?: boolean
  aoClicarEmNovo?: () => void;
}
export const FerramentasDaListagemNfe: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  textoDoSelect = '',
  textoDoData = dayjs(),
  aoMudarTextoDeBusca,
  aoMudarTextoDoSelect,
  aoMudarData,
  mostrarInputBusca = false,
  mostrarInputData = false,
  mostrarInputBasicSelect = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
}) => {
  const theme = useTheme();
 
  
  return (
    
    <Box
      gap={1}
      marginX={3}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(6)}
      component={Paper}
    >
      {mostrarInputBasicSelect && (
        <Select
          size="small" sx={{ minWidth: 130 }}
          value={textoDoSelect}
          onChange={(event) => aoMudarTextoDoSelect?.(event.target.value)}
        >
          <MenuItem value={'n.dataemissao'}>Data Emissão</MenuItem>
          <MenuItem value={'n.nfe'}>Numero</MenuItem>
          <MenuItem value={'pa.nome'}>Parceiros</MenuItem>
        </Select>
      )}

      {mostrarInputBusca && (
        <TextField
          size='small'
          value={textoDaBusca}
          placeholder={Environment.INPUT_DE_BUSCA}
          onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
        />
      )}  

      {mostrarInputData && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format='DD/MM/YYYY'
            value={textoDoData}
            onChange={aoMudarData} 
            slotProps={{
              textField: {    
                size:'small',
              }
            }}
          />
        </LocalizationProvider>
      )} 


      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={aoClicarEmNovo}
            endIcon={<Icon>add</Icon>}
          >{textoBotaoNovo}</Button>
        )}
      </Box>
    </Box>
  );
};