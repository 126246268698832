/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Api } from '../../axios-config';

export interface IListagemStatus {
  idstatus: string;
  descricao: string;
  encargos: boolean;
  salarios: boolean;
  folha: boolean;
  ferias: boolean;
  auxilio: boolean;
  experiencia: boolean;
  status: boolean;
  tipo: boolean;
}

export interface IDetalheStatus {
  descricao: string;
  encargos: boolean;
  salarios: boolean;
  folha: boolean;
  ferias: boolean;
  auxilio: boolean;
  experiencia: boolean;
  status: boolean;
  tipo: boolean;
}

type TComTotalCount = {
    data: IListagemStatus[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/statuscolaborador?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllist = async ( filter = '', id=''): Promise<TComTotalCount| Error> => {
  try {

    const urlRelativa: string = id === '' ? `/statuscolaboradolist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/statuscolaboradolist/${id}` ;
    
    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheStatus | Error> => {
  try {
    const { data } = await Api.get(`/statuscolaborador/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheStatus): Promise<string | Error> => {
  try {
    const { tipo, ...dadosIDetalheColaborador } = dados;
    const guuid = uuidv4();
    const { data } = await Api.post<IDetalheStatus>('/statuscolaborador',  {idstatus: guuid, ...dadosIDetalheColaborador});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheStatus): Promise<void | Error> => {
  try {
    const { tipo, ...dadosIDetalheColaborador } = dados;
    await Api.put(`/statuscolaborador/${id}`, dadosIDetalheColaborador);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/statuscolaborador/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const StatusService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
};