/*
-> Para usar esse componente importe-o com a validação: 
  import { VTextFieldCpf, cpfValidator } from '../../../shared/forms';

-> Para validação no yup use o atributo .test('cpf', 'CPF inválido', cpfValidator)
  cpf: yup.string().required().min(11).max(11).test('cpf', 'CPF inválido', cpfValidator),

-> Para validação do CPF e CNPJ  use: 
      .test('cpf-ou-cnpj', 'CPF ou CNPJ inválido', function(value) {
        if (!value) {
          return this.createError({ path: this.path, message: 'Campo obrigatório' });
        }

        if (value.length === 11) {
          if (!cpfValidator.call(this, value)) {
            return this.createError({ path: this.path, message: 'CPF inválido' });
          }
        } else if (value.length === 14) {
          if (!cnpjValidator.call(this, value)) {
            return this.createError({ path: this.path, message: 'CNPJ inválido' });
          }
        } else {
          return this.createError({ path: this.path, message: 'CPF ou CNPJ inválido' });
        }

        return true;
      })

-> Use o componente dessa forma:
  <VTextFieldCpf
    fullWidth
    name='cpf'
    disabled={isLoading}
    label='CPF'
  />
*/


import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';
import * as yup from 'yup';

type TVTextFieldProps = TextFieldProps & {
  name: string;
};

function validaCPF(cpf: string): boolean {
  let Soma = 0;
  let Resto: number;

  const strCPF = String(cpf).replace(/[^\d]/g, '');

  if (strCPF.length !== 11) return false;

  if ([
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ].indexOf(strCPF) !== -1) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(strCPF.substring(9, 10))) {
    return false;
  }

  Soma = 0;

  for (let i = 1; i <= 10; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(strCPF.substring(10, 11))) {
    return false;
  }

  return true;
}

function validaCNPJ(cnpj: string): boolean {
  let Soma: number;
  let Resto: number;

  const strCNPJ = cnpj.replace(/\D/g, '');

  if (strCNPJ.length !== 14) return false;

  if ([
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ].includes(strCNPJ)) {
    return false;
  }

  // Primeiro dígito verificador
  Soma = 0;
  const peso1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  for (let i = 0; i < 12; i++) {
    Soma += parseInt(strCNPJ.charAt(i)) * peso1[i];
  }

  Resto = Soma % 11;
  if (Resto < 2) {
    Resto = 0;
  } else {
    Resto = 11 - Resto;
  }

  if (Resto !== parseInt(strCNPJ.charAt(12))) {
    return false;
  }

  // Segundo dígito verificador
  Soma = 0;
  const peso2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  for (let i = 0; i < 13; i++) {
    Soma += parseInt(strCNPJ.charAt(i)) * peso2[i];
  }

  Resto = Soma % 11;
  if (Resto < 2) {
    Resto = 0;
  } else {
    Resto = 11 - Resto;
  }

  if (Resto !== parseInt(strCNPJ.charAt(13))) {
    return false;
  }

  return true;
}




export const VTextFieldCpf: React.FC<TVTextFieldProps> = ({ name, ...rest }) => {

  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  const formatValueString = (value: string | undefined | null) => {
    if (value === undefined || value === null || value === '') {
      return '';
    }
    else if(value.length === 11){
      return value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }else if(value.length === 14){
      return value.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return value;
  };

  const [value, setValue] = useState<string>(() => formatValueString(defaultValue));

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value.replace(/\D/g, ''), // Retorna o valor sem formatação
      setValue: (_, newValue) => setValue(formatValueString(newValue)),
    });
  }, [registerField, fieldName, value]);
  
  const formatInputValue = (inputValue: string) => {
    const formattedValue = inputValue.replace(/\D/g, '');

    if(formattedValue.length === 11){
      return formattedValue.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }else if(formattedValue.length === 14){
      return formattedValue.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return formattedValue;
  };


  return (
    
    <TextField
      {...rest}
      error={!!error }
      helperText={error}
      defaultValue={defaultValue}
      value={value || ''}
      onChange={(e) => {
        const inputValue = formatInputValue(e.target.value);
        setValue(inputValue);
        rest.onChange?.({ target: { value: inputValue } } as React.ChangeEvent<HTMLInputElement>);
      }}
      onKeyDown={(e) => {
        if (error) {
          clearError();
        }
        rest.onKeyDown?.(e);
      }}
    />
  );
  
};

export function cpfValidator(this: yup.TestContext, value: string): boolean {
  return validaCPF(value);
}

export function cnpjValidator(this: yup.TestContext, value: string): boolean {
  return validaCNPJ(value);
}
