import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';



export interface IListagemValeresumo {
    idvale:string,
    mes:string,
    periodo:string,
    historico:string,
    valor:number,
    financeiro:boolean,
    status:string,
    parcela:string,
    tipodesconto:string,
}
export interface IListagemValesgrafico {
    mes:number,
    periodo:string,
    total:number,
}


type TotalCount = {
    detalhes: IListagemValeresumo[];
    grafico: IListagemValesgrafico[];
    totalCount: number;
}

const getAll = async (idfun: string,  ano: string): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/valesresumo?idfun=${idfun}&ano=${ano}`;

    const { data, headers } = await Api.get(urlRelativa);  
    

    if ( data) {
      const [grafico, detalhes] = data;
      
      return {
        grafico,
        detalhes,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


export const ValeresumoService = {
  getAll
 
};