/*
-> Para usar esse componente importe-o com a validação: 
  import { VTextFieldCpf, cpfValidator } from '../../../shared/forms';

-> Para validação no yup use o atributo .test('cpf', 'CPF inválido', cpfValidator)
  cpf: yup.string().required().min(11).max(11).test('cpf', 'CPF inválido', cpfValidator),

-> Use o componente dessa forma:
  <VTextFieldCpf
    fullWidth
    name='cpf'
    disabled={isLoading}
    label='CPF'
  />
*/
import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';
import * as yup from 'yup';

type TVTextFieldProps = TextFieldProps & {
  name: string;
};

function validaCPF(cpf: string): boolean {
  let Soma = 0;
  let Resto: number;
  
  const strCPF = String(cpf).replace(/[^\d]/g, '');
  
  if (strCPF.length !== 11) return false;
  
  if ([
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ].indexOf(strCPF) !== -1) {
    return false;
  }
  
  for (let i = 1; i <= 9; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }
  
  Resto = (Soma * 10) % 11;
  
  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }
  
  if (Resto !== parseInt(strCPF.substring(9, 10))) {
    return false;
  }
  
  Soma = 0;
  
  for (let i = 1; i <= 10; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }
  
  Resto = (Soma * 10) % 11;
  
  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }
  
  if (Resto !== parseInt(strCPF.substring(10, 11))) {
    return false;
  }
  
  return true;
}
  
function validaCNPJ(cnpj: string): boolean {
  const strCNPJ = cnpj.replace(/[^\d]/g, '');
  
  if (strCNPJ.length !== 14) return false;
  
  if ([
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ].indexOf(strCNPJ) !== -1) {
    return false;
  }
  
  let tamanho = strCNPJ.length - 2;
  let numeros = strCNPJ.substring(0, tamanho);
  const digitos = strCNPJ.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) {
    return false;
  }
  
  tamanho = tamanho + 1;
  numeros = strCNPJ.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1))) {
    return false;
  }
  
  return true;
}

export const VTextFieldCpfCnpj: React.FC<TVTextFieldProps> = ({ name, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  

  const formatCpfCnpj = (value: string) => {
    const formattedValue = value.replace(/\D/g, '');
    if (formattedValue.length <= 11) {
      return formattedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return formattedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  };

  const [value, setValue] = useState<string>(() => formatCpfCnpj(defaultValue || ''));

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value.replace(/\D/g, ''),
      setValue: (_, newValue) => setValue(formatCpfCnpj(newValue || '')),
    });
  }, [registerField, fieldName, value]);

  return (
    <TextField
      {...rest}
      error={!!error}
      helperText={error}
      defaultValue={defaultValue}
      value={value || ''}
      onChange={(e) => {
        const inputValue = formatCpfCnpj(e.target.value);
        setValue(inputValue);
        rest.onChange?.({ target: { value: inputValue } } as React.ChangeEvent<HTMLInputElement>);
      }}
      onKeyDown={(e) => {
        if (error) {
          clearError();
        }
        rest.onKeyDown?.(e);
      }}
    />
  );
};

export function cpfcnpjValidator(this: yup.TestContext, value: string): boolean {
  const formattedValue = value.replace(/\D/g, '');
  if (formattedValue.length === 11) {
    return validaCPF(formattedValue);
  } else if (formattedValue.length === 14) {
    return validaCNPJ(formattedValue);
  }
  return false;
}