import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../hooks';
import { useField } from '@unform/core';
import { ProdutosService } from '../services/api/estoque/produtos/ProdutosService';


type TAutoCompleteOption = {
  idprod: string;
  label: string;
  descricao: string;
  vencimento: boolean;
  referencia: string;
  precoSaida: number;
  precoVenda: number;
  idproduto: string;
}

interface IAutoCompleteProdutoProps {
  isExternalLoading?: boolean;
  filtro?: string;
  combustivel: string;
  referencia?: (novoTextoselect: string | undefined) => void;
  descricao?: (novoTextoselect: string | undefined) => void;
  vencimento?: (novoTextoselect: boolean | undefined) => void;
  precoSaida?: (novoTextoselect: number | undefined) => void;
  precoVenda?: (novoTextoselect: number | undefined) => void;
  idproduto?: (novoTextoselect: string | undefined) => void;
}


export const AutoCompleteProduto: React.FC<IAutoCompleteProdutoProps> = ({ isExternalLoading = false, referencia, descricao, vencimento, precoSaida, precoVenda, idproduto, filtro = 'descricao', combustivel = ''}) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField('idprod');


  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      
      ProdutosService.getAlllist(busca, selectedId, filtro, combustivel)
        .then((result) => {
          setIsLoading(false);
          
          if (result instanceof Error) {
            // alert(result.message);
          } else {
            if(filtro === 'descricao'){
              setOpcoes(result.data.map(produto => ({ idprod: produto.idprod, label: produto.descricao, referencia: produto.referencia, descricao: produto.descricao, vencimento: produto.vencimento, precoSaida: produto.precosaida, precoVenda: produto.precovenda, idproduto: produto.idprod})));
            }else if(filtro === 'codbarraentrada'){
              setOpcoes(result.data.map(produto => ({ idprod: produto.idprod, label: produto.codbarraentrada, referencia: produto.referencia, descricao: produto.descricao, vencimento: produto.vencimento, precoSaida: produto.precosaida, precoVenda: produto.precovenda, idproduto: produto.idprod})));
            }else if(filtro === 'codbarrasaida'){
              setOpcoes(result.data.map(produto => ({ idprod: produto.idprod, label: produto.codbarrasaida, referencia: produto.referencia, descricao: produto.descricao, vencimento: produto.vencimento, precoSaida: produto.precosaida, precoVenda: produto.precovenda, idproduto: produto.idprod})));
            }else if(filtro === 'referencia'){
              setOpcoes(result.data.map(produto => ({ idprod: produto.idprod, label: produto.referencia, referencia: produto.referencia, descricao: produto.descricao, vencimento: produto.vencimento, precoSaida: produto.precosaida, precoVenda: produto.precovenda, idproduto: produto.idprod})));
            }            
          }
        });
    });
  }, [busca, selectedId, filtro]);
 
  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idprod === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);
  
  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idprod); idproduto?.(newValue?.idprod); referencia?.(newValue?.referencia); descricao?.(newValue?.descricao); vencimento?.(newValue?.vencimento); precoSaida?.(newValue?.precoSaida); precoVenda?.(newValue?.precoVenda); setBusca(''); clearError(); }}
      popupIcon={(isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          label="Pesquisar ítem"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};