/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Icon, MenuItem, Paper, Select, TextField, useTheme } from '@mui/material';
import { Environment } from '../../environment';

interface IFerramentasDaListagemProps {
  textoDaBusca?: string;
  textoDoSelect?: string;
  mostrarInputBusca?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  aoMudarTextoDoSelect?: (novoTextoselect: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  mostrarInputBasicSelect?: boolean
  aoClicarEmNovo?: () => void;
}
export const FerramentasDaListagemVale: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  textoDoSelect = '',
  aoMudarTextoDeBusca,
  aoMudarTextoDoSelect,
  mostrarInputBusca = false,
  mostrarInputBasicSelect = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
}) => {
  const theme = useTheme();
 
  
  return (
    
    <Box
      gap={1}
      marginX={3}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      //  height={theme.spacing(5)}
      component={Paper}
    >
      <Grid container item direction="row" spacing={1}> 
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}> 
          {mostrarInputBasicSelect && (
            <Select
              size="small" 
              fullWidth
              value={textoDoSelect}
              onChange={(event) => aoMudarTextoDoSelect?.(event.target.value)}
            >
              <MenuItem value={'todos'}>todos</MenuItem>
              <MenuItem value={'descontar'}>descontar</MenuItem>
              <MenuItem value={'descontado'}>descontado</MenuItem>
            </Select>
          )}
        </Grid> 
        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}> 
          {mostrarInputBusca && (
            <TextField
              size="small"
              value={textoDaBusca}
              placeholder={Environment.INPUT_DE_BUSCA}
              onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
            />
          )} 
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={1}> 
          <Box flex={1} display="flex" justifyContent="end">
            {mostrarBotaoNovo && (
              <Button
                fullWidth 
                color='primary'
                disableElevation
                variant='contained'
                onClick={aoClicarEmNovo}
                endIcon={<Icon>add</Icon>}
              >{textoBotaoNovo}</Button>
            )}
           
          </Box>

        </Grid> 
      </Grid> 

    </Box>
  );
};