import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagemCadastros } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemCadastros';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemParceiros, ParceirosService } from '../../../shared/services/api/cadastros/ParceirosService';


export const ListagemDeParceiros: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemParceiros[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'nome'; 
  }, [searchParams]);

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  
  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      ParceirosService.getAll(pagina, filter, tipo, )
        .then((result) => {
          setIsLoading(false);
          
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [pagina, filter, tipo,]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Parceiros'
      barraDeFerramentas={
        <FerramentasDaListagemCadastros
          mostrarInputBusca
          mostrarInputBasicSelect
          textoDaBusca={filter}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/parceiros/detalhes/novo')}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ filter: filter, tipo: textoselect, pagina: '1' }, { replace: true })}
          dadosSelecao={[
            { nameBD: 'nome', descricao: 'Nome / Razão Social' },
            { nameBD: 'cpf', descricao: 'CPF / CNPJ' },
            { nameBD: 'apelido', descricao: 'Apelido / Nome Fantasia' },
          ]} 
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome/Razão Social</TableCell>
              <TableCell>CPF/CNPJ</TableCell>
              <TableCell>Nome Fantasia / Apelido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idparceiro} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/parceiros/detalhes/${row.idparceiro}`)}
              >
                <TableCell>{row.nome}</TableCell>
                <TableCell align='left' >{row.cpf.length === 11 ? row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'): 
                  row.cpf.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') }</TableCell>
                <TableCell>{row.apelido}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_CADASTRO) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_CADASTRO)}
                    onChange={(_, newPage) => setSearchParams({ filter, tipo: tipo, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};