/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../environment';
import { ApiFinanceira } from '../axiosfinanceiro';
import { v4 as uuidv4 } from 'uuid';

export interface IListagemContasReceber {
  idreceber: string;
  dataoperacao: Date;
  emissao: Date;
  vencimento: Date;
  numerodoc: string;
  valorcompra: number;
  recebido: number;
  desconto: number;
  acrescimos: number;
  valorparcela: number;
  status: boolean;
  marcado: boolean;
  lcdpr: boolean;
  receber: number
  cultivo: string; 
  nfereceber: number;
  venc: boolean;
}

export interface IMarcados{
  idreceber: string;
	marcado: boolean;
	desconto: number;
	receber: number;
}

export interface ICheque{
  idcheque: string;
  banco: string;
  agencia: string;
  conta: string;
  datadeposito: Date;
  titular: string;
  valor: number;
  numero: string;
}

type TotalCount = {
    data: IListagemContasReceber[];
    totalCount: number;
    selecionado: number;
    total: number;
}

interface IRecebidos {
  valorrecebido: number;
  valorselecionado: number;
  idconta: number;
  databaixa: Date;
  valornfe: number;
  tipocaixa: boolean;
}

interface IReceber {
  idparceiro: string;
  idcultivo?: string | null;
  idplanoconta: string;
  idfazenda: string;
  idprodutor: number;
  emissao: Date; 
  vencimento: Date;
  historico: string;
  historicolcdpr: string;
  lcdpr: boolean;
  numerodoc: string;
  numeronfe: string;
  valorcompra: number;
  valorparcela: number;
  acrescimos: number;
  desconto: number;
  recebido: number;
  receber: number;
  valornfe: number;
  nfeparcela: number;
  nfereceber: number;
  nferecebido: number;
  tipo: number;
  tipodoc: number;
}

interface IGetReceber {
  idparceiro: string;
  idcultivo?: string | null;
  idplanoconta: string;
  idfazenda: string;
  idprodutor: number;
  emissao: Date;
  vencimento: Date;
  historico: string;
  historicolcdpr: string;
  lcdpr: boolean;
  numerodoc: string;
  numeronfe: string;
  valorcompra: number;
  valorparcela: number;
  acrescimos: number;
  desconto: number;
  recebido: number;
  receber: number;
  valornfe: number;
  nfeparcela: number;
  nfereceber: number;
  nferecebido: number;
  tipo: number;
  tipodoc: number;
  status: boolean;
  recebidas: number;
}

interface ErrorResponse {
  data: {
    errors: {
      [key: string]: string;
    };
  };
}

const create = async (dados: IReceber): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await ApiFinanceira.post<IReceber>('/contasreceber',  {idreceber: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const updateById = async (id: string, dados: IGetReceber): Promise<void | Error> => {
  try {

    await ApiFinanceira.put(`/contasreceber/${id}`, dados);

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Atualizar Contas Receber');
    }}
};

const getAll = async (page = 1, filter = '', tipo='', status: string): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/contasreceber?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&tipo=${tipo}&status=${status}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
        selecionado: Number(headers['x-total-selecionado'] || 0),
        total: Number(headers['x-total-valor'] || 0),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const Marcados = async (dados: IMarcados[]): Promise<void | Error> => {
  try {

    await ApiFinanceira.post('/recebermarcado', dados);    
   
    return;

  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Marcar');
    }}
};

const Baixa = async (dados: IRecebidos, receber: IListagemContasReceber[]): Promise<void | Error> => {
  try { 
    let historicos = '';  
    if(dados.valorrecebido === dados.valorselecionado){
      const baixa = receber.map(conta => {   
        historicos += `${conta.numerodoc}, `;
        return {
          idreceber: conta.idreceber,
          idconta: dados.idconta,
          dataoperacao: dados.databaixa,
          recebido: dados.valorrecebido,
          historico: `Recebimento dos numeros ${historicos.slice(0, -2)}`,
          tipocaixa: dados.tipocaixa,
          valornfe: conta.nfereceber,
          total: dados.valorselecionado,
        };
      });
      await ApiFinanceira.post('/receberbaixa', baixa); 
      return;
    }else{
      const baixa = receber.map(conta => {
        return {
          idreceber: conta.idreceber,
          idconta: dados.idconta,
          dataoperacao: dados.databaixa,
          recebido: dados.valorrecebido,
          tipocaixa: dados.tipocaixa,
          valornfe: 0,
          total: dados.valorselecionado,
        };
      });
      await ApiFinanceira.post('/receberbaixaparcial', baixa); 
      return;   
    }
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Baixra');
    }}
};

const BaixaCheque = async (dados: Omit<IRecebidos, 'idconta' | 'tipocaixa'>, receber: IListagemContasReceber[], cheque: ICheque[]): Promise<void | Error> => {
  try {  
    if(dados.valorrecebido === dados.valorselecionado){
      const baixa = receber.map(conta => {
        return {
          idreceber: conta.idreceber,
          dataoperacao: dados.databaixa,
          recebido: conta.receber,
          valornfe: conta.nfereceber,
          historico: '',
          tipocaixa: true,
          total: dados.valorselecionado,
        };
      });
      await ApiFinanceira.post('/receberbaixacheque',  [baixa, cheque]); 
      return;
    }else{
      const baixa = receber.map(conta => {
        return {
          idreceber: conta.idreceber,
          dataoperacao: dados.databaixa,
          recebido: dados.valorrecebido,
          valornfe: 0,
          historico: '',
          tipocaixa: true,
          total: dados.valorselecionado,
        };
      });
      await ApiFinanceira.post('/receberbaixachequeparcial', [baixa, cheque]); 
      return;   
    }
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Baixar Cheque');
    }}
};

const getById = async (id: string): Promise<IGetReceber | Error> => {
  try {
    const { data } = await ApiFinanceira.get(`/contasreceber/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Get');
    }}
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await ApiFinanceira.delete(`/contasreceber/${id}`);

  }catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Apagar o Registro');
    }}
};


export const ContasReceberService = {  
  getAll,
  Marcados,
  Baixa,
  BaixaCheque,
  create,
  updateById,
  getById,
  deleteById,
};