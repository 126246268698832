
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { PlanoDeContasService } from '../../../shared/services/api/cadastros/PlanoDeContasService';
import { IVFormErrors, VForm, VTextField, useVForm } from '../../../shared/forms';



interface IFormData {
  label: string;
}

export interface ConfirmationDialogRawProps {
  id: string;
  label: string;
  open: boolean;
  disabled: boolean;
  onDadosEditar: (dados: {id: string, label: string}) => void;
  onDadosAdicionar: (dados: {id: string, label: string, children: []}) => void;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  label: yup.string().required(),
});

export const DialogPlanoDeContas: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, label: valueProp, open, ...other } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(valueProp);
  const {formRef, save, } = useVForm();
  const [label, setLabel] = useState(props.label);
  const guuid = uuidv4();

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        if(editar === true){
          PlanoDeContasService
            .updateById(props.id, dadosValidados.label)
            .then((result) => {

              if (result instanceof Error) {
                alert(result.message);
              } else {
                props.onDadosEditar({id: props.id, ...dadosValidados});
              }
            });
        }else{          
          PlanoDeContasService
            .create({idpai: props.id, idplanoconta: guuid, descricao: dadosValidados.label})
            .then((result) => {

              if (result instanceof Error) {
                alert(result.message);
              } else{
                props.onDadosAdicionar({id: guuid, ...dadosValidados, children: []});
              }
            });          
        }
        setEditar(false);  
        setAdicionar(false);
        setBotoes(true);
        onClose();
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  useEffect(() => {
    setLabel(props.label);
    if(editar === true){      
      formRef.current?.setFieldValue('label', label);
    }else{
      formRef.current?.setData({
        label: '',
      });
    }
  }, [guuid]); 

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    } 
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
    setLabel('');  
    setEditar(false);  
    setAdicionar(false);
    setBotoes(true);
  };

  const [botoes, setBotoes] = useState(true);
  const [editar, setEditar] = useState(false);
  const handleEditar = () => {
    setEditar(true);
    setBotoes(false);
  };
  const [adicionar, setAdicionar] = useState(false);
  const handleAdicionar = () => {
    setAdicionar(true);
    setBotoes(false);
  };

  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Plano de Contas</DialogTitle>
      <DialogContent dividers>
        {(botoes === true) && (
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} > 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>   
                    <Button disabled={props.disabled} sx={{ minWidth:'196px', marginRight: '4px'  }} variant='outlined' autoFocus onClick={handleEditar}>Editar</Button> 
                    <Button sx={{ minWidth:'196px', marginLeft: '4px'    }} variant='outlined' autoFocus onClick={handleAdicionar}>Adicionar</Button>
                  </Grid>                             
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {(editar || adicionar === true) && (
          <VForm ref={formRef}  onSubmit={handleSave}>
            <Grid container direction="column" padding={1} >
              <Grid container item direction="row" padding={1} spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container item direction="row" spacing={1} > 
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>   
                      <VTextField
                        fullWidth
                        size='small'
                        name='label'
                        label='Descrição'
                        InputProps={{
                          style: { minWidth: '400px' }, 
                        }}
                      />
                    </Grid>                         
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </VForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button sx={{ minWidth:'25%'  }} variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button sx={{ minWidth:'25%'  }} variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 