import { Box, Grid, Paper } from '@mui/material';
import OutlinedCard2 from '../../../shared/forms/OutlinedCard2';
import { useEffect, useState } from 'react';
import { DashboardCadastroService } from '../../../shared/services/api/cadastros/DashboardCadastroService';
import { LayoutBaseDeDashboard } from '../../../shared/layouts';



export const DashboardCadastro = () => {

  const [Server, setServer] = useState(
    {
      parceiros: 0,
      fazendas: 0,
      trnasportadoras: 0,
      patrimonios: 0
    }
  );

  useEffect(() => {
    DashboardCadastroService.GetDashboardCadastroService()
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setServer(result);
        }
      });
  },[]); 


  return (
    <LayoutBaseDeDashboard
    >
      <Box margin={1} display="flex" component={Paper} variant="outlined" gap={1} >
        <Grid container item direction="row"  >
          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <OutlinedCard2
              nome="Parceiros" 
              valor={Server.parceiros} 
              cor="#82D2ED"
              img='/img/parceirosIcone.png'
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <OutlinedCard2 
              nome="Fazendas" 
              valor={Server.fazendas} 
              cor="#DEED82"
              img='/img/fazendaIcone.png'
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <OutlinedCard2 
              nome="Transportadoras" 
              valor={Server.trnasportadoras} 
              cor="#82D2ED"
              img='/img/transportadorasIcone.png'
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <OutlinedCard2 
              nome="Patrimônios" 
              valor={Server.patrimonios} 
              cor="#DEED82"
              img='/img/patrimoniosIcone.png'
            />
          </Grid>
        </Grid>
      </Box>
      <Box margin={1} height="80%" display="flex" alignItems="center" component={Paper} alignContent='center' >
        <img src="/img/cadastro.svg" style={{ width: '100%', height: '100%', alignItems:'center', opacity: 0.5}} alt="Imagem de cadastro" /> 
      </Box>
    </LayoutBaseDeDashboard>
  );
};