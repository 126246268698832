/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid,  LinearProgress,  Paper } from '@mui/material';
import { useState } from 'react';
import { AutoCompleteCultivo, AutoCompleteFazenda, AutoCompleteOperacoes01, AutoCompleteParceiro, IVFormErrors, VForm, VSwitch, VTextFieldData, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { RelatorioEstoqueService } from '../../../shared/services/api/estoque/Relatorios/RelatoriosService';
import ROperacaoSaidaDetalhes from './ROperacoesSaidaDetalhes';
import ROperacoesSaidaResumido from './ROperacoesSaidaResumido';
import ROperacoesVendaResumido from './ROperacoesVendaResumido';
import ROperacaoVendasDetalhes from './ROperacoesVendasDetalhes';
import ROperacoesEntradaResumido from './ROperacoesEntradaResumido';
import ROperacoesEntradaDetalhes from './ROperacoesEntradaDetalhes';

interface IFormData {
  idoperacoes: string;
  idfazenda: string;
  idparceiro: string;            
  idcultivo: string;   
  datainicial: Date;                     
  datafinal: Date;   
  detalhes: boolean;
}  

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idoperacoes:yup.string().required(), 
  idfazenda: yup.string().optional().default(''),
  idparceiro: yup.string().optional().default(''),
  idcultivo: yup.string().optional().default(''),
  datainicial: yup.date().required(),          
  datafinal: yup.date().required(),                    
  detalhes: yup.boolean().required(),   
});    
    
export const RelatoriosOperacoes: React.FC = () => {            
   
  const [isLoading, setIsLoading] = useState(false);   
  const {formRef, save, } = useVForm();     
  const [operacoes, setOperacao] = useState('');    
  const [venda, setvenda] = useState(false);  
  const [tipo, settipo] = useState(1);  
      
  const handleSave = (dados: IFormData) => {   
    formValidationSchema.      
      validate(dados, { abortEarly: false })        
      .then((dadosValidados) => { 
        if(tipo == 1){     
          RelatorioEstoqueService       
            .Operacoes({tipo: tipo, vendas: venda, ...dadosValidados})   
            .then((result) => {           
              setIsLoading(false);     
              if (result instanceof Error) {  
                alert(result.message);   
              }else {
                if(dados.detalhes){
                  if(result.length > 0){
                    if(venda){
                      ROperacaoVendasDetalhes(result, operacoes); 
                    }else{
                      ROperacaoSaidaDetalhes(result, operacoes); 
                    }       
                  }else{
                    alert('Não a Registros'); 
                  }        
                }else{
                  if(result.length > 0){
                    if(venda){
                      ROperacoesVendaResumido(result, operacoes);  
                    }else{
                      ROperacoesSaidaResumido(result, operacoes);  
                    }
                  }else{
                    alert('Não a Registros');    
                  }     
                }   
              }            
            });           
        }else{
          RelatorioEstoqueService       
            .OperacoesEntrada({tipo: tipo, vendas: venda, ...dadosValidados})   
            .then((result) => {           
              setIsLoading(false);     
              if (result instanceof Error) {     
                alert(result.message);               
              }else {                  
                if(dados.detalhes){                       
                  if(result.length > 0){    
                    ROperacoesEntradaDetalhes(result, operacoes);          
                  }else{  
                    alert('Não a Registros'); 
                  }                 
                }else{    
                  if(result.length > 0){
                    ROperacoesEntradaResumido(result, operacoes);  
                  }else{
                    alert('Não a Registros'); 
                  }     
                }   
              }        
            });     
        }  
      })  
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;  
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);   
      });  
  };    

  const handleOperacoes = (descricao: string | undefined) => {
    if (descricao != null) {
      setOperacao(descricao);
    }
  };

  const handleVenda = (venda: boolean | undefined) => {
    if(venda != undefined){
      setvenda(venda);
    }
  };

  const handleTipo = (tipo: number | undefined) => {
    if(tipo != undefined){
      settipo(tipo);
    }
  };
          
  return (
    <Box  alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
      <Box padding={1} paddingX={2} display="flex" alignItems="center" width='80%' height="20%" margin={1}  alignContent='center'></Box>
      <Box   width='auto' component={Paper} height="auto" 
        alignContent='center'>       
        <DialogTitle>Relatório de Operações do Estoque</DialogTitle>
        <DialogContent  dividers>
          <VForm ref={formRef}  onSubmit={handleSave}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />  
              </Grid>                   
            )}   
            <Grid container  padding={1} item direction="row">
              <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AutoCompleteOperacoes01
                    operacao={handleOperacoes} 
                    venda={handleVenda}
                    tipo={handleTipo}
                  />             
                </Grid>
                <Grid item xs={12} sm={12} md={5.5} lg={8} xl={8}>
                  {venda || tipo === 0 ? (
                    <AutoCompleteParceiro
                    />   
                  ) : (
                    <AutoCompleteFazenda
                    />   
                  )}                        
                </Grid> 
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}> 
                  <AutoCompleteCultivo    
                    isExternalLoading={venda || tipo === 0 ? true : isLoading}
                  />   
                </Grid>       
                <Grid item xs={6} sm={6} md={2.3} lg={2.3} xl={2.3}>
                  <VTextFieldData
                    fullWidth
                    name='datainicial'      
                    label='Data Inicial'    
                    disabled={isLoading}    
                  />        
                </Grid>  
                <Grid item xs={6} sm={6} md={2.3} lg={2.3} xl={2.3}>
                  <VTextFieldData  
                    fullWidth    
                    name='datafinal'    
                    label='Data Final'      
                    disabled={isLoading}          
                  />    
                </Grid>    
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <VSwitch    
                    name='detalhes'      
                    label='Detalhado'      
                    fdisabled={isLoading}    
                    aliamento='top'
                  />                
                </Grid>                 
              </Grid>  
            </Grid>            
          </VForm>           
        </DialogContent>     
        <DialogActions>
          <Button variant='outlined' autoFocus onClick={save}>Visualizar</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   