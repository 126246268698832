import { useEffect, useState } from 'react';
import { SwitchProps, Switch, FormControlLabel } from '@mui/material';
import { useField } from '@unform/core';
 
type TVSwitchdProps = SwitchProps & {
    name: string;
    label: string;
    fdisabled: boolean;
    aliamento: 'end' | 'start' | 'top' | 'bottom' 
    valor?: (novoTextoselect: boolean | undefined) => void;
}

export const VSwitch: React.FC<TVSwitchdProps> = ({valor, name, label, fdisabled, aliamento}) => {
  const { fieldName, registerField } = useField(name);

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => checked,
      setValue: (_, newchecked) => setChecked(newchecked),
    });
  }, [registerField, fieldName, checked]);

  return (
    <FormControlLabel 
      control={
        <Switch
          size='small'
          checked={checked}
          onChange={e => {setChecked(e.target.checked); valor?.(e.target.checked);}}
          color="primary"
          defaultChecked
          disabled = {fdisabled}
        />
      } 
      label = {label}
      labelPlacement={aliamento}
    />
  );
}; 