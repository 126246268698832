import { ApiFinanceira } from '../../axiosfinanceiro';

export interface DataDashboard {
  nivel: number,
  
  raberto: number,
  rfechado: number,
  rvencido: number,

  romaneioaberto:[],
  romaneiovencido:[],

  producao:[],
  producaoquebra:[],

  vendas:[],

  cultivo:[],

  clientes:[],
  frutas:[],
}

const GetDashboardCadastroService = async (): Promise<DataDashboard | Error> => {
  try {
    const { data } = await ApiFinanceira.get('/dashboardfaturamento');
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DashboardFaturamentoService = {
  GetDashboardCadastroService,
};