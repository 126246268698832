import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';

interface OutlinedCardProps {
  nome: string;
  valor: number;
  cor: string;
  loading?: boolean;
}

const OutlinedCard3: React.FC<OutlinedCardProps> = ({ nome, valor, cor, loading }) => {
  return (
    <Box sx={{ margin: 1 }}>
      <Card variant="outlined" >
        <CardContent sx={{ width: '0.1px', height: '70px' }} style={{  padding: '8px', display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box style={{ padding: '3px', backgroundColor: cor }} sx={{ width: '0.1px', height: '100px' }} />
          </Box>
          <Box sx={{ flex: '1 0 auto', margin: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: 16, flexGrow: 1 }} >
                {nome}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 25, textAlign: 'right' }} color="text.secondary">
              {loading ? <Skeleton variant="text" /> : new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(valor)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OutlinedCard3;
