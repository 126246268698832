import { Environment } from '../../../environment';
import { ApiFinanceira } from '../axiosfinanceiro';
import { v4 as uuidv4 } from 'uuid';
import { ErrorResponse } from 'react-router-dom';

export interface IListagem {
  idlan: string;
  entrada: string;
  saida: string;
  datalan: Date;
  numero: string;
  valor: number;
  historico: string;
}

export interface Ilancamento {
  identrada: number;
  idsaida: number;
  datalan: Date;
  numero: string;
  valor: number;
  historico: string;
}

type TotalCount = {
    data: IListagem[];
    totalCount: number;
}

const create = async (dados: Ilancamento): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await ApiFinanceira.post<IListagem>('/lanentrecontas',  {idlan: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  }  catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao criar o registro.');
    }}
};

const getAll = async (page = 1, datainicial = '', datafinal=''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/lanentrecontas?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&datainicial=${datainicial}&datafinal=${datafinal}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa); 

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await ApiFinanceira.delete(`/lanentrecontas/${id}`);

  }catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Apagar o Registro');
    }}
};

const getById = async (id: string): Promise<Ilancamento | Error> => {
  try {
    const { data } = await ApiFinanceira.get(`/lanentrecontas/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Get');
    }}
};

export const LancamentoEntreConstasService = {  
  getAll,
  create,
  deleteById,
  getById,
};