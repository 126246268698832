/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Divider, Icon, Paper, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';


interface IFerramentasDeDetalheProps {
  textoBotaoNovo?: string;

  mostrarBotaoNovo?: boolean;
  mostrarBotaoVoltar?: boolean;
  mostrarBotaoApagar?: boolean;
  mostrarBotaoSalvar?: boolean;
  disableBotaoSalvar?: boolean;
  disableBotaoSalvarEFechar?: boolean;
  mostrarBotaoSalvarEFechar?: boolean;
  disableBotaoImpressao?: boolean;
  mostrarBotaoImpressao?: boolean;
  disableBotaoTransmitir?: boolean;
  mostrarBotaoTransmitir?: boolean;
  disableBotaoProtocolo?: boolean;
  mostrarBotaoProtocolo?: boolean;
  mostrarBotaoCancelar?: boolean;
  disableBotaoCancelar?: boolean; 
  mostrarBotaoCarta?: boolean;
  disableBotaoCarta?: boolean; 

 

  mostrarBotaoNovoCarregando?: boolean;
  mostrarBotaoVoltarCarregando?: boolean;
  mostrarBotaoApagarCarregando?: boolean;
  mostrarBotaoSalvarCarregando?: boolean;
  mostrarBotaoSalvarEFecharCarregando?: boolean;
  mostrarBotaoImpressaoCarregando?: boolean;
  mostrarBotaoTransmitirCarregando?: boolean;
  mostrarBotaoCancelarCarregando?: boolean;
  mostrarBotaoCartaCarregando?: boolean;
  mostrarBotaoProtocoloCarregando?: boolean;

  aoClicarEmNovo?: () => void;
  aoClicarEmVoltar?: () => void; 
  aoClicarEmApagar?: () => void;
  aoClicarEmSalvar?: () => void;
  aoClicarEmSalvarEFechar?: () => void;
  aoClicarEmImpressao?: () => void;
  aoClicarEmTransmitir?: () => void;
  aoClicarEmCancelar?: () => void;
  aoClicarEmProtocolo?: () => void;
  aoClicarEmCarta?: () => void;
}
export const FerramentasDeDetalheNfe: React.FC<IFerramentasDeDetalheProps> = ({
  textoBotaoNovo = 'Novo',

  mostrarBotaoNovo = true,
  mostrarBotaoVoltar = true,
  mostrarBotaoApagar = true,
  mostrarBotaoSalvar = true,
  disableBotaoSalvar = false,
  disableBotaoSalvarEFechar = false,
  disableBotaoImpressao = false,
  disableBotaoTransmitir = false,
  disableBotaoProtocolo = false,
  disableBotaoCancelar = false,
  disableBotaoCarta = false,
  mostrarBotaoSalvarEFechar = false,
  mostrarBotaoImpressao = true,
  mostrarBotaoTransmitir = true,
  mostrarBotaoCancelar = true,
  mostrarBotaoProtocolo = true,
  mostrarBotaoCarta = true,

  mostrarBotaoNovoCarregando = false,
  mostrarBotaoVoltarCarregando = false,
  mostrarBotaoApagarCarregando = false,
  mostrarBotaoSalvarCarregando = false,
  mostrarBotaoSalvarEFecharCarregando = false,
  mostrarBotaoImpressaoCarregando = false,
  mostrarBotaoTransmitirCarregando = false,
  mostrarBotaoCancelarCarregando = false,
  mostrarBotaoProtocoloCarregando = false,
  mostrarBotaoCartaCarregando = false,

  aoClicarEmNovo,
  aoClicarEmVoltar,
  aoClicarEmApagar,
  aoClicarEmSalvar,
  aoClicarEmSalvarEFechar,
  aoClicarEmImpressao,
  aoClicarEmTransmitir,
  aoClicarEmCancelar,
  aoClicarEmProtocolo,
  aoClicarEmCarta,
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  return (
    <Box
      gap={1}
      marginX={2}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(6)}
      component={Paper}
    >
      {(mostrarBotaoVoltar && !mostrarBotaoVoltarCarregando) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmVoltar}
          startIcon={<Icon>arrow_back</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              Voltar
          </Typography>
        </Button>
      )}

      {mostrarBotaoVoltarCarregando && (
        <Skeleton width={110} height={60} />
      )}
      {
        (
          mostrarBotaoVoltar &&
          (mostrarBotaoNovo || mostrarBotaoApagar || mostrarBotaoSalvar || mostrarBotaoSalvarEFechar)
        ) && (
          <Divider variant='middle' orientation='vertical' />
        )
      }

      {(mostrarBotaoSalvar && !mostrarBotaoSalvarCarregando) && (
        <Button
          color='primary'
          disableElevation
          disabled={disableBotaoSalvar}
          variant='contained'
          onClick={aoClicarEmSalvar}
          startIcon={<Icon>save</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Salvar
          </Typography>
        </Button>
      )}

      {mostrarBotaoSalvarCarregando && (
        <Skeleton width={110} height={60} />
      )} 

      {(mostrarBotaoSalvarEFechar && !mostrarBotaoSalvarEFecharCarregando && !smDown && !mdDown) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          disabled={disableBotaoSalvarEFechar}
          onClick={aoClicarEmSalvarEFechar}
          startIcon={<Icon>save</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Salvar e Fechar
          </Typography>
        </Button>
      )}

      {(mostrarBotaoSalvarEFecharCarregando && !smDown && !mdDown) && (
        <Skeleton width={180} height={60} />
      )}

      {(mostrarBotaoApagar && !mostrarBotaoApagarCarregando) && (
        <Button
          color='error'
          disableElevation
          variant='contained'
          onClick={aoClicarEmApagar}
          startIcon={<Icon>delete</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Apagar
          </Typography>
        </Button>
      )}

      {mostrarBotaoApagarCarregando && (
        <Skeleton width={110} height={60} />
      )}
      
      {(mostrarBotaoNovo && !mostrarBotaoNovoCarregando && !smDown) && (
        <Button
          color='primary'
          disableElevation
          variant='outlined'
          onClick={aoClicarEmNovo}
          startIcon={<Icon>add</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {textoBotaoNovo}
          </Typography>
        </Button>
      )}

      {(mostrarBotaoNovoCarregando && !smDown) && (
        <Skeleton width={110} height={60} />
      )}
     
      {(mostrarBotaoImpressao && !mostrarBotaoImpressaoCarregando) && (
        <Button
          color='primary'
          disableElevation
          disabled={disableBotaoImpressao}
          variant='outlined'
          onClick={aoClicarEmImpressao}
          startIcon={<Icon>printoutlined</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Imprimir
          </Typography>
        </Button>
      )}

      {(mostrarBotaoTransmitir && !mostrarBotaoTransmitirCarregando) && (
        <Button
          color='primary'
          disableElevation
          disabled={disableBotaoTransmitir}
          variant='outlined'
          onClick={aoClicarEmTransmitir}
          startIcon={<Icon>languagerounded</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Transmitir NFe
          </Typography>
        </Button>
      )}

      {(mostrarBotaoProtocolo && !mostrarBotaoProtocoloCarregando) && (
        <Button
          color='primary'
          disableElevation
          disabled={disableBotaoProtocolo}
          variant='outlined'
          onClick={aoClicarEmProtocolo}
          startIcon={<Icon>addchart</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            Add Protocolo
          </Typography>
        </Button>
      )} 

      {(mostrarBotaoCancelar && !mostrarBotaoCancelarCarregando) && (
        <Button
          color='error'
          disableElevation
          disabled={disableBotaoCancelar}
          variant='outlined'
          onClick={aoClicarEmCancelar}
          startIcon={<Icon>close</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              Cancelar NFE
          </Typography>
        </Button>  
      )} 

      {mostrarBotaoImpressaoCarregando && (
        <Skeleton width={110} height={60} />
      )}

      {(mostrarBotaoCarta && !mostrarBotaoCartaCarregando) && (
        <Button
          color='primary'
          disableElevation
          disabled={disableBotaoCarta}
          variant='outlined'
          onClick={aoClicarEmCarta}
          startIcon={<Icon>chat</Icon>}
        >
          <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              Carta de Correção
          </Typography>
        </Button>  
      )} 

      {mostrarBotaoCartaCarregando && (
        <Skeleton width={110} height={60} />
      )}

    </Box >
  );
};