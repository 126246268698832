/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../../environment';
import { ApiFiscal } from '../../axiosfiscal';
import { v4 as uuidv4 } from 'uuid';

export interface IListagemLCDPR {
  idlcdpr: string;
  dataemissao: Date;
  datacaixa: Date;
  produtor: string;
  participante: string;
  numero: string;
  entrada: number;
  saida: number;
}

export interface IDetalheProdutoNfe {
  idprodutor: string;
  idfazenda: string;
  idconta: string;
  datacaixa: Date;
  dataemissao: Date;
  numero: string;
  tipodoc: number;
  tipolan: number;
  idparceiro?: string | null;
  entrada: number;
  saida: number;
  historico: string
  idparticipante: string;
}

export interface IRReceita {
  vencimento: Date;
  numeronfe: string;
  nome: string;
  cpf: string;
  historico: string;
  valornfe: number;
  nferecebido: number;
  nfereceber: number;
}

export interface IRomaneios {
  vencimento: Date;
  numeronfe: string;
  numero: number;
  nome: string;
  cpf: string;
  valornfe: number;
}

export interface IDespesa {
  vencimento: Date;
  numeronfe: string;
  parcela: string;
  nome: string;
  cpf: string;
  historico: string;
  valornfe: number;
  nfepago: number;
  nfepagar: number;
}

export interface ICaixa {
  dataemissao: Date;
  datacaixa: Date; 
  numero: string; 
  banco: string; 
  participante: string;
  historico: string;
  entrada: number;
  saida: number;
}

export interface ILCDPR {
  dataemissao: Date;
  datacaixa: Date; 
  fazenda: string; 
  numero: string; 
  banco: string; 
  participante: string; 
  historico: string; 
  entrada: number; 
  saida: number; 
  saldo: number;
}

type TotalCount = {
  data: IListagemLCDPR[];
  totalCount: number; 
}

const getAll = async (page = 1, dataInicial = '', dataFinal='', produtor = '', numero = '', tipo=''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/lcdpr?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&tipo=${tipo}&produtor=${produtor}&numero=${numero}&datainicial=${dataInicial}&datafinal=${dataFinal}`;

    const { data, headers } = await ApiFiscal.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllList = async (filter = '', id = ''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/lcdprlist?limit=${Environment.LIMITE_DE_LINHAS}&tipo=descricao&filter=${filter}` : `/lcdprlist/${id}`;

    const { data, headers } = await ApiFiscal.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheProdutoNfe | Error> => {
  try {
    const { data } = await ApiFiscal.get(`/lcdpr/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheProdutoNfe): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const { data } = await ApiFiscal.post<IDetalheProdutoNfe>('/lcdpr',  {idlcdpr: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheProdutoNfe): Promise<void | Error> => {
  try {
    await ApiFiscal.put(`/lcdpr/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await ApiFiscal.delete(`/lcdpr/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const RelatorioReceita = async (produtor = 0, dataInicial = '', dataFinal=''): Promise<IRReceita[] | Error> => {
  try {
    const urlRelativa = `/relatorioreceitadespesa?produtor=${produtor}&tipo=receita&datainicial=${dataInicial}&datafinal=${dataFinal}`;

    const { data } = await ApiFiscal.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RelatorioDespesa = async (produtor = 0, dataInicial = '', dataFinal=''): Promise<IDespesa[] | Error> => {
  try {
    const urlRelativa = `/relatorioreceitadespesa?produtor=${produtor}&tipo=despesa&datainicial=${dataInicial}&datafinal=${dataFinal}`;

    const { data } = await ApiFiscal.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RelatorioRoamenios = async (produtor = 0, dataInicial = '', dataFinal=''): Promise<IRomaneios[] | Error> => {
  try {
    const urlRelativa = `/relatorioreceitadespesa?produtor=${produtor}&tipo=romaneios&datainicial=${dataInicial}&datafinal=${dataFinal}`;

    const { data } = await ApiFiscal.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RelatorioCaixa = async (produtor = 0, tipo = '', dataInicial = '', dataFinal=''): Promise<ICaixa[] | Error> => {
  try {
    const urlRelativa = `/relatoriolcdpr?produtor=${produtor}&tipo=${tipo}&datainicial=${dataInicial}&datafinal=${dataFinal}`;

    const { data } = await ApiFiscal.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const RelatorioLCDPR = async (produtor = 0, dataInicial = '', dataFinal=''): Promise<ILCDPR[] | Error> => {
  try {
    const urlRelativa = `/relatoriolcdpr?produtor=${produtor}&tipo=lcdpr&datainicial=${dataInicial}&datafinal=${dataFinal}`;

    const { data } = await ApiFiscal.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const LCDPRService = {
  getAllList,
  getAll,
  getById,
  create,
  updateById,
  deleteById,
  RelatorioReceita,
  RelatorioDespesa,
  RelatorioRoamenios,
  RelatorioCaixa,
  RelatorioLCDPR,
};