import { Api } from '../../axios-config';

export interface DataDashboard {
  nivel: number,
  
  ativos: number,
  ferias: number,
  auxilio: number,
  experiencia: number,
  experienciavencer: number,
    
  admissoes:[], 
  demissoes:[],

  presencas:[], 
  faltas:[],
  atestados:[],

  cafe:[], 
  almoco:[],
  janta:[],

  horaextra:[], 
  bomba:[],
  pistola:[],
}

const GetDashboardRHService = async (): Promise<DataDashboard | Error> => {
  try {
    const { data } = await Api.get('/dashboard');
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DashboardRHService = {
  GetDashboardRHService,
};