import { Environment } from '../../../../environment';
import { Apiestoque } from '../../axiosestoque';

export interface IListagemRecebimento {
  idtransferencia: string;
  dataenvio: Date;
  datalan: Date;
  origem: string;
  portador: string;
}

export interface IDetalheRecebimento {
  iditens: string;
  qtdentrada: number;
  conversor: number;
  un: string;
  descricao: string;
  referencia: string;
  validade: Date;
}

type TotalCount = {
  data: IListagemRecebimento[];
  totalCount: number;
}

type TotalCountDia = {
  data: IDetalheRecebimento[];
  totalCount: number;
}

const getAll = async (page = 1): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/transferenciarecebimento?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (page = 1, id: string): Promise<TotalCountDia | Error> => {
  try {
    const urlRelativa = `/transferenciarecebimento/${id}?page=${page}&limit=1000`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const confirmar = async (id: string, status: number): Promise<void | Error> => {
  try {
    const dadosConfirmar = {status: status, datarecebimento: new Date()};
    await Apiestoque.put(`/transferenciarecebimento/${id}`, dadosConfirmar);

  } catch (error) {

    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    
  }
};

export const ConfirmarRecebimento = {
  getAll,
  getById,
  confirmar,
};