export const dataextenso = (data: Date): string => {

  function getFullMonthName(month: number): string {
    const months = [ 'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro' ];
    return months[month];
  }
  
  function formatDateToFullString(date: Date): string {
    const month = getFullMonthName(date.getMonth());
    const year = date.getFullYear();
    return `${month}/${year}`;
  }
  
  return  formatDateToFullString(data);
  
};
  