/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,  Grid,  Icon,  IconButton,  LinearProgress,  MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@mui/material';
import { ContasReceberService, IListagemContasReceber } from '../../../shared/services/api/Financeiro/ContasReceber';
import { AutoCompleteContaBancaria } from '../../../shared/forms/AutoCompleteContaBancaria';
import { IVFormErrors, VFieldMoney, VForm, VTextFieldData, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

export interface ConfirmationDialogRawProps {
    value: string;
    open: boolean;
    dados?: IListagemContasReceber[];
    vselecionado: number;
    onClose: (value?: string) => void;
    ontotal: (value?: number) => void;
    onDados: (dados?: IListagemContasReceber[]) => void;
}

interface IFormData {
  valorrecebido: number;
  valorselecionado: number;
  databaixa: Date;
  valornfe: number;
  tipocaixa: boolean;
}

export interface ICheque{
  idcheque: string;
  banco: string;
  agencia: string;
  conta: string;
  datadeposito: Date;
  titular: string;
  valor: number;
  numero: string;
}

const chequeSchema: yup.ObjectSchema<ICheque> = yup.object().shape({
  idcheque: yup.string().required(),
  banco: yup.string().required(),
  agencia: yup.string().required(),
  conta: yup.string().required(),
  datadeposito: yup.date().required('O campo é obrigatório')
    .test('valid-date', 'Não se Lança Cheque Com Data de Depósito Anterior 5 dias', function (value) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 5);
      if (value < currentDate) return false;
      return true;      
    }),
  titular: yup.string().required().min(5),
  valor: yup.number().required(),
  numero: yup.string().required(),
});

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'tipocaixa'>> = yup.object().shape({
  valorrecebido: yup.number().required().min(0.01).test('valorrecebido', 'O Valor Recebido não pode ser maior que o Valor Selecionado', 
    function (value, context) {const valorrecebido = context.parent.valorselecionado || 0; 
      return value <= valorrecebido && value !== 0;
    }),
  valorselecionado: yup.number().required(), 
  databaixa: yup.date().required(), 
  valornfe: yup.number().optional().default(0), 
});

type RowsType = ICheque[];

export const DialoBaixaCheque: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const { formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [id, setId] = useState('');
  const [rowsCheque, setRowsCheque] = useState<ICheque[]>([]);
  const [banco, setBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [datadeposito, setDatadeposito] = useState<Dayjs | null>(dayjs());
  const [titular, setTitular] = useState('');
  const [valorch, setValorch] = useState('');
  const [numero, setNumero] = useState('');
  const [contaError, setContaError] = useState('');
  const [agenciaError, setAgenciaError] = useState('');
  const [bancoError, setBancoError] = useState('');
  const [datadepositoError, setDatadepositoError] = useState('');
  const [titularError, setTitularError] = useState('');
  const [valorError, setValorError] = useState('');
  const [numeroError, setNumeroError] = useState('');
  const [valorcheque, setValorcheque] = useState('0');
  const [valorrestante, setValorrestante] = useState('');

  useEffect(() => {
    if(props.dados?.length === 1){
      setIsLoading1(false);
    }else{
      setIsLoading1(true);
    }
    if (props.dados && typeof props.dados === 'object') {
      setRowsCheque([]);
      setBanco('');
      setAgencia('');
      setConta('');
      setDatadeposito(dayjs());
      setTitular('');
      setValorch('');
      setNumero('');
      setContaError('');
      setAgenciaError('');
      setBancoError('');
      setDatadepositoError('');
      setTitularError('');
      setValorError('');
      setNumeroError('');
      setValorcheque('0');
      setValorrestante('');
      formRef.current?.setFieldValue('valorselecionado', props.vselecionado); 
      formRef.current?.setFieldValue('valorrecebido', props.vselecionado); 
      formRef.current?.setFieldValue('valorrecebido', props.vselecionado); 
      setValorrestante( Number(props.vselecionado).toLocaleString('pt-BR', { minimumFractionDigits: 2}));
      setValorcheque( Number('0').toLocaleString('pt-BR', { minimumFractionDigits: 2}));
    }
  }, [id, formRef]); 

  const handleSave = (dados: IFormData) => {
    if(Number(formatValueNumber(valorcheque)) != dados.valorrecebido){
      alert('Valor Recebido esta diferente do valor do Cheque');
    }else{
      formValidationSchema.
        validate(dados, { abortEarly: false })
        .then((dadosValidados) => {
          setIsLoading(true);
          if (props.dados && typeof props.dados === 'object') {
            ContasReceberService
              .BaixaCheque(dadosValidados, props.dados, rowsCheque)
              .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  setValue('0');
                  onClose('true');
                }
              });
          }
        })
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};  
          console.log(validationErrors);
          errors.inner.forEach(error => {
            if (!error.path) return;
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        });
    }
  };

  const handleAddCheque = () => {
    if(Number(formatValueNumber(valorch)) > Number(formatValueNumber(valorrestante))){
      setValorError('Valor Maior que o Valor Restante');
    }else{
      const idcheque = uuidv4();
      const valor = formatValueNumber(valorch);
      setIsLoading(true);
      chequeSchema
        .validate({ idcheque, banco, agencia, conta, datadeposito, titular, valor,  numero }, { abortEarly: false })
        .then(dadosValidados => {
          let updatedList: RowsType;
          updatedList = [...rowsCheque, dadosValidados]; 
          setRowsCheque(updatedList);
          const data = formRef.current?.getData();
          const restante= data?.valorrecebido;
          const vcheque = Number(formatValueNumber(valorcheque)) + Number(valor);
          setValorcheque(vcheque.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2}));
          const vresta =  Number(restante) - vcheque;      
          setValorrestante(vresta.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2}));
          setIsLoading(false);
          setValorch('');
          setNumero('');
          setDatadeposito(dayjs());
          setDatadepositoError('');
        }).catch((errors: yup.ValidationError) => {
          setIsLoading(false);
          errors.inner.forEach(error => {
            if (error.path === 'conta') {
              setContaError(error.message);
            } else if (error.path === 'agencia') {
              setAgenciaError(error.message);
            } else if (error.path === 'banco') {
              setBancoError(error.message);
            } else if (error.path === 'titular') {
              setTitularError(error.message);
            }else if (error.path === 'valor') {
              setValorError(error.message);
            }else if (error.path === 'numero') {
              setNumeroError(error.message);
            }else if (error.path === 'datadeposito') {
              setDatadepositoError(error.message);
            }
          });
        });
    }
  };

  const formatInputValue = (inputValue: string) => {
    inputValue = inputValue.replace(/\D/g, '');
    inputValue = inputValue.replace(/(\d)(\d{2})$/, '$1,$2');
    inputValue = inputValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return inputValue;
  };

  const formatValueNumber = (Value: string) => {
    if (Value === undefined || Value === null) {
      return '';  
    }  
    Value = Value.replace(/\D/g, '');
    Value = Value.replace(/(\d)(\d{2})$/, '$1.$2');
    Value = Value.replace(/(?=(\d{3})+(\D))\B/g, '');
    return Value;
  };

  const handlecalcularrestante = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{2})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const vresta = Number(valor) - Number(formatValueNumber(valorcheque));
      setValorrestante(vresta.toLocaleString('pt-BR', { minimumFractionDigits: 2}));
    }
  };  

  const handleCancel = () => {
    setValue('0');
    onClose('false');
  };

  useEffect(() => { 
    if (!open) {
      setValue(valueProp);
    }else{
      setId(valueProp);
    }
  }, [valueProp, open]);

  const handleDelete = (id: string, valor: number) => {
         
    const indexToDelete = rowsCheque.findIndex((item) => item.idcheque === id);
    if (indexToDelete !== -1) {
      const updatedList = [...rowsCheque];
      updatedList.splice(indexToDelete, 1);
      setRowsCheque(updatedList);
      const data = formRef.current?.getData();
      const vrecebido = data?.valorrecebido;
      const vcheque = Number(formatValueNumber(valorcheque)) - valor;
      const vresta =  Number(vrecebido) - vcheque;      
      setValorcheque(vcheque.toLocaleString('pt-BR', { minimumFractionDigits: 2}));
      setValorrestante(vresta.toLocaleString('pt-BR', { minimumFractionDigits: 2}));
    }
  };

  return (
    <Dialog 
      open={open}
      {...other}
      sx={{ width: '100%'  }}
      maxWidth="md"
    >
      <DialogTitle>Baixar Cheque</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef} onSubmit={handleSave}>
          <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
            <Grid container direction="column" padding={1} spacing={1}>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant='indeterminate' />
                </Grid>
              )}
              <Grid container item direction="row"  spacing={2}>  
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <VFieldMoney
                    fullWidth
                    size='small'
                    name='valorselecionado'
                    label='Valor Selecionado'
                    disabled={true}
                  />
                </Grid>  
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <VFieldMoney
                    fullWidth
                    size='small'
                    name='valorrecebido'
                    label='Valor Recebido'
                    disabled={isLoading1}
                    calcular={handlecalcularrestante}
                  />
                </Grid>       
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                  <TextField
                    fullWidth
                    size='small'
                    value={valorcheque}
                    label='Valor Cheques'
                    disabled={true}
                  />
                </Grid>  
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <TextField
                    fullWidth
                    size='small'
                    value={valorrestante}
                    label='Valor Restante'
                    disabled={true}
                  />
                </Grid>  
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                  <VTextFieldData
                    fullWidth
                    size='small'
                    name='databaixa'  
                    label='Data Recebimento'
                    disabled={isLoading}
                  />
                </Grid>  
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Banco'
                    value={banco}
                    disabled={isLoading}
                    error={!!bancoError}
                    helperText={bancoError}
                    onKeyDown={() => setBancoError('')}
                    onChange={(e) => (setBanco(e.target.value))}
                  />   
                </Grid>  
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Conta'
                    value={conta}
                    disabled={isLoading}
                    error={!!contaError}
                    helperText={contaError}
                    onKeyDown={() => setContaError('')}
                    onChange={(e) => (setConta(e.target.value))}
                  />   
                </Grid>  
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Agencia'
                    value={agencia}
                    disabled={isLoading}
                    error={!!agenciaError}
                    helperText={agenciaError}
                    onKeyDown={() => setAgenciaError('')}
                    onChange={(e) => (setAgencia(e.target.value))}
                  />   
                </Grid>       
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Data Deposito'
                      format='DD/MM/YYYY'
                      value={datadeposito}
                      onChange={(newValue) => setDatadeposito(newValue)}
                      onError={() => setDatadepositoError('')}
                      slotProps={{
                        textField: {  
                          size:'small',
                          error:!!datadepositoError,
                          helperText:datadepositoError,
                          onKeyDownCapture : (e) => {setDatadepositoError('');}}
                      }}

                    />
                  </LocalizationProvider>
                </Grid>  
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Nº Cheque'
                    value={numero}
                    disabled={isLoading}
                    error={!!numeroError}
                    helperText={numeroError}
                    onKeyDown={() => setNumeroError('')}
                    onChange={(e) => (setNumero(e.target.value))}
                  />   
                </Grid>       
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Valor'
                    disabled={isLoading}
                    error={!!valorError}
                    helperText={valorError}
                    onKeyDown={() => setValorError('')}
                    value={valorch || ''}
                    onChange={(e) => {
                      const inputValue = formatInputValue(e.target.value);
                      e.target.value = inputValue;
                      setValorch(inputValue);
                    }}
                  />   
                </Grid>  
                <Grid item  xs={10} sm={10} md={7} lg={7} xl={7}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Titular'
                    value={titular}
                    disabled={isLoading}
                    error={!!titularError}
                    helperText={titularError}
                    onKeyDown={() => setTitularError('')}
                    onChange={(e) => (setTitular(e.target.value))}
                  />   
                </Grid> 
                <Grid alignContent='center' item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <IconButton color="primary">
                    <AddCircleIcon  onClick={handleAddCheque} fontSize="large" />
                  </IconButton>
                </Grid>  
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableContainer component={Paper} variant="outlined" sx={{width: 'auto', height:280, maxHeight:280 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: 100 }}>Data Deposito</TableCell>
                          <TableCell>Numero</TableCell>
                          <TableCell>Titular</TableCell>
                          <TableCell style={{ width: 10 }} align='right' >Valor</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsCheque.map(row => (
                          <TableRow key={row.idcheque} 
                            hover
                            sx={{ cursor: 'pointer' }}  
                          >
                            <TableCell align='center'>{dayjs(row.datadeposito).format('DD/MM/YYYY')}</TableCell>
                            <TableCell align='right'>{row.numero}</TableCell>
                            <TableCell align='left'>{row.titular}</TableCell>
                            <TableCell align='right'>{row.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                            <TableCell align='center'  >
                              <IconButton color='error'  size="small" onClick={() => {handleDelete(row.idcheque, row.valor);}}>
                                <Icon>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody> 
                      <TableFooter>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>  
              </Grid> 
            </Grid> 
          </Box>
        </VForm>  
      </DialogContent>
      <DialogActions>
        <Button  variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
};