
export const Environment = {
  
  LIMITE_DE_LINHAS: 8,

  LIMITE_DE_LINHAS1: 7,

  LIMITE_DE_LINHASSMALL: 11,

  LIMITE_DE_CADASTRO: 10,
 
  INPUT_DE_BUSCA: 'Pesquisar...',
 
  LISTAGEM_VAZIA: 'Nenhum registro encontrado.',
 
  URL_BASE: process.env.REACT_APP_URL_BASE,

  URL_BASEFINANCEIRA: process.env.REACT_APP_URL_BASEFINANCEIRA,

  URL_BASEESTOQUE: process.env.REACT_APP_URL_BASEESTOQUE,

  URL_BASECADASTRO: process.env.REACT_APP_URL_BASECADASTRO,

  URL_BASEFISCAL: process.env.REACT_APP_URL_BASEFISCAL,

  URL_IMGFUNCIONARIO: process.env.REACT_APP_URL_IMGFUNCIONARIO,

  URL_IMGPATRIMONIO: process.env.REACT_APP_URL_IMGPATRIMONIO,

  URL_IMGUSUARIOS: process.env.REACT_APP_URL_IMGUSUARIOS,

  URL_IMGCULTIVOS: process.env.REACT_APP_URL_IMGCULTIVOS,

  URL_PDFNFE: process.env.REACT_APP_URL_PDFNFE,

  URL_IMGPRODUTOS: process.env.REACT_APP_URL_IMGPRODUTOS,

  COR_RELATORIOS: '#40481E',

  COR_RELATORIOS_TEXTO: '#000000'

};



