/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, ImageList, ImageListItem, ImageListItemBar, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Typography} from '@mui/material';
import { AutoCompleteCultivo, IVFormErrors, VForm, VSwitch, VTextField, useVForm } from '../../../shared/forms';
import InfoIcon from '@mui/icons-material/Info';
import { Environment } from '../../../shared/environment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PatrimoniosService } from '../../../shared/services/api/cadastros/PatrimoniosService';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';

interface IImage {
  imgPath: string;
  arquivo: File | undefined;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
  dados?: IImage[];
  onDados: (dados: IImage[]) => void;
}

export const DialogImagens: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const [images, setImages] = useState<IImage[]>([]);

  const handleSave = () => {
    ///
  };

  type RowsType = IImage[];

  useEffect(() => {
    if(props.id === 'novo'){
      if (props.dados && typeof props.dados === 'object') {
        if(props.dados.length === 1){
          setImages([]);
        }
      }
    } else {
      if (props.dados && typeof props.dados === 'object') {
        const list =  props.dados.map((item) => ({ imgPath: item.imgPath, arquivo: undefined}));
        setImages(list);
      }
    }
  }, [open]); 

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    props.onDados(images);
    onClose();
  };

  const handleDelete = (value: string) => {
    if (confirm('Realmente deseja apagar a Imagem?')) {
      PatrimoniosService.deleteByIdImg(props.id, value)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            const filteredList = images.filter((item: any) => item.imgPath !== value);
            props.onDados(filteredList);
            onClose();
          }
        });
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.files?.[0]);
    const imagen = event.target.files?.[0];  
    if (imagen) {
      const imageUrl = URL.createObjectURL(imagen);
      const updatedList = [{imgPath: imageUrl, arquivo: imagen}, ...images]; 
      setImages(updatedList);
    }
    if (props.id !== 'novo') {
      PatrimoniosService.updateByIdImg(props.id, imagen)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } 
        });
    }
  };
 
  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Imagens</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} > 
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>          
                    <ImageList sx={{ width: 500, height: 450 }}>
                      {images.map((item) => (
                        <ImageListItem key={item.imgPath}>
                          <img
                            src={item.imgPath}
                          />
                          <ImageListItemBar
                            actionIcon={
                              <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                              >
                                <InfoIcon onClick={() => {handleDelete(item.imgPath); }} />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <ArrowBackIosIcon onClick={handleCancel}   color='primary'  sx={{fontSize: 45}} />
        <Box flex={1} display="flex" justifyContent="end" marginRight={5} >
          <input
            type="file"
            id="img-input"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <label htmlFor="img-input">
            <ImageSearchRoundedIcon   color='secondary'  sx={{ fontSize: 45 }} />
          </label>
        </Box>  
      </DialogActions>
    </Dialog>
    
  );
}; 