import { useEffect, useMemo, useState } from 'react';
import { Avatar, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FerramentasDaListagemCadastros } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import {IListagemColaborador, ColaboradorService} from '../../../shared/services/api/rh/colaborador/ColaboradorService';


export const ListagemDeColaboradores: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemColaborador[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'false'; 
  }, [searchParams]);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  
  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      ColaboradorService.getAll(pagina, busca, tipo)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [busca, pagina, tipo]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Colaboradores'
      barraDeFerramentas={
        <FerramentasDaListagemCadastros
          mostrarInputBusca
          mostrarInputBasicSelect
          textoDaBusca={busca}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/funcionarios/detalhe/novo')}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ busca: busca, tipo: textoselect, pagina: '1' }, { replace: true})}
          dadosSelecao={[
            { nameBD: 'false', descricao: 'Ativo' },
            { nameBD: 'true', descricao: 'Inativo' },
          ]}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell  sx={{ maxWidth: 10, width:10 }} size="small" aria-label="a dense table"></TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Cpf</TableCell>
              <TableCell>Apelido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idfun} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/funcionarios/detalhe/${row.idfun}`)}
              >
                <Avatar sx={{maxWidth: 50, width:60, height:50, marginLeft:2, marginTop:1 }}   alt="F" src={Environment.URL_IMGFUNCIONARIO+row.urlimg}/>
                <TableCell>{row.nome}</TableCell>
                <TableCell>{row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</TableCell>
                <TableCell>{row.apelido}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({busca: busca, tipo: tipo, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};