import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import dayjs from 'dayjs';
import { IListagemSaidas, SaidaService } from '../../../shared/services/api/estoque/saida/SaidaService';
import { FerramentasDaListagemSaida } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemSaida';

export const ListagemDeSaida: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IListagemSaidas[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const [buscaparceiro, setBuscaParceiro] = useState(true);
  const [data, setData] = useState(true);
  const [buscafazenda, setBuscaFazenda] = useState(true);
 
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'data'; 
  }, [searchParams]);

  useEffect(() => {
    if(tipo === 'data'){
      setData(true);
      setBuscaFazenda(false);
      setBuscaParceiro(false);
    }
    else if(tipo === 'parceiro'){
      setBuscaParceiro(true);
      setBuscaFazenda(false);
      setData(false);
    }else{
      setBuscaFazenda(true);
      setBuscaParceiro(false);
      setData(false);
    }
  }, [tipo]);

  const idfazenda = useMemo(() => {
    return searchParams.get('fazenda') || ''; 
  }, [searchParams]);

  const idparceiro = useMemo(() => {
    return searchParams.get('parceiro') || ''; 
  }, [searchParams]);


  const dataInicial = useMemo(() => {
    return searchParams.get('inicial') || dayjs().startOf('month').format('DD/MM/YYYY');
  }, [searchParams]);
 
  const dataFinal = useMemo(() => {
    return searchParams.get('final') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  function verificaFormato(data: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(data);
  }
  useEffect(() => {
    if (tipo === 'data' && verificaFormato(dataInicial) && verificaFormato(dataFinal)) { 
      setIsLoading(true);
      debounce(() => {
        SaidaService.getAll(pagina, dataInicial, dataFinal, tipo, '')
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setRows([]);
    }        
  }, [dataInicial, dataFinal, pagina]);

  useEffect(() => {
    if (tipo === 'fazenda' && idfazenda != '') { 
      setIsLoading(true);
      debounce(() => {
        SaidaService.getAll(pagina, dataInicial, dataFinal, tipo, idfazenda)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setRows([]);
    }        
  }, [idfazenda, pagina]);

  useEffect(() => {
    if (tipo === 'parceiro' && idparceiro != '') { 
      setIsLoading(true);
      debounce(() => {
        SaidaService.getAll(pagina, dataInicial, dataFinal, tipo, idparceiro)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setRows([]);
    }    
  }, [idparceiro]);
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Saídas'
      barraDeFerramentas={
        <FerramentasDaListagemSaida
          mostrarInputFazenda={buscafazenda}
          mostrarInputParceiro={buscaparceiro}
          mostrarInputData={data}
          mostrarInputBasicSelect
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/saidaestoque/detalhe/novo')}
          aoMudarDataInicial={datainicial => setSearchParams({ fazenda: idfazenda, inicial: datainicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ fazenda: idfazenda, inicial: dataInicial, final: dataFinal, tipo: textoselect, pagina: '1' }, { replace: true})}
          aoMudarDataFinal={datafinal => setSearchParams({ fazenda: idfazenda, inicial: dataInicial, final: datafinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarFazenda={idFaz => setSearchParams({ fazenda: idFaz, inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarParceiro={idparceiro => setSearchParams({ parceiro: idparceiro, inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          dadosSelecao={[
            { nameBD: 'data', descricao: 'Data' },
            { nameBD: 'fazenda', descricao: 'Fazenda' },
            { nameBD: 'parceiro', descricao: 'Parceiro' },
          ]}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>{buscaparceiro ? 'Parceiro' : 'Fazenda'}</TableCell>
              <TableCell>Operação</TableCell>
              <TableCell align='right'>Total</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idsaida} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/saidaestoque/detalhe/${row.idsaida}`)}
              >
                <TableCell>{dayjs(row.dataoperacao).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{row.fazenda}</TableCell>
                <TableCell>{row.operacoes}</TableCell>
                <TableCell align='right'>{row.total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ fazenda: idfazenda, inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};