import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../hooks';
import { PlanoDeContasService } from '../services/api/cadastros/PlanoDeContasService';


type TAutoCompleteOption = {
  id: string;
  label: string;
  grupo: string;
}

interface IAutoCompletePlanoDeContasProps {
  isExternalLoading?: boolean;
  uuidplanoconta?: (novoTextoselect: string | undefined) => void;
}
export const AutoCompletePlanoDeContas01: React.FC<IAutoCompletePlanoDeContasProps> = ({ isExternalLoading = false, uuidplanoconta }) => {
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>('');

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');



  useEffect(() => {
    setIsLoading(true); 

    debounce(() => {
      PlanoDeContasService.getAlllist(busca, selectedId)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(planoDeContas => ({ id: planoDeContas.idplanoconta, label: planoDeContas.descricao, grupo: planoDeContas.grupo })));
          }
        });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.id === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);
  
  return (
    <Autocomplete
      fullWidth
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      groupBy={(opcoes) => opcoes.grupo}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.id); uuidplanoconta?.(newValue?.id); setBusca(''); }}
      popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          label="Plano de Contas"
        />
      )}
    />
  );
};