/* eslint-disable @typescript-eslint/no-explicit-any */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
 
interface IAniversariantes {
  nome: string; 
  aniversario: string;
}

async function aniversariantes(dados: IAniversariantes [], mes: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const rows = dados.map((row) => {
    return [
      {text: row.nome, alignment: 'left', fontSize: 14, border: [false, false, false, false]},
      {text: row.aniversario, alignment: 'left',  fontSize: 14, border: [false, false, false, false]},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous';   
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }

  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [15, 10, 10, 10],
    header: 'simple text',
    footer: {
      columns: [
        'Left part',
        { text: 'Right part', alignment: 'right' }
      ]
    },
    content: ([
      {   
        table: {
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],  
                table: {
                  widths: [500],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: '#FFFFFF',  alignment: 'center', fillColor: '#00A47B', border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                  ]
                }
              },
              
            ],
            [
              {text: 'Aniversariantes do Mês de '+mes, colSpan: 2, margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
              {}
            ]
            
          ]
        }
      },
      { margin: [30, 30, 0, 20],
        table:{
          widths: [450, 50],
          heights: 30,
          body: [  
            ...rows,
          ]
        },
      },
      
    ]),
      
  };
  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.open();
}
export default aniversariantes;

