import {  Box, Button, DialogActions, DialogContent, DialogTitle,  Grid,  LinearProgress,  MenuItem,  Paper, Select} from '@mui/material';
import { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AutoCompleteProdutorListagem } from '../../../shared/forms';
import { LCDPRService } from '../../../shared/services/api/fiscal/LCDPR/LCDPRService';
import RelatorioReceita from './RelatorioReceita';
import RelatorioDespesa from './RelatorioDespesa';
import RelatorioRomaneios from './RelatorioRomaneios';
           
export const RelatoriosReceitaDespesa: React.FC = () => {                      
  const [isLoading, setIsLoading] = useState(false);         
  const [uidProdutor, setUidProdutor] = useState(0);    
  const [nomeProdutor, setUidNomeProdutor] = useState('');    
  const [tipo, setTipo] = useState('receita');            
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs()); 
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs());      
                 
  const handleSave = () => {      
    if(tipo === 'receita'){
      LCDPRService                 
        .RelatorioReceita(uidProdutor, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'))   
        .then((result) => {           
          setIsLoading(false);              
          if (result instanceof Error) {                
            alert(result.message);                
          }else {  
            if(result.length > 0){  
              RelatorioReceita(result, nomeProdutor, `Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);  
            }else{
              alert('Não a Registros');   
            } 
          }             
        });        
    }  
    if(tipo === 'despesa'){
      LCDPRService                 
        .RelatorioDespesa(uidProdutor, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'))   
        .then((result) => {           
          setIsLoading(false);              
          if (result instanceof Error) {                
            alert(result.message);                
          }else {  
            if(result.length > 0){  
              RelatorioDespesa(result, nomeProdutor, `Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);  
            }else{
              alert('Não a Registros');   
            }
          }             
        });      
    }   
    if(tipo === 'romaneios'){
      LCDPRService                 
        .RelatorioRoamenios(uidProdutor, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'))   
        .then((result) => {           
          setIsLoading(false);              
          if (result instanceof Error) {                
            alert(result.message);                
          }else {  
            if(result.length > 0){  
              RelatorioRomaneios(result, nomeProdutor, `Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);  
            }else{
              alert('Não a Registros');   
            }
          }             
        });      
    }                                        
  };          

  const handleProdutor = (uidprodutor: number | undefined) => {    
    if (uidprodutor != null) {     
      setUidProdutor(uidprodutor);                  
    }else{                       
      setUidProdutor(0);                         
    }                 
  };  

  const handleNomeProdutor = (nome: string | undefined) => {    
    if (nome != null) {     
      setUidNomeProdutor(nome);                  
    }else{                       
      setUidNomeProdutor('');                         
    }                 
  };  

  return (
    <Box  alignItems="center" width='auto' alignContent='center' height="auto"  display="flex" flexDirection="column"  >
      <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1}  alignContent='center'></Box>
      <Box   width='auto' alignItems="center"  component={Paper} height="auto" margin={1} 
        alignContent='center'>       
        <DialogTitle>Relatório de Receita e Despesa do LCDPR</DialogTitle>
        <DialogContent  dividers>    
          {isLoading && (           
            <Grid item>  
              <LinearProgress variant='indeterminate' />  
            </Grid>                      
          )}   
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>  
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteProdutorListagem
                  uuidprodutor={handleProdutor}   
                  nome={handleNomeProdutor}  
                />                   
              </Grid>   
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={dataInicial}
                    onChange={(newValue) => setDataInicial(newValue)} 
                    label='Data Inicial'
                    slotProps={{
                      textField: {    
                        size:'small',
                      }
                    }}
                  />  
                </LocalizationProvider>
        
              </Grid>     
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={dataFinal}
                    onChange={(newValue) => setDataFinal(newValue)} 
                    label='Data Final'
                    slotProps={{
                      textField: {    
                        size:'small',
                      }
                    }}
                  />
                </LocalizationProvider>   
              </Grid>    
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>        
                <Select         
                  fullWidth
                  size='small'
                  value={tipo}
                  onChange={(event) => setTipo(event.target.value)}
                >   
                  <MenuItem value={'receita'}>Receita</MenuItem>    
                  <MenuItem value={'despesa'}>Despesa</MenuItem>
                  <MenuItem value={'romaneios'}>Romaneios Abertos</MenuItem>
                </Select>           
              </Grid>                     
            </Grid>              
          </Grid>            
        </DialogContent>                 
        <DialogActions>
          <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={handleSave}>Visualizar</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   