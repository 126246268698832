/* eslint-disable @typescript-eslint/no-unused-vars */
import {   useEffect, useMemo, useState } from 'react';
import { 
  LinearProgress, Pagination, Paper, Icon, IconButton,  Theme, Table, TableBody, useMediaQuery, 
  TableCell, TableContainer, TableFooter, TableHead, TableRow, useTheme, Typography, Checkbox,  
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField} from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/system';

import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IPermissao, UsuarioService } from '../../../shared/services/api/cadastros/UsuariosService';
import { useDrawerContext } from '../../../shared/contexts';
import { VForm, useVForm,} from '../../../shared/forms';
import NavTabsUsuario from './NavTabsUsuario';


export const ListagemPermissao: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
 
  const { id = '', nome = ''} = useParams();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();
  const { debounce } = useDebounce();
  const [rows, setRows] = useState<IPermissao[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const[filter, setfilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const { toggleDrawerOpen } = useDrawerContext();
  const [modulo, setModulo] = useState('cadastro');
 
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  const handleChangeBasic = (event: SelectChangeEvent) => {
    setModulo(event.target.value as string);
    setfilter('');
    UsuarioService.getAllPermissao(id, pagina, event.target.value, '')
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      UsuarioService.getAllPermissao(id, pagina, modulo, filter)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [pagina, filter]);

  function handleSave(id: string, permissao: boolean) {
    UsuarioService
      .updateByIdPermissao(id, permissao)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setIsLoading(false);
        }
      });
  }  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].id === e.target.id) {
        rows[i].permissao = e.target.checked;
        setSearchParams({pagina: pagina.toString() }, { replace: true });
        handleSave(e.target.id, e.target.checked);
        return;
      }
    }
  };

  return (
   
    <Box height="100%" display="flex" flexDirection="column" >

      <Box padding={1} display="flex" alignItems="center" gap={1} height={theme.spacing(smDown ? 6 : mdDown ? 8 : 10)}>
        {smDown && (
          <IconButton onClick={toggleDrawerOpen}>
            <Icon>menu</Icon>
          </IconButton>
        )}

        <Typography
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipses"
          variant={smDown ? 'h5' : mdDown ? 'h4' : 'h3'}
        >
          {'Usuário: '+nome}
        </Typography> 
         
      </Box>
      <Box component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Grid container item direction="row" spacing={1}>  
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Select
              fullWidth
              value={modulo}
              onChange={handleChangeBasic}
            >
              <MenuItem value={'cadastro'}>Cadastros</MenuItem>
              <MenuItem value={'rh'}>Recursos Humanos</MenuItem>
              <MenuItem value={'estoque'}>Estoque</MenuItem>
              <MenuItem value={'faturamento'}>Faturamento</MenuItem>
              <MenuItem value={'financeiro'}>Financeiro</MenuItem>
              <MenuItem value={'fiscal'}>Fiscal</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <TextField
              value={filter}
              placeholder={Environment.INPUT_DE_BUSCA}
              onChange={(e) => setfilter(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>

      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <NavTabsUsuario
          id={id}
          nome={nome}
          case={1}                
        />
        <Table>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Url</TableCell>
                <TableCell>Metodo</TableCell>
                <TableCell>Permissão</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id} 
                  hover
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{row.descricao}</TableCell>
                  <TableCell>{row.url}</TableCell>
                  <TableCell>{row.metodo}</TableCell>
                  <TableCell>
                    <Checkbox
                      id={row.id}
                      checked={row.permissao} 
                      onChange={handleChange}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
  
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
  
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > 10) && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / 10)}
                      onChange={(_, newPage) => setSearchParams({pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
    </Box>  
 
  );
};  