/* eslint-disable @typescript-eslint/no-explicit-any */

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

async function CartaoDePonto(nome: string, id: string, urlimg: string) {

  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl = process.env.REACT_APP_URL_IMGLOG || '';
  const imageData = await getBase64ImageFromUrl(imageUrl);

  const imageUrlFun = process.env.REACT_APP_URL_IMGFUNCIONARIO || '';
  const imageDataFun = await getBase64ImageFromUrl(imageUrlFun + urlimg);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }

  const documentDefinition: any = {
    pageSize: {
      width: 160,
      height: 250
    },
    pageOrientation: 'portrait',
    pageMargins: [0, 0, 0, 0],

    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [150],

          body: [
            [
              {
                image: imageData,
                width: 90,
                height: 90,
                border: [false, false, false, false],
                margin: [30, 0, 30, 0],
              },
            ],
            [
              {
                image: imageDataFun,
                width: 70,
                height: 90,
                border: [false, false, false, false],
                margin: [40, 0, 40, 23],
                // [left, top, right, bottom]
              },
            ],
            [
              {
                text: nome.trim().split(' ')[0],
                bold: true,
                alignment: 'center',
                fontSize: 14,
                margin: [0, 0, 0, 15],
                border: [false, false, false, false],
              },

            ],


          ],
          pageBreakBefore: true,
        }
      },
    ],
  };


  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default CartaoDePonto;

