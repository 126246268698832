/* eslint-disable @typescript-eslint/no-explicit-any */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface Item {
  iditens: string;
  descricao: string;
  validade: Date;
  referencia: string;
  localizacao: string;
  qtdsaida: number;
  preco: number;
  total: number;
}

interface Saida {
  idsaida: string;
  dataoperacao: string;
  cultivo: string;
  fazenda: string;
  parceiro: string;
  total: number; 
}

interface Entry {
  saida: Saida;
  itens: Item[];
}


async function ROperacoesVendaResumido(data: Entry[], operacao: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const TotalGeral = data.reduce((acc, item) => acc + item.saida.total, 0);
  const rows = data.map((row) => {
    return [
      {text: moment(row.saida.dataoperacao).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 9},
      {text: row.saida.parceiro, alignment: 'left',  fontSize: 9},
      {text: row.saida.fazenda, alignment: 'left',  fontSize: 9},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.saida.total), alignment: 'right',  fontSize: 9},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);
 
  const documentDefinition = {
 
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 540],
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Operações', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {text: 'Operação: '+operacao, border: [false, false, false, false]},
      {
        table:{
          widths: [ 50, 230, 130, 60],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [
              {text: 'Data', alignment: 'center', fillColor: '#dddddd', fontSize: 10},
              {text: 'Parceiro', alignment: 'left', fillColor: '#dddddd',  fontSize: 10},
              {text: 'Fazenda', alignment: 'left', fillColor: '#dddddd',  fontSize: 10},
              {text: 'Valor', alignment: 'right', fillColor: '#dddddd', fontSize: 10},
            ],
            ...rows,
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              { text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalGeral) , fontSize: 10, alignment: 'right'},
            ],
          ]

        },

      },
      
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition); 

  pdfDocGenerator.open();

}
export default ROperacoesVendaResumido;

