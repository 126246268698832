
import { useEffect, useState } from 'react';
import { LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate} from 'react-router-dom';
import { FerramentasDaListagem } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import {IListagemDependente, DependenteService} from '../../../shared/services/api/rh/colaborador/DependenteService';
import { Environment } from '../../../shared/environment';

import { useLocation } from 'react-router-dom';
import NavTabs from './NavTabs';
import dayjs from 'dayjs';


export const ListagemDependentes: React.FC = () => {

  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemDependente[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const location = useLocation();
  const idfun = new URLSearchParams(location.search).get('idfun');
  
  
  
  useEffect(() => {
    setIsLoading(true);

    if(idfun !== null){
      DependenteService.getAll(idfun)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          } else {
            
            setRows(result);
          }
        });
    }
  }, [idfun]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Dependentes'
      barraDeFerramentas={
        <FerramentasDaListagem
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate(`/dependentes/detalhe/novo/${idfun}`)}
        />
      }
    >
     
        
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <NavTabs
          id={idfun!}  
          case={1}
        />
        
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Data Nascimento</TableCell>
              <TableCell>Atualização Doc.</TableCell>
              <TableCell>Tipo Dependente</TableCell>
              <TableCell>Tipo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idfun} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/dependentes/detalhe/${row.iddependente}/${row.idfun}`)}
              >
                <TableCell>{row.nome}</TableCell>
                <TableCell>{dayjs(row.nascimento).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{dayjs(row.datadoc).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{row.tipodependente}</TableCell>
                <TableCell>{row.tipo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {rows.length === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};