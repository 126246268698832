/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Icon,  IconButton,  MenuItem,  Paper, Select, TextField, Typography, useTheme } from '@mui/material';
import { Environment } from '../../environment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

interface IFerramentasDaListagemProps {
  textoDaBusca?: string;
  textoDoSelect?: string;
  mostrarInputBusca?: boolean;
  mostrarInputData?: boolean;
  disbilitarBaixar?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  mostrarInputBasicSelect?: boolean
  aoClicarEmBaixa?: () => void;
  aoMudarTextoDoSelect?: (novoTextoselect: string) => void;
  aoClicarEmPesquisa?: (datainicial: Dayjs | null, datafinal: Dayjs | null) => void;
  aoClicarEmImpressao?: () => void;
}
export const FerramentasDaListagemFinanceiroCheque: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  textoDoSelect = '',
  mostrarInputBusca = false,
  mostrarInputData = true,
  aoMudarTextoDeBusca,
  aoMudarTextoDoSelect,
  aoClicarEmBaixa,
  disbilitarBaixar,
  aoClicarEmPesquisa,
  aoClicarEmImpressao,
}) => {
  const theme = useTheme();
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs()); 
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 

  const handlePesquisa = () => {
    aoClicarEmPesquisa?.(dataInicial, dataFinal);
  };

  return (
    <Box
      gap={1}
      marginX={2}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      //height={theme.spacing(6)}
      component={Paper}
    >
      <Grid container item direction="row" spacing={0.5}>  
        <Grid item xs={12} sm={4} md={5} lg={3} xl={2}>  
          <Select
            fullWidth
            size='small'
            value={textoDoSelect}
            onChange={(event) => aoMudarTextoDoSelect?.(event.target.value)}
          >
            <MenuItem value={'numerodoc'}>Numero</MenuItem>
            <MenuItem value={'data'}>Data p/ Deposito</MenuItem>
          </Select>
        </Grid>  

        {mostrarInputData && (
          <Grid item xs={5} sm={5.5} md={2.5} lg={2} xl={1.5}>  
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format='DD/MM/YYYY'
                value={dataInicial}  
                onChange={(newValue) => setDataInicial(newValue)}
                slotProps={{
                  textField: {    
                    size:'small',
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>   
        )}     
        {mostrarInputData && (
          <Grid item xs={5} sm={5.5} md={2.5} lg={2} xl={1.5}>   
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format='DD/MM/YYYY'
                value={dataFinal}
                onChange={(newValue) => setDataFinal(newValue)} 
                slotProps={{
                  textField: {    
                    size:'small',
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>   

        )}  

        {mostrarInputData && (
          <Grid item xs={1} sm={1} md={2} lg={1.5} xl={2}>  
            <IconButton color='primary'>
              <SearchIcon sx={{ fontSize: 30 }} onClick={() => {handlePesquisa();}} />
            </IconButton>
          </Grid>   
        )}   
            
        {mostrarInputBusca && (
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>   
            <TextField
              value={textoDaBusca}
              size='small'
              placeholder={Environment.INPUT_DE_BUSCA}
              onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
            />
          </Grid>   
        )} 
        <Grid item  xs={6} sm={65} md={2.5} lg={1.5} xl={1}>  
          <Button
            fullWidth
            size="small"
            color='primary'
            variant='contained'
            disableElevation
            onClick={aoClicarEmImpressao}
            startIcon={<Icon>printoutlined</Icon>}
          >
            <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  Imprimir
            </Typography>
          </Button>
        </Grid> 
        <Grid  item xs={6} sm={6} md={2.5} lg={1.5} xl={1}>  
          <Button 
            fullWidth
            size="small"
            color='primary'
            variant='contained'
            disabled={disbilitarBaixar}
            disableElevation
            onClick={aoClicarEmBaixa}
            startIcon={<Icon>paid</Icon>}
          >
            <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  Baixar
            </Typography>
          </Button>
        </Grid>
 
      </Grid>  
    </Box>
  );
};