import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,  Grid,  LinearProgress, Paper } from '@mui/material';
import { IVFormErrors, VForm,  VTextFieldData, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { CaixaConstasService } from '../../../shared/services/api/Financeiro/Caixa';

export interface ConfirmationDialogRawProps {
    open: boolean;
    idconta: number;
    onClose: (value?: string) => void;
}

interface IFormData {
  datafechar: Date;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  datafechar: yup.date().required(), 
});


export const DialoFecharCaixa: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose,  open, ...other } = props;
  const { formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        CaixaConstasService
          .FecharCaixa({conta: props.idconta, ...dadosValidados})
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              onClose('true');
            }
          });
        
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};  
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    onClose('false');
  };

  return (
    <Dialog 
      open={open}
      {...other}

    >
      <DialogTitle>Fechar Conta</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef} onSubmit={handleSave}>
          <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
            <Grid container direction="column" padding={1} spacing={1}>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant='indeterminate' />
                </Grid>
              )}
              <Grid container item direction="row"  spacing={2}>  
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <VTextFieldData
                    fullWidth
                    name='datafechar'  
                    label='Data Fechar'
                    disabled={isLoading}
                  />
                </Grid>       
              </Grid> 
            </Grid> 
          </Box>
        </VForm>  
      </DialogContent>
      <DialogActions>
        <Button  variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
};