/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,  Grid,  LinearProgress, Paper } from '@mui/material';
import {  AutoCompleteContaBancaria, IVFormErrors, VFieldMoney, VForm,  VTextFieldData,  useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { ValesFinanceiroConstasService } from '../../../shared/services/api/Financeiro/Vale';

export interface ConfirmationDialogRawProps {
    value: string;
    id: string;
    open: boolean;
    ValorDoVale: number;
    Historico: string;
    onClose: (value?: string) => void;
  }

interface IFormData {
  idoperacao: string;
  idconta: number;
  databaixa: Date;
  historico: string;
  totalvale: number;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idoperacao: yup.string().required(),
  idconta: yup.number().required(),
  databaixa: yup.date().required(),
  historico: yup.string().required(),
  totalvale: yup.number().required(),
});

export const DialogConfirmarVale: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const { formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    formRef.current?.setFieldValue('totalvale', props.ValorDoVale); 
  }, [id, formRef]); 


  const handleSave = (dados: IFormData) => {
    dados.historico = props.Historico;
    dados.idoperacao = props.id;
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        ValesFinanceiroConstasService
          .Confirmar(dadosValidados)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setValue('0');
              onClose('true');
            }
          });

      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};  

        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    setValue('0');
    onClose('false');
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }else{
      setId(valueProp);
    }
  }, [valueProp, open]);

  return (
    <Dialog 
      open={open}
      {...other}

    >
      <DialogTitle>Confirmar Vale</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef} onSubmit={handleSave}>
          <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
            <Grid container direction="column" padding={1} spacing={1}>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant='indeterminate' />
                </Grid>
              )}
              <Grid container item direction="row"  spacing={2}>  
                <Grid item  xs={12} sm={12} md={12} lg={2} xl={12}>
                  <AutoCompleteContaBancaria
                    name='idconta'
                    label='Conta Bancaria'
                    isExternalLoading={isLoading}
                  />   
                </Grid>  
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <VTextFieldData
                    fullWidth
                    name='databaixa'  
                    label='Data Pagamento'
                    disabled={isLoading}
                  />
                </Grid>       
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <VFieldMoney
                    fullWidth
                    name='totalvale'
                    label='Valor'
                    disabled={true}
                  />
                </Grid>      
              </Grid> 
            </Grid> 
          </Box>
        </VForm>  
      </DialogContent>
      <DialogActions>
        <Button  variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
};