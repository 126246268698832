import { Environment } from '../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apicadastro } from '../axioscadastro';

export interface IListagemFrutas {
  idfruta: string;
  cultivo: string;
  descricao: string;
  obs: string;
  status: boolean;
}

export interface IDetalheFruta {
  idcultivo: string;
  descricao: string;
  obs: string;
  status: boolean;
  producao: boolean;
}

type TComTotalCount = {
  data: IListagemFrutas[];
  totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/frutas?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllist = async (id = '', filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa: string = id === '' ?  `/frutaslist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/frutaslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheFruta | Error> => {
  try {
    const { data } = await Apicadastro.get(`/frutas/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheFruta): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalheFruta>('/frutas',  {idfruta: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheFruta): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/frutas/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/frutas/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const FrutaService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
};