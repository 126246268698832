import React, { useEffect, useState } from 'react';
import { InputAdornment, IconButton, TextField, TextFieldProps } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useField } from '@unform/core';

type TVTextFieldProps = TextFieldProps & {
  name: string;
  disabled?: boolean
};

export const VFieldMoneyHidden: React.FC<TVTextFieldProps> = ({ disabled, name, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const [value, setValue] = useState(defaultValue || '');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [tipo, setTipo] = useState('password');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => formatValueNumber(value),
      setValue: (_, newValue) => setValue(formatValueString(newValue)),
    });
  }, [registerField, fieldName, value]);

  const formatValueString = (Value: string) => {
    return Number(Value).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  };

  const formatValueNumber = (Value: string) => {
    if (Value === undefined || Value === null) {
      return '';
    }
    Value = Value.replace(/\D/g, '');
    Value = Value.replace(/(\d)(\d{2})$/, '$1.$2');
    Value = Value.replace(/(?=(\d{3})+(\D))\B/g, '');
    return Value;
  };

  const formatInputValue = (inputValue: string) => {
    inputValue = inputValue.replace(/\D/g, '');
    inputValue = inputValue.replace(/(\d)(\d{2})$/, '$1,$2');
    inputValue = inputValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return inputValue;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    if(showPassword === true){
      setTipo('password'); 
    }else{
      setTipo('text');
    }
  };

  return (
    <TextField
      {...rest}
      disabled={disabled}
      type={tipo}
      error={!!error}
      helperText={error}
      defaultValue={defaultValue}
      value={value}
      onChange={(e) => {
        const inputValue = formatInputValue(e.target.value);
        e.target.value = inputValue;
        setValue(inputValue);
        rest.onChange?.(e);
      }}
      onKeyDown={(e) => {
        if (error) {
          clearError();
        }
        rest.onKeyDown?.(e);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" disablePointerEvents={disabled}>
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
