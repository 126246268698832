/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Icon, IconButton, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';


import { FerramentasDaListagemNfe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemNfe, NfeService } from '../../../shared/services/api/fiscal/nfe/NfeService';
import dayjs from 'dayjs';

export const ListagemDeNfe: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemNfe[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [istexto, setIsTexto] = useState(false);
  const [isdate, setIsDate] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const basicselect = useMemo(() => {
    return searchParams.get('basicselect') || 'n.dataemissao'; 
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  useEffect(() => {
    if(basicselect === 'n.dataemissao'){
      setIsTexto(false);
      setIsDate(true);
    }else{
      setIsTexto(true);
      setIsDate(false);
    }
  }, [basicselect]);
  
  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      NfeService.getAll(pagina, busca, basicselect)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [busca, pagina, basicselect]);

  function cpf(v: string){
    v=v.replace(/\D/g,'');                    
    v=v.replace(/(\d{3})(\d)/,'$1.$2');       
    v=v.replace(/(\d{3})(\d)/,'$1.$2');       
    v=v.replace(/(\d{3})(\d{1,2})$/,'$1-$2'); 
    return v;
  }

 
  return (
    <LayoutBaseDePagina
      titulo='Listagem de NFE'
      barraDeFerramentas={
        <FerramentasDaListagemNfe
          mostrarInputBusca={istexto}
          mostrarInputData={isdate}
          mostrarInputBasicSelect
          textoDaBusca={busca}
          textoDoSelect={basicselect}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/nfe/detalhe/novo')}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, basicselect: basicselect, pagina: '1' }, { replace: true })}
          aoMudarData={novaData => {
            const formattedDate = novaData ? dayjs(novaData).format('DD/MM/YYYY') : '';
            setSearchParams({ busca: formattedDate, basicselect: basicselect, pagina: '1' }, { replace: true });
          }}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ busca: '', basicselect: textoselect, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center' >Data Emissão</TableCell>
              <TableCell align='right' >Nº NFE</TableCell>
              <TableCell align='left' >Produtor</TableCell>
              <TableCell align='left'>Parceiros</TableCell>
              <TableCell align='left'>CPF / CNPJ</TableCell>
              <TableCell align='right' >Valor</TableCell>
              <TableCell align='right' >status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idnfe} 
                
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/nfe/detalhe/${row.idnfe}`)}
              >
                <TableCell align='center'>{dayjs(row.dataemissao).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='right'>{row.nfe}</TableCell>
                <TableCell align='left'>{row.produtor}</TableCell>
                <TableCell align='left'>{row.parceiros}</TableCell>
                <TableCell align='left'>{row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</TableCell>
                <TableCell align='right'>{row.vnfe.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                <TableCell align='right'><IconButton color={row.status === 1 ? 'primary' : (row.status === 2 ? 'info' : 'error')} size="small">
                  <Icon >circle</Icon>
                </IconButton></TableCell>
              </TableRow>
            ))}
          </TableBody> 
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ busca, basicselect: basicselect, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  ); 
};