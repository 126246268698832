import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
 
export interface ITipoPgto {
  nome: string;
  tipoconta: string;
  agencia: string;
  conta: string;
  total: number;
}

async function ImprTipoPagamento(dados: ITipoPgto[], periodo: string, banco: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const Total = dados.reduce((acc, item) => acc + item.total, 0);
  const Quantidade = dados.length;

  const rows = dados.map((row) => {
    return [
      {text: row.nome, alignment: 'left', fontSize: 10},
      {text: row.tipoconta, alignment: 'left', fontSize: 10},
      {text: row.agencia, alignment: 'right', fontSize: 10},
      {text: row.conta, alignment: 'right', fontSize: 10},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.total), alignment: 'right',  fontSize: 10},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }
  
  const documentDefinition = {

    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 540],
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: '#FFFFFF',  alignment: 'center', fillColor: '#00A47B', border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório Tipo de Pagamento', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {
        table:{
          margin: [5, 10, 5, 10],
          heights:[10],
          widths: [530],
          body: [
            [
              {text: 'Período: '+periodo+' do Banco: '+banco, border: [false, false, false, false]},
            ],
          ]
        },
      },
      {
        table:{
          widths: [ 200, 80, 40, 80, 70],
          body: [
            [
              {text: 'Colaborador', alignment: 'left', fillColor: '#dddddd', fontSize: 10},
              {text: 'Tipo Conta', alignment: 'center', fillColor: '#dddddd',  fontSize: 10},
              {text: 'Agencia', alignment: 'center', fillColor: '#dddddd', fontSize: 10},
              {text: 'Nº Conta', alignment: 'center', fillColor: '#dddddd',  fontSize: 10},
              {text: 'Valor', alignment: 'right', fillColor: '#dddddd', fontSize: 10},
            ],
            ...rows,
            [
              {text: 'Quantidade de Colaboradores: '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 0}).format(Quantidade), alignment: 'left',  fontSize: 10, bold: true, border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: 'Valor Total', alignment: 'right', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Total), alignment: 'right',  fillColor: '#dddddd', fontSize: 10, bold: true},
            ]
          ]
          
        },
      },
      
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default ImprTipoPagamento;

