import { v4 as uuidv4 } from 'uuid';
import { Apiestoque } from '../../axiosestoque';
import { Environment } from '../../../../environment';


export interface IListagemGrupoProdutos {
  idgrupo: string;
  descricao: string;
  status: boolean;
}

export interface IDetalheGrupoProduto {
  descricao: string;
  status: boolean;
}

type TComTotalCount = {
    data: IListagemGrupoProdutos[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/grupos?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&filter=${filter}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllist = async (filter = '', id = ''): Promise<TComTotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/gruposlist?limit=${Environment.LIMITE_DE_LINHAS}&tipo=nome&filter=${filter}` : `/gruposlist/${id}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheGrupoProduto | Error> => {
  try {
    const { data } = await Apiestoque.get(`/grupos/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheGrupoProduto): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apiestoque.post<IDetalheGrupoProduto>('/grupos',  {idgrupo: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheGrupoProduto): Promise<void | Error> => {
  try {
    await Apiestoque.put(`/grupos/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/grupos/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const GrupoProdutoService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
};