import axios, { AxiosError } from 'axios';


export const errorInterceptor = (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'));
  }

  switch (error.response?.status) {
  case 401:
    localStorage.removeItem('APP_ACCESS_TOKEN');
    axios.defaults.headers.authorization = null;
    window.location.href = '/login'; // Ou recarrega a página, se preferir
    break;
  case 402:
    // Tratamento específico para erro 402
    break;
  case 400:
    return Promise.reject(error.response);
    // Você pode adicionar mais casos conforme necessário
  }

  return Promise.reject(error);
};
