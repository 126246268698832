import {  useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as yup from 'yup';
import { AuxilioService } from '../../../shared/services/api/rh/Auxilio/AuxilioService';

export interface ConfirmationDialogRawProps {
  id: string;
  idfun: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

const AuxilioSchema = yup.object().shape({
  datainicial: yup.date().required().test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();
    if (!value || (value.getMonth() < currentDate.getMonth() && value.getFullYear() <= currentDate.getFullYear())) {
      return false;
    }
    return true;
  }),
  datafinal: yup.date().required().test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();
    if (!value || (value.getMonth() < currentDate.getMonth() && value.getFullYear() <= currentDate.getFullYear())) {
      return false;
    }
    return true;
  }),
  obs: yup.string().optional().default(''),
}); 

export const DialogAuxilioAdiconar: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
 
  const { onClose, open, ...other } = props;
  const [datainicial, setDataInicial] = useState<Dayjs | null>(dayjs()); 
  const [datafinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 
  const [isLoading, setIsLoading] = useState(false);
  const [inicialError, setInicialError] = useState('');
  const [finalError, setFinalError] = useState('');
  const [obs, setObs] = useState('');
 
  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    AuxilioSchema.
      validate({ datainicial, datafinal, obs }, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        AuxilioService.create({idfun: props.idfun, ...dadosValidados})
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              onClose();
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'datainicial') {
            setInicialError(error.message);
          } else if (error.path === 'datafinal') {
            setFinalError(error.message);
          }
        });
      });
  };
  return (
    <Dialog
      open={open}
      {...other}      
    >
      <DialogTitle>Adicionar Auxílio</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" padding={3} gap={2} component={Paper} variant="outlined">
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <LocalizationProvider  dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={datainicial}
                    disabled={isLoading}
                    label='Data Saida'
                    onChange={(newValue) => setDataInicial(newValue)} 
                    slotProps={{
                      textField: {    
                        error:!!inicialError,
                        helperText:inicialError,
                        size:'small',
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <LocalizationProvider  dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    format='DD/MM/YYYY'
                    value={datafinal}
                    disabled={isLoading}
                    label='Data Retorno'
                    onChange={(newValue) => setDataFinal(newValue)} 
                    slotProps={{
                      textField: {    
                        error:!!finalError,
                        helperText:finalError,
                        size:'small',
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  value={obs}
                  disabled={isLoading}
                  label='Observações'
                  multiline
                  rows={4}
                  onChange={e => { setObs(e.target.value);}}
                />
              </Grid>
            </Grid>
          </Grid> 
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size='small' sx={{ minWidth:'30%'  }} variant='outlined' onClick={handleCancel}>Voltar</Button>      
        <Button size='small' sx={{ minWidth:'30%'  }} variant='contained' onClick={handleSubmit}>Adicionar</Button>     
      </DialogActions>
    </Dialog>
  );
}; 