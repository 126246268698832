/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
 
interface ItemEnt {
  iditens: string;
  descricao: string;
  un: string;
  conversor: number;
  validade: Date;
  referencia: string;
  localizacao: string;
  qtdentrada: number;
  preco: number;  
  total: number;
}

interface entrada {
  identrada: string;
	dataemissao: string;
	numerodoc: string;
	numeronota: string;
	nome: string;
	cpf: string;
	subtotal: number;
	desconto: number;
	acrescimo: number;
	totalnota: number;
	planoconta: string;
	operacao: string;
}

interface Entry {
  entrada: entrada;
  itens: ItemEnt[];
}

async function ROperacoesEntradaDetalhes(data: Entry[], operacao: string){
  const TotalGeral = data.reduce((acc, item) => acc + item.entrada.totalnota, 0);

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 50, 20, 10],
    pageOrientation: 'portrait',
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],  
          widths: [48, 500],  
          body: [
            [ 
              {
                image: imageData,   
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Operações', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      { text: 'Tipo de Operação: '+operacao,  alignment: 'left', fillColor: '#dddddd', fontSize: 14},     
      data.map(e => {
        const { entrada, itens } = e;
        const rows = itens.map((row) => {
          return [
            {text: row.descricao, alignment: 'left',  fontSize: 7},
            {text: row.referencia, alignment: 'left',  fontSize: 7},
            {text: row.localizacao, alignment: 'left',  fontSize: 7},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.qtdentrada) , fontSize: 7, alignment: 'right'},
            {text: row.un, alignment: 'center',  fontSize: 7},
            {text: row.conversor, alignment: 'center',  fontSize: 7},
            {text: (row.validade != null ? (moment(row.validade).format('DD/MM/YYYY')):('')), alignment: 'left',  fontSize: 7},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.preco) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.total) , fontSize: 7, alignment: 'right'},
          ]; 

        });  
        return [
          [             
            [                            
              {  
                margin: [0, 5, 0, 0],    
                table: { 
                  widths: [60, 60, 203, 60, 60, 60], 
                  body: [
                    [
                      {text: 'Emissão \n '+ moment(e.entrada.dataemissao).format('DD/MM/YYYY'), bold: true, fontSize: 9, alignment: 'left', border: [true, true, false, false]},
                      {text: 'Nº Documento\n'+e.entrada.numerodoc, fontSize: 9, alignment: 'left',  border: [false, true, false, false] },
                      {text: 'Parceiro \n'+ e.entrada.nome+' - '+(e.entrada.cpf.length == 11 ? (
                        'CPF: '+e.entrada.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                      ) : (
                        'CNPJ: '+e.entrada.cpf.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                      )), alignment: 'left', colSpan: 4, fontSize: 9, border: [false, true, true, false]}, 
                      {text: '', border: [true, false, false, false]},
                      {text: '', border: [true, false, false, false]},
                      {text: '', border: [true, false, false, false]},
                    ],
                    [
                      {text: '', border: [true, false, false, false]},
                      {text: '', border: [false, false, false, false]},
                      {text: 'Valor Itens: \n  ' + new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.entrada.subtotal) , fontSize: 9, bold: true, alignment: 'right', border: [false, false, false, false]},
                      {text: 'Desconto: \n '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.entrada.desconto) , fontSize: 9, bold: true, alignment: 'right', border: [false, false, false, false]},
                      {text: 'Acréscimo: \n '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.entrada.acrescimo) , fontSize: 9, bold: true, alignment: 'right', border: [false, false, false, false]},
                      {text: 'Valor Total \n '+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(e.entrada.totalnota) , fontSize: 9, bold: true, alignment: 'right', border: [false, false, true, false]},
                    ],
                  ] 
                },  
              },
              {
                table: {
                  widths: [169, 60, 70, 43, 14, 15, 35, 35, 35],
                  body: [
                    [
                      { text: 'Descrição',  alignment: 'left', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Referência',  alignment: 'left', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Localização',  alignment: 'left', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Quantidade',  alignment: 'left', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'UN',  alignment: 'center', fillColor: '#dddddd', fontSize: 8},
                      { text: 'Con',  alignment: 'center', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Validade',  alignment: 'center', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Preço',  alignment: 'right', fillColor: '#dddddd', fontSize: 8}, 
                      { text: 'Total',  alignment: 'right', fillColor: '#dddddd', fontSize: 8}
                    ],
                    ...rows,
                  ]
                },       
              }                                   
            ]
          ]                           
            
        ];
      }     
      ) ,
      { margin: [0, 5, 0, 0],  text: 'Total Geral: '+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TotalGeral) , fontSize: 10, alignment: 'right'}
    ]
  };
  pdfMake.createPdf(documentDefinition).open();

}

export default ROperacoesEntradaDetalhes;