import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { FerramentasDaListagem } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import NavTabsTransportadora from './NavTabsTransportadora';
import { IListagemReboque, ReboqueService } from '../../../shared/services/api/cadastros/ReboqueService';


export const ListagemDeReboques: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemReboque[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  const location = useLocation();
  const idtransportadora = new URLSearchParams(location.search).get('idtransportadora');
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  //Constantes para conversão de números em palavers com base no arrey de seleção
  const tiposDeCarroceria = [
    { nameBD: 0, descricao: 'Não aplicável' },
    { nameBD: 1, descricao: 'Aberta' },
    { nameBD: 2, descricao: 'Fechada' },
    { nameBD: 3, descricao: 'Graneleira' },
    { nameBD: 4, descricao: 'Porta Contêiner' },
    { nameBD: 5, descricao: 'Sider' },
  ];
  const obterDescricaoTipoCarroceria = (numero: number): string => {
    const tipoCarroceria = tiposDeCarroceria.find((tipo) => tipo.nameBD === numero);
    return tipoCarroceria ? tipoCarroceria.descricao : numero.toString();
  };

  
  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      ReboqueService.getAll(idtransportadora!, pagina, busca)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [idtransportadora, busca, pagina]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Semirreboques'
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca={false}
          textoDaBusca={busca}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate(`/reboques/detalhe/novo/${idtransportadora}`)}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <NavTabsTransportadora
          id={idtransportadora!}  
          case={2}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Placa</TableCell>
              <TableCell>UF</TableCell>
              <TableCell>Renavam</TableCell>
              <TableCell>Tara</TableCell>
              <TableCell>Capacidade Kg</TableCell>
              <TableCell>Capacidade M³</TableCell>
              <TableCell>Tipo Carroceria</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idtransportadora} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/reboques/detalhe/${row.idreboque}/${idtransportadora}`)}
              >
                <TableCell>{row.placa}</TableCell>
                <TableCell>{row.uf}</TableCell>
                <TableCell>{row.renavam}</TableCell>
                <TableCell>{row.tara}</TableCell>
                <TableCell>{row.capkg}</TableCell>
                <TableCell>{row.capm3}</TableCell>
                <TableCell>{obterDescricaoTipoCarroceria(row.tipocarroceria)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};