/* eslint-disable @typescript-eslint/no-unused-vars */
import { Apiestoque } from '../../axiosestoque';
import { v4 as uuidv4 } from 'uuid';

type NullableDate = Date | null | undefined;

export interface IListagemEntradas {
  identrada: string;
  dataemissao: Date;
  numerodoc: string;
  parceiros: string; 
  cpf: string;
  descricao: string;
  totalnota: number;
}

export interface IitensEntradaDialog{
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: NullableDate;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;
  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean;
}

interface IgetEntrada {
  idparceiro: string;
  idplanoconta: string;
  idprodutor: number;
  idoperacoes: string;
  dataoperacao: Date;
  dataemissao: Date;
  numeronota: string;
  numerodoc: string;
  subtotal: number;
  desconto: number;
  outrasdespesas: number;
  totalnota: number;
  totalnfe: number;
  acrescimo: number;
  obs: string;
  cpf: string;

  pagas: string;
  boleto: string;
  itens: IitensEntradaDialog[];
}

interface IDetalheEntrada {
  idparceiro: string;
  idplanoconta: string;
  idoperacoes: string;
  dataemissao: Date;
  numeronota: string;
  numerodoc: string;
  subtotal: number;
  desconto: number;
  outrasdespesas: number;
  totalnota: number;
  acrescimo: number;
  obs: string; 

  cpf: string;
}

interface IItensids{
  iditens: string;
}

interface IParcelasFaturamento{
  idcontaspagar: string; 
  idparceiro: string; 
  idplanoconta: string; 
  idprodutor: number;  
  dataemissao: Date; 
  numerodoc: string; 
  numeronfe: string; 
  valornfe: number; 
  valornota: number; 
  historico: string; 
  lcdpr: boolean; 
  tipodoc: number; 
  tipopagamento: string; 
  historicolcdpr: string; 
  vencimento: Date; 
  valorparcela: number; 
  nfeparcela: number; 
  parcela: string; 
  nfepago: number; 
  nfepagar: number;
  valorpago: number; 
  valorpagar: number;
  tipo: number;
  desconto: number;
  acrescimo: number;
  idfazenda: string;

  qtdParcela: number;
}

type TotalCount = {
  data: IListagemEntradas[];
  totalCount: number;
}

interface ErrorResponse {
  data: {
    errors: {
      [key: string]: string;
    };
  };
}

const getAll = async (page = 1, dataInicial = '', dataFinal='', filter = '', tipo=''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/entradaestoque?page=${page}&limit=${17}&tipo=${tipo}&filter=${filter}&datainicial=${dataInicial}&datafinal=${dataFinal}`;
    const { data, headers } = await Apiestoque.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || 17),
      };
    }
  
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IgetEntrada | Error> => { 
  try {

    const { data } = await Apiestoque.get(`/entradaestoque/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheEntrada, dadositens: IitensEntradaDialog[], faturamento: IParcelasFaturamento[]): Promise<string | Error> => {
  
  try {
    const guuid = uuidv4();
    const dadosExtraidos = dadositens.map(({ descricao, localizacao, reqvencimento, filtro, ...restoDoObjeto }) => ({ idoperacao: guuid, 
      tipo: 1, forma: 1,  ...restoDoObjeto }));
    const Pfaturamento = faturamento.map(({ qtdParcela, ...restoDoObjeto }) => ({ identrada: guuid, ...restoDoObjeto }));
    const { cpf, ...dadosIDetalheEntrada } = dados;
    const dadosEntrada = {identrada: guuid, ...dadosIDetalheEntrada};

    const { data } = await Apiestoque.post<IDetalheEntrada>('/entradaestoque',  [dadosEntrada, dadosExtraidos, Pfaturamento]);
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lançar Entrada');
    }
  }
};

const updateById = async (id: string, dados: IDetalheEntrada, dadositens: IitensEntradaDialog[], iddeletados: IItensids[], faturamento: IParcelasFaturamento[]): Promise<void | Error> => {
  try {
    const dadosExtraidos = dadositens.map(({ descricao, localizacao, reqvencimento, filtro, ...restoDoObjeto }) => ({ idoperacao: id, tipo: 1, forma: 1, ...restoDoObjeto }));
    const { cpf, ...dadosIDetalheEntrada } = dados;
    const dadosEntrada = {identrada: id, ...dadosIDetalheEntrada};
    const Pfaturamento = faturamento.map(({ qtdParcela, ...restoDoObjeto }) => ({ identrada: id, ...restoDoObjeto }));

    await Apiestoque.put(`/entradaestoque/${id}`, [dadosEntrada, dadosExtraidos, iddeletados, Pfaturamento]);

  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/entradaestoque/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const EntradaService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};