import {  Box, Button, DialogActions, DialogContent, DialogTitle,  Grid,  LinearProgress,  MenuItem,  Paper, Select} from '@mui/material';
import { useState } from 'react';
import { RelatorioService } from '../../../shared/services/api/rh/Relatorios/RelatoriosService';
import aniversariantes from './Aniversariantes';
           
export const Aniversariante: React.FC = () => {                      
  const [isLoading, setIsLoading] = useState(false);         
  const [mes, setMes] = useState('Janeiro');            

  const handleSave = () => {      
    RelatorioService               
      .RelatorioAniversariantes(mes)   
      .then((result) => {           
        setIsLoading(false);              
        if (result instanceof Error) {                
          alert(result.message);                   
        }else {     
          if(result.length > 0){     
            aniversariantes(result, mes);     
          }else{
            alert('Não a Registros');       
          }                    
        }                    
      });                                   
  };            

  return (
    <Box  alignItems="center" width='auto' alignContent='center' height="auto"  display="flex" flexDirection="column"  >
      <Box  display="flex" alignItems="center" alignSelf='center' alignContent='center'></Box>
      <Box   width='auto' alignItems="auto"  component={Paper} height="auto" margin={1} 
        alignContent='center'>       
        <DialogTitle>Aniversariantes do Mes</DialogTitle>
        <DialogContent  dividers sx={{width:300}}>    
          {isLoading && (           
            <Grid item>  
              <LinearProgress variant='indeterminate' />  
            </Grid>                      
          )}   
          <Grid container   item direction="row">
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>    
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>        
                <Select         
                  fullWidth
                  size='small'
                  value={mes}
                  onChange={(event) => setMes(event.target.value)}
                >   
                  <MenuItem value={'Janeiro'}>Janeiro</MenuItem>    
                  <MenuItem value={'Fevereiro'}>Fevereiro</MenuItem>
                  <MenuItem value={'Março'}>Março</MenuItem>
                  <MenuItem value={'Abril'}>Abril</MenuItem>
                  <MenuItem value={'Maio'}>Maio</MenuItem>
                  <MenuItem value={'Junho'}>Junho</MenuItem>
                  <MenuItem value={'Julho'}>Julho</MenuItem>    
                  <MenuItem value={'Agosto'}>Agosto</MenuItem>
                  <MenuItem value={'Setembro'}>Setembro</MenuItem>
                  <MenuItem value={'Outubro'}>Outubro</MenuItem>
                  <MenuItem value={'Novembro'}>Novembro</MenuItem>
                  <MenuItem value={'Dezembro'}>Dezembro</MenuItem>
                </Select>           
              </Grid>                     
            </Grid>              
          </Grid>            
        </DialogContent>                 
        <DialogActions>
          <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={handleSave}>Visualizar</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   