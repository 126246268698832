/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, IconButton, Paper,Skeleton, Typography, useTheme } from '@mui/material';
import OutlinedCard2 from '../../../shared/forms/OutlinedCard2';
import { useEffect, useState } from 'react';
import { DashboardEstoqueService } from '../../../shared/services/api/estoque/dashboard/DashboardEstoqueService'; 
import { LayoutBaseDeDashboard } from '../../../shared/layouts';
import { getTemaSistema } from '../../../shared/contexts';
import OutlinedCard3 from '../../../shared/forms/OutlinedCard3';
import GraficoArea from './GraficoArea';
import GraficoRosca from './GraficoRosca';
import SearchIcon from '@mui/icons-material/Search';
import { AutoCompleteLocalizacao01, AutoCompleteProduto1 } from '../../../shared/forms';
import { ListagemDashboardEntrada } from './ListagemDashboardEntrada';
import { ListagemDashboardSaida } from './ListagemDashboardSaida';
import { error } from 'console';

export const DashboardProd = () => {
  const teme = getTemaSistema();
  const [tema, setTema] = useState<'dark' | 'light'>('light');
  const [loading, setLoading] = useState(false);
  const [localizacao, setLocalizacao] = useState(''); 
  const [idLocalizacao, setIdLocalizacao] = useState(''); 
  const [produtos, setProdutos] = useState(''); 
  const [idprodutos, setIdProdutos] = useState(''); 

  const theme = useTheme();

  useEffect(() => {
    if (teme === 'dark') {
      setTema('dark');
    } else {
      setTema('light');
    }
  }, [teme]); 
  const [Server, setServer] = useState(
    {
      entrada:0,
      saida:0,
      saldo:0,
      entradas: [],
      saidas: [],
      entradasaida:[],
    }
  );


  type Extrair = {
    titulo: string;
    valor: number;
  };

  function extrairNomes(topdez: Extrair[] | undefined): string[] {
    return topdez ? topdez.map(topdez => topdez.titulo) : [];
  }
  
  function extrairValores(topdez: Extrair[] | undefined): number[] {
    return topdez ? topdez.map(topdez => topdez.valor) : [];
  }

  type ExtrairValor = {
    valor: number;
  };
  function extrairValores2(titulo: ExtrairValor[]): number[] {
    return titulo ? titulo.map(titulo => titulo.valor) : [];
  }

  const seriesData = extrairValores(Server.entradasaida);
  const labels = extrairNomes(Server.entradasaida);

  const seriesEntrada = [
    {
      name: 'Entrada',
      data: extrairValores2(Server.entradas)   
    }
  ];

  const optionsDataItensEntrada = {
    title: {
      text: 'Entradas',
    },
    colors:['#00E396'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.entradas) 
    } 
  };

  const seriesSaida = [
    {
      name: 'Entrada',
      data: extrairValores2(Server.saidas)   
    }
  ];

  const optionsDataItensSaida = {
    title: {
      text: 'Saida',
    },
    colors:['#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.saidas) 
    } 
  };


  useEffect(() => {
    setLoading(true);
    if(idprodutos !== '' && idLocalizacao !== '' ){
      
      DashboardEstoqueService.DashboardProd(idprodutos, idLocalizacao)
        .then((result) => {
          setLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            console.log(result);
            setServer(result);
          }
        });
    }
  }, [idprodutos, idLocalizacao]);

  const handleuuidLocalizacao = (uuid: string | undefined) => {
    if (uuid != null) {
      setIdLocalizacao(uuid);
    }
  }; 

  const handleuuidLocalizacaotext = (descricao: string | undefined) => {
    if (descricao != null) {
      setLocalizacao(descricao);
    }
  }; 

  const handleuuidProdutos = (uuid: string | undefined) => {
    if (uuid != null) {
      setIdProdutos(uuid);
    }
  }; 

  const handleuuidProdutostext = (descricao: string | undefined) => {
    if (descricao != null) {
      setProdutos(descricao);
    }
  }; 

  return (
    <LayoutBaseDeDashboard
    >
      <Box
        gap={1}
        marginX={1}
        padding={1}
        paddingX={2}
        display="flex"
        alignItems="center"
        height={theme.spacing(8)}
        component={Paper}
      >
        <Grid container item direction="row" spacing={1}>  
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
            <Grid container item direction="row" spacing={1}>  
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>  
                <AutoCompleteLocalizacao01
                  idLocalizacao={handleuuidLocalizacao}
                  localizacaoText={handleuuidLocalizacaotext}
                /> 
              </Grid>  

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>  
                <AutoCompleteProduto1
                  idproduto={handleuuidProdutos}
                  descricao={handleuuidProdutostext}
                  combustivel='false'
                  filtro='descricao'
                /> 
              </Grid>  
            </Grid> 
          </Grid> 
        </Grid> 
      </Box>

      <Box display="flex" component={Paper}  margin={1} variant="outlined"  gap={1}>
        <Grid container item direction="row"  >
          <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
            {loading ? <Skeleton variant="rectangular" height={80}  width={270} /> : 
              <OutlinedCard3
                nome=" Entrada" 
                valor={Server.entrada} 
                cor="#82D2ED"
              />
            }
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
            {loading ? <Skeleton variant="rectangular" height={80} width={270}/> : 
              <OutlinedCard3
                nome="Saida" 
                valor={Server.saida} 
                cor="#FF7F7F"
              />
            }
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
            {loading ? <Skeleton variant="rectangular" height={80} width={270}/> : 
              <OutlinedCard3
                nome="Saldo" 
                valor={Server.saldo} 
                cor="#DEED82"
              />
            }
          </Grid>
        </Grid>
      </Box >

      <Box display="flex" component={Paper} margin={1} variant="outlined">
        <Grid container item direction="row" >
          <Grid  item xs={2.3} sm={12} md={12} lg={2.3} xl={2.3}>
            <Box margin={3.5}>
              {loading ? <Skeleton variant="circular" height={270} width={270}/> : 
                <GraficoRosca
                  series={seriesData}
                  chart={{
                    type: 'donut',
                  }}
                  width={320}
                  labels={labels}
                  themeMode={tema}
                />
              }
            </Box>
          </Grid>
          <Grid  item xs={12} sm={12} md={12} lg={4.7} xl={4.7}>
            <Box>
              {loading ? <Skeleton variant="rectangular" height={270} width={600}/> : 
                <GraficoArea
                  series={seriesEntrada} 
                  options={optionsDataItensEntrada}
                />
              }
            </Box>
          </Grid> 
          <Grid  item xs={12} sm={12} md={12} lg={5} xl={5}>
            <Box >
              {loading ? <Skeleton variant="rectangular" height={270} width={600}/> : 
                <GraficoArea
                  series={seriesSaida} 
                  options={optionsDataItensSaida}
                />
              }
            </Box>
          </Grid>                    
        </Grid>
      </Box>
      <Box display="flex" component={Paper} margin={1} variant="outlined">
        <Grid container item direction="row" >
          <Grid  item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Box>
              {loading ? <Skeleton variant="rectangular" height={270} width={800}/> : 
                <ListagemDashboardEntrada
                  idLocalizacao={idLocalizacao}
                  idprod={idprodutos}
                />
              }
            </Box>
          </Grid>
          <Grid  item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Box>
              {loading ? <Skeleton variant="rectangular" height={270} width={800}/> : 
                <ListagemDashboardSaida
                  idLocalizacao={idLocalizacao}
                  idprod={idprodutos}
                />
              }
            </Box>
          </Grid> 
                       
        </Grid>
      </Box>      
    </LayoutBaseDeDashboard>
  );
};