
export const MenuFiscal =  [
  {
    icon: 'dashboard_customize',
    path: '/dashboardlcdpr',
    label: 'Pagina Inicial',
  },
  {
    icon: 'event_note',
    path: '/nfe',
    label: 'NFE',
  },
  {
    icon: 'card_giftcard',
    path: '/produtosnfe',
    label: 'Produtos NF-e',
  },
  {
    icon: 'auto_stories',
    path: '/lcdpr',
    label: 'LCDPR',
  },
];  

export const MenuFiscalrelatorio =  [
  {
    icon: 'print',
    path: '/relatorionfe',
    label: 'NFE',
  },
  {
    icon: 'print',
    path: '/relatorioreceitadespesa',
    label: 'Receita Despesa',
  },
  {
    icon: 'print',
    path: '/relatoriolcdpr',
    label: 'LCDPR',
  }
];