
import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField,  VForm, useVForm, IVFormErrors, BasicSelectUF } from '../../../shared/forms';

import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';

import { SelectNumber } from '../../../shared/forms/Selectnumber';
import { Apicadastro } from '../../../shared/services/api/axioscadastro';
import { ReboqueService } from '../../../shared/services/api/cadastros/ReboqueService';

interface IFormData {
  idtransportadora: string;
  placa: string;
  renavam: string;
  capkg: number;
  capm3: number;
  tipocarroceria: number;
  uf: string;
  tara: number;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idtransportadora: yup.string().required(),
  placa: yup.string().required().min(7).max(7),
  renavam: yup.string().required().min(1).max(12),
  capkg: yup.number().required().moreThan(0),
  capm3: yup.number().required().moreThan(0),
  tipocarroceria: yup.number().required(),
  uf: yup.string().required().min(2).max(2),
  tara: yup.number().required().moreThan(0),
});


export const DetalheDeReboques: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { idTransportadora = 'novo' } = useParams<'idTransportadora'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
 
  const [nomeTransportadora, setNomeTransportadora] = useState('');

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      ReboqueService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate(`/reboques?idtransportadora=${idTransportadora}`);
          } else {
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        idtransportadora: {idTransportadora},
        placa: '',
        renavam: '',
        capkg: '',
        capm3: '',
        tipocarroceria: '',
        uf: '',
        tara: '',
      });
    }
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Apicadastro.get(`/transportadoras/${idTransportadora}`);
        const transportadorasData = response.data;

        setNomeTransportadora(transportadorasData.nome);
          
      } catch (error) {
        setIsLoading(false);
        alert('Erro ao obter nome da Transportadora.');
      }
    };
  
    fetchData();
  }, [id, idTransportadora]);
  
  const handleSave = (dados: IFormData) => {
    const novosDados: IFormData = {
      ...dados,
      idtransportadora: idTransportadora,
    };
    formValidationSchema.
      validate(novosDados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          ReboqueService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate(`/reboques?idtransportadora=${idTransportadora}`);
                } else {
                  navigate(`/reboques/detalhe/${result}/${idTransportadora}`);
                }
              }
            });
        } else {
          ReboqueService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate(`/reboques?idtransportadora=${idTransportadora}`);
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ReboqueService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate(`/reboques?idtransportadora=${idTransportadora}`);
          }
        });
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Semirreboque' : nomeTransportadora}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate(`/reboques?idtransportadora=${idTransportadora}`)}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate(`/reboques/detalhe/novo/${idTransportadora}`)}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}


            <Grid container item direction="row" spacing={2}>
              

              <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                <TextField
                  fullWidth
                  value={nomeTransportadora}
                  disabled={true}
                  label='Nome/Razão Social'
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                <VTextField
                  fullWidth
                  name='placa'
                  disabled={isLoading}
                  label='Placa'
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>          
                <BasicSelectUF
                  name='uf'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                <VTextField
                  fullWidth
                  name='renavam'
                  disabled={isLoading}
                  label='Renavam'
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={3} lg={2.5} xl={2}>          
                <VTextField
                  fullWidth
                  name='tara'
                  disabled={isLoading}
                  label='Tara'
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={3} lg={2.5} xl={2}>          
                <VTextField
                  fullWidth
                  name='capkg'
                  disabled={isLoading}
                  label='Capacidade Kg'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>          
                <VTextField
                  fullWidth
                  name='capm3'
                  disabled={isLoading}
                  label='Capacidade M³'
                />
              </Grid>
              
              <Grid item xs={6} sm={12} md={6} lg={5} xl={2}>          
                <SelectNumber
                  data={[
                    { nameBD: 0, descricao: 'Não aplicável' },
                    { nameBD: 1, descricao: 'Aberta' },
                    { nameBD: 2, descricao: 'Fechada' },
                    { nameBD: 3, descricao: 'Graneleira' },
                    { nameBD: 4, descricao: 'Porta Contêiner' },
                    { nameBD: 5, descricao: 'Sider' },
                  ]}
                  label='Tipo de Carroceria'
                  name='tipocarroceria' 
                  disabled={isLoading}
                />
              </Grid>  
              
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};