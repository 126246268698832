import axios, { AxiosError } from 'axios';


export const errorInterceptor = (error: AxiosError) => {

   
  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'));
  }

  if (error.response?.status === 401) {
    localStorage.removeItem('APP_ACCESS_TOKEN');
    axios.defaults.headers.authorization = null;
    window.location.reload(); 
  }

  if (error.response?.status === 404) {
    localStorage.removeItem('APP_ACCESS_TOKEN');
    axios.defaults.headers.authorization = null;
    window.location.reload(); 
  }

  if (error.response?.status === 402) {
    //
  }

  if (error.response?.status === 400) {
    return Promise.reject(error.response);
  }


  return Promise.reject(error);
};