import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemMunicipios, MunicipiosService } from '../../../shared/services/api/cadastros/MunicipiosService';
import { DialogMunicipios } from './DialogMunicipios';





export const ListagemDeMunicipios: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  //const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemMunicipios[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const [open, setOpen] = useState(false);
  const [detalheterceiro, setdetalheterceiro ] = useState<string>('novo');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose = (newValue?: string) => {
    setOpen(false);
  };

  
  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      MunicipiosService.getAll(pagina, filter)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [pagina, filter, open]);

 
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Municípios'
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={filter}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => {setdetalheterceiro('novo'); setOpen(true);}}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, pagina: '1' }, { replace: true })}
          
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>UF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idmunicipio} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => {setdetalheterceiro(row.idmunicipio); setOpen(true);}}
              >
                <TableCell>{row.idmunicipio}</TableCell>
                <TableCell>{row.cidade}</TableCell>
                <TableCell>{row.uf}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_CADASTRO) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_CADASTRO)}
                    onChange={(_, newPage) => setSearchParams({ filter, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <DialogMunicipios
        id={detalheterceiro}
        value={''}  
        open={open} 
        onClose={handleClose}
      /> 
    </LayoutBaseDePagina>
  );
};