
import { Api } from '../../axios-config';

export interface IDetalheconfiguracoes {
  valorfaixa1: number;
  valorfaixa2: number;
  valorfaixa3: number;
  valorfaixa4: number;
  faixa2:number;
  aliquota1: number;
  aliquota2: number;
  aliquota3: number;
  aliquota4: number;
  remuneracao: number;
  idade: number;
  almoco: number;
  cafe: number;
  faltas1 :number;
  faltas2 :number;
  alivalor1 :number;
  alivalor2 :number;
  alivalor3 :number;
}

const getAll = async (page = 1, ): Promise<IDetalheconfiguracoes | Error> => {
  try {
    const urlRelativa = `/configuracoes?page=${page}}`;

    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data;
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};
const updateById = async (id: string, dados:IDetalheconfiguracoes): Promise<void | Error> => {
  try {
    await Api.put(`/configuracoes/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

export const Configuracoes = {
  getAll,
  updateById 
};